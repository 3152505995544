import type {
  InteractionSourceRaw,
  InteractionSourceValue,
} from '@/composables/useInteractionSource'

import { dataLayerPush } from './utils'
import type { MappedProduct } from './utils/mapProductGtm'

type AddToCartProps = {
  event: 'add_to_cart' | 'remove_from_cart'
  item_list_name: string
  position: {
    name: InteractionSourceRaw[]
    type: InteractionSourceValue
  }
  ecommerce: {
    currency: string
    value: number
    items: MappedProduct[]
    widget_name: string
    widget_full_path: string
    widget_code: string
    search_term: string
  }
}

function addToCart(addToCartProps: AddToCartProps) {
  dataLayerPush(addToCartProps)
}

export { addToCart as gtmEventAddToCart }
export type { AddToCartProps }
