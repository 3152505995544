import { dataLayerPush } from './utils'

function addContactInfo() {
  dataLayerPush({
    event: 'add_contact_info',
    checkout: {
      step_number: 3,
    },
  })
}

export { addContactInfo as gtmEventAddContactInfo }
