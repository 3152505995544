import type { Product } from '@/composables/useProductItem'

/**
 * Check whether provided product is currently unavailable to be bought.
 * @param {object} product Product to be checked
 * @returns {boolean} Indicates sold-out state.
 */
function isProductSoldOut(product: Product) {
  return product.origin.maxInCart === 0
}

/**
 * Check whether provided product is currently unavailable to be bought in the amount user requires by the amount of the product in their cart, but not completely sold out.
 * @param {object} product Product to be checked
 * @returns {(boolean|undefined)} Indicates the state when user requires bigger than available non-zero amount. Undefined when product is not in cart.
 */
function isProductPartiallySoldOut(product: Product) {
  if (!product?.cart) {
    return
  }
  // @todo type-fix
  return product.origin.maxInCart > 0 && product.cart.quantity! > product.origin.maxInCart
}

export { isProductSoldOut, isProductPartiallySoldOut }
