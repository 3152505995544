<script setup lang="ts">
import { ref } from 'vue'
import { useRouter } from 'vue-router'

import AddressInputBox from '@/components/AddressInputBox'
import UserLogin from '@/components/UserLogin'
import { BaseButton } from '@/componentsPure'
import type { ValidatePhase } from '@/data/api/frontApi/address/addressTypes'
import { t } from '@/services/translations'
import useAddressStore, { type UserAddress } from '@/store/pinia/useAddressStore'

const router = useRouter()
const { changeUserAddress } = useAddressStore()

const isAddressReachable = ref(false)
const handleChangeInputAddress = (phase: ValidatePhase) => {
  isAddressReachable.value = phase === 'available'
}

const addressNew = ref<UserAddress>({
  street: '',
  city: '',
  zip: '',
  gps: null,
  address: '',
  note: '',
})
async function acceptAddress() {
  if (isAddressReachable.value) {
    await changeUserAddress({ address: addressNew.value })
    const homepagePath = router.resolve({ name: 'Home' }).path

    window.location.href = window.location.origin + homepagePath
  }
}
</script>

<template>
  <div class="d-flex flex-column flex-md-row align-items-start">
    <AddressInputBox
      v-model="addressNew"
      class="address-selector"
      show-user-addresses
      show-status-messages
      accept-partial-address
      @address-validated="handleChangeInputAddress">
      <template #submit>
        <BaseButton
          variant="super-primary"
          size="md"
          data-tid="welcome-page-confirm-address"
          @click="acceptAddress"
          v-text="t('addressBannerInput.confirm')" />
      </template>
    </AddressInputBox>
  </div>
  <p class="text-5 mt-3 mb-1 mb-md-3">
    {{ t('addressBannerInput.knowYou') }}
    <UserLogin
      class="a-link-underline address-selector__login"
      data-tid="welcome-page-login"
      redirect-name="Home">
      {{ t('general.login') }}
    </UserLogin>
  </p>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.address-selector {
  width: 100%;
  color: color('black');

  &__login {
    color: color('white');

    @include media-breakpoint-up(lg) {
      color: color('primary');
    }
  }
}

:deep(.address-input-box__input-wrapper) {
  @include media-breakpoint-up(md) {
    width: 450px;
  }
}

:deep(.address-input-box__form) {
  @include media-breakpoint-up(md) {
    flex-wrap: nowrap;
  }
}
</style>
