import { Vendors, type VendorID } from '@/constants/vendors'
import appConfig from '@/services/appConfig'

import { fetchGet } from '../fetch'
import type { SuggestResponseRaw } from './suggestTypes'

/**
 * Sends GET request for suggested results.
 * @see https://sasandboxstdhotapidocs.z1.web.core.windows.net/#/Suggest/readSuggest
 * @param searchTerm Search term.
 * @param limit Max amount of returned products. Default is 10.
 * @param vendorId Vendor ID. Default is 1 (BASE).
 * @returns Promise of the API response with the suggested results.
 */
function getSuggest(
  searchTerm: string,
  vendorId: VendorID = Vendors.BASE,
  limit: number = appConfig.defaultSuggestionsProductCount,
) {
  return fetchGet<SuggestResponseRaw>(appConfig.frontApiBaseUrl + 'suggest', {
    query: searchTerm,
    limit,
    vendor: vendorId,
  })
}

export { getSuggest as frontApiGetSuggest }
