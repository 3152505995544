<script setup lang="ts">
import { computed, onMounted, ref, useSlots } from 'vue'

import { nanoid } from 'nanoid'
import { Field as VeeField } from 'vee-validate'

type FormTextareaProps = {
  modelValue: string
  placeholder: string
  label: string
  disabled?: boolean
  id?: string
  autofocus?: boolean
  /**
   * Name for input, used in VeeField component, must be unique.
   */
  name?: string
  /**
   * Validation rules for VeeField component.
   */
  rules?: string | Record<string, unknown>
}

const props = withDefaults(defineProps<FormTextareaProps>(), {
  id: () => `textarea-${nanoid()}`,
  name: undefined,
  rules: undefined,
})

const emit = defineEmits<{
  'update:modelValue': [newValue: string]
}>()

const inputValue = computed({
  get: () => props.modelValue,
  set: (newValue) => {
    emit('update:modelValue', newValue)
  },
})

const componentSlots = useSlots()
const isLabel = computed(() => !!(componentSlots.default?.() || props.label))

const input = ref<HTMLElement | null>(null)
onMounted(() => {
  if (props.autofocus) {
    input.value?.focus()
  }
})
</script>

<template>
  <div>
    <label
      v-if="isLabel"
      class="label"
      :for="id">
      <slot v-if="!!componentSlots.default?.()" />
      <template v-else>
        {{ label }}
      </template>
    </label>
    <VeeField
      v-if="rules"
      :id="id"
      v-slot="slots"
      v-model="inputValue"
      :name="props.name?.length ? props.name : props.id"
      :rules="rules">
      <textarea
        v-if="slots"
        ref="input"
        v-bind="slots.field"
        :class="[
          'form-text form-textarea a-textarea',
          {
            'validation-errors__input': slots.errors[0],
          },
        ]"
        :disabled="disabled"
        :placeholder="placeholder"
        type="text" />
      <span
        v-if="slots"
        class="validation-errors"
        v-text="slots.errors[0]" />
    </VeeField>

    <textarea
      v-else
      :id="id"
      ref="input"
      v-model="inputValue"
      type="text"
      :class="[
        'form-text a-textarea p-3',
        {
          'form-text--disabled': props.disabled,
        },
      ]"
      :placeholder="placeholder"
      :disabled="disabled" />
  </div>
</template>

<style src="../styles.scss" lang="scss" />
<style src="./styles.scss" lang="scss" scoped />
<style lang="scss" scoped>
.form-textarea {
  height: unset; // unset fixed height from .form-text
  min-height: 44px;
  padding: 10px;
}
</style>
