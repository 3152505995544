<script setup lang="ts">
import { computed } from 'vue'

import { CLUBS } from '@/components/Clubs/constants'
import Icon from '@/components/Icon/Icon.vue'
import ImgResponsive from '@/components/stories/atoms/imgResponsive/ImgResponsive.vue'
import useUser from '@/composables/useUser'
import { t } from '@/services/translations'

type CoverBenjaminekProps = {
  age: number
  amount: number
  discountsUrl: string
}

const props = defineProps<CoverBenjaminekProps>()

const { getUserClubEligibility } = useUser()

const isMember = computed(() => getUserClubEligibility[CLUBS.BENJAMINEK].isMember)
const isEligible = computed(() => getUserClubEligibility[CLUBS.BENJAMINEK].isEligible)

const isMemberAndEligible = computed(() => isMember.value && isEligible.value)
const isMemberAndNotEligible = computed(() => isMember.value && !isEligible.value)

const getEligibilityDescriptionText = computed(() => {
  if (isMemberAndEligible.value) return 'inClubIsEligible'
  return isMemberAndNotEligible.value ? 'inClubNotEligible' : 'notInClub'
})
</script>

<template>
  <div class="grid-container">
    <div class="cover-container" />
    <div
      class="header-container d-flex flex-column flex-sm-row align-items-sm-center justify-content-center px-2 py-sm-4">
      <div class="d-flex align-items-center justify-content-center text-white mt-4 mb-2 m-sm-0">
        <Icon
          icon="benjaminek"
          group="clubs"
          class="icon flex-shrink-0 mr-2" />
        <h1 class="header__club-name d-flex flex-column text-uppercase font-weight-bold m-0">
          <span
            class="text-6"
            v-text="t('kidsClub.cover.name.kidsClub')" />
          <span
            class="text-9"
            v-text="t('kidsClub.cover.name.benjaminek')" />
        </h1>
      </div>
      <hr class="header__separator" />
      <h2
        class="header__tagline text-5 text-uppercase font-weight-bold mx-auto mt-2 mb-3 m-sm-0"
        v-html="t('kidsClub.cover.tagline')" />
    </div>
    <div class="content-container text-center font-weight-bold">
      <div
        class="eligibility-title text-5 text-gray-darker text-uppercase bg-white p-2 mx-3 mx-sm-5 mb-3"
        v-text="
          isMember
            ? t('kidsClub.cover.eligibility.title.inClub')
            : t('kidsClub.cover.eligibility.title.notInClub', { age: props.age })
        " />
      <div
        class="eligibility-description text-4 text-white mx-2 mx-sm-5 mb-3"
        v-text="
          t(`kidsClub.cover.eligibility.description.${getEligibilityDescriptionText}`, {
            amount: props.amount,
          })
        " />
      <div class="mb-4">
        <RouterLink
          :to="isMember ? props.discountsUrl : { name: 'KidsClub' }"
          class="button d-inline-block text-3 text-black text-uppercase text-center bg-secondary border-0 rounded px-3 py-2"
          v-text="t(`kidsClub.cover.button.${isMember ? 'discount' : 'register'}`)" />
      </div>
    </div>
    <div class="image-container">
      <ImgResponsive
        :srcset="[
          {
            url: '/vue/img/clubs/benjaminek.webp',
          },
          {
            pixelRatio: 1.5,
            url: '/vue/img/clubs/benjaminek@x2.webp',
          },
        ]"
        class="image"
        lazyload
        :alt="t('kidsClub.title')" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.grid-container {
  display: grid;
}

.cover-container {
  $border-radius: 40px;

  grid-row: 1 / 5;
  grid-column: 1 / 3;
  height: 90%;
  background-color: color('pink-dark');
  border-top-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;

  @include media-breakpoint-up(sm) {
    height: 100%;
  }

  @include media-breakpoint-up(xl) {
    grid-row: 2 / 4;
    grid-column: 1 / 5;
  }
}

.header {
  &-container {
    grid-row: 1;
    grid-column: 1 / 3;
    align-self: stretch;
    justify-items: stretch;

    @include media-breakpoint-up(sm) {
      grid-row: 1 / 2;
    }

    @include media-breakpoint-up(xl) {
      grid-row: 2;
      grid-column: 1;
    }
  }

  &__club-name {
    line-height: 0.9;
  }

  &__separator {
    width: 221px;
    height: 1px;
    margin: 0 auto;
    background-color: color('white');
    border: 0;

    @include media-breakpoint-up(sm) {
      width: 1px;
      height: 46px;
      margin: auto 26px;
      background-color: color('white');
    }
  }

  &__tagline {
    line-height: 1.2;
    color: color('white', 0.6);
  }
}

.content-container {
  grid-row: 2;
  grid-column: 1 / 3;

  @include media-breakpoint-up(sm) {
    grid-column: 1;
  }

  @include media-breakpoint-up(xl) {
    grid-row: 3 / 4;
  }
}

.image-container {
  grid-row: 3;
  grid-column: 1 / 3;

  @include media-breakpoint-up(sm) {
    grid-row: 2;
    grid-column: 2;
  }

  @include media-breakpoint-up(xl) {
    grid-row: 1 / 5;
  }
}

.icon {
  $size: 44px;

  width: $size;
  height: $size;
}

.eligibility-title {
  line-height: 1.2;
}

.button {
  min-width: 197px;
  height: 36px;

  &:focus,
  &:active {
    text-decoration: none;
  }
}

.image {
  width: 184px;
  margin: 0 auto;

  @include media-breakpoint-up(sm) {
    width: 160px;
    margin-right: 35px;
    margin-bottom: 20px;
  }

  @include media-breakpoint-up(xl) {
    width: 389px;
    margin: 0 30px 0 0;
  }
}
</style>
