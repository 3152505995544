<script setup lang="ts">
import { ref, computed } from 'vue'

import useInteractionSource from '@/composables/useInteractionSource'
import type { Product } from '@/composables/useProductItem'
import { analyticsLoggerWidgetContent } from '@/services/analytics/analyticsLogger'
import useProductsStore from '@/store/pinia/useProductsStore'

import Widget from './components/Widget.vue'

const YOTTLY_CODES = ['cartComplement']

type WidgetAdditionalContentProps = {
  code: string
  border?: boolean
  noProductMsg: string
}

const props = defineProps<WidgetAdditionalContentProps>()

const { fetchPersonalizedProducts, fetchProductWidget } = useProductsStore()

const products = ref<Product[]>()
const yottlyProductIds = ref<number[]>([])

const loadProducts = async () => {
  const code = props.code
  let newProducts: Product[]

  if (YOTTLY_CODES.includes(code)) {
    const fetchedPersonalizedProducts = await fetchPersonalizedProducts(code)
    yottlyProductIds.value = fetchedPersonalizedProducts.yottlyProductIds
    newProducts = fetchedPersonalizedProducts.products
  } else {
    const fetchedProductWidget = await fetchProductWidget({ code })
    analyticsLoggerWidgetContent(fetchedProductWidget)
    newProducts = fetchedProductWidget.products
  }
  products.value = newProducts
}
const componentName = 'WidgetAdditionalContent'
const interactionSourceData = { code: componentName }

const yottly = computed(() => ({
  products: yottlyProductIds.value,
}))

useInteractionSource(componentName, interactionSourceData, yottly)
</script>

<template>
  <div class="d-sm-flex justify-content-center">
    <div class="additional-content">
      <slot />
    </div>
    <Widget
      v-if="props.code"
      :data-tid="`widget-${props.code}`"
      :products="products"
      :border="props.border"
      :no-product-msg="props.noProductMsg"
      class="widget"
      @intersecting="loadProducts" />
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

$width-sm: 254px;
$width-md: 340px;

.additional-content {
  @include media-breakpoint-up('sm') {
    max-width: $width-sm;
  }

  @include media-breakpoint-up('md') {
    max-width: $width-md;
  }
}

.widget {
  @include media-breakpoint-up('sm') {
    max-width: calc(100% - $width-sm);
  }

  @include media-breakpoint-up('md') {
    max-width: calc(100% - $width-md);
  }
}
</style>
