import type { ApiEmptyResponse } from '@/services/api/types'
import appConfig from '@/services/appConfig'

import { fetchPost } from '../fetch'

/**
 * Sends POST request for phone number validation.
 * @see https://sasandboxstdhotapidocs.z1.web.core.windows.net/#/Phone/createValidate
 * @param phoneNumber Phone number.
 * @returns Promise of the API response with the status of phone number validation.
 */
function postValidate(phoneNumber: string) {
  return fetchPost<ApiEmptyResponse>(appConfig.frontApiBaseUrl + 'phone/validate', undefined, {
    number: phoneNumber,
  })
}

export { postValidate as frontApiPostPhoneValidate }
