<script setup lang="ts">
import { computed, ref } from 'vue'

import { BaseTag } from '@/componentsPure'
import { resizer } from '@/services/resizer'
import { t } from '@/services/translations'
import useModalStore from '@/store/pinia/useModalStore'
import localizePrice from '@/utils/localizePrice'

import VoucherInput from './VoucherInput.vue'
import type { GiftVoucher } from './giftVouchersTypes'

type VoucherProps = {
  voucher: GiftVoucher
}
const props = defineProps<VoucherProps>()

const { openModal, MODAL_NAMES } = useModalStore()

const voucherCode = ref(props.voucher.voucher.code)
const isFutureVoucher = props.voucher.voucher.isFuture

const showVoucherCode = computed(() => !isFutureVoucher && voucherCode.value)
</script>

<template>
  <div class="wrapper bg-white p-3">
    <div class="position-relative mb-3">
      <img
        :src="resizer(props.voucher.image, 290 * 2, 176 * 2)"
        :alt="props.voucher.title"
        class="image"
        lazyload />

      <BaseTag
        v-if="props.voucher.voucher.percent"
        size="sale"
        modifier="strong secondary sale"
        class="image-tag">
        {{ props.voucher.voucher.percent }}
      </BaseTag>
      <BaseTag
        v-else-if="props.voucher.voucher.sale"
        size="sale"
        modifier="strong secondary sale"
        class="image-tag">
        {{ localizePrice(props.voucher.voucher.sale, { maximumFractionDigits: 0 }) }}
      </BaseTag>
    </div>

    <p class="text-5 font-weight-bold p-0 mb-2">{{ props.voucher.title }}</p>
    <p class="text-4 p-0 mb-3">
      <span v-html="props.voucher.description" />
      <span
        class="a-link-underline pointer"
        @click="
          openModal({
            id: MODAL_NAMES.voucherConditionsModal,
            triggerOptions: {
              conditions: voucher.conditions,
              voucherCode,
              showVoucherCode,
            },
          })
        ">
        {{ t('profile.giftsClub.conditionsButton') }}
      </span>
    </p>

    <BaseTag
      v-if="props.voucher.voucher.repeatUse"
      size="big"
      modifier="blue"
      class="mb-3">
      {{ t('profile.giftsClub.repeatUse') }}
    </BaseTag>

    <p
      v-if="isFutureVoucher"
      class="text-4 font-weight-bold text-center p-2 mb-3">
      {{ t('profile.giftsClub.voucherValidFrom') }}
      {{ props.voucher.voucher.formattedStartDate }}
    </p>
    <VoucherInput
      v-if="showVoucherCode"
      :model-value="voucherCode"
      class="mb-3" />

    <div
      v-if="props.voucher.url"
      class="text-center">
      <a
        :href="props.voucher.url"
        class="a-link-underline text-4 text-primary font-weight-bold p-2">
        {{ t('profile.giftsClub.showOfferLink') }}
      </a>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.wrapper {
  border-radius: var(--borderRadiusMedium);
}

.image {
  width: 100%;
  height: 176px;
  object-fit: cover;
  border-radius: var(--borderRadiusSmall);
}

.image-tag {
  position: absolute;
  top: 12px;
  left: 10px;
}
</style>
