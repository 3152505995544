const QueryTime = {
  /**
   * For data that is updated frequently.
   */
  MINUTE: 1000 * 60, // minute
  /**
   * For data that is updated infrequently.
   */
  HOUR: 1000 * 60 * 60, // hour
  /**
   * For data that is almost never updated.
   */
  MAX: 2147483647, // 24,8 days, max value for setTimeout
  /**
   * Default cache time.
   */
  TEN_MINUTES: 1000 * 60 * 10, // 10 minutes
} as const

export default QueryTime
