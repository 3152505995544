import { createOperation } from '@/services/apiv2'

import type { FrontApiEmptyResponse } from '../../frontApi/frontApiTypes'
import { modulePath } from './notificationsTypes'

type NotificationsDeletePath = {
  notificationId: string
}

type NotificationsDeletionRequest = {
  reason: string
}

type NotificationsDeleteParam = {
  customerId?: number
}

const notificationsDelete = createOperation<
  FrontApiEmptyResponse,
  FrontApiEmptyResponse,
  NotificationsDeletePath,
  NotificationsDeletionRequest,
  NotificationsDeleteParam
>(`${modulePath}/:notificationId/deletion`, {
  method: 'put',
})

export default notificationsDelete
