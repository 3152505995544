<script setup lang="ts">
import { ref, computed } from 'vue'

import { useTimeoutFn } from '@vueuse/core'
import dayjs from 'dayjs'
import { storeToRefs } from 'pinia'

import Icon from '@/components/Icon/Icon.vue'
import { BaseButton, Alert } from '@/componentsPure'
import useConfig from '@/composables/useConfig'
import { LocalStorageKeys } from '@/constants/storageKeys'
import { storageRetrieveLocal, storagePersistLocal } from '@/services/storage'
import { t } from '@/services/translations'
import useAddressStore from '@/store/pinia/useAddressStore'
import useCartStore from '@/store/pinia/useCartStore'
import useUserInterfaceStore from '@/store/pinia/useUserInterfaceStore'

import useAddressSelectorButton from '../AddressSelector/useAddressSelectorButton'
import WarehouseText from './WarehouseText.vue'

type WarehouseInfoBarProps = {
  cartFullWidth?: boolean
}

const props = defineProps<WarehouseInfoBarProps>()

const { isUserStreetOrCityFilled } = storeToRefs(useAddressStore())
const { isCartPreviewOpenDesktop } = storeToRefs(useUserInterfaceStore())

const { open } = useAddressSelectorButton()
const configData = useConfig()

const cartStore = useCartStore()
const { cartGetters } = storeToRefs(cartStore)

const isAddressOk = ref(false)
const isClosed = ref(false)

// time, after which the component closes
const componentCloseTime = 3000

type WarehouseClick = {
  currentTime: string
  warehouseId: number
}

function acceptAddress() {
  isAddressOk.value = true
  useTimeoutFn(() => {
    isClosed.value = true
  }, componentCloseTime)
  const currentTime = dayjs().toISOString()
  const warehouseClick: WarehouseClick = {
    currentTime: currentTime,
    warehouseId: configData.value.cart.warehouseId,
  }
  storagePersistLocal(LocalStorageKeys.warehouseClick, warehouseClick)
}

const storedInfo = storageRetrieveLocal<WarehouseClick>(LocalStorageKeys.warehouseClick)
const isVisible = computed(() => {
  if (
    !cartGetters.value.isCartLoaded ||
    !configData.value.cart.priceZone.customerName ||
    isUserStreetOrCityFilled.value ||
    isClosed.value
  )
    return false

  if (!storedInfo || storedInfo.warehouseId !== configData.value.cart.warehouseId) return true

  const storedDate = dayjs(storedInfo.currentTime)
  const currentDate = dayjs()
  // if the stored date in storage is a day or more old, return set isVisible=true
  return storedDate.isBefore(currentDate, 'day')
})

const addressStatus = computed(() => {
  return isAddressOk.value
    ? ({
        alertColor: 'success',
        iconType: 'ok',
        iconStyle: 'text-green',
      } as const)
    : ({
        alertColor: 'warning',
        iconType: 'address-pointer',
        iconStyle: 'text-primary',
      } as const)
})
</script>

<template>
  <Alert
    v-if="isVisible"
    :severity="addressStatus.alertColor"
    :class="[
      'd-flex flex-wrap align-item-center my-lg-2 bar-width',
      {
        'bar-width-cart-closed': !isCartPreviewOpenDesktop || props.cartFullWidth,
      },
    ]">
    <div
      class="address-icon-bg flex-shrink-0 d-flex justify-content-center align-items-center mr-3 my-md-auto">
      <Icon
        :icon="addressStatus.iconType"
        :class="`icon-size ${addressStatus.iconStyle}`" />
    </div>

    <WarehouseText
      :is-confirmed="isAddressOk"
      class="warehouse-text" />

    <div
      v-if="!isAddressOk"
      class="warehouse-buttons d-flex d-sm-block justify-content-end mt-3 my-sm-auto">
      <BaseButton
        class="mx-2"
        variant="primary"
        size="sm"
        data-tid="switchwarehouse-address"
        @click="open('warehouse-info-bar')">
        {{ t('warehouseInfoBar.buttonText') }}
      </BaseButton>
      <BaseButton
        class="mx-2"
        variant="neutral-light"
        size="sm"
        data-tid="switchwarehouse-address"
        @click="acceptAddress">
        OK
      </BaseButton>
    </div>
  </Alert>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.warehouse-text {
  flex: 1;
}

.icon-size {
  $size: 20px;

  width: $size;
  height: $size;
}

.bar-width {
  width: 100%;

  @include media-breakpoint-up(lg) {
    width: calc(100% + 8px);
  }
}

.bar-width-cart-closed {
  @include media-breakpoint-up(lg) {
    width: calc(100% - var(--previewCartWidth));
  }
}

.address-icon-bg {
  $icon-size: 48px;

  width: $icon-size;
  height: $icon-size;
  background-color: color('white');
  border-radius: var(--borderRadiusMedium);
}

.warehouse-buttons {
  flex: 0 100%;

  @include media-breakpoint-up(sm) {
    flex: none;
  }
}
</style>
