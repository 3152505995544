import { createOperation } from '@/services/apiv2'

import type { ClientAnonymResponse } from './clientTypes'

type ClientAnonymUuid = ClientAnonymResponse['client']['uuid']

const anonym = createOperation<ClientAnonymResponse, ClientAnonymUuid>('/client/anonym', {
  method: 'post',
  responseConverter(data) {
    return data.client.uuid
  },
})

export default anonym
