<script setup lang="ts">
import { useRoute } from 'vue-router'

export type NavBreadcrumbItem = {
  name: string
  id?: number | null
  url?: string
  tag?: string
  class?: string
}

type NavBreadcrumbProps = {
  /**
   * Items of the navigation - the individual "breadcrumbs" - objects containing url and name keys.
   */
  items: NavBreadcrumbItem[]
  /**
   * What to separate the items with
   */
  separator?: string
  /**
   * What tag will be rendered for each item
   */
  crumbTag?: string
  /**
   * Information if the breadcrumb fetch failed
   */
  fetchFailed?: boolean
  /**
   * Prevent link for current page
   */
  disallowSelfLink?: boolean
}

const props = withDefaults(defineProps<NavBreadcrumbProps>(), {
  separator: '>',
  crumbTag: 'router-link',
})

const route = useRoute()

function isItemSelfLink(item: NavBreadcrumbItem) {
  return item.url === route.fullPath
}

function getItemComponent(item: NavBreadcrumbItem) {
  if (props.disallowSelfLink && isItemSelfLink(item)) return 'span'
  return item.tag || props.crumbTag
}
</script>

<template>
  <nav
    class="breadcrumb"
    itemscope
    itemtype="https://schema.org/BreadcrumbList"
    :data-tid="$attrs['data-tid'] || 'breadcrumbs'">
    <template v-if="fetchFailed">
      <div class="failed-spacer"></div>
    </template>

    <template v-else-if="!items.length">
      <span class="a-placeholder-text mr-2">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span class="a-placeholder-text mr-2">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span class="a-placeholder-text">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
    </template>

    <template v-else>
      <template
        v-for="(item, index) in props.items"
        :key="index">
        <span
          :class="['breadcrumb__item', item.class]"
          itemprop="itemListElement"
          itemscope
          itemtype="https://schema.org/ListItem">
          <Component
            :is="getItemComponent(item)"
            :href="item.url"
            :to="item.url"
            itemprop="item"
            itemscope
            itemtype="https://schema.org/WebPage"
            :itemid="item.url"
            class="breadcrumb__link">
            <span
              itemprop="name"
              v-html="item.name" />
          </Component>
          <meta
            itemprop="position"
            :content="(index + 1).toString()" />
        </span>
        <span
          v-if="props.separator && index !== items.length - 1"
          :class="[
            'breadcrumb__separator',
            {
              'text-primary': props.separator === 'arrow',
            },
          ]"
          v-text="props.separator === 'arrow' ? '\u2192' : props.separator" />
      </template>
    </template>
  </nav>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.failed-spacer {
  // TODO: 19.5px; This is a workaround for the fact that the skeleton which uses text
  // Remove in KNW-14308
  width: 16px;
  min-height: 19.5px;
}

.breadcrumb {
  $this: &;

  &__item,
  &__separator {
    margin-right: em(4);
    vertical-align: middle;
  }

  a#{$this}__link {
    transition: color var(--fastTransitionTime);

    &:hover {
      color: color('primary');
    }
  }
}
</style>
