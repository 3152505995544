<script setup lang="ts">
import { ref, computed } from 'vue'

import ProductDetailFieldList from '@/components/Product/Detail/components/ProductDetailFieldList.vue'
import CTable from '@/components/Product/Detail/components/Table/ProductDetailTable.vue'
import ProductCarousel from '@/components/Product/ProductCarousel.vue'
import type { Product } from '@/composables/useProductItem'
import { t, tc } from '@/services/translations'
import useDeviceDetectorStore from '@/store/pinia/useDeviceDetectorStore'
import useProductsStore from '@/store/pinia/useProductsStore'
import { isProductSoldOut } from '@/store/utils/productAvailability'

type ProductDetailTabsProps = {
  product: Product
  isDisplayIngredients?: boolean
  isDisplaySimilarProducts?: boolean
}
const props = defineProps<ProductDetailTabsProps>()

const deviceDetectorStore = useDeviceDetectorStore()
const { fetchProductRelatedProducts } = useProductsStore()

const TAB_NAMES = {
  PRODUCT_DESCRIPTION: 'productDescription',
  INGREDIENTS: 'ingredients',
  SIMILAR_PRODUCTS: 'replacements',
  BRAND_PRODUCTS: 'brandProducts',
} as const

const PRODUCT_TABS = [
  {
    name: TAB_NAMES.PRODUCT_DESCRIPTION,
    dataTid: 'product-detail__description-tab',
  },
  {
    name: TAB_NAMES.INGREDIENTS,
    dataTid: 'product-detail__ingredients-tab',
  },
  {
    name: TAB_NAMES.SIMILAR_PRODUCTS,
    dataTid: 'product-detail__similar-tab',
  },
  {
    name: TAB_NAMES.BRAND_PRODUCTS,
    dataTid: 'product-detail__brand-tab',
  },
] as const
type ProductTabNames = (typeof PRODUCT_TABS)[number]['name']
type ProductTabNamesWithWidgets = Exclude<
  ProductTabNames,
  typeof TAB_NAMES.PRODUCT_DESCRIPTION | typeof TAB_NAMES.INGREDIENTS
>

const ghostProducts: Product[] = new Array(7).fill(null)
const widgetsOptions = {
  [TAB_NAMES.SIMILAR_PRODUCTS]: {
    products: ghostProducts,
    fetching: false,
    fetched: false,
  },
  [TAB_NAMES.BRAND_PRODUCTS]: {
    products: ghostProducts,
    fetching: false,
    fetched: false,
  },
}
const widgets = ref(widgetsOptions)

const contentEl = ref<Element | null>(null)
const isProductDetailLoaded = computed(() => !!props.product.origin.breadcrumbs.length)

const getProductTabs = computed(() =>
  PRODUCT_TABS.filter((tab) =>
    props.isDisplayIngredients ? tab : tab.name !== TAB_NAMES.INGREDIENTS,
  ).filter((tab) =>
    props.isDisplaySimilarProducts ? tab : tab.name !== TAB_NAMES.SIMILAR_PRODUCTS,
  ),
)

const activeTabName = ref<ProductTabNames>(getInitialTabName())

function setProductTabActive(tabName: ProductTabNames) {
  fetchProductCarousel(tabName)
  activeTabName.value = tabName

  window.history.replaceState(
    {
      productDetail: `${window.location.pathname}#${tabName}`,
    },
    '',
    `#${tabName}`,
  )

  scrollToContent()
}

async function fetchProductCarousel(productTabName: ProductTabNames) {
  const isRelevantProductTab = productTabName in widgets.value
  if (
    !isRelevantProductTab ||
    widgets.value[productTabName].fetching ||
    widgets.value[productTabName].fetched
  ) {
    return
  }
  const tabWidget = widgets.value[productTabName as ProductTabNamesWithWidgets]
  try {
    tabWidget.fetching = true
    const products = await fetchProductRelatedProducts({
      productId: props.product.id,
      relatedProductsKey: productTabName,
    })
    tabWidget.products = products
    tabWidget.fetched = true
  } catch (e) {
    tabWidget.fetched = false
  } finally {
    tabWidget.fetching = false
  }
}

function scrollToContent() {
  const contentElement = contentEl.value
  contentElement?.scrollIntoView(false)
}

function getInitialTabName() {
  // if product is sold out, then show similar products tab
  if (isProductSoldOut(props.product)) {
    return TAB_NAMES.SIMILAR_PRODUCTS
  }
  const urlHash = window.location.hash.replace('#', '')
  const foundName = PRODUCT_TABS.find((tab) => tab.name === urlHash)?.name

  return foundName ?? PRODUCT_TABS[0].name
}

setProductTabActive(activeTabName.value)

const isReplacementsTabActive = computed(() => activeTabName.value === TAB_NAMES.SIMILAR_PRODUCTS)
</script>

<template>
  <div>
    <div class="d-flex flex-wrap flex-column flex-md-row">
      <template
        v-for="(productTab, productTabIndex) in getProductTabs"
        :key="`productTab-${productTabIndex}`">
        <div
          v-if="!deviceDetectorStore.breakpointDownSm && productTabIndex !== 0"
          class="product-tab-control__separator" />

        <a
          :id="productTab.name"
          :class="[
            'product-tab-control',
            {
              'product-tab-control--active': productTab.name === activeTabName,
            },
          ]"
          :data-tid="productTab.dataTid"
          :href="`#${productTab.name}`"
          @click.prevent="setProductTabActive(productTab.name)"
          @mouseover="fetchProductCarousel(productTab.name)"
          v-text="t(`product.${productTab.name}`)" />
      </template>
    </div>
    <div ref="contentEl">
      <div
        v-if="!(activeTabName in widgets)"
        class="product-tab-content container-fluid py-3 p-lg-5"
        data-tid="product-detail__tab-content">
        <div
          v-if="activeTabName === TAB_NAMES.PRODUCT_DESCRIPTION"
          class="row">
          <div
            v-if="!!product.origin.detail?.description.length"
            class="col-12"
            data-tid="product-detail__description">
            <ProductDetailFieldList :items="product.origin.detail.description" />
          </div>
          <div
            v-if="!!product.origin.detail?.supplierInfo.length"
            class="col-md-6"
            data-tid="product-detail__supplierInfo">
            <ProductDetailFieldList :items="product.origin.detail.supplierInfo" />
          </div>
          <div
            v-if="!!product.origin.detail?.bestBefore"
            class="col-12 my-2"
            data-tid="product-detail__bestBefore">
            <div class="p-2 py-3 p-md-3 bg-gray-lighter">
              <strong
                class="d-block mb-2 text-4 text-uppercase"
                v-text="t('productDetail.bestBefore.title')" />
              <p class="mb-1">
                {{ t('productDetail.bestBefore.usual') }}:
                <span class="font-weight-bold">
                  {{
                    product.origin.detail.bestBefore.usual +
                    '&nbsp;' +
                    tc('productDetail.bestBefore.duration', product.origin.detail.bestBefore.usual)
                  }}
                </span>
              </p>
              <p class="mb-0">
                {{ t('productDetail.bestBefore.guaranteed') }}:
                <span class="font-weight-bold">
                  {{
                    product.origin.detail.bestBefore.guaranteed +
                    '&nbsp;' +
                    tc(
                      'productDetail.bestBefore.duration',
                      product.origin.detail.bestBefore.guaranteed,
                    )
                  }}
                </span>
              </p>
            </div>
          </div>
          <div
            v-if="isDisplayIngredients"
            class="content-divider col-12 my-4">
            <span
              class="content-divider__title px-2"
              v-text="t('product.dividingLineTitle')" />
          </div>
        </div>
        <div class="row">
          <div
            v-if="!!product.origin.detail?.ingredients.length"
            class="col-12 col-md-6"
            data-tid="product-detail__ingredients">
            <ProductDetailFieldList
              v-if="product.origin.detail.ingredients"
              :items="product.origin.detail.ingredients" />
          </div>
          <div
            v-if="
              !!product.origin.detail?.parameterGroups.length ||
              !!product.origin.detail?.nutritionalValues
            "
            class="col-12 col-md-6">
            <CTable
              v-if="!!product.origin.detail?.nutritionalValues"
              class="w-100 mb-3"
              :content="product.origin.detail.nutritionalValues as any /* @todo type fix */" />
            <template v-if="!!product.origin.detail?.parameterGroups.length">
              <CTable
                v-for="(parameterGroup, parameterGroupIndex) in product.origin.detail
                  ?.parameterGroups"
                :key="`parameter-group${parameterGroupIndex}`"
                class="w-100 mb-3"
                :content="parameterGroup as any /* @todo type fix */" />
            </template>
          </div>
        </div>
        <div
          v-if="isProductDetailLoaded"
          class="row mx-lg-0"
          data-tid="product-detail__alert">
          <p
            class="my-3 p-2 py-3 p-md-3 text-gray bg-gray-lighter"
            v-text="t('productDetail.manifest')" />
        </div>
      </div>
      <ProductCarousel
        v-else
        :product-detail-replacement="isReplacementsTabActive"
        :products="widgets[activeTabName as ProductTabNamesWithWidgets].products"
        data-tid="product-detail__tab-content carousel" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.product-tab-control {
  display: block;
  flex: 1;
  padding: 14px 5px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  background: color('gray-lighter');
  border: 0;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  transition-duration: var(--fastTransitionTime);
  transition-property: background-color, border;

  &:hover,
  &--active {
    padding: 14px 5px;
    background: color('white');
    border-top: 1px solid color('gray-light');
    border-bottom: 1px solid color('gray-light');
  }

  @include media-breakpoint-up(sm) {
    &--active {
      border-bottom-color: color('white');
    }
  }

  &__separator {
    width: 1px;
    background: color('gray-light');
  }
}

.product-tab-content {
  min-height: $productItemBoxHeight;

  @include media-breakpoint-up(lg) {
    min-height: $productItemBoxHeightLg;
  }
}

.content-divider {
  position: relative;
  height: 1px;
  text-align: center;
  background: color('gray-light');

  &__title {
    @include make-font-scale(4);

    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 180px;
    line-height: 1em;
    color: color('gray');
    text-transform: uppercase;
    background: color('white');
    transform: translate(-50%, -50%);
  }
}
</style>
