<script setup lang="ts">
import { ref } from 'vue'

import DialogConfirmOverlay from '@/components/Dialog/DialogConfirmOverlay.vue'
import Icon from '@/components/Icon/Icon.vue'
import { t } from '@/services/translations'

type BenjaminekChildCardProps = {
  name: string

  age: string
  showCrossButton: boolean
}

const props = defineProps<BenjaminekChildCardProps>()

const emit = defineEmits<{
  remove: []
}>()

const isDialogOverlayVisible = ref(false)

function removeChild() {
  emit('remove')
  isDialogOverlayVisible.value = false
}
</script>

<template>
  <div class="card-wrapper position-relative bg-primary-lighter p-2">
    <div class="card-content bg-white h-100">
      <Icon
        icon="benjaminekTransparent"
        group="clubs"
        class="benjaminek-icon text-primary mx-2" />

      <div class="child-info-container text-black text-3 text-left">
        <p class="child-info-item text-truncate m-0 p-0 mb-4">{{ props.name }}</p>
        <p class="child-info-item text-truncate m-0 p-0">{{ props.age }}</p>
      </div>

      <button
        v-if="props.showCrossButton"
        type="button"
        class="a-control-close align-self-start flex-shrink-0 m-2"
        @click.stop.prevent="isDialogOverlayVisible = true" />

      <DialogConfirmOverlay
        v-if="isDialogOverlayVisible"
        :title="t('kidsClub.children.removeChild.title')"
        :accept-label="t('kidsClub.children.removeChild.yes')"
        :decline-label="t('kidsClub.children.removeChild.no')"
        @accepted="removeChild"
        @declined="isDialogOverlayVisible = false" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

$card-border-radius: var(--borderRadiusMedium);
$cross-icon-size: 16px;

.card-wrapper {
  max-width: 320px;
  height: 125px;
}

.card-content {
  display: grid;
  grid-template-columns: 1.2fr 2fr minmax($cross-icon-size, $cross-icon-size * 2);
  align-items: center;
  justify-items: center;
}

.card-wrapper,
.card-content {
  border-radius: $card-border-radius;
}

.benjaminek-icon {
  width: 60px;
  height: 74px;
}

.child-info-container {
  width: 140px;
}

.child-info-item {
  border-bottom: 1px solid color('pink-dark');
}

.cross-icon {
  width: $cross-icon-size;
  height: $cross-icon-size;
}

:deep(.confirm-overlay) {
  border: 1px solid color('gray-lighter');
  border-radius: $card-border-radius;
}
</style>
