<script setup lang="ts">
import { computed } from 'vue'

import localizePrice from '@/utils/localizePrice'

type PriceDisplayProps = {
  price: number
  decimalPosition?: 'top' | 'bottom'
  separator?: keyof typeof separators
}

const props = defineProps<PriceDisplayProps>()

const parsedAmount = computed(() => {
  // Find the position of the comma and space
  const input = localizePrice(props.price)
  const lastSpaceIndex = input.lastIndexOf(' ')
  const currency = input.slice(lastSpaceIndex).trim()

  // Trim the string to leave only the amount
  const amount = input.slice(0, lastSpaceIndex).trim()

  // Find the position of the comma (for separating decimalPart)
  const commaIndex = amount.lastIndexOf(',')

  let wholePart: string, decimalPart: string

  if (commaIndex !== -1) {
    wholePart = amount.slice(0, commaIndex).trim()
    decimalPart = amount.slice(commaIndex + 1).trim()
  } else {
    wholePart = amount.trim()
    decimalPart = '00'
  }

  return {
    wholePart,
    decimalPart,
    currency,
  }
})

const decimalComponent = computed(() => (props.decimalPosition === 'top' ? 'sup' : 'sub'))

const separators = {
  space: ' ',
  comma: ',',
  dot: '.',
} as const

const separatorCharacter = computed(() => {
  if (!props.separator) return ''

  return separators[props.separator]
})
</script>

<template>
  <div class="d-flex flex-nowrap align-items-baseline justify-content-center">
    <span v-text="parsedAmount.wholePart" />

    <span v-text="separatorCharacter" />

    <Component
      :is="decimalComponent"
      class="p-0"
      v-text="parsedAmount.decimalPart" />

    <div
      class="currency"
      v-text="parsedAmount.currency" />
  </div>
</template>

<style scoped lang="scss">
sub {
  bottom: 0;
}

.currency {
  padding-left: 2px;
}
</style>
