<script setup lang="ts">
import { computed } from 'vue'

import { storeToRefs } from 'pinia'

import AddressBannerInput from '@/components/AddressBanner/AddressBannerInput.vue'
import Icon from '@/components/Icon/Icon.vue'
import ImgResponsive from '@/components/stories/atoms/imgResponsive/ImgResponsive.vue'
import useDeliveryData from '@/composables/transport/useDeliveryData'
import useConfig from '@/composables/useConfig'
import { t } from '@/services/translations'
import useAddressStore from '@/store/pinia/useAddressStore'
import { formatTime, formatWeekday } from '@/utils/date'

import useAddressSelectorButton from '../AddressSelector/useAddressSelectorButton'

import bannerSrc from '__K3W_DOMAIN__/img/address-banner.webp'
import bannerSrc2x from '__K3W_DOMAIN__/img/address-banner@2x.webp'

const imageSrcSet = [
  {
    url: bannerSrc,
  },
  {
    pixelRatio: 2,
    url: bannerSrc2x,
  },
]

const { open } = useAddressSelectorButton()

const addressStore = useAddressStore()
const { data: deliveryData } = useDeliveryData()
const configData = useConfig()

const { isUserStreetOrCityFilled, userAddress } = storeToRefs(addressStore)

const priceZone = configData.value.cart.priceZone
const bannerTitle = computed(() => {
  if (priceZone.customerName)
    return `${t('addressBanner.geolocationTitle')} ${priceZone.customerName}`
  return t('addressBanner.title')
})

const bannerSubtitle = computed(() => {
  return priceZone.customerName ? t('addressBanner.geolocationText') : t('addressBanner.text')
})

const getPreviewEarliestTimeslot = computed(() => {
  if (!deliveryData.value?.earliestDeliveryTime) {
    return '…'
  }

  return t('addressSelector.buttonTitle', {
    day: formatWeekday(deliveryData.value.earliestDeliveryTime),
    time: formatTime(deliveryData.value.earliestDeliveryTime),
  })
})
</script>

<template>
  <div class="strip">
    <div class="banner-background h-100 mx-auto position-relative">
      <ImgResponsive
        :srcset="imageSrcSet"
        class="banner-picture d-block position-absolute"
        class-img="banner-img"
        :alt="t('addressBanner.alt')" />
      <div
        class="container pl-md-5 banner-content d-flex align-items-center h-100 position-relative">
        <div v-if="!isUserStreetOrCityFilled">
          <div class="banner-heading text-10 font-weight-bolder">
            {{ bannerTitle }}
          </div>
          <p class="text-5 mb-0 mb-sm-2">
            {{ bannerSubtitle }}
          </p>
          <AddressBannerInput />
        </div>
        <div v-else>
          <div
            class="banner-heading-saved text-10 mb-5 font-weight-bolder"
            v-text="t('addressBanner.savedTitle')" />
          <div class="banner-address-bg px-3 py-2">
            <p class="text-5 mb-0">
              {{ t('addressBanner.deliverTo.start') }}
              <span
                class="font-weight-bold"
                v-text="t('addressBanner.deliverTo.end', { time: getPreviewEarliestTimeslot })" />
            </p>
            <span class="banner-address text-4 d-flex align-items-center font-weight-bold">
              <Icon
                icon="pointer"
                class="pointer-icon mr-2" />
              <span
                class="banner-address-overflow"
                @click="open('address-banner', { hideTimeslots: true })">
                {{ userAddress.address }}
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@use '@/legacy' as *;

.strip {
  width: 100%;
  margin-top: 0.5rem;
  background: linear-gradient(90deg, #f1f1f1 0%, rgba(241, 241, 241, 0%) 56.11%);

  @include media-breakpoint-up(sm) {
    margin-top: 1rem;
  }
}

.banner-picture {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.strip,
.banner-picture,
.banner-content {
  border-radius: var(--borderRadiusMedium);
}

:deep(.banner-img) {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 90%;
}

.banner-content {
  min-height: 320px;
  padding-block: 20px;
  color: color('white');
  background: #0000004d;

  @include media-breakpoint-up(lg) {
    color: color('black');
    background: unset;
  }
}

.banner-heading {
  max-width: 530px;
}

.banner-heading-saved {
  max-width: 580px;
}

.banner-heading,
.banner-heading-saved {
  line-height: 1.2;
  color: color('white');

  @include media-breakpoint-up(lg) {
    color: color('text-headings');
  }
}

.banner-address-bg {
  width: fit-content;
  color: color('black');
  background: color('white');
}

.banner-address,
.banner-address-overflow {
  color: color('primary');
}

.banner-address-overflow {
  max-width: 242px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;

  @include media-breakpoint-up(sm) {
    max-width: unset;
  }

  &:hover {
    text-decoration: underline;
  }
}

.pointer-icon {
  width: 18px;
  height: 24px;
  fill: color('contrast-red');
}
</style>
