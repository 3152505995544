<script setup lang="ts">
type Overlay = 'black' | 'white'

type BaseLoaderProps = {
  overlay?: Overlay
}
const props = defineProps<BaseLoaderProps>()
</script>

<template>
  <div :class="['loader', props.overlay && `loader--overlay loader--overlay-${props.overlay}`]" />
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

$size: 32px;
$thickness: 4px;
$animation-speed: 1s;

.loader {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  &::after {
    width: $size;
    height: $size;
    content: '';
    border: $thickness solid color('primary');
    border-top-color: transparent;
    border-radius: 50%;
    animation: spin $animation-speed linear infinite;
  }

  &--overlay {
    &::before {
      position: absolute;
      width: 100%;
      height: 100%;
      content: '';
    }

    &-black::before {
      background: color('black');
      opacity: 0.1;
    }

    &-white::before {
      background: color('white');
      opacity: 0.5;
    }
  }
}
</style>
