import type States from '@/components/Notification/States'

type NotificationResponse = {
  id: string
  state: (typeof States)[keyof typeof States]
  description: string
  title: string
  parameters: {
    batchId: string
    imageUrl: string
    creditsAmount?: number
    redirectUrl?: string
    copyBox?: string
  }
  validityTo: string
  timestamp: string
  hideValidity: boolean
}

type NotificationsResponse = NotificationResponse[]

type NotificationsCountParams = {
  consumer?: string
  customerId?: number
  maxSize?: number
}

const modulePath = 'notification'

export type { NotificationsResponse, NotificationResponse, NotificationsCountParams }
export { modulePath }
