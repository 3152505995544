<script setup lang="ts">
import { ref, computed, getCurrentInstance, reactive } from 'vue'

import debounce from 'lodash-es/debounce'

import Icon from '@/components/Icon/Icon.vue'
import { Tooltip } from '@/componentsPure'
import useConfig from '@/composables/useConfig'
import useInteractionSource from '@/composables/useInteractionSource'
import type { Product } from '@/composables/useProductItem'
import { VENDOR_CONFIG, Vendors } from '@/constants/vendors'
import appConfig from '@/services/appConfig'
import { t } from '@/services/translations'
import useCartStore from '@/store/pinia/useCartStore'
import { isProductSoldOut, isProductPartiallySoldOut } from '@/store/utils/productAvailability'
import isHTMLInputElement from '@/utils/isHTMLInputElement'

type ProductAmountProps = {
  product: Product
  hasMatchingVendorId: boolean
  unlisted?: boolean
  removable?: boolean
  size?: 'sm' | 'md' | 'lg'
  replaceable?: boolean
  productDetail?: boolean
}

const props = withDefaults(defineProps<ProductAmountProps>(), {
  removable: true,
  size: 'md',
})

const inst = getCurrentInstance()
const emit = defineEmits<{
  remove: [id: Product['id']]
  replace: []
  openDetail: []
}>()

const cartStore = useCartStore()

const lastInteractionElement = reactive<{
  element: string | null
}>({
  element: null,
})

/**
 * Returns null if input's not mounted yet,
 * zero if input's value is not a number.
 */
function getInputQuantity() {
  if (inputQuantityRef.value === null) return null

  const inputQuantity = inputQuantityRef.value.value

  return Number(inputQuantity) || 0
}

function setNewQuantity(quantityNew: number, increasing: boolean, forceTooltip = false) {
  cartStore.cartActions.setCartProductQuantity({
    product: {
      id: props.product.id,
      quantity: quantityNew,
    },
    interactionSource: getInteractionSource(),
    increasing,
  })
  toggleTooltip(forceTooltip)
}

const setNewQuantityDebounce = debounce((quantityNew, increasing) => {
  const adjustedQuantity = adjustQuantityUnitSteps(quantityNew, increasing)
  setNewQuantity(adjustedQuantity, increasing, !!adjustedQuantity)
}, appConfig.productQuantityDebouncing)

function minus(interactionElement: string) {
  const inputQuantity = getInputQuantity()

  lastInteractionElement.element = interactionElement

  if (inputQuantity === 0 || inputQuantity === unitStep.value) {
    removeProduct()
  } else if (Number(inputQuantity)) {
    setNewQuantity(adjustQuantityUnitSteps(Number(inputQuantity) - 1, false), false)
  }
}

function plus(interactionElement: string) {
  const inputQuantity = getInputQuantity()

  lastInteractionElement.element = interactionElement

  if (inputQuantity === 0) {
    quantity.value = unitStep.value
  } else if (!isMaxQuantity.value) {
    setNewQuantity(adjustQuantityUnitSteps(Number(inputQuantity) + 1, true), true)
  }
}

function adjustQuantityUnitSteps(wantedQuantity: number, increasing: boolean) {
  if (!limitStep.value || (wantedQuantity < unitStep.value && !increasing)) {
    return 0
  }
  if (wantedQuantity < unitStep.value) {
    return unitStep.value
  }
  if (wantedQuantity % unitStep.value) {
    return Math.min(
      wantedQuantity - (wantedQuantity % unitStep.value) + (increasing ? unitStep.value : 0),
      limitStep.value,
    )
  }

  return Math.min(wantedQuantity, limitStep.value)
}

function checkQuantity(event: KeyboardEvent | FocusEvent) {
  if (!isHTMLInputElement(event.target)) return

  const value = Number(event.target.value)

  if (value > limitStep.value) {
    event.target.value = limitStep.value.toString()
  }

  if (
    (value < unitStep.value || Number.isNaN(value)) &&
    event instanceof FocusEvent &&
    event.relatedTarget !== inputQuantityRef.value
  ) {
    removeProduct()
  }
}

function removeProduct() {
  if (props.removable) {
    cartStore.cartActions.removeCartProduct({
      productId: props.product.id,
      interactionSource: getInteractionSource(),
    })
  } else {
    quantity.value = unitStep.value
    inst?.proxy?.$forceUpdate() // WTF?
    emit('remove', props.product.id)
  }
}

const autoHideTooltip = debounce(() => {
  tooltipVisible.value = false
}, appConfig.tooltipHideDelay)

function toggleTooltip(forceTooltip) {
  if (tooltipVisible.value && quantity.value < limitStep.value) {
    tooltipVisible.value = false
  } else if (isLimitReached.value || forceTooltip) {
    tooltipVisible.value = true
    autoHideTooltip()
  }
}

const tooltipVisible = ref(false)
const inputQuantityRef = ref<HTMLInputElement | null>(null)
const configData = useConfig()

const isProductLoaded = computed(() => !!props.product)

const effectiveMaxInCartLimit = computed(() =>
  props.product.origin.vendorId === Vendors.PHARMACY
    ? configData.value.general.maxInCartLimitPharmacy
    : configData.value.general.maxInCartLimit,
)

const maxInCart = computed(() => props.product.origin.maxInCart || effectiveMaxInCartLimit.value)

const limitInCart = computed(
  () => props.product.origin.limitInCart || effectiveMaxInCartLimit.value,
)

const unitStep = computed(() => props.product.origin.unitStep || 1)

const limitStep = computed(() => {
  const limit = Math.min(maxInCart.value, limitInCart.value)
  return limit - (limit % unitStep.value)
})

const isMaxQuantity = computed(() => props.product.computed.quantity >= limitStep.value)

const isLimitReached = computed(
  () => Number.isInteger(limitInCart.value) && quantity.value >= limitStep.value,
)

const isSoldOut = computed(() => isProductSoldOut(props.product) || !limitStep.value)

const limitingMessage = computed(() => {
  if (isLimitReached.value && limitInCart.value <= maxInCart.value) {
    return t('amount.maxInCart.tooltip', { amount: limitStep.value })
  }
  if (isLimitReached.value) {
    return t('amount.lowAmount.tooltip', { amount: limitStep.value })
  }
  return t('amount.unitStep.tooltip', { amount: unitStep.value })
})

const isLimitedAvailability = computed(() => isProductPartiallySoldOut(props.product))

const isLimitNoteVisible = computed(
  () =>
    Number.isInteger(limitInCart.value) &&
    limitInCart.value < effectiveMaxInCartLimit.value &&
    limitInCart.value === maxInCart.value,
)

const isStepNoteVisible = computed(() => unitStep.value > 1)

const quantity = computed({
  get: () => props.product.computed.quantity || 0,
  set: (quantityNew) => {
    lastInteractionElement.element = 'input_quantity'

    const increasing = quantityNew > quantity.value
    if (inputQuantityRef.value && quantityNew > 0 && quantity.value !== quantityNew) {
      if (inputQuantityRef.value.checkValidity()) {
        setNewQuantity(quantityNew, increasing)
        setNewQuantityDebounce.cancel()
      } else {
        setNewQuantityDebounce(quantityNew, increasing)
      }
    }
  },
})

const getVendorString = computed(
  () => VENDOR_CONFIG[props.product.origin.vendorId]?.generalName || 'otherStore',
)

const getInteractionSource = useInteractionSource('ProductAmount', lastInteractionElement)

// Redirect to product detail page if product has unmatched vendor
// it is a fix for https://mallfresh.atlassian.net/browse/KNW-17027
// For full fixing and intended 'product from other context in modal'
// we need huge refactoring (8sp).
function redirectToDetail() {
  window.location.href = props.product.origin.url
}
</script>

<template>
  <Tooltip
    :visible="tooltipVisible"
    nowrap>
    <div
      :class="[
        'product-amount',
        `product-amount--size-${props.size}`,
        `product-amount--vendor-${getVendorString}`,
        {
          'text-red font-weight-bold': isLimitedAvailability,
        },
      ]">
      <template v-if="!isProductLoaded">
        <button
          v-if="props.removable"
          data-tid="product-to-cart__to-cart-disabled"
          class="product-amount__add product-amount__add--disabled" />
      </template>
      <button
        v-else-if="isSoldOut && props.replaceable && props.hasMatchingVendorId"
        data-tid="product-to-cart__replacement"
        class="product-amount__add font-weight-bold"
        @click="emit('replace')"
        v-text="t('product.chooseAlternative')" />
      <button
        v-else-if="isSoldOut || props.unlisted"
        data-tid="product-to-cart__to-cart-disabled"
        class="product-amount__add product-amount__add--disabled"
        v-text="t('product.soldOut')" />
      <button
        v-else-if="!props.hasMatchingVendorId"
        data-tid="product-to-cart__to-cart-disabled"
        class="product-amount__add"
        @click="redirectToDetail">
        <strong v-text="t(`vendors.unmatchedProductVendor.${getVendorString}`)" />
      </button>
      <button
        v-else-if="quantity === 0"
        data-tid="product-to-cart__to-cart"
        :class="[
          'product-amount__add',
          { 'product-amount__add--product-detail': props.productDetail },
        ]"
        @click="plus('button_add_to_cart')">
        <strong v-text="t('amount.addToCart')" />
        <span
          v-if="isStepNoteVisible || isLimitNoteVisible"
          :class="['product-amount__limit-msg', { 'text-white': props.productDetail }]"
          v-text="
            t(`amount.${isStepNoteVisible ? 'unitStep' : 'maxInCart'}.button`, {
              amount: isStepNoteVisible ? unitStep : maxInCart,
            })
          " />
      </button>
      <template v-else>
        <button
          key="amount-decrease"
          data-tid="product-to-cart__decrease-amount"
          class="product-amount__btn product-amount__btn--decrease"
          @click="minus('button_minus')">
          <Icon
            icon="minus"
            class="product-amount__btn-icons" />
        </button>
        <input
          ref="inputQuantityRef"
          v-model="quantity"
          type="number"
          data-tid="product-box__amount"
          class="product-amount__input"
          pattern="[0-9]*"
          min="0"
          :max="limitStep"
          :step="unitStep"
          @blur="checkQuantity"
          @keyup.enter="checkQuantity"
          @keyup.up="toggleTooltip(false)" />
        <button
          key="amount-increase"
          data-tid="product-to-cart__increase-amount"
          class="product-amount__btn product-amount__btn--increase"
          :class="{
            'product-amount__btn--disabled': isMaxQuantity,
          }"
          @click="plus('button_plus')">
          <Icon
            icon="plus"
            class="product-amount__btn-icons" />
        </button>
      </template>
    </div>
    <template
      v-if="isProductLoaded"
      #message>
      <div
        class="product-amount__limit-tooltip"
        v-html="limitingMessage" />
    </template>
  </Tooltip>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;
@use 'sass:color' as sassColor;

$base-size: 14px;
$border-width: 1px;
$box-shadow-spread: $border-width;

.product-amount {
  display: flex;
  justify-content: space-between;
  width: em(100, $base-size);
  min-height: em(24, $base-size);

  &__add,
  &__btn,
  &__input {
    border-radius: em(4, $base-size);
  }

  &__add {
    flex: 1;
    font-size: em(13, $base-size);
    color: color('primary');
    background: color('primary-light');
    border: 0;
    transition: background-color var(--baseTransitionTime) ease-in-out;

    &:hover {
      background: color('off-primary-light');
    }

    &--disabled {
      color: color('gray-light');
      pointer-events: none;
      cursor: auto;
      background: transparent;
      border: $border-width solid color('gray-light');
    }

    &--product-detail {
      color: color('white');
      background: color('primary');
      border: 0;
      transition: background-color var(--baseTransitionTime) ease-in-out;

      &:hover {
        background: color('off-primary-mid');
      }
    }
  }

  &__btn {
    position: relative;
    width: em(24, $base-size);
    padding: 0;
    color: color('black');
    border: 0;
    transition: background var(--baseTransitionTime);

    &--decrease {
      background: color('primary-light');

      &:hover {
        background: color('off-primary-light');
      }
    }

    &--increase {
      color: color('white');
      background: color('primary');

      &:hover {
        background: color('off-primary-mid');
      }
    }

    &--disabled {
      cursor: default;
      background: (color('background-disabled'));

      &:hover {
        background: (color('background-disabled'));
      }
    }
  }

  &__btn-icons {
    position: absolute;
    top: 50%;
    left: 50%;
    width: em(7, $base-size);
    height: em(7, $base-size);
    transform: translate(-50%, -50%);
  }

  &__input {
    width: em(44, $base-size);
    padding: em(0 4, $base-size);
    font-size: inherit !important;
    font-weight: inherit;
    color: inherit;
    text-align: center;

    // removes iOS native input shadow
    appearance: textfield;
    background-image: linear-gradient(color('white'), color('white'));
    border: none;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      appearance: none;
    }

    &:hover {
      box-shadow: inset 0 0 0 $box-shadow-spread color('gray-light');
    }

    &:focus {
      box-shadow: inset 0 0 0 $box-shadow-spread color('gray-darker');
    }
  }

  &__limit-tooltip {
    line-height: 1;
    text-align: center;

    @include make-font-scale(2);
  }

  &__limit-msg {
    @include make-font-scale(1);

    display: block;
    font-weight: 400;
    letter-spacing: -0.02em;
  }

  &--size-sm {
    font-size: 12px;
  }

  &--size-md,
  &--size-lg {
    font-size: 14px;
  }

  &--size-lg {
    width: em(120, $base-size);
    min-height: em(32, $base-size);

    .product-amount__btn {
      width: em(32, $base-size);
    }
  }

  &--vendor-pharmacy & {
    &__btn--decrease:hover {
      background: color('green-light');
    }

    &__add,
    &__btn--increase {
      color: color('white');
      background: color('green');

      &:hover {
        background-color: sassColor.adjust(color('green'), $lightness: -15%);
      }
    }

    &__add--disabled,
    &__btn--disabled {
      color: color('gray-light');
      background: transparent;
    }
  }
}
</style>
