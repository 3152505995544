import dayjs, { type Dayjs } from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'

function dateSameOrAfter(date: Dayjs, sameOrAfterDate: Dayjs) {
  dayjs.extend(isSameOrAfter)
  return date.isSameOrAfter(sameOrAfterDate)
}

function dateSameOrBefore(date: Dayjs, sameOrBeforeDate: Dayjs) {
  dayjs.extend(isSameOrBefore)
  return date.isSameOrBefore(sameOrBeforeDate)
}

export { dateSameOrAfter, dateSameOrBefore }
