import apiInstances from '../apiInstances'
import address from './address'
import auth from './auth'
import banners from './banners'
import benjaminek from './benjaminek'
import brands from './brands'
import checkout from './checkout'
import client from './client'
import configuration from './configuration'
import customContent from './customContent'
import menu from './menu'
import payment from './payment'
import privacy from './privacy'
import profile from './profile'
import staticContent from './staticContent'
import transport from './transport'

const frontApi = apiInstances.front({
  address,
  auth,
  banners,
  brands,
  customContent,
  menu,
  profile,
  staticContent,
  configuration,
  checkout,
  privacy,
  client,
  transport,
  payment,
  benjaminek,
})

export default frontApi
