/**
 * Encodes data into string.
 * Used for url query parameters as it also encodes characters that are part of the URI syntax.
 * @param input Data to encode.
 * @returns Encoded data as string.
 */
function encode(input: unknown) {
  return encodeURIComponent(JSON.stringify(input))
}

/**
 * Decodes data encoded by {@link encode} function into it's original value and type.
 * @param input Data to decode.
 * @returns Decoded data as original type.
 */
function decode<T = any>(input: string) {
  if (typeof input !== 'string') {
    return
  }
  return JSON.parse(decodeURIComponent(input)) as T
}

export { encode as queryStringEncode, decode as queryStringDecode }
