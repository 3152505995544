import dayjs, { Dayjs } from 'dayjs'

import type {
  ComplaintPrevious,
  ProfileOrderDetailResponse,
  ReadOrderList,
} from '@/services/api/front/profileTypes'

import type { SelectedTimeslot } from '../modules/cart'

type TransformedComplaintPrevious = ReplaceRecordKeys<
  ComplaintPrevious,
  {
    created: Dayjs
  }
>

type TransformedComplaints = ReplaceRecordKeys<
  ProfileOrderDetailResponse['complaints'],
  {
    until: Dayjs | null
    previous: TransformedComplaintPrevious[]
  }
>
type TransformedDetailOrder = ReplaceRecordKeys<
  ProfileOrderDetailResponse,
  {
    additionalOrderCloseDateTime: Dayjs | null
    created: Dayjs
    transport: SelectedTimeslot
    complaints: TransformedComplaints
  }
>

type TransformedReadOrderList = ReplaceRecordKeys<
  ReadOrderList,
  {
    additionalOrderCloseDateTime: Dayjs | null
    created: Dayjs
    transport: SelectedTimeslot
    complaintUntil: Dayjs | null
  }
>

function prepareOrderDetail(
  order: ProfileOrderDetailResponse | null,
): TransformedDetailOrder | null {
  if (!order) return null

  return {
    ...order,
    additionalOrderCloseDateTime: order.additionalOrderCloseDateTime
      ? dayjs(order.additionalOrderCloseDateTime)
      : null,
    created: dayjs(order.created),
    transport: {
      ...order.transport,
      start: dayjs(order.transport.start),
      end: dayjs(order.transport.end),
    },
    complaints: {
      ...order.complaints,
      until: order.complaints.until ? dayjs(order.complaints.until) : null,
      previous: order.complaints.previous.map((complaint) => ({
        ...complaint,
        created: dayjs(complaint.created),
      })),
    },
  }
}

function prepareSimpleOrder(order: ReadOrderList): TransformedReadOrderList {
  return {
    ...order,
    additionalOrderCloseDateTime: order.additionalOrderCloseDateTime
      ? dayjs(order.additionalOrderCloseDateTime)
      : null,
    created: dayjs(order.created),
    transport: {
      ...order.transport,
      start: dayjs(order.transport.start),
      end: dayjs(order.transport.end),
    },
    complaintUntil: order.complaintUntil ? dayjs(order.complaintUntil) : null,
  }
}

export { prepareOrderDetail, prepareSimpleOrder }
export type { TransformedDetailOrder, TransformedReadOrderList }
