import type { ApiEmptyResponse } from '@/services/api/types'
import appConfig from '@/services/appConfig'

import { fetchPost, fetchDelete } from '../fetch'

function postProduct(productId: number) {
  const url = appConfig.frontApiBaseUrl + 'favorites/product/' + productId
  const nameController = `favorite${productId}`

  return fetchPost<ApiEmptyResponse>(url, undefined, undefined, undefined, nameController)
}

function deleteProduct(productId: number) {
  const url = appConfig.frontApiBaseUrl + 'favorites/product/' + productId

  return fetchDelete<ApiEmptyResponse>(url, `favorite${productId}`)
}

export {
  postProduct as frontApiPostFavoritesProduct,
  deleteProduct as frontApiDeleteFavoritesProduct,
}
