import appConfig from '@/services/appConfig'

/**
 * Scrolls whole page to the top.
 */
function scrollTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}

/**
 * Calc size body scrollbar width.
 */
function getBodyScrollbarWidth() {
  return window.innerWidth - document.body.clientWidth
}

/**
 * Handles locking document.body scrollbar css class, styles, animation, etc..
 */
function toggleBodyScroll(isScrollLocked = false) {
  const scrollbarWidth = getBodyScrollbarWidth() + 'px'
  document.documentElement.classList[isScrollLocked ? 'add' : 'remove'](
    appConfig.modalBackdropLockCssClass,
  )
  document.body.style.paddingRight = isScrollLocked ? scrollbarWidth : ''
}

export { scrollTop, getBodyScrollbarWidth, toggleBodyScroll }
