import appConfig from '../appConfig'

function getEocAuthUri() {
  const eocAuth = appConfig.eocAuth.find((eocAuthItem) =>
    window.location.href.includes(eocAuthItem.domainSubstr),
  )
  return eocAuth?.authCommonUri ?? null
}

const authUri = `${window.location.origin}${appConfig.auth.authPath}`

enum LogLevel {
  Error = 0,
  Warning = 1,
  Info = 2,
  Verbose = 3,
  Trace = 4,
}

const msalConfig = {
  auth: {
    clientId: appConfig.auth.clientId,
    authority: appConfig.auth.authoritySignUpSignIn,
    knownAuthorities: [appConfig.auth.authorityDomain],
    postLogoutRedirectUri: authUri + '?logout=1',
    redirectUri: getEocAuthUri() ?? authUri,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
    // to enable same functionality as in msal 2.x, we need to set this to true
    // @see https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/v2-migration.md#claims-based-caching
    claimsBasedCachingEnabled: true,
  },
  system: {
    // @todo What is this for?
    loggerOptions: {
      loggerCallback: (level, _, containsPii) => {
        if (containsPii) {
          return
        }
        switch (level) {
          case LogLevel.Verbose:
          case LogLevel.Info:
          case LogLevel.Warning:
          case LogLevel.Error:
        }
      },
    },
  },
}

export default msalConfig
