<script setup lang="ts">
import { computed } from 'vue'

// @TODO Should be an endpoint, because same file is used in PHP app.
// When changes are made, they should be reflected in both files.
import Tags from './tagStylesConfig.json'

type BaseTagProps = {
  modifier?: string // Name of predefined tag overall style. See /App/config/tagStylesConfig.json.
  size?: 'small' | 'big' | 'sale' // Name of predefined tag size.
}

type Tag = {
  key: string
  class: string
}

const props = defineProps<BaseTagProps>()

const sizeClass = computed(() => props.size && `a-tag--${props.size}`)
const modifierClass = computed(() => {
  return (Tags as Tag[]).find((item) => item.key === props.modifier)?.class
})
</script>

<template>
  <span :class="['a-tag', sizeClass, modifierClass]">
    <!-- @slot Tag text content, preferably one line of short text -->
    <slot />
  </span>
</template>
