<script setup lang="ts">
import { CarouselBanners } from '@/components/HomePageBanners'
import TitleSection from '@/components/global/TitleSection.vue'
import { useBanners } from '@/composables/banner'
import { t } from '@/services/translations'

const { banners } = useBanners('homepageSpecialOffer')
</script>

<template>
  <div
    v-if="banners.length"
    data-tid="homepage__section">
    <TitleSection :title="t('specialOffer.title')" />
    <CarouselBanners
      :banners="banners"
      class="mt-3" />
  </div>
</template>
