import { createOperation } from '@/services/apiv2'

import {
  modulePath,
  type NotificationsCountParams,
  type NotificationsResponse,
} from './notificationsTypes'

const notificationsValid = createOperation<
  NotificationsResponse,
  NotificationsResponse,
  undefined,
  undefined,
  NotificationsCountParams
>(modulePath, {
  method: 'get',
})

export default notificationsValid
