/**
 *
 * @param name of cookie
 * @param value of cookie
 * @param days cookie is stored
 */
const setCookie = (name: string, value: string, days = 365, domain?: string) => {
  const maxAge = '; max-age=' + days * 24 * 60 * 60
  document.cookie =
    name + '=' + (value ?? '') + maxAge + (domain ? `; domain=.${domain}` : '') + '; path=/'
}

/**
 *
 * @param name of stored cookie
 * @returns value of stored cookie
 */
const getCookie = (name: string) => {
  return document.cookie.split('; ').reduce((identifier, character) => {
    const parts = character.split('=')
    return parts[0] === name ? decodeURIComponent(parts[1]) : identifier
  }, '')
}

export { setCookie, getCookie }
