<script setup lang="ts">
import { computed, ref } from 'vue'

import { storeToRefs } from 'pinia'

import ProductAmount from '@/components/global/ProductAmount.vue'
import { Tooltip } from '@/componentsPure'
import useInteractionSource from '@/composables/useInteractionSource'
import { useProductItem } from '@/composables/useProductItem'
import type { ProductVariant } from '@/services/api/front/productTypes'
import { resizer } from '@/services/resizer'
import useDeviceDetectorStore from '@/store/pinia/useDeviceDetectorStore'
import useProductsStore from '@/store/pinia/useProductsStore'
import localizePrice from '@/utils/localizePrice'

type ProductVariantRowProps = {
  variant: ProductVariant
  selected: boolean
  index: number
}

const emits = defineEmits<{ clickOnVariant: [id: number] }>()

const props = defineProps<ProductVariantRowProps>()

const deviceDetectorStore = useDeviceDetectorStore()

const { breakpointUpLg } = storeToRefs(deviceDetectorStore)

const { getProductOrThrow } = useProductsStore()

const interactionInfo = ref({ order: props.index })

// we know that the product exists in store
const product = ref(getProductOrThrow(props.variant.id))

const { isCheaperPrice, bestCheapPriceInfo } = useProductItem(product)

// adds path part and index to interaction source path
useInteractionSource('ProductDetailVariant', interactionInfo)

function makeVariantImageSrcSet(url: string, width: number) {
  return `${resizer(url, width * 2)} 1.5x, ${resizer(url, width)} 1x`
}

const variantUnits = computed(() => {
  return `${localizePrice(product.value.computed.pricePerUnit.price)}/${
    product.value.computed.pricePerUnit.unit
  }`
})

const bestCheapPriceInfoLabel = computed(() => {
  return bestCheapPriceInfo.value.type !== 'sale' || breakpointUpLg.value
})
</script>

<template>
  <div>
    <Tooltip
      wrapper-class="w-100"
      direction="bottom"
      text-color="white"
      bg-color="neutrals-70"
      shadow
      nowrap>
      <template #message>
        <span v-text="variant.name" />
      </template>
      <div
        class="variant-row"
        :class="{ selected, pointer: !selected }"
        data-tid="variant-row">
        <div
          class="variant-row__inner"
          @click="emits('clickOnVariant', variant.id)">
          <div class="img-container">
            <img
              class="image"
              :alt="variant.name"
              loading="lazy"
              :srcset="makeVariantImageSrcSet(variant.image, 90)" />
          </div>

          <div class="price-box">
            <div class="d-flex">
              <div
                v-if="bestCheapPriceInfoLabel"
                class="text-neutrals-70 text-nowrap pr-1"
                v-text="bestCheapPriceInfo.label" />
              <div
                v-if="bestCheapPriceInfo.price"
                class="price-crossed">
                <s>
                  {{ localizePrice(bestCheapPriceInfo.price) }}
                </s>
              </div>
            </div>

            <strong
              :class="[
                'price',
                {
                  'text-contrast-red': isCheaperPrice,
                },
              ]"
              data-tid="multibuy-price"
              v-text="localizePrice(product.computed.price)" />
            <div
              class="price-unit text-neutrals-70"
              v-text="variantUnits" />
          </div>
        </div>

        <ProductAmount
          :product="product"
          has-matching-vendor-id
          :unlisted="product.detail?.unlisted"
          size="lg" />
      </div>
    </Tooltip>
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.variant-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  border-bottom: 1px solid color('gray-lighter');

  &__inner {
    display: flex;
    flex-grow: 1;
    align-items: center;
    line-height: 1.2;
  }

  .img-container {
    width: 50px;
    height: 62px;
  }

  .image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }

  .price-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 8px;
    text-align: left;

    .price-crossed,
    .price-unit {
      @include make-font-size(4);
    }

    .price {
      @include make-font-size(6);
    }
  }

  .desc {
    width: 90px;
    overflow: hidden;
    line-height: 1.2em;

    @include make-font-size(2);
    @include make-line-clamp(2);
  }

  &.selected {
    border-radius: 4px;
    outline: 1px solid color('gray-light');
    box-shadow:
      2px 2px 5px 0 color('gray-lighter'),
      -2px -2px 5px 0 color('gray-lighter');

    .desc {
      font-weight: var(--fontWeightBold);
    }
  }

  @include media-breakpoint-up(lg) {
    .price-box {
      .price {
        @include make-font-size(9);
      }
    }
  }
}
</style>
