import { createOperation } from '@/services/apiv2'

import type { PutCheckoutCreditRequest, PutCheckoutCreditResponse } from './checkoutTypes'

/**
 * Sets the amount of credits used for the order
 * @see https://sasandboxstdhotapidocs.z1.web.core.windows.net/#/Checkout/updateCredits
 */
const updateCredits = createOperation<
  PutCheckoutCreditResponse,
  PutCheckoutCreditResponse,
  undefined,
  PutCheckoutCreditRequest
>('/checkout/credit', {
  method: 'put',
})

export default updateCredits
