<script setup lang="ts">
import { CLUBS } from '@/components/Clubs/constants'
import useUser from '@/composables/useUser'

const { getUserClubEligibility } = useUser()

const isInBenjaminek = getUserClubEligibility[CLUBS.BENJAMINEK].isMember
const isInFullcare = getUserClubEligibility[CLUBS.FULL_CARE].isMember
</script>

<template>
  <slot
    :is-in-benjaminek="isInBenjaminek"
    :is-in-fullcare="isInFullcare" />
</template>
