import { ref, computed } from 'vue'

import capitalize from 'lodash-es/capitalize'
import { defineStore } from 'pinia'

import { getBreakpointNames, getMinMedia } from '../../services/responsiveMedia'

// @TODO We should remove dependency on css breakpoints. TS should be
// the single source of truth for breakpoints.
type Breakpoints = 'xs' | 'sm' | 'md' | 'lg' | 'xl'

type BreakpointGetters = {
  [key in Breakpoints as `breakpointUp${Capitalize<Breakpoints>}`]: boolean
} & {
  [key in Breakpoints as `breakpointDown${Capitalize<Breakpoints>}`]: boolean
}

const breakpointGetters = {} as unknown as BreakpointGetters

/**
 * Provides info about device and browser, and also about current breakpoint.
 */
const useDeviceDetectorStore = defineStore('useDeviceDetectorStore', () => {
  /**
   * List of breakpoints with info if they are active or not.
   */
  const breakpoints = ref(
    getBreakpointNames().map((breakpoint: string) => {
      return {
        name: breakpoint,
        matches: window.matchMedia(getMinMedia(breakpoint)).matches,
      }
    }),
  )

  // state
  const isIos = /iPad|iPhone|iPod/i.test(window.navigator.userAgent)
  const isAndroid = /Android/i.test(window.navigator.userAgent)
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    window.navigator.userAgent,
  )
  const isSafari = /^((?!chrome|android|crios).)*safari/i.test(window.navigator.userAgent)
  const isEdge = /Edg/i.test(window.navigator.userAgent)
  const isEdgeMobile = isMobile && isEdge
  const isTouch = 'ontouchstart' in window
  const isSpeechRecognition = 'SpeechRecognition' in window || 'webkitSpeechRecognition' in window
  const hasSpeechRecognition = isSpeechRecognition && !isSafari

  // getters
  /**
   * Returns name of current bootstrap breakpoint.
   */
  const getCurrentBootstrapBreakpointName = computed(() => {
    const activeBootstrapBreakpoints = breakpoints.value.filter((breakpoint) => breakpoint.matches)
    return activeBootstrapBreakpoints[activeBootstrapBreakpoints.length - 1].name
  })

  /**
   * Returns index of current breakpoint.
   */
  const breakpointActiveIndex = computed(() => {
    const filterActive = breakpoints.value.filter((breakpoint) => breakpoint.matches)
    return breakpoints.value.indexOf(filterActive[filterActive.length - 1])
  })

  /**
   * Sets value of breakpoints to true/false based on current breakpoint.
   */
  getBreakpointNames().forEach((breakpointName) => {
    const breakpointIndex = getBreakpointNames().indexOf(breakpointName)
    breakpointGetters[`breakpointDown${capitalize(breakpointName)}`] = computed(
      () => breakpointIndex >= breakpointActiveIndex.value,
    )

    breakpointGetters[`breakpointUp${capitalize(breakpointName)}`] = computed(
      () => breakpointIndex <= breakpointActiveIndex.value,
    )
  })

  /**
   * Adds event listeners to breakpoints to update their values.
   */
  breakpoints.value.forEach((breakpoint) => {
    const mql = window.matchMedia(getMinMedia(breakpoint.name))

    // TODO: delete this when we have solution for older browsers
    function mqlCallback(cb: (event: MediaQueryListEvent) => void) {
      mql.addEventListener ? mql.addEventListener('change', cb) : mql.addListener(cb)
    }
    mqlCallback((event) => {
      Object.assign(breakpoint ?? {}, {
        name: breakpoint.name,
        matches: event.matches,
      })
    })
  })

  return {
    ...breakpointGetters,
    breakpoints,
    isIos,
    isAndroid,
    isTouch,
    isSafari,
    isEdge,
    isEdgeMobile,
    isMobile,
    hasSpeechRecognition,
    getCurrentBootstrapBreakpointName,
  }
})

export default useDeviceDetectorStore
