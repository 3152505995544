<script setup lang="ts">
import { ref, computed, useSlots } from 'vue'

type ToggleProps = {
  modelValue?: boolean
  initVisible?: boolean
}

const props = withDefaults(defineProps<ToggleProps>(), {
  modelValue: undefined,
})

const emit = defineEmits<{
  'update:modelValue': [newValue: boolean]
}>()

const isOpen = ref(props.initVisible)

const isViewOpen = computed({
  get: () => props.modelValue ?? isOpen.value,
  set: (value) => {
    isOpen.value = value
    emit('update:modelValue', isOpen.value)
  },
})

const toggleView = () => {
  isViewOpen.value = !isViewOpen.value
}

const componentSlots = useSlots()
</script>

<template>
  <span>
    <slot
      v-if="isViewOpen"
      name="view" />
    <div
      v-if="!isViewOpen || !componentSlots.close?.()"
      @click.prevent="toggleView">
      <slot name="control" />
    </div>
    <div
      v-if="isViewOpen"
      @click.prevent="isViewOpen = false">
      <slot name="close" />
    </div>
    <slot v-if="isViewOpen && !componentSlots.view?.()" />
  </span>
</template>
