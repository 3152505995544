import { dataLayerPush } from './utils'

type ModalInitiator =
  | 'unknown'
  | 'save-address-button'
  | 'modal-address-link'
  | 'address-banner'
  | 'home-button'
  | 'warehouse-info-bar'
  | 'welcome-go-shopping'
  | 'add-to-cart'
  | 'preview-cart'

function modal(isOpen: boolean, location: string, initiator: ModalInitiator, activity = '') {
  dataLayerPush({
    event: 'modal',
    activity: activity || (isOpen ? 'open' : 'close'),
    location,
    initiator,
  })
}

export { modal as gtmEventToggleModal }
export type { ModalInitiator }
