import { dataLayerPush } from './utils'

const redirectCookieKey = 'redirectFrom'

function redirectUrl(redirectFrom: string) {
  dataLayerPush({
    event: 'redirect_url',
    click_url: redirectFrom,
  })
}

export { redirectUrl as gtmEventRedirectUrl, redirectCookieKey }
