import type { ResizeClubBannersType } from '@/components/BannerClub/resizeClubBanners'
import type { BannerThankYouPage } from '@/composables/banner/useBannerThankYouPage'
import type { Banner, BannerType } from '@/data/api/frontApi/banners/bannerTypes'

import { dataLayerPush } from './utils'

type PromotionName = 'view_promotion' | 'select_promotion'

type AdminBannerType =
  | BannerType
  | 'category'
  | 'mobile'
  | 'childClubBenjaminek'
  | 'fullCare'
  | 'clubGifts'
  | 'homepageSecondary'

function bannerClickOrImpression(
  name: PromotionName,
  banner: Banner | BannerThankYouPage | ResizeClubBannersType[number] | undefined,
  type: AdminBannerType,
  index?: number,
) {
  if (!banner) return

  dataLayerPush({
    event: name,
    promotion_id: banner.id.toString(),
    promotion_name: banner.name,
    creative_slot: type,
    creative_position: index ? index + 1 : 1,
    link: banner.link,
  })
}

export { bannerClickOrImpression as gtmEventBannerClickOrImpression }
export type { PromotionName, AdminBannerType }
