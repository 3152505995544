<script setup lang="ts">
import { computed, ref } from 'vue'

import useProductVariants from '@/components/Product/Detail/components/Variants/useProductVariants'
import { frontApiProductGetById } from '@/services/api/front/product'
import type { ProductVariant } from '@/services/api/front/productTypes'
import { t } from '@/services/translations'
import useProductsStore from '@/store/pinia/useProductsStore'

import ProductVariantRow from './ProductVariantRow.vue'

type ProductVariantsProps = {
  productId: number
  associationCode: string
}
const props = defineProps<ProductVariantsProps>()

const { setProduct } = useProductsStore()

const productId = computed(() => props.productId)

const associationCode = computed(() => props.associationCode)

const emit = defineEmits<{ select: [id: number] }>()

// any variant can be selected by clicking
const selectedVariantId = ref(props.productId)

const variants = useProductVariants(productId, associationCode)

// filter out current detail variant + these variants that don't have cumulative price
const filteredVariants = computed(() => {
  return (variants.value ?? []).filter(
    (variant) => !isSelected(variant) && hasCumulativePrice(variant),
  )

  function hasCumulativePrice(variant: ProductVariant) {
    return (variant.cumulativePrices ?? []).some(
      (price) => price.associationCode === props.associationCode,
    )
  }
  function isSelected(variant: ProductVariant) {
    return variant.id === selectedVariantId.value
  }
})

const showMore = ref(false)

const moreVariants = computed(() =>
  filteredVariants.value.length > 3 && !showMore.value
    ? filteredVariants.value.slice(0, 3)
    : filteredVariants.value,
)

async function handleSelect(id: number) {
  if (id === selectedVariantId.value) {
    return
  }
  selectedVariantId.value = id
  const {
    data: { product, breadcrumbs },
  } = await frontApiProductGetById(id)
  setProduct({ breadcrumbs, ...product })
  emit('select', id)
}
</script>

<template>
  <h3
    v-if="filteredVariants.length"
    class="variants-heading"
    v-text="t('productDetail.variantsHeading')" />
  <div>
    <ProductVariantRow
      v-for="(variant, index) in moreVariants"
      :key="variant.id"
      :variant="variant"
      :selected="selectedVariantId === variant.id"
      :index="index + 1"
      @click-on-variant="handleSelect(variant.id)" />
  </div>

  <button
    v-if="filteredVariants.length > 3 && !showMore"
    class="btn d-block mx-auto text-primary mt-3"
    @click="showMore = !showMore"
    v-text="t('productDetail.showMoreVariants')" />
</template>

<style scoped lang="scss">
@use '@/legacy' as *;

.variants-heading {
  margin-top: 32px;
  font-size: get-font-size(4);
  font-weight: 600;
}
</style>
