import type { LocationQuery } from 'vue-router'

/**
 * Converts URL query string to JS object. If there's an PHP array in query string,
 * it will be converted to JS array.
 * @param url URL.
 */
function convertUrlParamsToObj(url: string): LocationQuery {
  const paramsUrl = new URLSearchParams(url.split('?')[1])
  const paramsAsObject: Record<string, string | string[]> = {}

  paramsUrl.forEach((value, key) => {
    const arrayCheckRegex = /\[\d*\]/
    const isArray = !!arrayCheckRegex.exec(key)

    if (!isArray) {
      paramsAsObject[key] = value
    } else {
      const keyParam = key.replace(arrayCheckRegex, '')

      paramsAsObject[keyParam] = paramsAsObject[keyParam]
        ? [...paramsAsObject[keyParam], value]
        : [value]
    }
  })

  return paramsAsObject
}

export default convertUrlParamsToObj
