<script setup lang="ts">
import { useSlots } from 'vue'

import Icon from '@/components/Icon/Icon.vue'

const defaultEntityAndType = 'button'
const sizes = ['xs', 'sm', 'md', 'lg'] as const
const variants = [
  'action',
  'brand',
  'brand-light',
  'neutral-light',
  'neutral',
  'neutral-dark',
  'red',
  'green',
  'gray-dark',
  'dark-brand',
  'super-primary',
  'primary',
  'secondary',
  'tertiary',
  'peach',
] as const

export type ButtonVariants = (typeof variants)[number]

type IconProps = {
  name: string
  group?: string
  class?: string
}

type BaseButtonProps = {
  entity?: string // What type of Vue component or HTML tag will the button be rendered as.
  disabled?: boolean // HTML button 'disabled' attribute.
  type?: typeof defaultEntityAndType | 'submit' // HTML button 'type' attribute.
  to?: string | object // Path or object of route where the button leads to.
  size?: (typeof sizes)[number] // Defines button size.
  variant: (typeof variants)[number] // Defines button color variant.
  pill?: boolean // When true, button shape curves more like a pill.
  inverted?: boolean // When true, button color variant is inverted.
  opaqueWhenDisabled?: boolean // Disables opacity of disabled button. Useful when we want the button to be colour-independent of its surroundings (e.g. on a dark or saturated-color background)
  borderless?: boolean // Removes border when true.
  iconLeft?: IconProps // Left icon object containing name, group and optionally css class
  iconRight?: IconProps // Right icon object containing name, group and optionally css class
}

const props = withDefaults(defineProps<BaseButtonProps>(), {
  entity: defaultEntityAndType,
  type: defaultEntityAndType,
  to: undefined,
  size: 'xs',
  iconLeft: undefined,
  iconRight: undefined,
})

const componentSlots = useSlots()
</script>

<template>
  <Component
    :is="props.entity"
    :type="props.type"
    :to="props.to"
    :class="[
      'btn',
      `btn--${props.size}`,
      `btn--${props.variant}`,
      {
        'rounded-pill': props.pill,
        'btn--inverted': props.inverted,
        'btn--borderless': props.borderless,
        'btn--disabled': props.disabled,
        'btn--opaque': props.disabled && props.opaqueWhenDisabled,
      },
    ]"
    :disabled="props.disabled || null"
    :tabindex="props.disabled ? -1 : 0">
    <!-- @slot Left content -->
    <slot name="leftContent">
      <Icon
        v-if="props.iconLeft"
        :icon="props.iconLeft.name"
        :group="props.iconLeft.group"
        :class="props.iconLeft.class || 'mr-1'" />
    </slot>
    <span class="d-inline-block">
      <!-- @slot Default inner button content -->
      <slot />
    </span>
    <!-- @slot Right content -->
    <slot
      v-if="!!componentSlots.rightContent?.() || props.iconRight"
      name="rightContent">
      <Icon
        v-if="props.iconRight"
        :icon="props.iconRight.name"
        :group="props.iconRight.group"
        :class="props.iconRight.class || 'ml-1'" />
    </slot>
  </Component>
</template>

<style src="./styles/main.scss" lang="scss" scoped />
<style src="./styles/sizes.scss" lang="scss" scoped />
<style src="./styles/variants.scss" lang="scss" scoped />
