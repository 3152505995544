import type { Dayjs } from 'dayjs'

import { t } from '@/services/translations'

import formatDate from './formatDate'
import formatTime from './formatTime'

/**
 * Formats given date to relative "till" format. Eg.
 * "today until 23:58"
 * "until 23:58 tomorrow"
 * "until 31. 2. 2123 23:58"
 * @param date Date to create the readable string from
 * @returns Formatted date or null if given date is invalid.
 */
function formatUntilDate(date: Dayjs): string | null {
  if (!date?.isValid?.()) {
    return null
  }

  if (date.tz().isToday()) {
    return t('additionalOrder.untilTodayFormat', { time: formatTime(date) })
  }

  if (date.tz().isTomorrow()) {
    return t('additionalOrder.untilTomorrowFormat', { time: formatTime(date) })
  }

  return t('additionalOrder.untilFormat', { date: formatDate(date), time: formatTime(date) })
}

export default formatUntilDate
