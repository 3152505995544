import appConfig from '@/services/appConfig'

import { fetchPut } from '../fetch'

const modulePath = 'privacy'

type NewPrivacySettings = {
  id?: number
  code?: string
  value: boolean
}

type PrivacySettingsRequest = {
  settings: NewPrivacySettings[]
}

type PrivacySettingsItem = {
  code: string
  description: string
  id: number
  title: string
  type: string
  value: boolean
}

type PrivacySettings = {
  title: string
  items: PrivacySettingsItem[]
}

type PrivacySettingsResponse = {
  reviewed: boolean
  settings: PrivacySettings[]
}

function putSettings(
  code: NonNullable<NewPrivacySettings['code']>,
  value: NewPrivacySettings['value'],
) {
  const requestData: PrivacySettingsRequest = {
    settings: [
      {
        code,
        value,
      },
    ],
  }

  return fetchPut<PrivacySettingsResponse>(
    `${appConfig.frontApiBaseUrl}${modulePath}/settings`,
    requestData,
  )
}

export { putSettings as frontApiPutPrivacySettings }
