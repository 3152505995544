import { ref, computed } from 'vue'

import { useQuery } from '@tanstack/vue-query'

import QueryTime from '@/constants/QueryTime'
import { addressQueryKeys } from '@/constants/queryKeys'
import { frontApiGetAddressSuggest } from '@/services/api/front/address'

function useAddressGetSuggestions() {
  const suggestionInputValue = ref('')
  const enabledSuggestion = computed(() => !!suggestionInputValue.value)

  const { data: suggestions, isSuccess } = useQuery({
    queryKey: addressQueryKeys.suggestions(suggestionInputValue),
    queryFn: () => frontApiGetAddressSuggest(suggestionInputValue.value),
    select: (responseObject) => responseObject.data.suggestions,
    enabled: enabledSuggestion,
    staleTime: QueryTime.MINUTE,
    gcTime: QueryTime.MINUTE,
  })

  return {
    isSuccess,
    suggestionInputValue,
    suggestions,
  }
}

export default useAddressGetSuggestions
