import { storeToRefs } from 'pinia'

import { ensureConfig } from '@/composables/useConfig'
import loadExternalJs from '@/services/loadExternalJs'
import { isCookieConsentGranted } from '@/services/privacy'
import useCartStore from '@/store/pinia/useCartStore'

import appConfig from '../appConfig'
import { currentEnv } from '../environment'
import logger from '../logger'

const log = logger('yottly')

// @todo Move yottly to a separate service.

// Create a global yottly callback
window._yottlyOnload = window._yottlyOnload || []

/**
 * Get yottly trackpoint ID.
 * @returns String with yottly trackpoint ID or null if yottly is disabled.
 */
async function getYottlyTrackPointId() {
  const config = await ensureConfig()
  if (
    !config.analytics.yottlyTracking.enable ||
    !isCookieConsentGranted('analytics_storage') ||
    currentEnv === 'localhost'
  ) {
    return null
  }

  return config.analytics.yottlyTracking.trackPointId
}

// resolve() is called right after yottly script is initialized
const yottlyLoaded = new Promise<void>((resolve) => {
  window._yottlyOnload?.push(() => {
    resolve()
  })
})

function _getProductsForInteraction() {
  const cartStore = useCartStore()
  const { cartGetters } = storeToRefs(cartStore)
  return cartGetters.value.getCartProductsFullData.map((product) => {
    return {
      productId: `${product.id}`,
      amount: product.quantity,
    }
  })
}

function _mapOrderProducts(orderProducts) {
  return orderProducts.map((item) => ({ productId: `${item.product.id}`, price: item.price }))
}

/**
 * Ensures Yottly script is loaded and properly initialized.
 */
async function ensureYottly() {
  const yottlyTrackpointId = await getYottlyTrackPointId()
  if (getYottlyTrackPointId === null) return

  const scriptUrl = `https://yottlyscript.com/script.js?tp=${yottlyTrackpointId}`

  try {
    await loadExternalJs(scriptUrl)
    await yottlyLoaded
  } catch (e) {
    // Only log locally, not in production
    log({
      message: 'Failed to load Yottly script.',
      severity: 'error',
    })
  }
}

async function initYottly() {
  const config = await ensureConfig()

  if (isCookieConsentGranted('analytics_storage')) {
    const yottlyTrackpointId = await getYottlyTrackPointId()
    if (yottlyTrackpointId !== null) {
      await ensureYottly()
    }

    // isUserSignedIn from useUser - cant use because vuequery must be in setup function
    if (config.user.email) {
      // getUser id - same problem
      window.diffAnalytics?.customerLoggedIn(config.user.luigisboxUserId ?? '')
    }
  }
}

async function setCartInteraction(dataCart = _getProductsForInteraction(), onOrderPage = false) {
  const yottlyTrackpointId = await getYottlyTrackPointId()
  if (yottlyTrackpointId !== null) {
    await ensureYottly()
    window.diffAnalytics?.cartInteraction({
      content: dataCart,
      onOrderPage,
    })
  }
}

/**
 * @see https://doc.samba.ai/knowledge-base/customer-tracking/?lang=en#diffanalytics-orderorderinfo
 */
async function setOrder(orderProducts) {
  const yottlyTrackpointId = await getYottlyTrackPointId()
  if (yottlyTrackpointId !== null) {
    await ensureYottly()
    const orderItems = _mapOrderProducts(orderProducts)
    window.diffAnalytics?.order({
      content: orderItems,
    })
  }
}

async function setProductId(productId) {
  const yottlyTrackpointId = await getYottlyTrackPointId()
  if (yottlyTrackpointId !== null) {
    await ensureYottly()
    window.diffAnalytics?.productId(`${productId}`)
  }
}

async function setProductInteraction(productId) {
  const yottlyTrackpointId = await getYottlyTrackPointId()
  if (yottlyTrackpointId !== null) {
    await ensureYottly()
    window.diffAnalytics?.productInteraction({
      productId: `${productId}`,
      yottlyOnline: {
        campaign: 'default',
        recommender: 'basket-complements',
      },
    })
  }
}

/**
 * Checks whether given interaction source contains product provided by Yottly.
 * @param {Array<Object>} interactionSource Interaction source tree.
 * @returns {boolean} True if interaction source tree contains element provided by Yottly.
 * @private
 */
function isProductFromYottly(interactionSource) {
  return interactionSource?.some((item) => item.yottly)
}

const getPersonaliser = {
  async cartComplement() {
    const cartStore = useCartStore()
    const { cartGetters } = storeToRefs(cartStore)
    try {
      const yottlyTrackpointId = await getYottlyTrackPointId()
      if (yottlyTrackpointId === null) {
        throw new Error('Yottly is not enabled')
      }

      await ensureYottly()
      const yottlyResponse = await new Promise<any>((resolve, reject) => {
        window.diffAnalytics?.personaliser().basketComplements(
          {
            count: appConfig.defaultPersonalizedWidgetProductCount,
            content: cartGetters.value.getCartProducts.map((product) => product.id),
          },
          (err, data) => {
            if (err instanceof Error) {
              reject(err)
            }
            resolve(data)
          },
        )
      })
      return yottlyResponse.recommendation.map((product) => parseInt(product.id))
    } catch (e) {
      return []
    }
  },
}

export {
  initYottly,
  setCartInteraction as yottlySetCartInteraction,
  setOrder as yottlySetOrder,
  setProductId as yottlySetProductId,
  setProductInteraction as yottlySetProductInteraction,
  isProductFromYottly,
  getPersonaliser as yottlyGetPersonaliser,
}
