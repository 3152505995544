<script setup>
import { useClubSectionTitleProps } from '@/components/Clubs/ClubSectionTitle/useClubSectionTitle'

const props = defineProps(useClubSectionTitleProps)
</script>

<template>
  <p
    :class="`text-${props.titleColor} font-weight-bold text-7 text-center text-uppercase mb-4`"
    v-text="props.title" />
</template>
