import { type Ref, computed, watch } from 'vue'

import { useQuery } from '@tanstack/vue-query'

import QueryTime from '@/constants/QueryTime'
import { phoneQueryKeys } from '@/constants/queryKeys'
import { frontApiPostPhoneValidate } from '@/services/api/front/phone'
import type { ResponseNotOKError } from '@/services/api/makeRequest'
import type { ApiEmptyResponse } from '@/services/api/types'

type ReturnCbFn = (data: ApiEmptyResponse | undefined) => void

function usePhoneValidate(validatedValue: Ref<string>) {
  const enabledValidation = computed(() => !!validatedValue.value)
  let onNumberChangedCb: ReturnCbFn

  function disableRetries(failureCount: number, error: ResponseNotOKError) {
    if (error.response.data.status === 400) return false

    return failureCount < 2
  }

  const { data: phoneValidation } = useQuery({
    queryKey: phoneQueryKeys.validate(validatedValue),
    queryFn: () => frontApiPostPhoneValidate(validatedValue.value),
    select: (responseObject) => responseObject.data,
    enabled: enabledValidation,
    staleTime: QueryTime.MINUTE,
    gcTime: QueryTime.MINUTE,
    // disable retries for 40x errors. In case sending short phone numbers
    retry: disableRetries,
  })

  watch(phoneValidation, () => {
    onNumberChangedCb?.(phoneValidation.value)
  })

  return (callbackFn: ReturnCbFn) => {
    onNumberChangedCb = callbackFn
  }
}

export default usePhoneValidate
