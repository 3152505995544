import { fetchGet } from '@/services/api/fetch'

import appConfig from '../../appConfig'

export type StaticPage = {
  url: string
  title: string
  html: string
  css: string
  js: string
  vendorId: number
  shouldBeTitleHiddenOnFront: boolean
}

export type StaticBlock = {
  code: string
  html: string
}

export type ResponseObject<Data> = {
  data: Data
  headers: object
}

export async function fetchStaticPage(slug: string) {
  const url = `${appConfig.frontApiBaseUrl}static-page/${slug}`
  return (await fetchGet(url)) as unknown as ResponseObject<StaticPage>
}

export async function fetchStaticBlock(slug: string) {
  const url = `${appConfig.frontApiBaseUrl}static-block/${slug}`
  return (await fetchGet(url)) as unknown as ResponseObject<StaticBlock>
}
