<script setup lang="ts">
import { ref, computed } from 'vue'

import { vOnClickOutside } from '@vueuse/components'
import { storeToRefs } from 'pinia'

import Icon from '@/components/Icon/Icon.vue'
import CCreate from '@/components/Product/Detail/components/ShoppingList/Create/ProductDetailShoppingListCreate.vue'
import AToggle from '@/components/stories/atoms/toggle/Toggle.vue'
import type { TypeModelValue } from '@/components/stories/molecules/checklist/Checklist.vue'
import MChecklist from '@/components/stories/molecules/checklist/Checklist.vue'
import type { Product } from '@/composables/useProductItem'
import { t } from '@/services/translations'
import useProductDetailStore from '@/store/pinia/useProductDetailStore'

type ProductDetailShoppingListControlProps = {
  product: Product
}

const props = defineProps<ProductDetailShoppingListControlProps>()

const productDetailStore = useProductDetailStore()
const { addProductToShoppingList, removeProductFromShoppingList } = productDetailStore
const { shoppingLists } = storeToRefs(productDetailStore)

const isOpenContent = ref(false)

const getShoppingListsChecklist = computed(() =>
  shoppingLists.value.map((list) => ({
    id: list.id,
    name: list.name,
    value: !!props.product.origin.detail?.shoppingListIds?.includes(list.id), // why is shoppingListIds in product?
  })),
)

function handleShoppingListItemState(shoppingListItem: TypeModelValue) {
  if (typeof shoppingListItem.id !== 'number') throw new Error('Shopping list id must be a number')
  if (shoppingListItem.value) {
    addProductToShoppingList(props.product, shoppingListItem.id)
  } else {
    removeProductFromShoppingList(props.product, shoppingListItem.id)
  }
}
</script>

<template>
  <AToggle
    v-model="isOpenContent"
    v-on-click-outside="() => (isOpenContent = false)"
    class="shopping-list-control">
    <template #control>
      <button class="control">
        <Icon
          :icon="isOpenContent ? 'cross' : 'plus'"
          class="control-icon" />
        {{ t('product.shoppingList.open') }}
      </button>
    </template>

    <div class="content p-3">
      <MChecklist
        v-if="shoppingLists.length"
        :model-value="getShoppingListsChecklist"
        class="checklist pb-3 mb-3"
        @changed-item="handleShoppingListItemState" />
      <AToggle class="shopping-list-control">
        <template #control>
          <button
            class="btn-add a-link-underline"
            v-text="t('product.shoppingList.create')" />
        </template>
        <CCreate class="mt-3 create-form" />
      </AToggle>
    </div>
  </AToggle>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.shopping-list-control {
  position: relative;
  z-index: $zix-above;
  display: block;
  min-width: 300px;
  text-align: left;
}

.control {
  @include make-font-scale(4);

  width: 100%;
  min-width: 215px;
  height: 42px;
  font-weight: $font-weight-bold;
  color: color('primary');
  text-align: center;
  background: color('white');
  border: none;

  @include media-breakpoint-up(md) {
    padding-right: 4px;
    text-align: right;
  }
}

.control-icon {
  position: relative;
  top: -2px;
  left: -2px;
}

.content {
  position: absolute;
  top: calc(100% - 1px);
  width: 100%;
  background: color('white');
  border: 1px solid color('gray-light');

  .create-form {
    width: calc(100% - 33px);
  }
}

.checklist {
  border-bottom: 1px solid color('gray-light');
}

.btn-add {
  padding: 0;
  background: none;
  border: 0;
}
</style>
