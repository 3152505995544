import type { OrdersData } from '@/services/api/ordersTracking/ordersTrackingTypes'
import { createOperation } from '@/services/apiv2'

import type { EarlyDeliveryPathParams, PostEarlyDeliveryRequest } from './earlyDeliveryTypes'

const earlyDeliverySetting = createOperation<
  OrdersData,
  OrdersData,
  EarlyDeliveryPathParams,
  PostEarlyDeliveryRequest
>('/:orderNumber/early-delivery-setting', {
  method: 'post',
})

export default earlyDeliverySetting
