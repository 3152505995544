import { createOperation } from '@/services/apiv2'

import type { BenjaminekChildrenResponse } from './benjaminekTypes'

/**
 * Sends GET request for getting children registered in Benjaminek club.
 * @see https://sasandboxstdhotapidocs.z1.web.core.windows.net/#/Benjaminek/readBenjaminekChildren
 * @returns Promise of the API response with children registered in Benjaminek club.
 */
const getChildren = createOperation<BenjaminekChildrenResponse>('/benjaminek/children', {
  method: 'get',
})

export default getChildren
