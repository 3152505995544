import { createOperation } from '@/services/apiv2'

import type { BannersParams, BannersResponse } from './bannerTypes'

const getBanners = createOperation<
  BannersResponse,
  BannersResponse,
  undefined,
  undefined,
  BannersParams
>('/banners', {
  method: 'get',
})

export default getBanners
