import type { RouteLocationNormalized } from 'vue-router'

// Routes to selected compoments, that are always considered as SPA routes, history push.
const selectedComponents = [
  'Home',
  'PageProducts',
  'PageProductDetail',
  'PageProfileClub',
  'PageThankYou',
  'BrandList',
  'BrandDetail',
  'Credits',
]

function getComponent(route: RouteLocationNormalized) {
  return typeof route.matched[0]?.meta.component === 'string'
    ? route.matched[0].meta.component
    : null
}

export function isSpaRoute(to: RouteLocationNormalized, from: RouteLocationNormalized) {
  const fromComponent = getComponent(from)
  const toComponent = getComponent(to)
  if (!fromComponent || !toComponent) {
    return false
  }

  // Routes to same component - is SPA route, history push.
  // @todo Page "Profile" is dependent on this logic. We must rewrite Profile completely
  // in order to remove matchingComponent logic below.
  const toMatchingComponent = fromComponent === toComponent

  // Routes to static page (recognize by component name) - not SPA route, should reload.
  const toStaticPage = toComponent === 'PageStatic'

  const toSelectedComponent =
    selectedComponents.includes(fromComponent) && selectedComponents.includes(toComponent)

  // Routes from OR to ForceReload component - not SPA route, should reload.
  const isForceReload = fromComponent === 'ForceReload' || toComponent === 'ForceReload'

  return !toStaticPage && !isForceReload && (toMatchingComponent || toSelectedComponent)
}
