import { createOperation } from '@/services/apiv2'

import type {
  BenjaminekRegistrationRequest,
  BenjaminekRegistrationResponse,
} from './benjaminekTypes'

/**
 * Sends POST request for registration in Benjaminek club.
 * @see https://sasandboxstdhotapidocs.z1.web.core.windows.net/#/Benjaminek/createBenjaminekRegistration
 * @param agreedWithConditions Whether the customer agreed to the terms and conditions.
 * @param children Array of children data objects.
 * @returns Promise of the API response with translatedMessage (success) or context with translatedDetail (error).
 */
const registration = createOperation<
  BenjaminekRegistrationResponse,
  BenjaminekRegistrationResponse,
  undefined,
  BenjaminekRegistrationRequest
>('/benjaminek/registration', {
  method: 'post',
})

export default registration
