import { t } from '@/services/translations'

import logApmError from '../logApmError'
import { toastError } from '../toast'
import AuthError from './AuthError'
import { MsalRedirectFlow } from './aadConstants'
import getMsalInstance from './getMsalInstance'
import getMsalRedirectResponse from './getMsalRedirectResponse'
import launchMsalRedirect from './launchMsalRedirect'
import type { MsalExtraQueryParams } from './launchMsalRedirect'
import msalState from './msalState'

/**
 * Triggers login redirect.
 * @param finalUrl URL to redirect after success. If not set, trigger URL is used.
 */
async function aadLogin(finalUrl?: string, extraQueryParameters?: MsalExtraQueryParams) {
  if (msalState.inProgress) return
  msalState.inProgress = true

  try {
    const msalInstance = await getMsalInstance()
    await getMsalRedirectResponse() // Clear response from previous redirect without processing it
    await launchMsalRedirect(msalInstance, {
      flow: MsalRedirectFlow.signUpSignIn,
      finalUrl,
      extraQueryParameters,
    })
    msalState.inProgress = false
  } catch (error) {
    logApmError(new AuthError('login_error', error))
    toastError(t('auth.error.general'))
  }
}

export default aadLogin
