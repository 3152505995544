import { storeToRefs } from 'pinia'

import type {
  CumulativePrices,
  PricePerUnit,
  ProductOrigin,
} from '@/services/api/front/productTypes'
import useCartStore from '@/store/pinia/useCartStore'

/**
 * Get product cumulative price step.
 * @param {object} product
 * @param {number} [product.quantity]
 * @param {array} [product.cumulativePrices]
 * @returns {?object} Current cumulative price step.
 * @todo this should be removed too, last place where it is used is in amountSyncDom.js
 */
function getCumulativePriceStep({
  quantity,
  cumulativePrices,
}: {
  quantity: number
  cumulativePrices: CumulativePrices[]
}) {
  if (
    !cumulativePrices ||
    cumulativePrices.length < 1 ||
    quantity <
      cumulativePrices.reduce((prev, curr) => (curr.quantity <= prev.quantity ? curr : prev))
        .quantity
  ) {
    return null
  }

  return cumulativePrices.reduce((prev, curr) =>
    quantity > prev.quantity && quantity >= curr.quantity ? curr : prev,
  )
}

/**
 * Get product price and step information
 * @returns price - price of product (calculated using applied steps if any)
 * @returns pricePerUnit - price per unit of product (calculated using applied steps if any)
 * @returns currentStep - current step that is applied to product
 * @returns nextStep - next step that will be applied to product after `remainingQuantity` is added
 * @returns groups - all groups that this product is part of
 * @returns effectiveQuantity - quantity that has been used for calculation of steps and price
 * @returns remainingQuantity - quantity that is left to reach next step
 */
function getProductPrice(product: ProductOrigin & { quantity: number }): {
  price: number
  pricePerUnit: PricePerUnit
  currentStep: CumulativePrices | null
  nextStep: CumulativePrices | null
  groups: (string | null)[]
  remainingQuantity: number | null
  effectiveQuantity: number
} {
  // find all groups that this product is part of
  // groups contain all groups, defined by associationCode
  const { assocGroups } = storeToRefs(useCartStore())
  const groups = _getProductGroups(product)

  // these variables will contain final values after iteration
  let currentStep: CumulativePrices | null = null
  let nextStep: CumulativePrices | null = null
  let remainingQuantity: number | null = null
  let effectiveQuantity: number = product.quantity ?? 0

  // for each group, we will try to find next step
  for (const group of groups) {
    // quantity of products in this group without current product
    const quantity = (group ? assocGroups.value[group]?.count : product.quantity) ?? 0

    const groupSteps = product.cumulativePrices.filter(
      (step) => (step.associationCode ?? null) === group,
    )

    let partCurrentStep: CumulativePrices | null = null
    let partNextStep = groupSteps[0] ?? null
    let partRemainingQuantity: number | null = partNextStep
      ? partNextStep?.quantity - quantity
      : null

    // let's find current and next step
    for (let i = 0; i < groupSteps.length; i++) {
      const step = groupSteps[i]
      if (step.quantity <= quantity) {
        partCurrentStep = step
        partNextStep = groupSteps[i + 1] || null
        partRemainingQuantity = partNextStep ? partNextStep.quantity - quantity : null
      }
    }

    if (effectiveQuantity < quantity) {
      effectiveQuantity = quantity
    }

    // if current step is better than previous found one let's use it
    if (
      (!currentStep && partCurrentStep) ||
      (currentStep && partCurrentStep && currentStep.price > partCurrentStep.price)
    ) {
      currentStep = partCurrentStep
    }

    if (
      (!nextStep && partNextStep) ||
      (partNextStep && nextStep && nextStep.price > partNextStep.price)
    ) {
      nextStep = partNextStep
      remainingQuantity = partRemainingQuantity
    }
  }

  return {
    price: currentStep?.price ?? product.price,
    pricePerUnit: currentStep?.pricePerUnit ?? product.pricePerUnit,
    currentStep,
    nextStep,
    groups,
    remainingQuantity,
    effectiveQuantity,
  }
}

/**
 * Get product cumulative or sale/action discount.
 * @param {object} product
 * @returns {number} Discount.
 */
function getTotalDiscount(product: ProductOrigin & { quantity: number }) {
  if (!product.recommendedPrice) {
    return 0
  }
  return (product.recommendedPrice - getProductPrice(product).price) * product.quantity
}

function _getProductGroups(product: ProductOrigin & { quantity: number }) {
  // all groups this product belongs to (defined by associationCode, including null associationCode
  return product.cumulativePrices.reduce(
    (acc, price) => {
      const code = price.associationCode ?? null
      if (!acc.includes(code)) {
        acc.push(code)
      }
      return acc
    },
    [] as (string | null)[],
  )
}

export { getProductPrice, getCumulativePriceStep, getTotalDiscount }
