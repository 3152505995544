<script setup lang="ts">
/**
 * Wrapper component for useInteractionSource composable.
 * If you want data to be reactive, pass reactive 'data' prop.
 * For more detail information about data and reactivity, see useInteractionSource.
 *
 * @see useInteractionSource
 */
import useInteractionSource, {
  type InteractionSourceValue,
} from '@/composables/useInteractionSource'

type InteractionSourceProps = {
  name: string
  data?: Record<string, InteractionSourceValue>
}

const props = defineProps<InteractionSourceProps>()

useInteractionSource(props.name, props.data)
</script>

<template>
  <slot />
</template>
