<script setup lang="ts">
type GiftsClubWrapperProps = {
  title: string
  description?: string
}

const props = defineProps<GiftsClubWrapperProps>()
</script>

<!-- Wrapper component with title and description for components on Gifts club page in Profile -->
<template>
  <div class="wrapper">
    <h3 class="text-title text-8 text-brand">
      {{ props.title }}
    </h3>
    <p
      v-if="props.description"
      class="text-description text-4 text-brand">
      {{ props.description }}
    </p>

    <!-- Slot with content -->
    <slot />
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.wrapper {
  padding: 16px 8px;
  background-color: color('gray-lighter');
  border-radius: var(--borderRadiusMedium);

  @include media-breakpoint-up(lg) {
    padding: 24px;
  }
}

.text-title {
  padding: 8px;
  margin: 0;
  font-weight: bold;
  text-align: center;
}

.text-description {
  padding: 8px;
  margin-bottom: 16px;
  text-align: center;
}
</style>
