<script setup>
import { computed } from 'vue'

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  name: {
    type: String,
    default: null,
  },
  formValue: {
    type: String,
    default: null,
  },
  variant: {
    type: String,
    default: null,
  },
})

const emit = defineEmits(['update:modelValue'])

const checked = computed({
  get() {
    return props.modelValue
  },
  set(newValue) {
    emit('update:modelValue', newValue)
  },
})
</script>

<template>
  <label
    :class="[
      'form-radio__label',
      {
        'form-radio__label--checked': modelValue === formValue,
        'form-radio__label--disabled': disabled,
        'form-radio__label--small': variant === 'small',
      },
    ]">
    <input
      v-model="checked"
      type="radio"
      :name="name"
      :value="formValue"
      :disabled="disabled || null"
      class="form-radio__input" />
    <slot />
  </label>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

$box-size: 16px;
$box-size-small: 14px;

.form-radio {
  &__label {
    position: relative;
    padding-left: em($box-size + 10);
    line-height: em($box-size);

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: em($box-size);
      height: em($box-size);
      content: '';
      background: color('white');
      border: em(1px) solid color('gray');
      border-radius: 50%;
    }

    &::after {
      position: absolute;
      top: em(4px);
      left: em(3px);
      width: em(10px);
      height: em(6px);
      content: '';
      border-bottom: em(2px) solid color('white');
      border-left: em(2px) solid color('white');
      transform: rotate(-45deg);
    }

    &--checked::before {
      background: color('gray-darker');
      border-color: color('gray-darker');
    }

    &--disabled {
      opacity: 0.7;

      &::before {
        opacity: 0.5;
      }
    }

    &--small {
      padding-left: em($box-size-small + 4);
      line-height: em($box-size-small);

      &::before {
        width: em($box-size-small);
        height: em($box-size-small);
      }

      &::after {
        top: em(5px);
        width: em(8px);
        height: em(4px);
      }
    }
  }

  &__input {
    position: absolute;
    top: 0;
    left: 0;
    z-index: $zix-base;
    width: 100%;
    height: 100%;
    margin: 0;
    cursor: pointer;
    opacity: 0;

    &:disabled {
      cursor: default;
    }
  }
}
</style>
