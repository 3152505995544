import type { InteractionSourceRaw } from '@/composables/useInteractionSource'

/**
 * Gets name of last element that user directly interacted with.
 * @param {Array<Object>} interactionSource Tree of components that were interacted with. Contains name of component and other optional info.
 * @returns {string} Element's name
 */
function getGtmElementType(interactionSource: InteractionSourceRaw[]) {
  const gtmElements = interactionSource.filter((source) => source.element)
  return gtmElements.length > 0 ? gtmElements[gtmElements.length - 1].element : null
}

export { getGtmElementType }
