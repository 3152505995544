<script setup lang="ts">
import { computed } from 'vue'

import { FormCheckbox } from '@/components/Form'
import useReturnableCarrier from '@/composables/product/useReturnableCarrier'
import useInteractionSource from '@/composables/useInteractionSource'
import type { Product } from '@/composables/useProductItem'
import { t } from '@/services/translations'

type ReturnableCarrierInfoProps = {
  product: Product
}

const props = defineProps<ReturnableCarrierInfoProps>()

const getInteractionSource = useInteractionSource('returnableItems', {
  element: 'checkbox_returnable_item',
})

const { inputCarrierValue } = useReturnableCarrier(props.product, getInteractionSource())

const carrierUnitPrice = computed(() => props.product.origin.returnableCarrier?.price ?? 0)
</script>

<template>
  <div class="checkbox-container">
    <FormCheckbox
      v-model="inputCarrierValue"
      class="input-pointer">
      {{ t('productDetail.returnableCarrierInfo', { price: carrierUnitPrice }) }}
    </FormCheckbox>
  </div>
</template>

<style scoped lang="scss">
@use '@/legacy' as *;

.checkbox-container {
  position: relative;
  padding-left: 4px;
  margin: 16px 0;
  font-size: 16px;
}

.input-pointer {
  cursor: pointer;
}
</style>
