<script setup lang="ts">
import Icon from '../Icon/Icon.vue'

type TitleSectionProps = {
  title?: string
  link?: string
  btnText?: string
}
const props = defineProps<TitleSectionProps>()
</script>

<template>
  <div class="title-section">
    <!-- TODO href: Change to RouterLink after BE starts sending a relative link -->
    <a
      v-if="props.link && props.title"
      :href="props.link">
      <h3
        class="title"
        data-tid="section-title"
        v-text="props.title" />
    </a>
    <h3
      v-else-if="props.title"
      class="title"
      v-text="props.title" />

    <!-- TODO href: Change to RouterLink after BE starts sending a relative link -->
    <a
      v-if="props.link && props.btnText"
      :href="props.link"
      class="btn font-weight-bold d-flex align-items-center">
      {{ props.btnText }}
      <Icon
        class="control-icon"
        icon="arrow-button" />
    </a>
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.title-section {
  display: flex;
  align-items: flex-end;
  width: 100%;
  min-height: $titleSectionHeight;
  padding-bottom: 10px;
  border-bottom: 1px solid color('gray-light');
}

.title {
  @include make-line-clamp(1);
  @include make-font-scale(8);

  margin: 0;
  text-overflow: ellipsis;
  word-break: break-word;
}

.btn {
  @include make-font-scale(4);

  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  padding: 5px 10px;
  margin-left: auto;
  color: color('primary');

  @include media-breakpoint-up(md) {
    padding: 8px 25px 7px;
  }

  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
  }
}

.control-icon {
  $size: 24px;

  width: $size;
  height: $size;
  margin-bottom: -1px;
}
</style>
