<script setup lang="ts">
import Icon from '@/components/Icon/Icon.vue'
import ProductItemBox from '@/components/ProductItemBox'
import { CarouselWrapper, CarouselItem } from '@/components/global/Carousel'
import type { Product } from '@/composables/useProductItem'
import { t } from '@/services/translations'

type ProductCarouselProps = {
  products: Product[]
  ghost?: boolean
  visibilityRatio?: number
  noProductMsg?: string
  productDetailReplacement?: boolean
}

const props = defineProps<ProductCarouselProps>()
</script>

<template>
  <div class="product-carousel">
    <CarouselWrapper
      :hidden-control="props.ghost"
      :visibility-ratio="props.visibilityRatio">
      <template v-if="props.products.length">
        <CarouselItem
          v-for="(product, index) in props.products"
          :key="`product${index}`">
          <ProductItemBox
            :product="product"
            :order="index + 1"
            separated />
        </CarouselItem>
      </template>
      <div
        v-else
        class="align-self-center m-auto p-2 text-gray text-center">
        <Icon
          icon="no-grocery-found"
          group="error"
          class="d-block mx-auto mb-3 no-product-icon" />
        <span
          v-if="props.productDetailReplacement"
          v-text="t('productDetail.noReplacements')" />
        <span
          v-else
          v-html="props.noProductMsg || t('widget.widgetHasNoProducts')" />
      </div>
    </CarouselWrapper>
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.product-carousel {
  box-sizing: content-box;
  height: $productItemBoxHeight;

  @include media-breakpoint-up(lg) {
    height: $productItemBoxHeightLg;
  }

  &--border {
    margin-top: -1px;
    border: 1px solid color('gray-light');
  }
}

.no-product-icon {
  width: 80px;
  height: 80px;
}
</style>
