import type { DeliveryWindowSlotData } from '@/services/analytics/gtm/deliveryWindow'
import type { TimeslotTime } from '@/store/pinia/useTimeslotsStore'
import { formatTime } from '@/utils/date'

function getSlotForAnalytics(time: TimeslotTime): DeliveryWindowSlotData {
  return {
    time: `${formatTime(time.start)}-${formatTime(time.end)}`,
    price: time.price,
    is_available: !time.unavailable && !time.closed,
    is_ecologic: time.isEco,
  }
}

export default getSlotForAnalytics
