import { computed, type ComputedRef, type Ref } from 'vue'
import { useRouter } from 'vue-router'

import dayjs from 'dayjs'
import capitalize from 'lodash-es/capitalize'
import { storeToRefs } from 'pinia'

import { type VendorID, Vendors } from '@/constants/vendors'
import { analyticsLoggerProductDetailOpen } from '@/services/analytics/analyticsLogger'
import type { ProductDetail, ProductOrigin } from '@/services/api/front/productTypes'
import appConfig from '@/services/appConfig'
import { t } from '@/services/translations'
import type { PartialCartProduct } from '@/store/modules/cart'
import useCartStore from '@/store/pinia/useCartStore'
import useProductDetailStore from '@/store/pinia/useProductDetailStore'
import type { ProductComputed } from '@/store/pinia/useProductsStore'
import useUserInterfaceStore from '@/store/pinia/useUserInterfaceStore'
import { isProductSoldOut } from '@/store/utils/productAvailability'
import { getDateDetailed } from '@/utils/dateTime'

import useDeliveryData from './transport/useDeliveryData'
import useInteractionSource from './useInteractionSource'

function useProductItem(
  product: ComputedRef<Product> | Ref<Product>,
  getInteractionSourceFn?: ReturnType<typeof useInteractionSource>,
) {
  const { currentVendorId } = storeToRefs(useUserInterfaceStore())
  const { openProductDetailModal } = useProductDetailStore()

  const router = useRouter()
  const { cartActions, cartGetters } = useCartStore()

  const { data: deliveryData } = useDeliveryData()

  const getInteractionSource = getInteractionSourceFn ?? useInteractionSource()

  // Computed
  const productId = computed(() => product.value?.id)
  const isProductLoaded = computed(() => !!product.value)
  // Quantity and stock
  const cartProduct = computed(() => cartGetters.getCartProduct(productId.value))
  const cartProductQuantity = computed(() => cartProduct.value?.quantity ?? 0)
  const getPlannedStockDateWithDayPart = computed(() => {
    if (!product.value?.origin.plannedStock) {
      return
    }
    const { date, dayPart } = getDateDetailed(product.value.origin.plannedStock)
    const dayTranslation = t(`general.${dayPart}`)
    return `${date} ${dayTranslation}`
  })
  const isSoldOut = computed(() => isProductSoldOut(product.value))
  // Price
  const isCheaperPrice = computed(
    () =>
      product.value?.origin.recommendedPrice &&
      product.value?.origin.recommendedPrice.toFixed(2) !== '0.00' &&
      product.value?.computed.price.toFixed(2) !==
        product.value?.origin.recommendedPrice.toFixed(2),
  )
  const bestCheapPriceInfo = computed(() => {
    // is it sale price?
    if (isCheaperPrice.value) {
      return {
        type: 'sale' as const,
        label: t('product.originalPrice'),
        price: product.value.origin.recommendedPrice,
      }
    }
    // is better than recommended sell price?
    if (product.value.origin.recommendedSellPrice - product.value?.computed.price > 0.1) {
      return {
        type: 'recommended' as const,
        label: t('product.recommendedSellPrice'),
        price: product.value.origin.recommendedSellPrice,
      }
    }
    // otherwise no better price
    return {
      type: 'common' as const,
      label: t('product.commonPrice'),
      price: null,
    }
  })
  const hasProductCumulativePrices = computed(() => !!product.value?.origin.cumulativePrices.length)
  // Vendors
  const hasBaseVendor = computed(() => product.value?.origin.vendorId === Vendors.BASE)
  const hasMatchingVendorId = computed(
    () => product.value?.origin.vendorId === currentVendorId.value,
  )
  // Params
  const isSale = computed(() => productId.value > appConfig.saleProductMinimalId)
  const isFirstOrderDayVisible = computed(
    () => product.value?.origin.firstOrderDay && !isSale.value,
  )
  const hasProductGifts = computed(() => !!product.value?.origin.giftIds.length)
  const hasReturnables = computed(
    () =>
      product.value?.origin.returnablePackagePrice > 0 || product.value?.origin.returnableCarrier,
  )
  const hasProductGroups = computed(() => !!product.value?.origin.productGroups.length)
  const getProductGroupsText = computed(() => {
    return hasProductGroups.value ? capitalize(product.value.origin.productGroups.join(', ')) : ''
  })

  const hasMultiBuyVariants = computed(
    () =>
      !!product.value?.origin.associationCode &&
      product.value.origin.cumulativePrices.some((price) => price.associationCode),
  )
  const packagesPrice = computed(
    () => product.value.origin.returnablePackagePrice * product.value.computed.quantity,
  )

  const carrierQuantity = computed(() => cartProduct.value?.carrierQuantity ?? 0)
  const carrierPrice = computed(
    () => (product.value.origin.returnableCarrier?.price ?? 0) * carrierQuantity.value,
  )

  // Store
  const removeProductFromCart = (interactionElement: string) => {
    cartActions.removeCartProduct({
      productId: productId.value,
      interactionSource: getInteractionSource({
        element: interactionElement,
      }),
    })
  }

  // Other methods
  const openDetail = (activeTabName?: string, productToOpen = product) => {
    // as product.origin.url is null, we wont open detail
    // hopefully this is an edge case and i suggest thant nullable attribute should be removed
    if (!productToOpen.value.origin.url) {
      return
    }
    let productDetailRoute = router.resolve(productToOpen.value.origin.url)
    if (activeTabName) {
      productDetailRoute = {
        ...productDetailRoute,
        hash: activeTabName,
      }
    }

    analyticsLoggerProductDetailOpen(productToOpen.value.id, undefined, getInteractionSource())
    openProductDetailModal({
      ...productDetailRoute,
      vendorContextId: currentVendorId.value ?? null,
    })
  }
  const openDetailRelated = () => {
    // detail won't open when no related product is exists
    if (!product.value?.origin.relatedProduct) {
      return
    }
    analyticsLoggerProductDetailOpen(
      product.value?.origin.relatedProduct.id,
      product.value?.origin.relatedProduct.url,
      getInteractionSource(),
    )
    openProductDetailModal({
      ...router.resolve(product.value?.origin.relatedProduct.url),
      vendorContextId: currentVendorId.value ?? null,
    })
  }
  const formatQuantity = (productQuantity: ProductQuantity) => {
    return `${productQuantity.prefix} ${productQuantity.value}${productQuantity.unit}`.trim()
  }

  const isProductUnavailable = computed(() => {
    if (product.value.origin.maxInCart < 1) {
      return true
    }

    // firstOrderDay is the next available day for the product
    // if it is before the earliest delivery time, the product is available (unavailable = false)
    if (
      isFirstOrderDayVisible.value &&
      deliveryData.value?.earliestDeliveryTime &&
      product.value.origin.firstOrderDay
    ) {
      return dayjs(product.value.origin.firstOrderDay).isAfter(
        deliveryData.value.earliestDeliveryTime,
      )
    }

    return false
  })

  return {
    productId,
    cartProduct,
    cartProductQuantity,
    carrierPrice,
    carrierQuantity,
    packagesPrice,
    isProductLoaded,
    isCheaperPrice,
    bestCheapPriceInfo,
    isSale,
    isSoldOut,
    isFirstOrderDayVisible,
    hasBaseVendor,
    hasMatchingVendorId,
    hasProductCumulativePrices,
    getPlannedStockDateWithDayPart,
    hasProductGifts,
    hasReturnables,
    hasProductGroups,
    hasMultiBuyVariants,
    getProductGroupsText,
    isProductUnavailable,
    removeProductFromCart,
    openDetail,
    openDetailRelated,
    formatQuantity,
  }
}

type FieldListItem = {
  title: string
  value: string
  type: 'url' | 'text' | 'html'
}

type ProductQuantity = {
  prefix: string
  value: number
  unit: string
}

type Product = {
  id: number
  cart: PartialCartProduct

  computed: ProductComputed
  name: string
  image?: string
  origin: ProductOrigin
  quantity: number
  /**
   * Value from "origin" (see above) date converted to dayjs.
   */
  firstOrderDay: dayjs.Dayjs
  detail?: ProductDetail
  price: number
}

type ProductCategory = {
  id: number
  highlighted: boolean
  image: string
  name: string
  url: string
  vendorId: VendorID
}

export { useProductItem }

export type { Product, FieldListItem, ProductCategory }
