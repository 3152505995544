<script setup>
import ClubSectionTitle from '@/components/Clubs/ClubSectionTitle/ClubSectionTitle.vue'
import { useClubSectionTitleProps } from '@/components/Clubs/ClubSectionTitle/useClubSectionTitle'

const props = defineProps(useClubSectionTitleProps)
</script>

<template>
  <ClubSectionTitle :title="props.title" />
  <div class="list d-flex flex-wrap mb-3">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.list {
  row-gap: 20px;
}

:deep(.benefit-item-wrap) {
  flex-basis: 50%;

  @include media-breakpoint-up(md) {
    flex-basis: 33.3%;
  }
}

:deep(.benefit-item-icon-background) {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 97.32px;
  height: 61.89px;
  color: color('white');
  background: url('/img/icons/cloud.svg?v=1') no-repeat center/cover;
}

:deep(.benefit-item-icon) {
  color: color('pink-dark');
}
</style>
