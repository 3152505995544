import { createOperation } from '@/services/apiv2'

import type { DeleteCheckoutVoucherResponse } from './checkoutTypes'

/**
 * Remove voucher from checkout
 * @see https://sasandboxstdhotapidocs.z1.web.core.windows.net/#/Checkout/removeVoucher
 */
const removeVoucher = createOperation<DeleteCheckoutVoucherResponse>('/checkout/voucher', {
  method: 'delete',
})

export default removeVoucher
