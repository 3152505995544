<script setup lang="ts">
import { BaseButton } from '@/componentsPure'
import { t } from '@/services/translations'

type DialogConfirmOverlayProps = {
  title?: string
  acceptLabel?: string
  declineLabel?: string
  dataTidAccept?: string
  dataTidDecline?: string
}

const props = defineProps<DialogConfirmOverlayProps>()

const emit = defineEmits<{
  accepted: []
  declined: []
}>()
</script>

<template>
  <Transition name="fade">
    <div class="confirm-overlay">
      <p
        v-if="props.title"
        class="text-3 text-gray-darker text-center w-100 mb-2"
        v-text="props.title" />
      <BaseButton
        variant="primary"
        rounded
        :data-tid="props.dataTidAccept"
        class="confirm-btn mr-2"
        @click="emit('accepted')">
        {{ acceptLabel || t('general.remove') }}
      </BaseButton>
      <BaseButton
        variant="neutral-light"
        rounded
        :data-tid="props.dataTidDecline"
        class="confirm-btn"
        @click="emit('declined')">
        {{ declineLabel || t('general.back') }}
      </BaseButton>
    </div>
  </Transition>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.confirm-overlay {
  position: absolute;
  inset: 0 0 0 0;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  background: color('white', 0.95);
}

.confirm-btn {
  @include make-font-scale(1);

  min-width: 94px;
  padding: 0;
  font-weight: normal;
  line-height: 24px;
}
</style>
