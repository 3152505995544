import appConfig from '@/services/appConfig'

import { fetchGet } from '../fetch'
import type {
  ProfileClubInfoResponse,
  ProfileOrderDetailResponse,
  ProfileOrderListResponse,
  ReadProfileResponse,
} from './profileTypes'

const modulePath = 'profile'

type OrderListParams = {
  limit?: number
  offset?: number
  stateCategory?: string
  ignoreNotPaid?: boolean
}

/**
 * Calls profile/order-list EP
 * @see https://sasandboxstdhotapidocs.z1.web.core.windows.net/#/Profile/getProfileOrderList
 * @param limit Number of orders in response.
 * @param offset
 * @param stateCategory Filter orders by state.
 * @returns Promise of the API response with orders array.
 */
function getProfileOrders(orderListParams: OrderListParams) {
  return fetchGet<ProfileOrderListResponse>(
    appConfig.frontApiBaseUrl + modulePath + '/order-list/',
    orderListParams,
  )
}

/**
 * Calls profile/order EP
 * @see https://sasandboxstdhotapidocs.z1.web.core.windows.net/#/Profile/getProfileOrderDetail
 * @param orderId Order identification.
 * @returns Promise of the API response with order object.
 */
function getProfileOrder(orderId: number) {
  return fetchGet<ProfileOrderDetailResponse>(
    appConfig.frontApiBaseUrl + modulePath + '/order/' + orderId,
  )
}

/**
 * Calls profile
 * @see https://sasandboxstdhotapidocs.z1.web.core.windows.net/#/Profile/readProfile
 * @returns Returns the profile of the authorized user
 */
function getProfile() {
  return fetchGet<ReadProfileResponse>(appConfig.frontApiBaseUrl + modulePath)
}

/**
 * Calls profile/club-info EP
 * @see https://sasandboxstdhotapidocs.z1.web.core.windows.net/#/Profile/readClubInfo
 * @returns Promise of the API response with clubs information
 */
function getProfileClubInfo() {
  return fetchGet<ProfileClubInfoResponse>(appConfig.frontApiBaseUrl + modulePath + '/club-info')
}

export {
  getProfileOrders as frontApiGetProfileOrders,
  getProfileOrder as frontApiGetProfileOrder,
  getProfile as frontApiGetProfile,
  getProfileClubInfo as frontApiGetProfileClubInfo,
}

export type { OrderListParams }
