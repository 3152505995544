import debounce from 'lodash-es/debounce'

import { ensureConfig } from '@/composables/useConfig'
import type { Product } from '@/composables/useProductItem'
import { type VendorID } from '@/constants/vendors'
import { luigisboxApiPost } from '@/services/api/luigisbox/luigisbox'
import appConfig from '@/services/appConfig'
import { analyticsCookiesValues, getCookieConsents } from '@/services/privacy'
import useProductsStore, { type Widget } from '@/store/pinia/useProductsStore'
import type { SuggestResponse } from '@/store/pinia/useUserInterfaceStore'

type ListType = 'Autocomplete' | 'Search Results' | 'Recommendation'

// @see https://docs.luigisbox.com/event_api.html#event-rest-api
type ActionType = 'event' | 'pv' | 'click' | 'transaction'

type Item = {
  title: string
  type: string
  url: string
  position: number
  price?: number
}

type Action = {
  type: ActionType
  client_id?: number
  id?: string
  consent_granted: boolean
  customer_id: number | null
  path: string
  referer: string
  title: string
  tracker_id: string
  url: string
  local_timestamp: number
  context: {
    warehouses: string[]
  }
  lists?: Partial<
    Record<
      ListType,
      {
        items: Item[]
        query?: {
          filters?: {
            RecommenderClientId?: string
            RecommendationId?: string
            ItemIds?: number[]
            Recommender?: string
            Type?: string
            _Variant?: string
          }
          string?: string
        }
      }
    >
  >
  action?: {
    type: 'buy' | 'click'
    resource_identifier: string
  }
  items?: object[]
}

type LuigisboxApiPostArgument = Parameters<typeof luigisboxApiPost>[0]

async function sendLuigisboxApiRequest(data: LuigisboxApiPostArgument, code: string) {
  try {
    await luigisboxApiPost(data)
  } catch (error) {
    console.warn("Luigi's Box API request failed.", code)
  }
}

/**
 * Generates random string of numbers of length 19.
 * @returns Random numeric string.
 * @private
 */
function _generateUniqueId() {
  const timestamp = '' + Date.now()
  return timestamp + Math.floor(10 ** (19 - timestamp.length) * Math.random())
}

export type ContentQueryFiltersOptions = {
  vendorId?: VendorID
  hasSuklId?: 0 | 1
}

function _generateContentQuery(
  type: ListType,
  queryItem: string,
  { vendorId, hasSuklId }: ContentQueryFiltersOptions = {},
) {
  if (type === 'Recommendation') {
    return {
      filters: {
        ItemIds: [queryItem],
        Recommender: 'meta_recomender',
        RecommenderClientId: 'replacements_item_detail',
        Type: 'complementary_combined_assoc',
        _Variant: 'Luigis',
      },
    }
  }

  const filters = {}
  const filtersMap = {
    vendor_id: vendorId,
    has_sukl_id: hasSuklId,
  }
  for (const [key, value] of Object.entries(filtersMap)) {
    if (value === undefined) continue
    filters[key] = value
  }

  return {
    string: queryItem,
    filters,
  }
}

function _isConsentGranted() {
  const consentPreferences = getCookieConsents()
  return consentPreferences?.personalization_storage === analyticsCookiesValues.granted
}

/**
 * Generates data object for each event.
 * @returns Object with shared values for each event.
 * @private
 */
async function generateDefaultData() {
  const config = await ensureConfig()
  return {
    ...(config.user.luigisboxClientId && { client_id: config.user.luigisboxClientId }),
    customer_id: config.user.luigisboxCustomerId,
    path: '/',
    tracker_id: config.externalServices.luigisBoxTrackerId,
    consent_granted: _isConsentGranted(),
    url: document.URL,
    referer: document.referrer,
    title: document.title,
    local_timestamp: Math.floor(Date.now() / 1000),
    context: {
      warehouses: [config.cart.warehouseId.toString()],
    },
    id: _generateUniqueId(),
  }
}

/**
 * Sends data to Luigi's Box API for every pageView.
 */
async function pageView() {
  const defaultData = await generateDefaultData()
  sendLuigisboxApiRequest({ ...defaultData, type: 'pv' }, 'pageView')
}

/**
 * Sends data to Luigi's Box API when user manipulate with product.
 * @param productId ID of item.
 * @param productUrl Optional url of the product to be passed as resource identifier.
 * @param type actionData type, can be 'buy' or 'click'. Do not confuse with action type, which is always 'click'.
 */
async function clickEvent(productId: number, type: 'buy' | 'click' = 'buy', productUrl?: string) {
  const { getProduct } = useProductsStore()
  const product = getProduct(productId)

  const finalProductUrl = productUrl ?? product?.origin?.url

  const actionData = {
    action: {
      type,
      resource_identifier: window.location.origin + finalProductUrl,
    },
  } as const
  const defaultData = await generateDefaultData()

  sendLuigisboxApiRequest({ ...defaultData, type: 'click', ...actionData }, 'clickEvent')
}

/**
 * Generates items array for list event.
 * @param products
 */
function _generateItems(products: Product[]) {
  return products.map((item, index) => ({
    title: item.origin.name,
    url: window.location.origin + item.origin.url,
    position: index + 1,
    price: item.computed.price,
    type: 'item',
  }))
}

/**
 * Sends data to Luigi's Box API when user searches or uses suggester.
 * @param products Search or suggester results.
 * @param queryItem User input search term.
 * @param listType Information of the location where the event is triggered.
 * @private
 */
async function _listEvent(
  products: Product[],
  queryItem: string,
  listType: ListType,
  filtersOptions?: ContentQueryFiltersOptions,
) {
  const items = _generateItems(products)

  const listDataContent = {
    items,
    query: _generateContentQuery(listType, queryItem, filtersOptions),
  }

  const listData = {
    lists: {},
  }

  listData.lists[listType] = listDataContent

  const defaultData = await generateDefaultData()
  const data = { ...defaultData, type: 'event' as const, ...listData }
  sendLuigisboxApiRequest(data, '_listEvent')
}

/**
 * Sends data about all found products on search page to Luigi's Box API.
 * @param products Products given as result of user's search.
 * @param queryItem User input search term.
 */
const searchPage = (
  products: Product[],
  queryItem: string,
  filtersOptions?: ContentQueryFiltersOptions,
) => {
  _listEvent(products, queryItem, 'Search Results', filtersOptions)
}

/**
 * Sends data about all found products in suggester to Luigi's Box API.
 * This function's execution is debounced by the productSearch.suggesterAnalyticsDebouncing value in app configuration.
 * @param products Products given as result of user's search.
 * @param searchTerm User input search term.
 */
const getDebouncedSearchSuggester = () =>
  debounce(
    (
      products: Product[],
      queryItem: string,
      dataSourceEndpoint: SuggestResponse['dataSourceEndpoint'],
      filtersOptions?: ContentQueryFiltersOptions,
    ) => {
      const listType = dataSourceEndpoint === 'search' ? 'Search Results' : 'Autocomplete'
      _listEvent(products, queryItem, listType, filtersOptions)
    },
    appConfig.productSearch.suggesterAnalyticsDebouncing,
  )
const debouncedSearchSuggester = getDebouncedSearchSuggester()
/**
 * Sends data about all found products on search page to Luigi's Box API.
 * @param products Products given as result of user's search.
 * @param queryItem User input search term.
 */
function recommendations(products: Product[], queryItem: string) {
  _listEvent(products, queryItem, 'Recommendation')
}

async function widgetContent(widget: Widget) {
  if (!widget.recommendationAnalyticsData) {
    return
  }
  const defaultData = await generateDefaultData()
  const items = _generateItems(widget.products)
  const itemIds = widget.products.map((item) => item.id)

  const action: Action = {
    type: 'event',
    ...defaultData,
    lists: {
      Recommendation: {
        items,
        query: {
          filters: {
            RecommenderClientId: widget.recommendationAnalyticsData.recommendationClientId,
            RecommendationId: widget.recommendationAnalyticsData.recommendationId,
            /* We are sending all productIds until ItemIds won't be part of the API fetchProductWidget */
            ItemIds: itemIds,
            Recommender: widget.recommendationAnalyticsData.recommender,
          },
        },
      },
    },
  }
  sendLuigisboxApiRequest(action, 'widgetContent')
}

type Order = {
  orderProducts: ProductInfo[]
}
type ProductInfo = {
  id: number
  deliveredQuantity: number
  price: number
  product: {
    name: string
    percentageDiscount: number
    cumulativePrices: { quantity: number }[]
    url: string
  }
}

async function purchaseEvent(order: Order) {
  const isDiscounted = (item: ProductInfo) =>
    item.product.cumulativePrices?.[0]?.quantity <= item.deliveredQuantity

  const items = order.orderProducts.map((item) => {
    return {
      title: item.product.name,
      url: window.location.origin + item.product.url,
      count: item.deliveredQuantity,
      total_price: item.price,
      was_discounted: item.product.percentageDiscount > 0,
      was_volume_discounted: isDiscounted(item),
    }
  })

  const defaultDataObject = await generateDefaultData()

  const event: Partial<Action> = {
    type: 'transaction',
    tracker_id: defaultDataObject.tracker_id,
    ...(defaultDataObject.client_id && { client_id: defaultDataObject.client_id }),
    local_timestamp: defaultDataObject.local_timestamp,
    id: defaultDataObject.id,
    url: defaultDataObject.referer,
    path: '/',
    title: 'Potvrzení objednávky',
    items,
  }

  sendLuigisboxApiRequest(event, 'purchaseEvent')
}

export {
  pageView as luigisboxPageview,
  clickEvent as luigisboxClickEvent,
  searchPage as luigisboxSearchPage,
  debouncedSearchSuggester as luigisboxDebouncedSearchSuggester,
  getDebouncedSearchSuggester as luigisboxGetDebouncedSearchSuggester,
  recommendations as luigisboxRecommendationsAlternatives,
  widgetContent as luigisboxWidgetContent,
  purchaseEvent as luigisboxPurchase,
}

export type { Action }
