import type { Banner } from '@/data/api/frontApi/banners/bannerTypes'
import { resizer } from '@/services/resizer'
import { getMaxMedia } from '@/services/responsiveMedia'

import type { ImgSrc, ImgVariant } from '../stories/atoms/imgResponsive/types'

type ResizeClubBannersType = {
  id: Banner['id']
  name: Banner['name']
  caption: Banner['caption']
  link: Banner['link']
  images: {
    srcset: ImgSrc[]
    variants: ImgVariant[]
  }
}[]

const defaultSizeVariant = 'main'
const mobileSizeVariant = 'mobileProfile'
const pixelRatio = 1.5

const CLUB_BANNER_SIZE = {
  DESKTOP: {
    WIDTH: 1050,
    HEIGHT: 400,
  },
  MOBILE: {
    WIDTH: 743,
    HEIGHT: 1045,
  },
} as const

/**
 * @param banners Banners array
 * @returns an array of club banners, resized, variants added
 */
function resizeClubBanners(banners: Banner[]): ResizeClubBannersType {
  const media = getMaxMedia('xs')

  return banners.map((banner) => {
    const defaultSizeVariantImage = banner.images[defaultSizeVariant]
    const mobileSizeVariantImage = banner.images[mobileSizeVariant] ?? defaultSizeVariantImage

    return {
      id: banner.id,
      name: banner.name,
      caption: banner.caption,
      link: banner.link,
      images: {
        srcset: [
          {
            url: resizer(
              defaultSizeVariantImage,
              CLUB_BANNER_SIZE.DESKTOP.WIDTH,
              CLUB_BANNER_SIZE.DESKTOP.HEIGHT,
            ),
          },
          {
            pixelRatio,
            url: resizer(
              defaultSizeVariantImage,
              CLUB_BANNER_SIZE.DESKTOP.WIDTH * 2,
              CLUB_BANNER_SIZE.DESKTOP.HEIGHT * 2,
            ),
          },
        ],
        variants: [
          {
            media,
            srcset: [
              {
                url: resizer(
                  mobileSizeVariantImage,
                  CLUB_BANNER_SIZE.MOBILE.WIDTH,
                  CLUB_BANNER_SIZE.MOBILE.HEIGHT,
                ),
              },
              {
                pixelRatio,
                url: resizer(
                  mobileSizeVariantImage,
                  CLUB_BANNER_SIZE.MOBILE.WIDTH * 2,
                  CLUB_BANNER_SIZE.MOBILE.HEIGHT * 2,
                ),
              },
            ],
          },
        ],
      },
    }
  })
}

export type { ResizeClubBannersType }

export { resizeClubBanners, CLUB_BANNER_SIZE }
