import { POSITION, type PluginOptions as ToastificationOptions } from 'vue-toastification'

import { t } from '@/services/translations'

const MAX_TOASTS = 5
const TIMEOUT = 8000
const TIMEOUT_LONGER = 30000

const toastOptions: ToastificationOptions = {
  accessibility: { closeButtonLabel: t('general.close') },
  closeOnClick: false,
  draggable: false,
  maxToasts: MAX_TOASTS,
  newestOnTop: false,
  position: POSITION.BOTTOM_RIGHT,
  transition: 'Vue-Toastification__fade',
}

export { MAX_TOASTS, TIMEOUT, TIMEOUT_LONGER, toastOptions }
