import type { Product } from '@/composables/useProductItem'

type ProductGroupMap = {
  [key: string]: { count: number; products: { [id: number]: number } }
}

/**
 * Calculate groups of products
 * Each product can be in multiple groups
 * @param products
 */
function calculateGroups(products: readonly Product[]) {
  const groups: ProductGroupMap = {}

  function addProductToGroup(groupName: string | null, product: Product) {
    if (!groupName) {
      return
    }
    if (!groups[groupName]) {
      groups[groupName] = { count: 0, products: {} }
    }
    if (!groups[groupName].products[product.id]) {
      groups[groupName].count += product.computed.quantity
      groups[groupName].products[product.id] = product.computed.quantity
    }
  }

  products.forEach((product) => {
    addProductToGroup(product.origin?.associationCode, product)
    product.origin.cumulativePrices.forEach((price) => {
      addProductToGroup(price.associationCode, product)
    })
  })

  return groups
}

export default calculateGroups
