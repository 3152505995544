import { ref } from 'vue'

import { defineStore } from 'pinia'

import { frontApiGetGift, type Gift } from '@/services/api/front/gift'

const useGiftsStore = defineStore('useGiftsStore', () => {
  const gifts = ref<Gift[]>([])

  function getGiftById(id: number) {
    return gifts.value.find((gift) => gift.id === id)
  }

  function getGiftsByIds(ids: number[]) {
    return gifts.value.filter((gift) => ids.includes(gift.id))
  }

  function setGifts(newGifts: Gift[]) {
    const updatedGifts = gifts.value.map((gift) => {
      const foundGift = newGifts.find((newGift) => newGift.id === gift.id)

      return foundGift ? { ...gift, ...foundGift } : gift
    })

    const newGiftsToAdd = newGifts.filter(
      (newGift) => !gifts.value.some((gift) => gift.id === newGift.id),
    )

    // Merge the updated gifts with the new gifts
    gifts.value = [...updatedGifts, ...newGiftsToAdd]
  }

  async function fetchGifts(giftIds: number[]) {
    const unloadedGiftIds = giftIds.filter((id) => !gifts.value.some((gift) => gift.id === id))

    if (!unloadedGiftIds.length) return
    //@todo vuequery
    const {
      data: { gifts: apiGifts },
    } = await frontApiGetGift(unloadedGiftIds)

    if (apiGifts) {
      setGifts(apiGifts)
    }
  }

  return {
    getGiftById,
    getGiftsByIds,
    setGifts,
    fetchGifts,
    gifts,
  }
})

export default useGiftsStore
