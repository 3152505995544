import { useMutation } from '@tanstack/vue-query'

import { addressQueryKeys } from '@/constants/queryKeys'
import { frontApiPostDeliveryAddress } from '@/services/api/front/address'
import queryClient from '@/services/queryClient'

// TODO: doresit tenhle TYP!!!!!
type AddressForm = any

function useAddressCreate() {
  const { mutate } = useMutation({
    mutationFn: frontApiPostDeliveryAddress,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: addressQueryKeys.detail() })
    },
  })

  function createAddress(form: AddressForm) {
    mutate(form)
  }

  return { createAddress }
}

export default useAddressCreate
