<script setup>
import { ref, computed, useSlots } from 'vue'

import Icon from '@/components/Icon/Icon.vue'
import useDeviceDetectorStore from '@/store/pinia/useDeviceDetectorStore'

const props = defineProps({
  icon: {
    type: Object,
    default: () => ({
      name: 'eye',
      group: undefined,
    }),
  },
  iconTouchClose: {
    type: Object,
    default: () => ({
      name: 'cross',
      group: undefined,
    }),
  },
  control: {
    type: Object,
    default: null,
  },
  open: {
    type: Boolean,
    default: null,
  },
})

const emits = defineEmits(['update:open', 'controlClick'])

const { isTouch } = useDeviceDetectorStore()

const isContentOpen = ref(false)

const isOpen = computed({
  get() {
    return props.open ?? isContentOpen.value
  },
  set(value) {
    isContentOpen.value = value
    emits('update:open', value)
  },
})

const slots = useSlots()

const isTouchOpen = computed(() => {
  return !!slots.default?.() && isTouch && isOpen.value
})

function handleControlClick() {
  isOpen.value = !isOpen.value
  emits('controlClick')
}
</script>

<template>
  <span>
    <button
      :class="[
        'btn control-trigger p-0',
        {
          'bg-gray-dark': isTouchOpen,
        },
        props.control.class,
      ]"
      :title="props.control.title"
      @mouseover="isTouch ? null : (isOpen = true)"
      @click.prevent="handleControlClick">
      <Icon
        :icon="isTouchOpen ? props.iconTouchClose.name : props.icon.name"
        :group="isTouchOpen ? props.iconTouchClose.group : props.icon.group"
        :class="isTouchOpen ? props.iconTouchClose.class : props.icon.class" />
    </button>
    <div
      v-if="!!(slots.default && slots.default()) && isOpen"
      class="content-popover">
      <slot />
    </div>
  </span>
</template>

<style scoped lang="scss">
@use '@/legacy' as *;

.control-trigger {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 27px;
  height: 27px;
  line-height: 1.3;
  border-radius: 50%;

  .icon {
    width: 16px;
    height: 16px;
  }
}

.content-popover {
  @include make-font-scale(2);

  margin: -14px 7px 0 17px;
  line-height: 1.1;
  text-align: left;
}
</style>
