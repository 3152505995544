import type {
  PathParams,
  QueryParams,
  OperationOptions,
  OperationRequestArguments,
  OperationRequest,
} from './apiTypes'
import fetchFromEndpoint from './fetchFromEndpoint'

function createOperation<
  /**
   * Data returned from response.
   */
  TData = any,
  /**
   * Converted response exposed to developer.
   */
  TDataExposed = TData,
  /**
   * Dynamic path params. Base URL must have corresponding placeholders.
   * E.g. /my/path/:id in case "id" is a path parameter.
   */
  TPath extends PathParams | undefined = undefined,
  /**
   * Request in format required by endpoint.
   */
  TRequest = undefined,
  /**
   * Query string in format required by endpoint.
   */
  TQueryParams extends QueryParams | undefined = undefined,
  /**
   * Request in format exposed to developer.
   */
  TRequestExposed = TRequest,
  /**
   * Query string in format exposed to developer.
   */
  TQueryParamsExposed = TQueryParams,
>(
  operationPath: string,
  options: OperationOptions<
    TData,
    TDataExposed,
    TRequest,
    TQueryParams,
    TRequestExposed,
    TQueryParamsExposed
  >,
) {
  if (operationPath.startsWith('/')) {
    operationPath = operationPath.slice(1)
  }
  return function operationWithApi(baseUrl: string) {
    function operation(
      ...args: OperationRequestArguments<TQueryParamsExposed, TRequestExposed, TPath>
    ) {
      // why requestData don't work without this weird type casting?
      const requestData = args[0] as
        | Partial<OperationRequest<TQueryParamsExposed, TRequestExposed, TPath>>
        | undefined

      return fetchFromEndpoint<
        TData,
        TDataExposed,
        TPath,
        TRequest,
        TQueryParams,
        TRequestExposed,
        TQueryParamsExposed
      >({
        baseUrl: baseUrl,
        endpointPath: operationPath,
        method: options.method,
        queryParams: requestData?.queryParams,
        requestData: requestData?.body,
        pathParams: requestData?.path,
        requestConverter: options.requestConverter,
        responseConverter: options.responseConverter,
        queryParamsConverter: options.queryParamsConverter,
        requestHeaders: requestData?.requestHeaders,
      })
    }

    return operation
  }
}

export default createOperation
