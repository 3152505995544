<script setup lang="ts">
type HowDoesItWorkProps = {
  imagePath: string
  title: string
  alt?: string
}

const props = defineProps<HowDoesItWorkProps>()
</script>

<template>
  <div>
    <div class="icon-wrapper d-flex align-items-center justify-content-center mx-auto">
      <img
        :src="props.imagePath"
        :alt="alt"
        class="icon" />
    </div>

    <p class="text-6 text-brand font-weight-bold text-center m-0 mt-3">
      {{ props.title }}
    </p>
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.icon-wrapper {
  $size: 170px;

  width: $size;
  height: $size;
  background: linear-gradient(143deg, color('white') 13.54%, color('white', 0) 84.71%);
  border-radius: 170px;
  box-shadow: 0 16px 48px 0 color('gray-lighter');
}

.icon {
  $size: 120px;

  width: $size;
  height: $size;
}
</style>
