import type { DeliveryAddress } from '@/data/api/frontApi/address/addressTypes'

import { dataLayerPush } from './utils'

function addressChosen(addressData: DeliveryAddress) {
  dataLayerPush({
    event: 'addressChosen',
    selectedAddress: addressData ? 1 : 0,
  })
}

export { addressChosen as gtmEventAddressChosen }
