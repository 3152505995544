import { createOperation } from '@/services/apiv2'

import type { DeliveryAddress, PostDeliveryAddressRequest } from './addressTypes'

const createDeliveryAddress = createOperation<
  DeliveryAddress,
  DeliveryAddress,
  undefined,
  PostDeliveryAddressRequest,
  undefined,
  PostDeliveryAddressRequest
>('/address/delivery', {
  method: 'post',
})

export default createDeliveryAddress
