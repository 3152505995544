<script setup lang="ts">
import loadExternalJs from '@/services/loadExternalJs'

type UtilScriptProps = {
  src?: string
}

const props = defineProps<UtilScriptProps>()

if (props.src) {
  await loadExternalJs(props.src)
}
dispatchFakeDomContentLoaded()

function dispatchFakeDomContentLoaded() {
  window.document.dispatchEvent(
    new Event('DOMContentLoaded', {
      bubbles: true,
    }),
  )
}
</script>

<template>
  <Component
    :is="'script'"
    v-if="!props.src">
    <slot />
  </Component>
</template>
