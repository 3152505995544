type PhpMap = Record<
  string,
  {
    name: string
    component: string
    meta: Record<string, string | boolean>
  }
>

/**
 * Maps Nette presenter names to Vue components.
 */
const phpMap: PhpMap = {
  'Front:OrderTracking:default': {
    name: 'PageOrderTracking',
    component: 'PageOrderTracking',
    meta: {
      gtmPageType: 'profile',
      closePreviewCart: true,
    },
  },
  'Front:Welcome:default': {
    name: 'PageWelcome',
    component: 'PageWelcome',
    meta: {
      gtmPageType: 'welcome',
      hasHeaderHidden: true,
    },
  },
  'Front:PreLaunchLandingPage:default': {
    name: 'PagePreLaunchLandingPage',
    component: 'PagePreLaunchLandingPage',
    meta: {
      gtmPageType: 'PreLaunchLandingPage',
      hasHeaderHidden: true,
      hasFooterHidden: true,
    },
  },
  'Front:BigTipPage:default': {
    name: 'PageBigTip',
    component: 'PageBigTip',
    meta: {
      gtmPageType: 'PageBigTip',
    },
  },
  'Front:Privacy:settings': {
    name: 'PrivacySettings',
    component: 'PrivacySettings',
    meta: {
      gtmPageType: 'profile',
      closePreviewCart: true,
    },
  },
  'Front:Privacy:privacyPolicy': {
    name: 'PrivacyPolicy',
    component: 'PrivacyPolicy',
    meta: {
      gtmPageType: 'other',
    },
  },
  'Front:Privacy:request': {
    name: 'PrivacyRequest',
    component: 'PrivacyRequest',
    meta: {
      gtmPageType: 'other',
    },
  },
  'Front:Recipe:default': {
    name: 'PageRecipes',
    component: 'PageRecipes',
    meta: {
      gtmPageType: 'recipes',
      pageType: 'recipes',
    },
  },
  'Front:Recipe:detail': {
    name: 'PageRecipeDetail',
    component: 'PageRecipeDetail',
    meta: {
      gtmPageType: 'recipes',
      pageType: 'recipes',
    },
  },
  'Front:SearchProduct:default': {
    name: 'ProductSearchResults',
    component: 'PageProducts',
    meta: {
      gtmPageType: 'search',
    },
  },
  'Front:MultipleSearch:default': {
    name: 'ProductMultipleSearchResults',
    component: 'MultiSearchResults',
    meta: {
      gtmPageType: 'search',
    },
  },
  'Front:BenjaminekRegistration:default': {
    name: 'KidsClub',
    component: 'KidsClub',
    meta: {
      gtmPageType: 'content',
      pageType: 'content',
    },
  },
  'ShoppingCart:CartContent:default': {
    name: 'Cart',
    component: 'ForceReload',
    meta: {
      hasSimpleHeaderLayout: true,
      hasFooterHidden: true,
      customLogoHeadingKey: 'cart.checkout',
      gtmPageType: 'cart',
    },
  },
  'ShoppingCart:PersonalData:default': {
    name: 'PersonalData',
    component: 'ForceReload',
    meta: {
      hasSimpleHeaderLayout: true,
      hasFooterHidden: true,
      customLogoHeadingKey: 'cart.checkout',
      gtmPageType: 'checkout',
    },
  },
  'ShoppingCart:TransportPaymentFood:default': {
    name: 'TransportPayment',
    component: 'ForceReload',
    meta: {
      hasSimpleHeaderLayout: true,
      hasFooterHidden: true,
      customLogoHeadingKey: 'cart.checkout',
      gtmPageType: 'checkout',
      checkoutStep: 'transportPayment',
    },
  },
  'ShoppingCart:Summary:default': {
    name: 'Summary',
    component: 'ForceReload',
    meta: {
      hasSimpleHeaderLayout: true,
      hasFooterHidden: true,
      customLogoHeadingKey: 'cart.checkout',
      gtmPageType: 'checkout',
      checkoutStep: 'summary',
    },
  },
  'ShoppingCart:AdditionalOrderSummary:default': {
    name: 'SummaryAdditional',
    component: 'ForceReload',
    meta: {
      hasSimpleHeaderLayout: true,
      hasFooterHidden: true,
      customLogoHeadingKey: 'cart.checkout',
      gtmPageType: 'checkout',
      checkoutStep: 'summaryAdditional',
    },
  },
  'ShoppingCart:ThankYou:default': {
    name: 'PageThankYou',
    component: 'PageThankYou',
    meta: {
      hasAuthorizedAccess: true,
      gtmPageType: 'purchase',
      closePreviewCart: true,
    },
  },
  'Front:StaticPage:view': {
    name: 'StaticPage',
    component: 'PageStatic',
    meta: {
      gtmPageType: 'content',
      pageType: 'content',
    },
  },
  'Profile:Complaint:new': {
    name: 'NewComplaint',
    component: 'NewComplaint',
    meta: {
      hasAuthorizedAccess: true,
      gtmPageType: 'profile',
      closePreviewCart: true,
    },
  },
  'Profile:Order:default': {
    name: 'ProfileOrder',
    component: 'Profile',
    meta: {
      hasAuthorizedAccess: true,
      gtmPageType: 'profile',
      closePreviewCart: true,
    },
  },
  Profile: {
    name: 'Profile',
    component: 'Profile',
    meta: {
      hasAuthorizedAccess: true,
      gtmPageType: 'profile',
      closePreviewCart: true,
    },
  },
  'Profile:Credits:default': {
    name: 'Credits',
    component: 'Credits',
    meta: {
      hasAuthorizedAccess: true,
      gtmPageType: 'profile',
      closePreviewCart: true,
    },
  },
  'Profile:Cards:default': {
    name: 'Cards',
    component: 'ForceReload',
    meta: {
      hasAuthorizedAccess: true,
      gtmPageType: 'profile',
      closePreviewCart: true,
    },
  },
  'Profile:Profile:default': {
    name: 'ProfileDefault',
    component: 'Profile',
    meta: {
      hasAuthorizedAccess: true,
      gtmPageType: 'profile',
      closePreviewCart: true,
    },
  },
  'Profile:Favorites:default': {
    name: 'Favorites',
    component: 'PageProducts',
    meta: {
      hasAuthorizedAccess: true,
      gtmPageType: 'wishlist',
    },
  },
  'Profile:Clubs:benjaminek': {
    name: 'ProfileClubKids',
    component: 'PageProfileClub',
    meta: {
      hasAuthorizedAccess: true,
      gtmPageType: 'profile',
      closePreviewCart: true,
    },
  },
  'Profile:Clubs:fullCare': {
    name: 'ProfileClubFullCare',
    component: 'PageProfileClub',
    meta: {
      hasAuthorizedAccess: true,
      gtmPageType: 'profile',
      closePreviewCart: true,
    },
  },
  'Profile:Clubs:gifts': {
    name: 'ProfileClubGifts',
    component: 'Gifts',
    meta: {
      hasAuthorizedAccess: true,
      gtmPageType: 'profile',
      closePreviewCart: true,
    },
  },
  'Profile:Savings:default': {
    name: 'Savings',
    component: 'Savings',
    meta: {
      hasAuthorizedAccess: true,
      gtmPageType: 'profile',
      closePreviewCart: true,
    },
  },
  'Front:Auth:default': {
    name: 'Auth',
    component: 'Auth',
    meta: {
      gtmPageType: 'other',
      appMainWrapperComponent: 'AppMainAuth',
    },
  },
  'Front:Category:all': {
    name: 'AllProductPage',
    component: 'PageProducts',
    meta: {
      gtmPageType: 'product',
      pageType: 'product',
    },
  },
  'Front:Brand:default': {
    name: 'Brands',
    component: 'BrandList',
    meta: {
      gtmPageType: 'brand',
    },
  },
  'Front:MyCart:default': {
    name: 'MyCart',
    component: 'PageMyCart',
    meta: {
      hasHidingMenu: true,
      gtmPageType: 'category',
      pageType: 'category',
    },
  },
  'Front:Rating:order': {
    name: 'OrderRating',
    component: 'OrderRating',
    meta: {
      gtmPageType: 'other',
    },
  },
  'Front:CsobAction:default': {
    name: 'PaymentFingerprint',
    component: 'ForceReload',
    meta: {
      gtmPageType: 'other',
      hasHeaderHidden: true,
      hasFooterHidden: true,
      hasGlobalModalsHidden: true,
    },
  },
  'Front:CsobAction:processPayment': {
    name: 'PaymentProcess',
    component: 'ForceReload',
    meta: {
      gtmPageType: 'other',
      hasHeaderHidden: true,
      hasFooterHidden: true,
      hasGlobalModalsHidden: true,
    },
  },
  'Front:RaiffeisenBank:default': {
    name: 'PaymentFingerprintRaiffeisenBank',
    component: 'ForceReload',
    meta: {
      gtmPageType: 'other',
      hasHeaderHidden: true,
      hasFooterHidden: true,
      hasGlobalModalsHidden: true,
    },
  },
  'Front:Cart:default': {
    name: 'PageCart',
    component: 'PageCart',
    meta: {
      hasSimpleHeaderLayout: true,
      hasSimpleFooterLayout: true,
      customLogoHeadingKey: 'cart.checkout',
      gtmPageType: 'checkout2',
    },
  },
  'Front:Checkout:default': {
    name: 'Checkout',
    component: 'PageCheckout',
    meta: {
      hasSimpleHeaderLayout: true,
      hasSimpleFooterLayout: true,
      customLogoHeadingKey: 'cart.checkout',
      gtmPageType: 'checkout2',
    },
  },
  'Front:Checkout2:default': {
    name: 'Checkout2',
    component: 'PageCheckout2',
    meta: {
      hasSimpleHeaderLayout: true,
      hasFooterHidden: true,
      gtmPageType: 'checkout2',
    },
  },
  'Front:Sign:logoutApp': {
    name: 'LogoutMobileApp',
    component: 'PageLogoutMobileApp',
    meta: {
      hasSimpleHeaderLayout: true,
      gtmPageType: 'login',
      hasHeaderHidden: true,
      hasFooterHidden: true,
      hasGlobalModalsHidden: true,
    },
  },
  'Profile:NewsletterList:default': {
    name: 'Newsletter',
    component: 'Newsletter',
    meta: {
      hasAuthorizedAccess: true,
      gtmPageType: 'profile',
      closePreviewCart: true,
    },
  },
}

export default phpMap
