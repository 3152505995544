import dayjs from 'dayjs'
import { min } from 'lodash-es'

import type {
  TransportTimeslotDay,
  TransportTimeslotTime,
} from '@/services/api/front/transportTypes'
import { formatDate, formatWeekday } from '@/utils/date'

import type { RefinedTimeslot, TimeslotTime } from '../pinia/useTimeslotsStore'

// @fixup https://mallfresh.atlassian.net/browse/KNW-18480 - move to api responseConvertor!
function convertApiTimeslotTime(apiTimeslotTimes: TransportTimeslotTime[]): TimeslotTime[] {
  return apiTimeslotTimes.map((apiTimeslotTime) => {
    const { start, end, ...rest } = apiTimeslotTime

    return {
      ...rest,
      start: dayjs(start),
      end: dayjs(end),
    }
  })
}
// @fixup https://mallfresh.atlassian.net/browse/KNW-18480 - move to api responseConvertor!
function refineTimeslots(timeslotsData: TransportTimeslotDay[]): RefinedTimeslot[] {
  const refinedTimeslots = timeslotsData.map<RefinedTimeslot>((timeslotDay) => {
    const times = convertApiTimeslotTime(timeslotDay.times)
    const unavailable = !times.some((timeslotTime) => !timeslotTime.unavailable)
    const prices = times
      .filter((timeslotTime) => !timeslotTime.closed)
      .map((timeslotTime) => timeslotTime.price)

    const day = formatWeekday(times[0].start)!
    const cheapestPrices = prices.filter((price) => price > 0)

    return {
      day,
      date: times[0].start, // Any start or end Date in any slot is sufficient.
      times,
      dateString: formatDate(times[0].start)!,
      hasFreeDeliveryPrice: prices.some((price) => price === 0),
      cheapestPrice: min(cheapestPrices) ?? null,
      hasFreeTimeslots: prices.length > 0,
      unavailable,
    }
  })

  /**
   * @todo Kill this abomination as soon as possible!
   *
   * This is done in order to display PREVIOUS DAY (grayed out, disabled) in DeliveryTimeslots even AFTER CUTOFF.
   * Cutoff means that our API does not send remaining closed timeslots in the evening of today.
   *
   * In destinations with SDD (same day delivery), "today" is added as the previous day (in case of cutoff).
   * In case of NDD (next day delivery) destinations, "tomorrow" is added as the previous day (in case of cutoff).
   *
   * Problem is, we cannot distinguish between SDD and NDD in the data.
   * That means, for the combination of SDD and NO CUTOFF, nothing is altered in the timeslots, which is fine, BUT...
   * ...for the combination of NDD and NO CUTOFF, "today" is added, even though we never deliver "today" in NDD destinations.
   *
   * Another problem is that our implementation of this nuisance can differ from the implementation in the mobile app.
   *
   * @see discussion https://mallfresh.atlassian.net/browse/KNW-322
   */
  // @todo move abomination to DeliveryDays.vue
  if (timeslotsData.length) {
    const firstDayTimes = timeslotsData[0].times
    const firstTimeslotStart = dayjs(firstDayTimes[0].start)
    if (!firstTimeslotStart.tz().isToday()) {
      const start = firstTimeslotStart.subtract(1, 'day')
      const dayBefore: RefinedTimeslot = {
        day: formatWeekday(start)!,
        date: start,
        times: [],
        dateString: formatDate(start)!,
        cheapestPrice: null,
      }
      return [dayBefore, ...refinedTimeslots]
    }
  }
  return refinedTimeslots
}
export default refineTimeslots
