import type { Notification } from '@/components/Notification/notificationTypes'

/**
 * Map in-store notification to format for GTM
 */
function mapInStoreNotification(notification: Notification) {
  return {
    title: notification.title,
    batchId: notification.parameters.batchId,
  }
}

export { mapInStoreNotification }
