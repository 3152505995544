import { ref, computed } from 'vue'

import { defineStore } from 'pinia'

import modalIndex from '@/constants/modalIndex'
import { analyticsLoggerModalToggled } from '@/services/analytics/analyticsLogger'
import type { ModalInitiator } from '@/services/analytics/gtm/modal'
import { toggleBodyScroll } from '@/utils/scroll'

const MODAL_NAMES = {
  ...modalIndex,
} as const

type ModalId = (typeof MODAL_NAMES)[keyof typeof MODAL_NAMES]

type ModalKeys = keyof typeof MODAL_NAMES

type Modal<TriggerOptions = unknown> = {
  /**
   * Identification.
   */
  id: ModalId
  /**
   * If currently open.
   */
  isOpen: boolean
  /**
   * If has to be document.body scrollable.
   */
  hasBackdropScroll?: boolean
  /**
   * Name of a modals group, which collides between each other.
   */
  collisionGroup?: string
  /**
   * Optional data passed when modal is triggered and cleared when closed.
   */
  triggerOptions?: TriggerOptions
}

const useModalStore = defineStore('useModalStore', () => {
  const modals = ref<Modal[]>([])

  // getters
  const isBackdropScrollLocked = computed(
    () => !!modals.value.filter((modal) => modal.isOpen && !modal?.hasBackdropScroll).length,
  )

  function getModal<TriggerOptions = unknown>(id: ModalId) {
    return (modals.value.find((modal) => modal.id === id) ?? null) as Modal<TriggerOptions> | null
  }

  function getModalCollisionGroup(group: string) {
    return modals.value.filter((modal) => modal?.collisionGroup === group)
  }

  function isModalFromCollisionGroupOpen(group: string) {
    return getModalCollisionGroup(group).some((modal) => modal.isOpen)
  }

  function isModalOpen(id: ModalId) {
    return modals.value.some((modal) => modal.id === id && modal.isOpen)
  }

  // actions
  function createModal(modal: Modal) {
    const isModalExist = modals.value.some((existingModal) => existingModal.id === modal.id)
    if (!isModalExist) {
      modals.value = [...modals.value, modal]
    }
  }

  function removeModal(id: ModalId) {
    modals.value = modals.value.filter((modal) => modal.id !== id)
  }

  function setTriggerOptions<T>(payload: { id: ModalId; triggerOptions: T }) {
    const { id, triggerOptions } = payload

    modals.value = modals.value.map((modal) =>
      modal.id === id ? { ...modal, triggerOptions } : modal,
    )
  }

  function setModalVisibility(id: ModalId, isOpen: boolean) {
    modals.value = modals.value.map((modal) =>
      modal.id === id ? { ...modal, isOpen } : { ...modal },
    )
  }
  /**
   * Toggle a modal
   * @param modal - modal object
   * @param analyticsId - id for analytics (modal.id by default)
   */
  function onToggle(
    modal: Modal,
    initiator: ModalInitiator = 'unknown',
    analyticsId: string = modal.id,
  ) {
    toggleBodyScroll(isBackdropScrollLocked.value)
    analyticsLoggerModalToggled(modal.isOpen, analyticsId, initiator)
  }

  // close all open modals from collision group
  function closeAllCollisionGroupModals(collisionGroup: string) {
    const modalGroups = getModalCollisionGroup(collisionGroup)

    modals.value = modals.value.map((modal) =>
      modalGroups.includes(modal) ? { ...modal, isOpen: false } : modal,
    )
  }
  /**
   * Opens a modal
   * @param payload - modalId or modalId with options
   * @param analyticsId - optional id for analytics
   */
  function openModal<TriggerOptions>(
    payload: ModalId | { id: ModalId; triggerOptions: TriggerOptions },
    analyticsId?: string,
    initiator?: ModalInitiator,
  ) {
    let id: ModalId
    if (typeof payload === 'object') {
      id = payload.id
      setTriggerOptions<TriggerOptions>(payload)
    } else {
      id = payload
    }

    const modal = getModal(id)
    if (!modal || modal.isOpen) return

    modal?.collisionGroup && closeAllCollisionGroupModals(modal.collisionGroup)

    setModalVisibility(id, true)
    onToggle({ ...modal, isOpen: true }, initiator, analyticsId)
  }
  /**
   * Closes a modal
   * @param id - modalId
   * @param analyticsId - optional id for analytics
   */
  function closeModal(id: ModalId, analyticsId?: string, initiator?: ModalInitiator) {
    setTriggerOptions({
      id,
      triggerOptions: null,
    })

    const modal = getModal(id)
    if (!modal?.isOpen) return

    setModalVisibility(id, false)
    onToggle({ ...modal, isOpen: false }, initiator, analyticsId)
  }

  function toggleModal(id: ModalId) {
    const modal = getModal(id)
    if (!modal) return

    modal.isOpen ? closeModal(id) : openModal(id)
  }

  return {
    getModal,
    isModalOpen,
    getModalCollisionGroup,
    isModalFromCollisionGroupOpen,
    isBackdropScrollLocked,
    createModal,
    removeModal,
    openModal,
    closeModal,
    toggleModal,
    MODAL_NAMES,
  }
})

export default useModalStore

export type { Modal, ModalId, ModalKeys }
