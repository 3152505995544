import modalIndex from '@/constants/modalIndex'
import type { ModalInitiator } from '@/services/analytics/gtm/modal'
import useModalStore from '@/store/pinia/useModalStore'

function useSelectAddressModal() {
  const { openModal } = useModalStore()

  function openAddressModal(
    triggerOptions: {
      autoClose?: boolean
      forceRedirect?: boolean
      continueWithoutAddressButton?: boolean
    },
    initiator: ModalInitiator,
  ) {
    const {
      autoClose = true,
      forceRedirect = false,
      continueWithoutAddressButton = false,
    } = triggerOptions

    openModal(
      {
        id: modalIndex.addressSelector,
        triggerOptions: {
          autoClose,
          forceRedirect,
          continueWithoutAddressButton,
        },
      },
      undefined,
      initiator,
    )
  }

  return openAddressModal
}

export default useSelectAddressModal
