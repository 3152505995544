<script setup lang="ts">
import { computed } from 'vue'

import { vIntersectionObserver } from '@vueuse/components'

import ImgResponsive from '@/components/stories/atoms/imgResponsive/ImgResponsive.vue'
import { useBanners } from '@/composables/banner'
import type { BannerType } from '@/data/api/frontApi/banners/bannerTypes'
import { analyticsLoggerBannerImpression } from '@/services/analytics/analyticsLogger'
import onIntersectionObserver from '@/services/analytics/gtm/utils/intersectingBanner'

import { resizeClubBanners, CLUB_BANNER_SIZE } from './resizeClubBanners'

type BannerClubProps = {
  type: BannerType
}

const props = defineProps<BannerClubProps>()

const { banners: fetchedBanners } = useBanners(props.type)

const banners = computed(() =>
  fetchedBanners.value.length ? resizeClubBanners(fetchedBanners.value) : [],
)
</script>

<template>
  <div v-if="banners.length">
    <!-- TODO href: Can't change to RouterLink as all the urls in admin are absolute -->
    <Component
      :is="banner?.link?.length ? 'a' : 'div'"
      v-for="(banner, index) in banners"
      :key="index"
      v-intersection-observer="onIntersectionObserver('view_promotion', banner, props.type, index)"
      :href="banner?.link?.length ? banner.link : null"
      :class="[
        'banner-wrapper',
        {
          'mt-4': index,
        },
      ]"
      @click="analyticsLoggerBannerImpression('select_promotion', banner, props.type, index)">
      <ImgResponsive
        class="banner-image"
        :alt="banner.name"
        :srcset="banner.images.srcset"
        :variants="banner.images.variants"
        lazyload />
    </Component>
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.banner-wrapper {
  position: relative;
  display: block;
  aspect-ratio: v-bind('CLUB_BANNER_SIZE.MOBILE.WIDTH') / v-bind('CLUB_BANNER_SIZE.MOBILE.HEIGHT');
  background-color: color('gray-lighter');

  @include media-breakpoint-up(sm) {
    aspect-ratio: v-bind('CLUB_BANNER_SIZE.DESKTOP.WIDTH') /
      v-bind('CLUB_BANNER_SIZE.DESKTOP.HEIGHT');
  }
}

.banner-image {
  position: absolute;
  top: 0;
  left: 0;
}
</style>
