<script setup lang="ts">
import { onMounted, onBeforeUnmount, inject } from 'vue'

import intersectionObserverKey from '../intersectionObserverKey'

// We know inject exists because it's always inside a CarouselWrapper
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const resetIntersectionObserver = inject(intersectionObserverKey)!

onMounted(() => {
  resetIntersectionObserver()
})

onBeforeUnmount(() => {
  resetIntersectionObserver()
})
</script>

<template>
  <div class="carousel-item">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.carousel-item {
  flex: none;
  max-width: 100%;
  scroll-snap-align: start;
}
</style>
