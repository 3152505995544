import { computed, ref } from 'vue'

import { defineStore } from 'pinia'

import type { Product } from '@/composables/useProductItem'
import modalIndex from '@/constants/modalIndex'
import router from '@/router'
import { setPreviousUrl } from '@/router/beforeEachHook'
import usePageViewAnalytics from '@/services/analytics/usePageViewAnalytics'
import { frontApiProductGetBySlug } from '@/services/api/front/product'
import {
  webApiPostShoppingList,
  webApiPostShoppingListAdd,
  webApiPostShoppingListRemove,
} from '@/services/api/web/shoppingList'
import { pushHistoryState } from '@/services/url'

import { getTotalDiscount } from '../utils/discountedPrice'
import useGiftsStore from './useGiftsStore'
import useModalStore from './useModalStore'
import useProductsStore, { type ProductOrigin } from './useProductsStore'
import useUserInterfaceStore from './useUserInterfaceStore'

type ShoppingList = {
  id: number
  name: string
}

type ProductDetailModalOptions = {
  isPopstateEvent?: boolean
  fullPath: string
  path: string
  query: { sale?: string }
  hash: string
  vendorContextId: number | null
}

const useProductDetailStore = defineStore('useProductDetailStore', () => {
  const productUrl = ref<string | null>(null)
  const productSale = ref<string | null>(null)
  const modalFallbackUrl = ref<URL | null>(null)
  const modalVendorContextId = ref<number | null>(null)
  const productNotFound = ref<boolean>(false)
  const shoppingLists = ref<ShoppingList[]>([])

  const sendPageView = usePageViewAnalytics()

  const getProductDetail = computed<Product | null>(() => {
    const { getProductBySlug } = useProductsStore()
    const productSlug = productSale.value
      ? productUrl.value + '?sale=' + productSale.value
      : productUrl.value
    return productSlug ? getProductBySlug(productSlug) : null
  })

  function getProductWithPriceAndQuantity(
    productId: number,
  ): ProductOrigin & { quantity: number; price: number } {
    const { getProduct } = useProductsStore()
    const product = getProduct(productId)!
    return {
      ...product.origin,
      quantity: product.computed.quantity,
      price: product.computed.price,
    }
  }

  function getNextStepInfo(productId: number): null | {
    remainingQuantity: number
    price: number
  } {
    const { getProductOrThrow } = useProductsStore()
    const product = getProductOrThrow(productId)

    if (!product.computed.nextStep) {
      return null
    }
    return {
      remainingQuantity: product.computed.remainingQuantity!,
      price: product.computed.nextStep.price,
    }
  }

  function getDiscount(productId: number) {
    // @todo type fix
    return getTotalDiscount(getProductWithPriceAndQuantity(productId))
  }

  function cleanStates() {
    modalFallbackUrl.value = null
    productUrl.value = null
    productSale.value = null
    productNotFound.value = false
  }

  // @todo remove these functions from store...someday
  function openModal() {
    const { openModal: open } = useModalStore()
    open(modalIndex.productDetail)
  }

  function closeModal() {
    const { closeModal: close } = useModalStore()
    close(modalIndex.productDetail)
  }

  function setModalFallbackUrl() {
    if (!modalFallbackUrl.value) {
      modalFallbackUrl.value = new URL(window.location.href)
    }
  }

  function openProductDetailModal(payload: ProductDetailModalOptions) {
    const { isPopstateEvent, fullPath, path, query, hash, vendorContextId } = payload
    productUrl.value = path
    productSale.value = query.sale ?? null
    modalVendorContextId.value = vendorContextId
    let fullPathString = fullPath
    if (hash) {
      fullPathString += `#${hash}`
    }

    const isSale = !!query.sale
    fetchProductDetail({ path: path, isSale }).catch((error) => {
      console.log(
        error instanceof Error ? error : new Error('Unknown error while fetching product detail.'),
      )
    })
    openModal()

    if (!isPopstateEvent) {
      setModalFallbackUrl()
      setPreviousUrl(window.location.href)
      pushHistoryState(
        {
          productDetail: fullPathString,
        },
        fullPathString,
      )
      const toRoute = router.resolve(fullPathString)

      sendPageView({
        pageData: {
          gtmPageType: toRoute.meta.gtmPageType,
          fullPath: toRoute.fullPath,
          checkoutStep: toRoute.meta.checkoutStep,
        },
        isClientSideRouted: true,
        sendToLugisBox: false,
      })
    }
  }

  function closeProductDetailModal(isPushingHistoryState: boolean) {
    if (isPushingHistoryState) {
      closeModal()
    } else if (modalFallbackUrl.value) {
      setPreviousUrl(window.location.href)
      pushHistoryState({}, modalFallbackUrl.value)
      sendPageView({ pageData: {}, isClientSideRouted: true, sendToLugisBox: false })

      if (modalVendorContextId.value !== null) {
        const { setVendorId } = useUserInterfaceStore()
        setVendorId(modalVendorContextId.value)
      } else {
        location.reload() // This should never happen
      }
    }

    cleanStates()
  }

  async function fetchProductDetail(payload: { path: string; isSale: boolean }): Promise<Product> {
    const { path, isSale } = payload
    const slug = path.replace(/^\//, '') // remove leading slash from path
    const { setProduct, getProductOrThrow } = useProductsStore()

    const {
      data: { breadcrumbs, gifts, product, shoppingLists: newShoppingLists, categoryTree },
    } = await frontApiProductGetBySlug(slug, isSale).catch((error) => {
      if (error?.response?.data?.status === 404) {
        productNotFound.value = true
      }
      throw error
    })

    setProduct({
      breadcrumbs,
      categoryTree,
      ...product,
    })

    const { setVendorId } = useUserInterfaceStore()
    const { setGifts } = useGiftsStore()
    setVendorId(product.vendorId)
    setGifts(gifts)
    shoppingLists.value = [...newShoppingLists]

    return getProductOrThrow(product.id)
  }

  async function createShoppingList(name: string) {
    const {
      data: { shoppingListId },
    } = await webApiPostShoppingList(name)

    shoppingLists.value = [...shoppingLists.value, { id: shoppingListId, name }]
  }

  function addProductToShoppingList(product: Product, shoppingListId: number) {
    if (!product?.origin?.detail?.shoppingListIds) return

    product.origin.detail.shoppingListIds = [
      ...product.origin.detail.shoppingListIds,
      shoppingListId,
    ]

    webApiPostShoppingListAdd(shoppingListId, product.id)
  }

  function removeProductFromShoppingList(product: Product, shoppingListId: number) {
    if (!product.origin.detail) return

    product.origin.detail.shoppingListIds = product.origin.detail.shoppingListIds.filter(
      (id) => id !== shoppingListId,
    )
    webApiPostShoppingListRemove(shoppingListId, product.id)
  }

  return {
    getProductDetail,
    modalVendorContextId,
    productNotFound,
    shoppingLists,
    getProductWithPriceAndQuantity,
    getNextStepInfo,
    getDiscount,
    openModal,
    closeModal,
    setModalFallbackUrl,
    openProductDetailModal,
    closeProductDetailModal,
    fetchProductDetail,
    createShoppingList,
    addProductToShoppingList,
    removeProductFromShoppingList,
  }
})

export default useProductDetailStore
