import type { InteractionSourceRaw } from '@/composables/useInteractionSource'
import { pick, pickItemListName, pickProductItems } from '@/services/analytics/gtm/utils/pick'

import { dataLayerPush } from './utils'

type BreadCrumbs = {
  id: number
  name: string
  url: string
}[]

function viewItemList(source: InteractionSourceRaw[]) {
  const breadCrumbs = pick<BreadCrumbs>('breadcrumbs', source, [])
  const lastItem = breadCrumbs.slice(-1)[0]
  const categoryName = lastItem?.['name'] || null
  const itemListName = pickItemListName(source)
  const categoryId = lastItem?.['id'] || null
  const position = pick<number>('boxListingIndex', source, 0) + 1
  const items = pickProductItems(source)
  const categoryLevel = `L${breadCrumbs.length}`
  const itemListPath = breadCrumbs.map((item) => item.name).join(' / ')

  dataLayerPush({
    event: 'view_item_list',
    category_name: categoryName, // renamed from 'item_list_name'
    item_list_name: itemListName, //path or name of widget
    category_id: categoryId, //category id
    position, // position of list in the page, starting with 1
    items, // array of products
    category_level: categoryLevel, // level of category
    item_list_path: itemListPath,
  })
}

export { viewItemList as gtmEventViewItemList }
