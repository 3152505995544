import { computed, nextTick, ref } from 'vue'

import { defineStore } from 'pinia'

import type { Product } from '@/composables/useProductItem'
import modalIndex from '@/constants/modalIndex'
import { SessionStorageKeys } from '@/constants/storageKeys'
import { Vendors, type VendorID } from '@/constants/vendors'
import { frontApiGetSuggest } from '@/services/api/front/suggest'
import type { SuggestResponseRaw } from '@/services/api/front/suggestTypes'
import { storagePersistSession, storageRetrieveSession } from '@/services/storage'
import { toastInfo } from '@/services/toast'
import { t } from '@/services/translations'
import searchTermCleaner from '@/utils/searchTermCleaner'

import useModalStore from './useModalStore'
import useProductsStore from './useProductsStore'

const baseHeaderSettings = {
  actionMenu: true,
  showL2Categories: true,
  showBackToHomepage: false,
}

type HeaderSettings = typeof baseHeaderSettings
type OffsetTop = number | ((shouldScrollUp: boolean) => number)

type ScrollToElementPayload = {
  targetElementId: string
  behavior?: 'smooth'
  offsetTop?: OffsetTop
}

type SuggestResponse = {
  products: Product[]
  categories: SuggestResponseRaw['categories']
  brands: SuggestResponseRaw['brands']
  dataSourceEndpoint: SuggestResponseRaw['dataSourceEndpoint']
  otherProducts: SuggestResponseRaw['otherProducts']
}

const _computeOffset = (
  offsetTop: OffsetTop,
  targetElementOffsetTop: number,
  currentScrollY: number,
  headerHeight: number,
) => {
  if (typeof offsetTop === 'function') {
    const shouldScrollUp = targetElementOffsetTop <= currentScrollY
    return offsetTop(shouldScrollUp)
  }

  return headerHeight + offsetTop
}

function isKnownVendorId(id: number): id is VendorID {
  return id === Vendors.BASE || id === Vendors.PHARMACY
}

function vendorIdHeaderSettings(vendorId: number): HeaderSettings {
  switch (vendorId) {
    case Vendors.BASE:
      return baseHeaderSettings
    case Vendors.PHARMACY:
      return {
        actionMenu: false,
        showL2Categories: false,
        showBackToHomepage: true,
      }
    default:
      return baseHeaderSettings
  }
}

const useUserInterfaceStore = defineStore('useUserInterfaceStore', () => {
  const storageVendorId = Number(
    storageRetrieveSession<string>(SessionStorageKeys.userInterfaceVendorId),
  )

  const isCartPreviewOpenDesktop = ref(
    !!storageRetrieveSession<boolean>(SessionStorageKeys.openPreviewCart),
  )

  const isHeaderMenuVisible = ref(true)
  const headerHeight = ref(0)
  const contentHeaderHeight = ref(0)
  const currentVendorId = ref(isKnownVendorId(storageVendorId) ? storageVendorId : undefined)
  const currentL1CategoryId = ref(0)
  const currentL2CategoryId = ref(0)
  const headerSettings = ref(vendorIdHeaderSettings(storageVendorId))
  const isArtificialScroll = ref(false)

  const isPharmacy = computed(() => currentVendorId.value === Vendors.PHARMACY)

  function setCartPreviewOpenDesktop(isOpen: boolean) {
    isCartPreviewOpenDesktop.value = isOpen
    storagePersistSession(SessionStorageKeys.openPreviewCart, isOpen)
  }

  async function autoOpenCartPreviewDesktop(currentRouteName: string | symbol | null | undefined) {
    if (storageRetrieveSession(SessionStorageKeys.firstProductAdded)) return

    storagePersistSession(SessionStorageKeys.firstProductAdded, true)
    setCartPreviewOpenDesktop(true)

    if (currentRouteName === 'Home') {
      setTimeout(() => setCartPreviewOpenDesktop(false), 5000)
    }
  }

  function fetchSuggest({
    searchTerm,
    vendorId,
    limit,
  }: {
    searchTerm: string
    vendorId?: VendorID
    limit?: number
  }) {
    const vendorIdParam = vendorId ?? currentVendorId.value ?? Vendors.BASE
    return new Promise<SuggestResponse>((resolve, reject) => {
      const cleanTerm = searchTermCleaner(searchTerm)

      if (cleanTerm.length < 2) {
        return reject(new Error('Search term must be at least 2 characters long'))
      }

      frontApiGetSuggest(cleanTerm, vendorIdParam, limit)
        .then((response) => {
          const { products, categories, brands, otherProducts, dataSourceEndpoint } = response.data
          const productIds = products.map((product) => product.id)
          const { getProducts, setProducts } = useProductsStore()
          const storedProductIds = new Set(getProducts(productIds).map((product) => product.id))
          const newProducts = [...products].filter((product) => !storedProductIds.has(product.id))

          setProducts(newProducts)
          resolve({
            products: getProducts(productIds),
            categories,
            brands,
            dataSourceEndpoint,
            otherProducts: {
              ...otherProducts,
            },
          })
        })
        .catch(reject)
    })
  }

  function setHeaderMenuVisible(menuVisible: boolean) {
    isHeaderMenuVisible.value = menuVisible
  }

  function setHeaderHeight(height: number) {
    headerHeight.value = height
  }

  function setContentHeaderHeight(height: number) {
    contentHeaderHeight.value = height
  }

  function setHeaderSettingsWithDefaults(settings?: HeaderSettings) {
    headerSettings.value = { ...baseHeaderSettings, ...settings }
  }

  function setVendorId(id: number) {
    // vendor specific header settings
    const vendorHeaderSettings = vendorIdHeaderSettings(id)
    setHeaderSettingsWithDefaults(vendorHeaderSettings)

    const prevState = currentVendorId.value
    // fresh load or history push without context change
    if (id === prevState) {
      return
    }
    if (isKnownVendorId(id)) {
      storagePersistSession(SessionStorageKeys.userInterfaceVendorId, id.toString())
      currentVendorId.value = id
      switch (id) {
        // redirect or history push into base vendor
        case Vendors.BASE:
          if (prevState) {
            toastInfo(t('vendors.backToBase'), { onlyForVendorIds: [Vendors.BASE] })
          }
          break
        // fresh load, redirect or history push into pharmacy vendor
        case Vendors.PHARMACY: {
          // setVendorId can be called before modals are initialized (eg. pageload),
          // so we better wait 1 tick for full mount of them
          nextTick(() => {
            const { openModal } = useModalStore()
            openModal(modalIndex.pharmacyWelcome)
          })
          break
        }
      }
    }
  }

  function setCurrentL1CategoryId(id: number) {
    currentL1CategoryId.value = id
  }

  function setCurrentL2CategoryId(id: number) {
    currentL2CategoryId.value = id
  }

  function scrollToElement({
    targetElementId,
    behavior = 'smooth' as const,
    offsetTop = 0,
  }: ScrollToElementPayload) {
    isArtificialScroll.value = true

    const currentScrollY = window.scrollY
    // @todo If there's no targetElement, shouldn't we throw an error?
    const elementTop = document.getElementById(targetElementId)?.getBoundingClientRect().top ?? 0
    const targetElementOffsetTop = elementTop + currentScrollY

    const offset = _computeOffset(
      offsetTop,
      targetElementOffsetTop,
      currentScrollY,
      headerHeight.value + contentHeaderHeight.value,
    )

    const top = targetElementOffsetTop - offset

    window.scrollTo({
      top,
      behavior,
    })

    setTimeout(() => {
      isArtificialScroll.value = false
    }, 100)
  }

  return {
    isCartPreviewOpenDesktop,
    isHeaderMenuVisible,
    headerHeight,
    contentHeaderHeight,
    currentVendorId,
    currentL1CategoryId,
    currentL2CategoryId,
    headerSettings,
    isArtificialScroll,
    isPharmacy,
    setCartPreviewOpenDesktop,
    autoOpenCartPreviewDesktop,
    fetchSuggest,
    setHeaderMenuVisible,
    setHeaderHeight,
    setContentHeaderHeight,
    setVendorId,
    setCurrentL1CategoryId,
    setCurrentL2CategoryId,
    scrollToElement,
  }
})

export default useUserInterfaceStore

export type { SuggestResponse }
