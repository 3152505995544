/**
 *  Get value of a provided CSS variable stored in the root element
 * @param variable The variable whose value is requested.
 * @param parsing Optionally turn off numeric parsing or force parsing to float. If not set, parse to integer.
 * @returns Numeric value of the variable.
 */

function get(variable: string, parsing: 'off'): string | undefined
function get(variable: string, parsing: 'float'): number | undefined
function get(variable: string): number | undefined

function get(variable: string, parsing?: 'off' | 'float') {
  const value = getComputedStyle(document.documentElement).getPropertyValue(variable)

  if (!value) {
    return
  }

  switch (parsing) {
    case 'off':
      return value
    case 'float':
      return parseFloat(value)
    default:
      return parseInt(value, 10)
  }
}

export { get as getCssVariable }
