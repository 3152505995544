import appConfig from '@/services/appConfig'

const storagePrefix = appConfig.browserStoragePrefix

/**
 * Returns the storage key with the prefix.
 * @param key Key to be used as identifier.
 */
function getStorageKey(key: string) {
  return `${storagePrefix}.${key}`
}

/**
 * Retrieves value from persistent storage.
 * @param storageType Identifier of the storage type.
 * @param key Identifier key of the stored value.
 * @returns Stored value. Null if key was not found, or value expired.
 */
function retrieve<T = any>(storageType: Storage, key: string) {
  const item = storageType.getItem(getStorageKey(key))

  if (!item) {
    return null
  }

  const { expiry, value } = JSON.parse(item)

  if (new Date().getTime() > expiry) {
    storageType.removeItem(getStorageKey(key))
    return null
  }

  return value as T
}

const retrieveSession = <T = any>(key: string) => retrieve<T>(window.sessionStorage, key)
const retrieveLocal = <T = any>(key: string) => retrieve<T>(window.localStorage, key)

/**
 * Persists value in the storage.
 * @param storageType Identifier of the storage type.
 * @param key Key to be used as identifier.
 * @param value Value to be stored.
 * @param daysToLive Number of days to keep the value available. Internally converted to milliseconds. If not set, availability is thirty days.
 */
function persist(storageType: Storage, key: string, value: unknown, daysToLive = 30) {
  storageType.setItem(
    getStorageKey(key),
    JSON.stringify({
      expiry: new Date().getTime() + daysToLive * 86400000,
      value,
    }),
  )
}

const persistSession = (key: string, value: unknown, daysToLive?: number) =>
  persist(window.sessionStorage, key, value, daysToLive)
const persistLocal = (key: string, value: unknown, daysToLive?: number) =>
  persist(window.localStorage, key, value, daysToLive)

/**
 * Remove value from the storage.
 * @param storageType Identifier of the storage type.
 * @param key Key to be used as identifier.
 */
function remove(storageType: Storage, key: string) {
  storageType.removeItem(getStorageKey(key))
}

const removeSession = (key: string) => remove(window.sessionStorage, key)
const removeLocal = (key: string) => remove(window.localStorage, key)

export {
  retrieveSession as storageRetrieveSession,
  retrieveLocal as storageRetrieveLocal,
  persistSession as storagePersistSession,
  persistLocal as storagePersistLocal,
  removeSession as storageRemoveSession,
  removeLocal as storageRemoveLocal,
}
