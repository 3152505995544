<script setup lang="ts">
import { ref, computed } from 'vue'

import { storeToRefs } from 'pinia'

import CumulativePrice from '@/components/CumulativePrice/CumulativePrice.vue'
import Icon from '@/components/Icon/Icon.vue'
import ProductAttributes from '@/components/Product/ProductAttributes.vue'
import ProductBadgePopover from '@/components/Product/ProductBadgePopover.vue'
import ProductControlFavorite from '@/components/Product/ProductControlFavorite.vue'
import ProductControlGift from '@/components/Product/ProductControlGift.vue'
import FutureDeliveryTag from '@/components/ProductItemBox/FutureDeliveryTag.vue'
import DiscountInfo from '@/components/global/DiscountInfo.vue'
import ProductAmount from '@/components/global/ProductAmount.vue'
import AnimationSwitchContent from '@/components/stories/atoms/animationSwitchContent/AnimationSwitchContent.vue'
import { BaseTag, Tooltip } from '@/componentsPure'
import useInteractionSource from '@/composables/useInteractionSource'
import { useProductItem, type Product } from '@/composables/useProductItem'
import useUser from '@/composables/useUser'
import appConfig from '@/services/appConfig'
import { resizer } from '@/services/resizer'
import { t } from '@/services/translations'
import useDeviceDetectorStore from '@/store/pinia/useDeviceDetectorStore'
import useGiftsStore from '@/store/pinia/useGiftsStore'
import useProductDetailStore from '@/store/pinia/useProductDetailStore'
import localizePrice from '@/utils/localizePrice'

type ProductItemBoxProps = {
  product: Product
  order?: number
  separated?: boolean
}

const props = defineProps<ProductItemBoxProps>()

const deviceDetectorStore = useDeviceDetectorStore()
const { breakpointDownMd } = storeToRefs(deviceDetectorStore)
const { fetchGifts } = useGiftsStore()
const { getDiscount } = useProductDetailStore()
const { isUserSignedIn } = useUser()

// Initial data
const hasBoxHover = ref(false)
const giftInfoOpen = ref(false)
const purchasedInfoOpen = ref(false)
const isFetchingGifts = ref(false)
const isProductImageVisible = ref(true)
const isCumulativePriceInvoked = ref(false)
const isCumulativePriceInvokedByTouch = ref(false)

const productRef = computed(() => props.product)

const interactionSource = computed(() => ({
  order: props.order,
}))
const yottly = computed(() => ({
  productId: props.product?.id || 0,
}))

const getInteractionSource = useInteractionSource('ProductItemBox', interactionSource, yottly)

const {
  cartProductQuantity,
  carrierPrice,
  packagesPrice,
  bestCheapPriceInfo,
  getPlannedStockDateWithDayPart,
  getProductGroupsText,
  hasBaseVendor,
  hasMatchingVendorId,
  hasProductCumulativePrices,
  hasProductGifts,
  hasProductGroups,
  isFirstOrderDayVisible,
  isCheaperPrice,
  productId,
  openDetail,
  openDetailRelated,
  hasMultiBuyVariants,
  isProductUnavailable,
} = useProductItem(productRef, getInteractionSource)

// Computed
const hasCartProductCumulativeDiscount = computed(() => getDiscount(productId.value) > 1)
const getImageDimensions = computed(() => ({
  default: [breakpointDownMd.value ? 262 : 207, breakpointDownMd.value ? 120 : 174] as const,
  retina: [breakpointDownMd.value ? 524 : 414, breakpointDownMd.value ? 240 : 348] as const,
}))
const getCumulativePriceClaim = computed(() => {
  if (!hasProductCumulativePrices.value) {
    return null
  }

  const cumulativePrices = props.product.origin.cumulativePrices
  const { price: cheapestPrice, quantity: cheapestQuantity } =
    cumulativePrices[cumulativePrices.length - 1]

  if (cumulativePrices.length > 1) {
    return t('cumulativePrices.morePiecesFrom', {
      price: localizePrice(cheapestPrice),
    })
  }

  return t('cumulativePrices.lessPriceFromQuantity', {
    price: localizePrice(cheapestPrice),
    quantity: cheapestQuantity,
  })
})

const isCumulativePriceVisible = computed(() => {
  return !!(
    hasProductCumulativePrices.value &&
    (isCumulativePriceInvoked.value || cartProductQuantity.value)
  )
})

const getQuantityPricePrefix = computed(() => props.product?.origin.productQuantity?.prefix)

const getRelatedProduct = computed(() => props.product?.origin.relatedProduct)

const getActionLabel = computed(() => props.product?.origin.actionLabel)

const getProductTags = computed(() => {
  return props.product?.origin.labels?.length
    ? props.product.origin.labels.filter((tag) => !tag.excludeFromBox)
    : null
})

const isProductFavorite = computed(() => props.product?.origin.favorite)

const isControlFavoriteVisible = computed(() => {
  return (
    isProductFavorite.value ||
    (isUserSignedIn && (deviceDetectorStore.isTouch || hasBoxHover.value))
  )
})

const giftInfo = computed({
  get() {
    return giftInfoOpen.value
  },
  set(val) {
    purchasedInfoOpen.value = false
    giftInfoOpen.value = val
  },
})

// check if there is space to display recommendedSellPrice
const showRecommendedSellPrice = computed(
  () => !(hasProductCumulativePrices.value || getActionLabel.value || getRelatedProduct.value),
)

const purchasedInfo = computed({
  get() {
    return purchasedInfoOpen.value
  },
  set(val) {
    giftInfoOpen.value = false
    purchasedInfoOpen.value = val
  },
})

// Methods

async function fetchProductGifts() {
  if (hasProductGifts.value && !isFetchingGifts.value) {
    isFetchingGifts.value = true
    await fetchGifts(props.product.origin.giftIds)
    isFetchingGifts.value = false
  }
}

function handleBoxMouseEnter() {
  hasBoxHover.value = true
  fetchProductGifts()
  invokeCumulativePrice()
}

function handleBoxMouseLeave() {
  hasBoxHover.value = false
  giftInfoOpen.value = false
  purchasedInfoOpen.value = false
  isCumulativePriceInvoked.value = false
}

function invokeCumulativePrice() {
  if (!isCumulativePriceInvokedByTouch.value) {
    isCumulativePriceInvoked.value = true
  }
  isCumulativePriceInvokedByTouch.value = false
}
</script>

<template>
  <article
    class="product"
    data-tid="product-box"
    :data-product-id="productId"
    @mouseenter="handleBoxMouseEnter"
    @mouseleave="handleBoxMouseLeave">
    <div
      class="content"
      @mouseleave="isCumulativePriceInvoked = false">
      <div class="content__info">
        <a
          data-tid="product-box__name"
          :href="props.product.origin.url"
          @click.prevent="openDetail()">
          <h2
            class="name"
            v-text="props.product.origin.name" />
          <div
            v-if="isProductUnavailable"
            class="claim">
            <FutureDeliveryTag
              v-if="isFirstOrderDayVisible"
              :first-order-day="props.product.firstOrderDay" />
            <span
              v-else
              class="text-uppercase text-red"
              v-text="t('product.soldOut')" />
            <p
              v-if="props.product.origin.plannedStock"
              class="mb-0"
              v-text="`${t('product.expecting')} ${getPlannedStockDateWithDayPart}`" />
          </div>
        </a>

        <ProductAttributes
          :product="props.product"
          class="attributes" />

        <Tooltip
          v-if="bestCheapPriceInfo.type === 'recommended' && showRecommendedSellPrice"
          text-color="white"
          nowrap>
          <span class="separated">
            <span class="text-gray pr-1 text-nowrap d-inline-block">
              {{ bestCheapPriceInfo.label }}
            </span>
            <s
              data-tid="product-box__crossed-price"
              class="text-black">
              {{ localizePrice(bestCheapPriceInfo.price) }}
            </s>
          </span>
          <template
            v-if="appConfig.enableRecommendedPriceInfo"
            #message>
            <span v-html="t(`product.recommendedPriceInfo`)" />
          </template>
        </Tooltip>

        <AnimationSwitchContent :show-secondary="isCumulativePriceVisible">
          <template #primary>
            <div
              v-if="getActionLabel || getRelatedProduct"
              :class="[
                'separated',
                {
                  'separated--small': getRelatedProduct,
                },
              ]">
              <div
                v-if="getActionLabel"
                v-html="getActionLabel" />
              <a
                v-if="getRelatedProduct"
                :href="getRelatedProduct.url"
                class="related"
                @click.prevent="openDetailRelated">
                <span
                  v-text="
                    t(`product.buy.${getRelatedProduct.isSale ? 'sale' : 'regular'}`, {
                      price: localizePrice(getRelatedProduct.price),
                    })
                  " />
                <Icon icon="arrow-button" />
              </a>
            </div>
            <div
              v-if="getCumulativePriceClaim"
              class="separated"
              v-html="getCumulativePriceClaim" />
          </template>
          <template #secondary>
            <CumulativePrice
              v-if="hasProductCumulativePrices"
              class="cumulative-price"
              :product="props.product"
              secondary />
          </template>
        </AnimationSwitchContent>
      </div>

      <section
        :aria-label="t('product.price')"
        class="price">
        <span
          v-if="getQuantityPricePrefix"
          class="price__prefix"
          v-text="getQuantityPricePrefix" />
        <s
          v-if="isCheaperPrice"
          data-tid="product-box__crossed-price"
          class="price__original"
          v-text="localizePrice(props.product.computed.recommendedPrice)" />
        <span
          data-tid="product-price"
          :class="[
            'price__selling',
            {
              'text-red': isCheaperPrice || bestCheapPriceInfo.price,
            },
          ]"
          v-text="localizePrice(props.product.computed.price)" />
        <div
          class="text-gray"
          v-text="
            `${localizePrice(props.product.computed.pricePerUnit.price)}/${
              props.product.computed.pricePerUnit.unit
            }`
          " />
      </section>
      <div class="groups">
        <span
          v-if="hasMultiBuyVariants"
          data-tid="product-box__product-groups"
          v-text="t('product.hasMultiBuyVariants')" />
        <span
          v-if="hasProductGroups"
          data-tid="product-box__product-groups"
          v-text="getProductGroupsText" />
      </div>

      <ProductAmount
        :product="props.product"
        :has-matching-vendor-id="hasMatchingVendorId"
        size="lg"
        :replaceable="hasBaseVendor"
        class="amount"
        @touchstart="isCumulativePriceInvokedByTouch = true"
        @replace="openDetail('similarProducts')"
        @open-detail="openDetail" />
    </div>

    <a
      :href="props.product.origin.url"
      class="position-relative d-block w-100 h-100"
      @click.prevent="openDetail()">
      <div
        :class="[
          'image-container',
          {
            'image-container--faded': isProductUnavailable,
          },
        ]">
        <img
          v-if="isProductImageVisible"
          :class="[
            'mh-100',
            { unavailable: isProductUnavailable && !props.product.origin.firstOrderDay },
          ]"
          loading="lazy"
          :srcset="`${resizer(
            props.product.origin.image,
            ...getImageDimensions.retina,
          )} 1.5x, ${resizer(props.product.origin.image, ...getImageDimensions.default)} 1x`"
          :alt="props.product.origin.name" />
      </div>

      <section
        v-if="getProductTags"
        :aria-label="t('product.tags')"
        class="tags"
        data-tid="product-box__labels">
        <BaseTag
          v-for="(tag, index) in getProductTags"
          :key="`tag-${index}`"
          :modifier="tag.styleKey ?? undefined"
          :data-tid="`productbox-product-labels-${index}`">
          {{ tag.name }}
        </BaseTag>
      </section>
      <Transition name="fade">
        <div
          v-if="cartProductQuantity"
          class="cart-info"
          data-tid="productbox-product-cart-info">
          <div
            v-text="
              t('product.inCartValue', {
                count: cartProductQuantity,
                value: localizePrice(props.product.computed.price * cartProductQuantity),
              })
            " />

          <div
            v-if="packagesPrice"
            v-text="
              t('product.boxOverlayPackagesPrice', { price: localizePrice(packagesPrice) })
            " />

          <div
            v-if="carrierPrice"
            v-text="t('product.boxOverlayCarrierPrice', { price: localizePrice(carrierPrice) })" />

          <DiscountInfo
            v-if="hasCartProductCumulativeDiscount"
            class="cart-info__discount"
            :product="props.product"
            savings-css-class="text-white"
            discount-only />
        </div>
      </Transition>
    </a>
    <ProductBadgePopover
      v-if="props.product.origin.purchased"
      v-model:open="purchasedInfo"
      class="control text-right"
      :icon="{
        name: 'purchased',
        group: 'product',
      }"
      :control="{
        class: 'purchased-info-control',
        title: t('product.previouslyPurchased.controlTitle'),
      }">
      <strong
        class="purchased-info-content"
        v-text="t('product.previouslyPurchased.description')" />
    </ProductBadgePopover>
    <ProductControlGift
      v-if="hasProductGifts"
      v-model:open="giftInfo"
      :gift-id="props.product.origin.giftIds[0]"
      :show-more-link="props.product.origin.url"
      :class="[
        'control',
        {
          'control--second': props.product.origin.purchased,
        },
      ]"
      @show-more="openDetail"
      @control-click="fetchProductGifts" />
    <Transition name="fade">
      <ProductControlFavorite
        v-show="isControlFavoriteVisible"
        data-tid="product-list__favourite-button"
        :is-favorite="props.product.origin.favorite"
        :product-id="props.product.id"
        :highlight="hasBoxHover"
        :class="[
          'control',
          {
            'control--second': hasProductGifts || props.product.origin.purchased,
            'control--third': hasProductGifts && props.product.origin.purchased,
          },
        ]" />
    </Transition>

    <div
      v-if="separated"
      class="product-separator" />
  </article>
</template>

<style lang="scss" scoped>
@use 'sass:color' as sassColor;
@use '@/legacy' as *;

$name-line-height: 16px;
$name-padding-bottom: 2px;
$content-line-height: 14px;
$content-spacing: 6px;
$content-separator-height: 1px;
$attributes-height: 22px;
$top-area-spacing-x: 4px;
$top-area-spacing-x-sm: 8px;
$top-area-spacing-y: 8px;

.product {
  position: relative;
  width: $productItemBoxWidth;
  height: $productItemBoxHeight;
  text-align: center;
  background: color('white');
  transition:
    box-shadow var(--baseTransitionTime),
    z-index var(--baseTransitionTime);

  @include media-breakpoint-up(lg) {
    height: $productItemBoxHeightLg;
  }

  @include media-breakpoint-only(xl) {
    &:hover {
      z-index: $zix-product--xl;
      box-shadow: 5px 5px 30px 0 color('black', 0.1);
    }
  }
}

.content {
  @include make-font-scale(2);

  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zix-product-item-box-content;
  padding: (($attributes-height * 0.5) + $content-spacing) 8px 8px;
  font-weight: 500;
  line-height: 1.2;
  background: color('white', 0.9);

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: $name-line-height + $content-line-height + (2 * $content-separator-height) +
      (3 * $content-spacing);
    line-height: $content-line-height;
  }
}

.name {
  @include make-font-scale(3);

  max-height: ($name-line-height * 2) + $name-padding-bottom;
  padding-bottom: $name-padding-bottom;
  margin: 0 0 ($content-spacing - $name-padding-bottom);
  overflow: hidden;
  font-weight: inherit;
  line-height: $name-line-height;
  text-transform: inherit;
  transition: max-height 0.5s;

  &:hover {
    max-height: $productItemBoxHeight;
  }
}

.claim {
  @include make-font-scale(1);

  position: absolute;
  right: 0;
  bottom: 100%;
  left: 0;
  padding: 5px 10px (10px + ($attributes-height * 0.5));
  background: color('white', 0.9);
}

.separated {
  @mixin separator {
    display: block;
    width: 120px;
    max-width: 97%;
    height: $content-separator-height;
    margin: $content-spacing auto;
    content: '';
    background: linear-gradient(
      90deg,
      color('gray-light', 0) 0%,
      color('gray-light') 50%,
      color('gray-light', 0) 100%
    );
  }

  color: color('contrast-red');

  &::before {
    @include separator;
  }

  &:first-of-type::before {
    margin-top: 0;
  }

  &:last-of-type::after {
    @include separator;

    margin-bottom: 0;
  }

  &--small {
    @include make-font-scale(1);

    color: color('gray');
  }
}

.related {
  $icon-size: 12px;

  position: relative;
  display: inline-block;
  padding-right: $icon-size;
  color: color('contrast-red');
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }

  .icon {
    position: absolute;
    top: 50%;
    right: 0;
    width: $icon-size;
    height: $icon-size;
    transform: translateY(-50%);
  }
}

.cumulative-price {
  min-height: $content-line-height + (2 * $content-separator-height) + (2 * $content-spacing);
}

.attributes {
  @include make-font-scale(3);

  position: absolute;
  top: -1 * ($attributes-height * 0.5);
  right: 0;
  left: 0;
}

.price {
  @include make-font-size(1);

  margin-top: $content-spacing;
  font-weight: 400;

  @include media-breakpoint-up(sm) {
    font-size: inherit;
  }

  &__prefix {
    font-size: 8px;
    vertical-align: 3px;
  }

  &__original {
    vertical-align: 2px;
  }

  &__selling {
    @include make-font-scale(6);

    font-weight: 600;
  }
}

.groups {
  @include make-font-scale(1);

  min-height: 12px;
  color: color('gray');
}

.groups,
.amount {
  margin-top: $content-spacing;
}

.image-container {
  @include image-container;

  display: flex;
  align-items: center;
  justify-content: center;

  &--faded .is-active {
    opacity: 0.4;
  }

  .unavailable {
    opacity: 0.2;
  }
}

.tags {
  position: absolute;
  top: $top-area-spacing-y;
  left: $top-area-spacing-x;
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: flex-start;

  @include media-breakpoint-up(sm) {
    left: $top-area-spacing-x-sm;
  }
}

.cart-info {
  position: absolute;
  top: 89px;
  left: 50%;
  max-width: 90%;
  padding: 7px 10px;
  font-weight: 600;
  color: color('white');
  white-space: nowrap;
  background: color('contrast-green');
  border-radius: 8px;
  opacity: 0.95;
  transform: translate(-50%, -50%);

  @include media-breakpoint-up(sm) {
    padding: 7px 15px;
  }

  &__discount {
    @include make-font-scale(2);

    color: inherit;
  }
}

.control {
  $control-size-gap: 29px;

  position: absolute;
  top: $top-area-spacing-y;
  right: $top-area-spacing-x;
  z-index: $zix-product-item-control;

  &--second {
    top: $control-size-gap + $top-area-spacing-y;
  }

  &--third {
    top: $control-size-gap * 2 + $top-area-spacing-y;
  }
}

.product-separator {
  position: absolute;
  top: 15%;
  left: -2px;
  z-index: $zix-base;
  display: table; // fix width Safari
  max-width: 2px;
  height: 70%;
  border-right: 2px solid;
  border-image: linear-gradient(color('white'), color('gray-lighter'), color('white')) 10 100%;
}

:deep(.purchased-info-control) {
  color: white;
  background-color: color('green-slime');
}

:deep(.purchased-info-content) {
  display: block;
  padding: 24px 33px;
  text-align: center;
  background: sassColor.adjust(color('green-slime'), $lightness: 33%);
}
</style>
