<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'

import { useQueryClient } from '@tanstack/vue-query'

import { FormCheckbox } from '@/components/Form'
import useChangePrivacySettings from '@/composables/useChangePrivacySettings'
import useConfig from '@/composables/useConfig'
import useUser from '@/composables/useUser'
import { configurationQueryKeys } from '@/constants/queryKeys'
import type { ConfigurationWebResponse } from '@/data/api/frontApi/configuration/configurationTypes'
import {
  analyticsLoggerNewsletterConsent,
  analyticsLoggerNewsletterFormImpression,
} from '@/services/analytics/analyticsLogger'
import { toastError, toastSuccess } from '@/services/toast'
import { t } from '@/services/translations'

type ConsentNewsletterProps = {
  title?: string
}

const props = withDefaults(defineProps<ConsentNewsletterProps>(), {
  title: t('checkout.newsletterConsent.title'),
})

const queryClient = useQueryClient()

const configData = useConfig()
const { isUserSignedIn } = useUser()
const adjustPrivacySettings = useChangePrivacySettings()

const hasUserNewsletterEnabled = computed(() => configData.value.user.newsletterEnabled)

const isNewsletterVisible = ref(!hasUserNewsletterEnabled.value)

const newslettersCheckboxValue = computed({
  get() {
    return hasUserNewsletterEnabled.value
  },
  async set(value) {
    try {
      queryClient.setQueryData(configurationQueryKeys.all, (oldData: ConfigurationWebResponse) => ({
        ...oldData,
        user: { ...oldData.user, newsletterEnabled: value },
      }))

      await adjustPrivacySettings({ code: 'newsletter', value })
      toastSuccess(t('profile.newsletter.succeed'))
    } catch (error) {
      toastError(t('profile.newsletter.error'))

      queryClient.setQueryData(configurationQueryKeys.all, (oldData: ConfigurationWebResponse) => ({
        ...oldData,
        user: { ...oldData.user, newsletterEnabled: !value },
      }))
    }
    analyticsLoggerNewsletterConsent(value)
  },
})

onMounted(() => {
  // Send Newsletter form impression event only if a user is signed in and is not subscribed to newsletter
  if (isUserSignedIn && isNewsletterVisible.value) {
    analyticsLoggerNewsletterFormImpression()
  }
})
</script>

<template>
  <div
    v-if="isUserSignedIn && isNewsletterVisible"
    class="newsletter-container p-3 rounded text-center">
    <h3
      class="mb-2 mt-sm-2 text-7 font-weight-bold"
      v-html="props.title" />
    <FormCheckbox
      v-model="newslettersCheckboxValue"
      class="text-4">
      <span v-html="t('checkout.newsletterConsent.checkboxLabel')" />
    </FormCheckbox>
    <p
      class="mt-2 mb-0 text-2 text-gray-dark"
      v-html="t('checkout.newsletterConsent.notice')" />
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.newsletter-container {
  background-color: color('gray-lighter', 0.75);
}
</style>
