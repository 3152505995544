import type { LocationQueryRaw, RouteLocation } from 'vue-router'

import { t } from '@/services/translations'

import appConfig from './appConfig'

/**
 * Set default title of page
 * @param title
 */
function setDocumentTitle(title: string) {
  window.document.title = title
}

/**
 * Set title of page with sufix or default title
 * @param title
 */
function setDocumentTitleWithSufixOrDefault(title: string | undefined) {
  if (title) {
    setDocumentTitle(`${title} | ${t('general.brand')}`)
  } else {
    setDocumentTitle(t('document.defaultTitle'))
  }
}

function setMetaDescription(value: string) {
  const descriptionSelector = document.querySelectorAll("meta[name$='description']")

  descriptionSelector.forEach((item) => {
    item.setAttribute('content', value)
  })
}

/**
 * Remove canonical link if exists
 */
function removeLinkCanonicalIfExists() {
  const linkElement = document.querySelector('link[rel="canonical"]')

  linkElement?.remove()
}

/**
 * Set meta robots tag
 * @param value value of meta robots tag
 */
function setRobotsMetaTag(value: string) {
  const metaRobotsElement = document.querySelector('meta[name="robots"]')

  metaRobotsElement?.setAttribute('content', value)
}

/**
 *  Set canonical link tag
 * @param url for canonical link
 */
function setLinkCanonicalTag(url: string) {
  const metaRobotsElement = document.querySelector('meta[name="robots"]')

  if (metaRobotsElement?.parentNode) {
    const linkElement = document.createElement('link')

    linkElement.setAttribute('rel', 'canonical')
    linkElement.setAttribute('href', url)
    metaRobotsElement?.parentNode.insertBefore(linkElement, metaRobotsElement.nextSibling)
  }
}

/**
 * More than one query param is forbidden.
 * Exacly one query param is allowed if it is in whitelist.
 * No query param is allowed.
 */
function hasForbiddenQueryParams(query: LocationQueryRaw) {
  const queryKeys = Object.keys(query)

  if (queryKeys.length > 1) return true
  if (queryKeys.length === 1) return !appConfig.queryStringWhitelist.includes(queryKeys[0])
  return false
}

/**
 * Set meta robots tag for robots
 * @param routeLocation current route location
 */
function setMetaTagsForRobots(routeLocation: RouteLocation) {
  removeLinkCanonicalIfExists()

  // If route is search page or has query params, set noindex, follow
  if (routeLocation.meta?.pageType === 'search' || hasForbiddenQueryParams(routeLocation.query)) {
    setRobotsMetaTag('noindex, follow')
  } else {
    setRobotsMetaTag('index, follow')
  }

  // If route is not search page, set canonical link
  if (routeLocation.meta?.pageType !== 'search') {
    const domain = window.location.origin

    setLinkCanonicalTag(domain + routeLocation.path)
  }
}

function setCanonicalUrl() {
  const canonicalElement = document.querySelector('link[rel="canonical"]')

  canonicalElement?.setAttribute('href', window.location.href)
}

export {
  setDocumentTitle,
  setMetaTagsForRobots,
  setDocumentTitleWithSufixOrDefault,
  setMetaDescription,
  setRobotsMetaTag,
  setCanonicalUrl,
}
