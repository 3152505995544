import { dataLayerPush } from './utils'

function notificationBell(count: number) {
  dataLayerPush({
    event: 'notification_bell',
    count,
  })
}

export { notificationBell as gtmEventNotificationBell }
