import type { ApiInstance, ApiTags } from './apiTypes'

/**
 * Runs Object.entries on an object and returns typed entries.
 * Normally, Object.entries doesn't preserve types.
 */
const getObjectEntries = <T extends object>(obj: T) => {
  return Object.entries(obj) as [keyof T, T[keyof T]][]
}

/**
 * Prepares an API instance.
 * Further, API needs tags with operations to be passed in.
 * @param baseUrl Full URL of the API. E.g. https://api.kosik.cz/v1
 */
function createApi(baseUrl: string) {
  if (!baseUrl.endsWith('/')) {
    baseUrl += '/'
  }

  // If baseUrl doesn't start with http or https, we add absolute part to it
  // It is needed when new Url() is created in ky implementation
  if (!/https?/.exec(baseUrl)) {
    const { protocol, hostname, port } = window.location
    const portSuffix = port ? `:${window.location.port}` : ''
    const url = `${protocol}//${hostname}${portSuffix}`
    baseUrl = `${url}${baseUrl}`
  }

  return function apiInstance<T extends ApiTags>(tags: T) {
    // 1) We iterate over tags.
    // 2) Then inside tag we iterate over operations.
    // 3) Every operation is given baseUrl of the API.
    // 4) Keys and return values are type-casted to preserve operation type-shape.

    const tagsWithoutContext = getObjectEntries(tags) // 4

    // 1
    const tagsWithApiContext = tagsWithoutContext.reduce<ApiInstance<T>>(
      (tagsAcc, [tagKey, tag]) => {
        const tagWithoutContext = getObjectEntries(tag) // 4
        // 2
        const tagWithApiContext = tagWithoutContext.reduce<{
          [TagKey in keyof typeof tag]: ReturnType<(typeof tag)[TagKey]>
        }>((operationsAcc, [operationName, operationInstance]) => {
          // 3
          operationsAcc[operationName] = operationInstance(baseUrl) as ReturnType<
            T[keyof T][keyof T[keyof T]]
          >
          return operationsAcc
        }, Object.create(null))
        tagsAcc[tagKey] = tagWithApiContext
        return tagsAcc
      },
      Object.create(null),
    )

    return tagsWithApiContext
  }
}

export default createApi
