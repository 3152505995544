import { nanoid } from 'nanoid'

import { ensureConfig } from '@/composables/useConfig'
import type { Action } from '@/services/analytics/luigisbox'
import { fetchPost } from '@/services/api/fetch'

async function post(data: Partial<Action>) {
  const config = await ensureConfig()
  return fetchPost(
    config.externalServices.luigisBoxApiUrl,
    undefined,
    data,
    undefined,
    nanoid(),
    true,
  )
}

export { post as luigisboxApiPost }
