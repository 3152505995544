import type {
  NotificationActionName,
  Notification,
} from '@/components/Notification/notificationTypes'

import { dataLayerPush, mapInStoreNotification } from './utils'

function notificationClick(actionName: NotificationActionName, notification: Notification) {
  dataLayerPush({
    event: 'notification_click',
    type: actionName,
    notification: mapInStoreNotification(notification),
  })
}

export { notificationClick as gtmEventNotificationClick }
