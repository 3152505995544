import { createOperation } from '@/services/apiv2'

import type { StaticBlockResponse } from './staticContentTypes'

type StaticBlockPath = {
  code: string
}

const staticPage = createOperation<StaticBlockResponse, StaticBlockResponse, StaticBlockPath>(
  '/static-block/:code',
  {
    method: 'get',
  },
)

export default staticPage
