import type { AccountInfo, PublicClientApplication } from '@azure/msal-browser'

import appConfig from '../appConfig'
import aadLogout from './aadLogout'

const hasSignUpSignInFlow = (account: AccountInfo) =>
  account.homeAccountId.toUpperCase().includes(appConfig.auth.flowSignUpSignIn.toUpperCase())

const hasValidAuthority = (account: AccountInfo) =>
  account.idTokenClaims?.iss?.toUpperCase().includes(appConfig.auth.authorityDomain.toUpperCase())

const hasValidClientId = (account: AccountInfo) =>
  account.idTokenClaims?.aud === appConfig.auth.clientId

/**
 * Filters MSAL accounts of this service's origin.
 * @param accounts Accounts to be filtered.
 * @returns Accounts of suitable origin.
 */
function filterAuthOriginAccounts(accounts: AccountInfo[]) {
  return accounts.filter(
    (account) =>
      hasSignUpSignInFlow(account) && hasValidAuthority(account) && hasValidClientId(account),
  )
}

/**
 * Sets active account on MSAL instance.
 */
function selectActiveAccount(msalInstance: PublicClientApplication) {
  let accounts = msalInstance.getAllAccounts()

  if (accounts.length === 0) {
    return
  }

  if (accounts.length === 1) {
    msalInstance.setActiveAccount(accounts[0])
    return
  }

  accounts = filterAuthOriginAccounts(accounts)

  if (!accounts.length) {
    return
  }

  if (accounts.every((account) => account.localAccountId === accounts[0].localAccountId)) {
    msalInstance.setActiveAccount(accounts[0])
    return
  }

  aadLogout()
}

export default selectActiveAccount
