<script setup lang="ts">
import { computed } from 'vue'

import Icon from '@/components/Icon/Icon.vue'
import { GiftsClubWrapper } from '@/componentsPure'
import GiftsClubRibbon from '@/componentsPure/GiftsClubRibbon'
import { t } from '@/services/translations'
import localizePrice from '@/utils/localizePrice'

type GiftsClubProgressBarProps = {
  title: string
  ordersWithMinValue: number
  orderMinValue: number
}

const props = defineProps<GiftsClubProgressBarProps>()

const requiredOrdersWithMinValue = 2 // amount of orders with minimum value required to be in the club

const localizedOrderMinValue = computed(() =>
  localizePrice(props.orderMinValue, { maximumFractionDigits: 0 }),
)

const ribbonTitle = computed(() => {
  if (props.ordersWithMinValue < requiredOrdersWithMinValue) {
    return t('profile.giftsClub.ribbon.remainingOrdersAmount', {
      remainingOrdersAmount: requiredOrdersWithMinValue - props.ordersWithMinValue,
      orderMinValue: localizedOrderMinValue.value,
    })
  }

  return t('profile.giftsClub.ribbon.sufficientOrdersAmount')
})
</script>

<template>
  <GiftsClubWrapper :title="props.title">
    <GiftsClubRibbon
      :theme="props.ordersWithMinValue === requiredOrdersWithMinValue ? 'success' : 'warning'"
      class="gifts-club-ribbon">
      <template #title>{{ ribbonTitle }}</template>

      <div class="gifts-club-progress-bar">
        <div class="gifts-club-progress-bar__content">
          <!-- First and second icons -->
          <template
            v-for="orderCount in requiredOrdersWithMinValue"
            :key="orderCount">
            <div class="order-step">
              <div class="icon-wrapper">
                <Icon
                  group="clubs"
                  :icon="props.ordersWithMinValue >= orderCount ? 'check-mark' : 'cross'"
                  :class="['icon', { 'icon--green': props.ordersWithMinValue >= orderCount }]" />
              </div>
              <p class="amount-text">{{ localizedOrderMinValue }}</p>
            </div>

            <div class="divider" />
          </template>

          <!-- Third icon -->
          <div class="icon-wrapper icon-wrapper--last">
            <Icon
              group="clubs"
              icon="gift"
              :class="[
                'icon icon--last',
                { 'icon--green': props.ordersWithMinValue === requiredOrdersWithMinValue },
              ]" />
          </div>
        </div>

        <!-- Green triangle in the background if a user has 1 order with min value -->
        <div
          v-if="props.ordersWithMinValue === 1"
          class="arrow-background" />
      </div>
    </GiftsClubRibbon>
  </GiftsClubWrapper>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.gifts-club-ribbon {
  max-width: 790px;
  margin: 16px auto 0;
}

.gifts-club-progress-bar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 22px 33px;
  overflow: hidden;
  background-color: color('white');
  border-radius: var(--borderRadiusMedium);

  @include media-breakpoint-up(sm) {
    padding: 32px 45px;
  }

  @include media-breakpoint-up(lg) {
    padding: 60px 76px;
  }
}

.gifts-club-progress-bar__content {
  z-index: $zix-above;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 24px 0;
}

.arrow-background {
  position: absolute;
  right: 85%;
  width: 300px;
  height: 300px;
  background-color: color('off-contrast-green');
  transform: rotate(45deg);

  @include media-breakpoint-up(sm) {
    right: 75%;
  }

  @include media-breakpoint-up(md) {
    right: 80%;
  }

  @include media-breakpoint-up(lg) {
    right: 75%;
  }
}

.order-step {
  margin-top: 16px;
}

.icon-wrapper {
  $size: 42px;

  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: $size;
  height: $size;
  margin: 8px 0;
  background-color: color('neutrals-10');
  border-radius: 50%;

  @include media-breakpoint-up(lg) {
    $size: 78px;

    width: $size;
    height: $size;
  }

  &--last {
    $size: 55px;

    width: $size;
    height: $size;

    @include media-breakpoint-up(lg) {
      $size: 110px;

      width: $size;
      height: $size;
    }
  }
}

.icon {
  $size: 16px;

  width: $size;
  height: $size;
  color: color('accent-red');

  @include media-breakpoint-up(lg) {
    $size: 26px;

    width: $size;
    height: $size;
  }

  &--last {
    $size: 30px;

    width: $size;
    height: $size;

    @include media-breakpoint-up(lg) {
      $size: 55px;

      width: $size;
      height: $size;
    }
  }

  &--green {
    color: color('contrast-green');
  }
}

.divider {
  width: 186px;
  height: 6px;
  background-color: color('neutrals-10');
}

.amount-text {
  margin: 0;
  font-weight: bold;
  text-align: center;

  @include make-font-size(1);

  @include media-breakpoint-up(lg) {
    @include make-font-size(4);
  }
}
</style>
