import type { Dayjs } from 'dayjs'

/**
 * Checks if passed dates represents the same day, month and year.
 */
function isSameDay(date1: Dayjs, date2: Dayjs) {
  return date1.isSame(date2, 'day')
}

export default isSameDay
