import { ensureConfig } from '@/composables/useConfig'
import { previousUrl } from '@/router'
import appConfig from '@/services/appConfig'
import { currentEnv } from '@/services/environment'

import { dataLayerPush } from './utils'

type PageData = {
  fullPath: string
  gtmPageType?: string
  checkoutStep?: string
}

type PageViewEvent = {
  event: 'page_view'
  app: {
    environment: string
    country: string
  }
  page: {
    url: string // "https://www.kosik.cz/"
    title: string // "Košík.cz page title",
    type: string // ie. "homepage",
    previous_page: string // url of previous page
    is_virtual: boolean
  }

  client_data: {
    login_status: boolean
    id: number // if logged in, then k3w_id, otherwise pseudo_id, it's a combination of k3W and pseudo_id
    destination_id: number
    sub_destination_id: number | null
  }
}

/**
 * Event that is sent on every pageview
 * @param {Object} pageData Target route location in object format
 * @param {boolean} isClientSideRouted False if navigation triggered reload
 * @returns {void}
 */
async function pageView(pageData: PageData, isClientSideRouted: boolean): Promise<void> {
  const config = await ensureConfig()

  const userId = config.user.luigisboxUserId
  const isUserSignedIn = !!config.user.email

  const title = document.title
  let gtmPageType: string

  if (pageData.gtmPageType) {
    gtmPageType = pageData.gtmPageType
  } else {
    gtmPageType = title.includes('404') ? 'error' : 'other'
  }

  const eventJson: PageViewEvent = {
    event: 'page_view',
    app: {
      environment: currentEnv!,
      country: appConfig.domain,
    },
    page: {
      title,
      url: window.location.href,
      previous_page: previousUrl,
      type: gtmPageType,
      is_virtual: isClientSideRouted,
    },
    client_data: {
      id: userId,
      login_status: isUserSignedIn,
      destination_id: config.cart.destinationId,
      sub_destination_id: config.cart.subDestinationId,
    },
  }
  dataLayerPush(eventJson, false)
}

export { pageView as gtmEventPageView }
export type { PageData as GTMEventPageData }
