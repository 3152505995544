import { createOperation } from '@/services/apiv2'

import {
  modulePath,
  type NotificationsCountParams,
  type NotificationsResponse,
} from './notificationsTypes'

const notificationsHistory = createOperation<
  NotificationsResponse,
  NotificationsResponse,
  undefined,
  undefined,
  NotificationsCountParams
>(`${modulePath}/history`, {
  method: 'get',
})

export default notificationsHistory
