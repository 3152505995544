const MONTHS_PER_YEAR = 12
const AVERAGE_DELIVERY_PRICE = 54
const AVERAGE_CASHBACK_COEFFICIENT = 0.05
const AVERAGE_ORDERS_COST_PER_MONTH = 1500
const AVERAGE_NAPPIES_COST_PER_MONTH = 1000

/**
 * Calculates delivery savings per month
 * @param ordersCountPerMonth Count of orders per month
 * @returns Delivery savings per month
 */
const calculateDeliverySavingsPerMonth = (ordersCountPerMonth: number) => {
  return ordersCountPerMonth * AVERAGE_DELIVERY_PRICE
}

/**
 * Calculates delivery savings per year
 * @param ordersCountPerMonth Count of orders per month
 * @returns Delivery savings per year
 */
const calculateDeliverySavingsPerYear = (ordersCountPerMonth: number) => {
  return calculateDeliverySavingsPerMonth(ordersCountPerMonth) * MONTHS_PER_YEAR
}

/**
 * Calculates cashback from orders per month
 * @param spendingPerMonth Spending per month
 * @param round If true, the result is rounded down
 * @returns Cashback from orders per month (rounded down if round = true)
 */
const calculateCashbackPerMonth = (spendingPerMonth: number, round = true) => {
  const result = spendingPerMonth * AVERAGE_CASHBACK_COEFFICIENT

  return round ? Math.floor(result) : result
}

/**
 * Calculates cashback from orders per year
 * @param spendingPerMonth Spending per month
 * @returns Rounded down cashback from orders per year
 */
const calculateCashbackPerYear = (spendingPerMonth: number) => {
  return Math.floor(calculateCashbackPerMonth(spendingPerMonth, false) * MONTHS_PER_YEAR)
}

/**
 * Calculates count of free orders per year
 * @param spendingPerMonth Spending per month
 * @returns Rounded down count of free orders per year
 */
const calculateFreeOrdersCountPerYear = (spendingPerMonth: number) => {
  return Math.floor(calculateCashbackPerYear(spendingPerMonth) / AVERAGE_ORDERS_COST_PER_MONTH)
}

/**
 * Calculates count of months with free nappies per year
 * @param spendingPerMonth Spending per month
 * @returns Count of months with free nappies per year (rounded down if bigger than 1, with 2 decimals if less than 1)
 */
const calculateMonthsCountWithFreeNappiesPerYear = (spendingPerMonth: number) => {
  const result = calculateCashbackPerYear(spendingPerMonth) / AVERAGE_NAPPIES_COST_PER_MONTH

  return result >= 1 || result === 0 ? Math.floor(result) : Number(result.toFixed(2))
}

export {
  calculateDeliverySavingsPerMonth,
  calculateDeliverySavingsPerYear,
  calculateCashbackPerMonth,
  calculateCashbackPerYear,
  calculateFreeOrdersCountPerYear,
  calculateMonthsCountWithFreeNappiesPerYear,
}
