/**
 * Data to be pushed to dataLayer.
 * only event is required
 * @see https://mallfresh.atlassian.net/wiki/spaces/KA/pages/7461503003/GTM+Documentation%3A+GTM+and+dataLayer+implementation#DataLayer
 */
type DataLayerPushData = {
  event: string
  [key: string]: unknown
}

/**
 * Pushes data to dataLayer.
 * @param {object} data Data to be pushed.
 * @param {boolean} [_clear=true] If true, _clear key is created in data object.
 * @see https://stackoverflow.com/questions/47892127/succinct-concise-syntax-for-optional-object-keys-in-es6-es7
 * @returns {void}
 */
function dataLayerPush(data: DataLayerPushData, _clear = true) {
  window.dataLayer = window.dataLayer || []

  window.dataLayer.push({
    ...data,
    ...(_clear && { _clear }),
  })
}

export { dataLayerPush }
