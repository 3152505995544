<script setup>
import { computed } from 'vue'

import Icon from '@/components/Icon/Icon.vue'

const props = defineProps({
  /**
   * Used by v-model. Do not use directly.
   */
  modelValue: {
    type: Number,
    required: true,
  },
  /**
   * Minimal value the counter can reach.
   */
  min: {
    type: Number,
    default: null,
  },
  /**
   * Maximal value the counter can reach.
   */
  max: {
    type: Number,
    default: null,
  },
  /**
   * Amount the counter decreases and increases its value by.
   */
  step: {
    type: Number,
    default: 1,
  },
  /**
   * Sets the pill-shaped visual appearance of the counter.
   */
  pill: {
    type: Boolean,
    default: false,
  },
  /**
   * Object containing TID values used for testing purposes.
   * Values are assigned to data attributes of the component and its specific parts.
   *
   * The object overrides the default one as a whole, no merging is executed.
   *
   * For all available keys of this component follow the default object as an example.
   */
  dataTids: {
    type: Object,
    default: () => ({
      formCounter: 'form-counter',
      decrease: 'decrease',
      increase: 'increase',
      input: 'input',
    }),
  },
})

const emit = defineEmits(['update:modelValue'])
const getMin = computed(() => props.min ?? -Infinity)
const getMax = computed(() => props.max ?? Infinity)

const counterValue = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    if (!value) {
      value = 0
    }

    if (value < getMin.value) {
      value = getMin.value
    } else if (value > getMax.value) {
      value = getMax.value
    }

    emit('update:modelValue', value)
  },
})

const decrease = () => {
  counterValue.value = Math.max(counterValue.value - props.step, getMin.value)
}
const increase = () => {
  counterValue.value = Math.min(counterValue.value + props.step, getMax.value)
}

const isMinReached = computed(() => counterValue.value <= getMin.value)
const isMaxReached = computed(() => counterValue.value >= getMax.value)
</script>

<template>
  <div
    :data-tid="dataTids.formCounter"
    :class="[
      'form-counter',
      {
        'form-counter--pill': pill,
      },
    ]">
    <button
      :data-tid="dataTids.decrease"
      type="button"
      class="form-counter__control"
      :disabled="isMinReached || null"
      @click="decrease">
      <Icon
        icon="minus"
        class="form-counter__control-icon" />
    </button>

    <input
      v-model.number="counterValue"
      :data-tid="dataTids.input"
      type="number"
      class="form-counter__input"
      pattern="[0-9]*"
      :min="min"
      :max="max" />

    <button
      :data-tid="dataTids.increase"
      type="button"
      class="form-counter__control form-counter__control--striking"
      :disabled="isMaxReached || null"
      @click="increase">
      <Icon
        icon="plus"
        class="form-counter__control-icon" />
    </button>
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;
@use 'sass:color' as sassColor;

$counter-width: 100px;
$counter-height: 24px;
$counter-width-pill: 120px;
$counter-height-pill: 32px;

.form-counter {
  display: inline-flex;
  gap: em(4px);
  width: em($counter-width);
  height: em($counter-height);

  &--pill {
    gap: 0;
    width: em($counter-width-pill);
    height: em($counter-height-pill);
    overflow: hidden;
    border-radius: em(9999px);
  }

  &__control {
    position: relative;
    width: em($counter-height);
    padding: 0;
    color: color('black');
    background: color('primary-light');
    border: 0;
    border-radius: em($borderRadiusSmall);
    transition: background var(--baseTransitionTime) ease-in-out;

    &:hover {
      background: color('off-primary-light');
    }

    &[disabled] {
      background: color('gray-light');
    }

    &--striking {
      color: color('white');
      background: color('primary');

      &:hover {
        background: color('off-primary-mid');
      }

      &[disabled] {
        background: color('gray-light');
      }
    }
  }

  &--pill &__control {
    width: em(35px);
    border-radius: 0;
  }

  &__control-icon {
    $size: 7px;

    position: absolute;
    top: 50%;
    left: 50%;
    width: em($size);
    height: em($size);
    transform: translate(-50%, -50%);
  }

  &--pill &__control-icon {
    $size: 12px;

    width: em($size);
    height: em($size);
  }

  &__input {
    flex: 1;
    min-width: 0;
    font-size: em(14px) !important;
    color: inherit;
    text-align: center;

    // @FIX: removes iOS native input shadow
    appearance: textfield;
    background-image: linear-gradient(color('white'), color('white'));
    border: 0;
    border-radius: em($borderRadiusSmall);

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      appearance: none;
    }

    &:hover {
      box-shadow: inset 0 0 0 1px color('gray-light');
    }

    &:focus {
      box-shadow: inset 0 0 0 1px color('gray-darker');
    }
  }

  &--pill &__input {
    font-weight: 700;
    border: 1px solid color('gray-lighter');
    border-right: 0;
    border-left: 0;
    border-radius: 0;

    &:hover,
    &:focus {
      box-shadow: none;
    }
  }
}
</style>
