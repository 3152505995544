<script setup lang="ts">
import { ref, computed, watch } from 'vue'

import { onClickOutside } from '@vueuse/core'
import { storeToRefs } from 'pinia'

import { BaseButton } from '@/componentsPure'
import modalIndex from '@/constants/modalIndex'
import type { GPS } from '@/data/api/frontApi/address/addressTypes'
import { analyticsLoggerAddressPin } from '@/services/analytics/analyticsLogger'
import { toastSuccess } from '@/services/toast'
import { t } from '@/services/translations'
import useDeviceDetectorStore from '@/store/pinia/useDeviceDetectorStore'
import useModalStore from '@/store/pinia/useModalStore'

import DeliveryOnLocationDesktopSavePanel from './DeliveryOnLocationDesktopSavePanel.vue'
import LocationMapEditWrapper from './LocationMapEditWrapper.vue'
import useDeliveryOnLocationMap from './useDeliveryOnLocationMap'

type DeliveryOnLocationMapProps = {
  // @TODO probably remove null as the map wont work without gps https://mallfresh.atlassian.net/browse/KNW-18567
  // parent component should handle that null wont be passed in
  currentGps: GPS | null // GPS coordinates set by pin
  // @TODO check addressGps also
  addressGps: GPS | null // GPS coordinates of the address
  address: string
  street: string
  zip: string
  editable?: boolean
  hasPolygons?: boolean
  canValidateGps?: boolean
}

const props = defineProps<DeliveryOnLocationMapProps>()
const edit = defineModel<boolean>('edit', { default: false })
const emit = defineEmits<{
  save: [positionGps: GPS | null]
}>()

const deviceDetectorStore = useDeviceDetectorStore()
const { openModal, closeModal } = useModalStore()

const { breakpointDownSm } = storeToRefs(deviceDetectorStore)

const deliveryOnLocationMap = ref<HTMLDivElement>()
const address = computed(() => (props.canValidateGps ? props.address : ''))
const { changedLocations, gpsIsValid, saveIsDisabled, onMapMove, onMapChanged } =
  useDeliveryOnLocationMap(address)

//Set the saved GPS coordinates to the state (it could be either the original or the new ones, who knows).
changedLocations.value = props.currentGps

onClickOutside(deliveryOnLocationMap, () => {
  !breakpointDownSm.value && edit.value && onCancel()
})

const emptyGps = computed(
  () => changedLocations.value?.lat === 0 && changedLocations.value?.lng === 0,
)
const editDesktop = computed(() => !breakpointDownSm.value && edit.value)

function openMapModal() {
  openModal({
    id: modalIndex.deliveryOnLocation,
    triggerOptions: {
      currentGps: props.currentGps,
      addressGps: props.addressGps,
      address: props.address,
      street: props.street,
      zip: props.zip,
      onSave,
    },
  })
}

// save location to state and trigger save to form
function onSave(newLocation: GPS) {
  changedLocations.value = { ...newLocation }
  saveGps()
}

function onCancel() {
  edit.value = false
  analyticsLoggerAddressPin({ activity: 'pin_closed' })
  changedLocations.value = props.currentGps ? { ...props.currentGps } : null
}

function closeMapModal() {
  closeModal(modalIndex.deliveryOnLocation)
}

function makeMapEditable() {
  analyticsLoggerAddressPin({ activity: 'pin_opened' })
  edit.value = true
  if (breakpointDownSm.value) {
    openMapModal()
  }
}

// reset to original address location
function onResetLocation() {
  props.addressGps && onMapChanged(props.addressGps)
}

function saveGps() {
  if (changedLocations.value) {
    edit.value = false

    let saveValue: GPS | null = changedLocations.value
    if (
      props.addressGps?.lat === changedLocations.value.lat &&
      props.addressGps?.lng === changedLocations.value.lng
    ) {
      saveValue = null
    }

    analyticsLoggerAddressPin({ activity: 'pin_saved' })
    toastSuccess(t('delivery.deliveryLocation.saveSuccessfull'))
    emit('save', saveValue)
  }
}

watch(
  [() => props.addressGps, () => props.currentGps],
  ([newAddressGps, newCurrentGps], [oldAddressGps]) => {
    // user changed the address in input
    if (newAddressGps !== oldAddressGps && oldAddressGps !== null) {
      changedLocations.value = newAddressGps
    }
    // user used map pin to save address with different gps coordinates
    else if (newAddressGps !== newCurrentGps && newCurrentGps) {
      changedLocations.value = newCurrentGps
    } else {
      changedLocations.value = newAddressGps
    }
  },
)

watch(
  () => breakpointDownSm.value,
  (newVal) => {
    if (edit.value) {
      newVal ? openMapModal() : closeMapModal()
    }
  },
)
</script>

<template>
  <div
    ref="deliveryOnLocationMap"
    class="delivery-on-location-map"
    data-tid="location-map">
    <LocationMapEditWrapper
      :gps="changedLocations"
      :location-error="gpsIsValid === false"
      :edit="editDesktop"
      class="location-map-edit-wrapper"
      :on-map-move="onMapMove"
      @map-changed="onMapChanged"
      @reset-location="onResetLocation"
      @cancel-edit="onCancel">
      <template #edit-btn>
        <BaseButton
          v-if="props.editable"
          variant="dark-brand"
          :disabled="emptyGps"
          class="delivery-map-edit-btn"
          @click.prevent="makeMapEditable">
          {{ t('delivery.deliveryLocation.editButton') }}
        </BaseButton>
      </template>
    </LocationMapEditWrapper>
    <DeliveryOnLocationDesktopSavePanel
      v-if="editDesktop && props.editable"
      :submit-disabled="saveIsDisabled"
      @cancel="onCancel"
      @save="saveGps" />
  </div>
</template>

<style scoped lang="scss">
@use '@/legacy' as *;

.delivery-on-location-map {
  border: 1px solid color(gray-light);
  border-top: none;
  border-bottom-right-radius: var(--borderRadiusMedium);
  border-bottom-left-radius: var(--borderRadiusMedium);

  .location-map-edit-wrapper {
    height: 330px;
    background: color('gray-lighter');

    .delivery-map-edit-btn {
      font-size: get-font-size(4);
    }
  }
}
</style>
