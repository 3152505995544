<script setup lang="ts">
import { computed, ref, type Ref } from 'vue'
import { useRoute } from 'vue-router'

import ProductDetailGhost from '@/components/Product/Detail/Ghost/ProductDetailGhost.vue'
import ProductDetail from '@/components/Product/Detail/ProductDetail.vue'
import WarehouseInfoBar from '@/components/WarehouseInfoBar'
import useCurrentCategoryId, { type NavBreadcrumbItem } from '@/composables/useCurrentCategoryId'
import { type Product } from '@/composables/useProductItem'
import { setMetaDescription } from '@/services/documentHeadControl'
import useProductDetailStore from '@/store/pinia/useProductDetailStore'

import ErrorPage from './Error/ErrorPage.vue'

const route = useRoute()
const { fetchProductDetail } = useProductDetailStore()

function isProduct(maybeProduct: any): maybeProduct is Product {
  return maybeProduct?.origin !== undefined
}

const product: Ref<Product | null> = ref(null)
const isPageExist = ref(true)
const fetchProductDetailApi = async () => {
  try {
    product.value = await fetchProductDetail({
      path: route.path,
      isSale: !!route.query?.sale,
    })

    if (isProduct(product.value) && product.value.origin.detail?.metaDescription) {
      setMetaDescription(product.value.origin.detail.metaDescription)
    }

    isPageExist.value = true
  } catch (e) {
    isPageExist.value = false
  }
}
fetchProductDetailApi()

if (window.history.state?.productDetail) {
  delete window.history.state.productDetail
}

// This just simulates regular product breadcrumbs to
// correctly activate current menu item in L1 menu.
const breadcrumbs = computed<NavBreadcrumbItem[]>(() => {
  if (!isProduct(product.value)) return []

  return [
    {
      name: 'Dummy',
    } satisfies NavBreadcrumbItem,
    ...product.value.origin.breadcrumbs,
  ]
})
useCurrentCategoryId(breadcrumbs)
</script>

<template>
  <div
    v-if="isPageExist"
    class="container">
    <WarehouseInfoBar cart-full-width />
    <ProductDetail
      v-if="isProduct(product)"
      :product="product"
      is-page />
    <ProductDetailGhost v-else />
  </div>
  <ErrorPage v-else />
</template>
