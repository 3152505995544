import useModalStore from '@/store/pinia/useModalStore'

export const usePopupProps = {
  name: {
    type: String,
    required: true,
  },
  size: {
    type: String,
    default: 'medium',
  },
  onlyMobile: {
    type: Boolean,
    default: false,
  },
  transitionName: {
    type: String,
    default: 'fade',
  },
  classCss: {
    type: String,
    default: '',
  },
  boxClassCss: {
    type: String,
    default: '',
  },
  classCloseCross: {
    type: String,
    default: '',
  },
  dataTidCloseCross: {
    type: String,
    default: 'user-info-dialog__close-modal',
  },
  collisionGroup: {
    type: String,
    default: undefined,
  },
  analyticsId: {
    type: String,
    default: undefined,
  },
  disableClose: {
    type: Boolean,
    default: false,
  },
}

export function usePopup(props) {
  const { createModal, openModal, closeModal } = useModalStore()

  const setOpen = () => {
    openModal(props.name)
  }

  const setClose = (analyticsId) => {
    closeModal(props.name, analyticsId)
  }

  const setState = (isOpen, analyticsId) => {
    isOpen ? setOpen() : setClose(analyticsId)
  }

  return {
    createModal,
    setOpen,
    setClose,
    setState,
  }
}
