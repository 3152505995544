<script setup lang="ts">
import { CLUBS } from '@/components/Clubs/constants'
import Icon from '@/components/Icon/Icon.vue'
import ImgResponsive from '@/components/stories/atoms/imgResponsive/ImgResponsive.vue'
import useUser from '@/composables/useUser'
import { t } from '@/services/translations'

const { getUserClubEligibility } = useUser()

const isMember = getUserClubEligibility[CLUBS.FULL_CARE].isMember
</script>

<template>
  <div class="grid-container">
    <div class="cover-container" />
    <div class="header-container text-center px-2 my-4">
      <img
        :src="'/vue/img/clubs/full-care-logo.svg?v=1'"
        :alt="t('fullCare.title')"
        class="full-care-logo" />
    </div>
    <div class="content-container text-center mx-3 mx-lg-5">
      <div
        class="eligibility-title text-6 font-weight-bold text-white text-uppercase p-2 mb-4"
        v-text="t(`fullCare.cover.${isMember ? 'inClub' : 'notInClub'}.title`)" />
      <div
        v-if="!isMember"
        class="text-4 font-weight-bold text-gray-dark mb-3"
        v-text="t(`fullCare.cover.notInClub.description`)" />
      <div
        class="text-4 font-weight-bold text-gray-darker text-uppercase mb-2"
        v-text="t(`fullCare.cover.${isMember ? 'inClub' : 'notInClub'}.phone`)" />
      <div
        class="contact-info-container d-flex flex-wrap align-items-center justify-content-center mb-3">
        <div class="d-flex align-items-center">
          <Icon
            icon="phone"
            group="clubs"
            class="icon-phone text-primary-light flex-shrink-0 mr-2" />
          <a
            :href="t('fullCare.cover.phone.link')"
            class="text-8 text-gray-darker"
            v-html="t('fullCare.cover.phone.number')" />
        </div>
        <div
          v-if="isMember"
          class="d-flex align-items-center ml-4">
          <Icon
            icon="clock"
            class="icon-clock text-primary-light flex-shrink-0 mr-2" />
          <div
            class="text-7 text-gray-darker"
            v-text="t(`fullCare.cover.openHours`)" />
        </div>
      </div>
    </div>
    <div class="image-container">
      <ImgResponsive
        :srcset="[
          {
            url: '/vue/img/clubs/full-care.webp',
          },
          {
            pixelRatio: 1.5,
            url: '/vue/img/clubs/full-care@x2.webp',
          },
        ]"
        class="image"
        lazyload
        :alt="t('fullCare.title')" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.grid-container {
  display: grid;
}

.cover-container {
  $border-radius: 40px;

  grid-row: 1 / 5;
  grid-column: 1 / 3;
  height: 90%;
  background-color: color('pink-light');
  border-top-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;

  @include media-breakpoint-up(sm) {
    height: 100%;
  }

  @include media-breakpoint-up(xl) {
    grid-row: 2 / 4;
    grid-column: 1 / 5;
  }
}

.header {
  &-container {
    grid-row: 1;
    grid-column: 1 / 3;
    align-self: stretch;
    justify-items: stretch;

    @include media-breakpoint-up(sm) {
      grid-row: 1 / 2;
    }

    @include media-breakpoint-up(xl) {
      grid-row: 2;
      grid-column: 1;
    }
  }
}

.content-container {
  grid-row: 2;
  grid-column: 1 / 3;

  @include media-breakpoint-up(sm) {
    grid-column: 1;
  }

  @include media-breakpoint-up(xl) {
    grid-row: 3 / 4;
  }
}

.image-container {
  grid-row: 3;
  grid-column: 1 / 3;

  @include media-breakpoint-up(sm) {
    grid-row: 2;
    grid-column: 2;
  }

  @include media-breakpoint-up(xl) {
    grid-row: 1 / 5;
  }
}

.full-care-logo {
  width: 205px;
  height: 46px;
}

.icon {
  &-phone {
    $size: 32px;

    width: $size;
    height: $size;
  }

  &-clock {
    $size: 24px;

    width: $size;
    height: $size;
  }
}

.eligibility-title {
  line-height: 1.2;
  background-color: color('primary', 0.8);
}

.contact-info-container {
  row-gap: 10px;
}

.image {
  width: 184px;
  margin: 0 auto;

  @include media-breakpoint-up(sm) {
    width: 160px;
    margin-right: 35px;
    margin-bottom: 20px;
  }

  @include media-breakpoint-up(xl) {
    width: 355px;
    margin: 0 30px 0 0;
  }
}
</style>
