import { onMounted } from 'vue'

import { Vendors } from '@/constants/vendors'
import useUserInterfaceStore from '@/store/pinia/useUserInterfaceStore'

function useSetDefaultVendorId() {
  const { setVendorId } = useUserInterfaceStore()
  onMounted(() => setVendorId(Vendors.BASE))
}

export default useSetDefaultVendorId
