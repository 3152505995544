import { dataLayerPush } from './utils'

type AddressSaveType = 'new_address' | 'address_changed'

function addressSave(type: AddressSaveType) {
  dataLayerPush({ event: 'address_save', type })
}

export { addressSave as gtmEventAddressSave }
export type { AddressSaveType }
