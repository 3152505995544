<script setup lang="ts">
import { storeToRefs } from 'pinia'

import { GiftsClubWrapper } from '@/componentsPure'
import useDeviceDetectorStore from '@/store/pinia/useDeviceDetectorStore'

import GiftsClubBenefitItem from './GiftsClubBenefitItem.vue'
import type { GiftsClubBenefits } from './giftsClubBenefitsTypes'

type BenefitsListProps = GiftsClubBenefits

const props = defineProps<BenefitsListProps>()

const deviceDetectorStore = useDeviceDetectorStore()
const { breakpointUpXl } = storeToRefs(deviceDetectorStore)
</script>

<template>
  <GiftsClubWrapper :title="props.title">
    <div
      :class="['items', { 'items--compact-mobile': props.type === 'compact' && !breakpointUpXl }]">
      <div
        v-for="(item, index) in props.items"
        :key="index"
        class="item">
        <GiftsClubBenefitItem
          :title="item.title"
          :description="item.description"
          :image="props.items[index].image"
          :class="{ 'h-100': props.type === 'classic' }" />
        <div
          v-if="props.type === 'compact' && !breakpointUpXl && index !== props.items.length - 1"
          class="divider" />
      </div>
    </div>
  </GiftsClubWrapper>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.items {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  align-items: stretch;
  justify-content: center;
  margin-top: 16px;

  &--compact-mobile {
    gap: unset;
    background-color: color('white');
    border-radius: var(--borderRadiusMedium);
  }
}

.item {
  width: 100%;

  @include media-breakpoint-up(xl) {
    max-width: 320px;
  }
}

.divider {
  margin: 0 16px;
  border-bottom: 1px solid color('neutrals-20');
}
</style>
