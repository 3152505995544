import { createOperation } from '@/services/apiv2'

import type { ClientSavingsQueryParams, ClientSavingsResponse } from './clientTypes'

const savings = createOperation<
  ClientSavingsResponse,
  ClientSavingsResponse,
  undefined,
  undefined,
  ClientSavingsQueryParams
>('client/savings', {
  method: 'get',
})

export default savings
