<script setup lang="ts">
import { computed, ref } from 'vue'

import deburr from 'lodash-es/deburr'

import { FormCheckbox } from '@/components/Form'
import Icon from '@/components/Icon/Icon.vue'
import { t } from '@/services/translations'

export type TypeModelValue = {
  id: number | string
  name: string
  value: boolean
  count?: number
}

type TypeProps = {
  modelValue: TypeModelValue[]
  searchable?: boolean
  limit?: number
  small?: boolean
  messageEmpty?: string
}

const props = withDefaults(defineProps<TypeProps>(), {
  limit: Infinity,
  messageEmpty: '',
})
const searchTerm = ref('')

const checkboxes = computed<TypeModelValue[]>(() => {
  if (searchTerm.value) {
    return props.modelValue.filter((checkbox) => {
      const term = new RegExp(deburr(searchTerm.value), 'gi')

      return term.test(deburr(checkbox.name))
    })
  }

  return props.modelValue
})

const showAll = ref(false)
const isToggleVisible = computed(() => props.limit < checkboxes.value.length)

const checkboxesVisible = computed((): TypeModelValue[] => {
  if (isToggleVisible.value && !showAll.value && props.limit !== Infinity) {
    return checkboxes.value.slice(0, props.limit - 1)
  }

  return checkboxes.value
})

const searchTermNotFound = computed(() => searchTerm.value.length > 0 && !checkboxes.value.length)

const toggleShowAll = () => {
  showAll.value = !showAll.value
}

const emit = defineEmits<{
  'update:modelValue': [newValue: TypeModelValue[]]
  changedItem: [value: TypeModelValue]
}>()

const emitInput = async (changedItem: TypeModelValue): Promise<void> => {
  window.setTimeout((): void => {
    emit('update:modelValue', props.modelValue)
    emit('changedItem', changedItem)
  })
}
</script>

<template>
  <div>
    <div
      v-if="props.searchable"
      class="position-relative mb-2">
      <input
        v-model="searchTerm"
        type="text"
        class="search-input" />
      <Icon
        icon="magnifier"
        class="search-input__icon" />
    </div>
    <template
      v-for="(checkbox, index) in checkboxesVisible"
      :key="`checkbox-${index}`">
      <FormCheckbox
        v-model="checkbox.value"
        :nowrap="props.small"
        :small="props.small"
        :disabled="checkbox.count === 0 || false"
        :class="[
          'checklist-item',
          {
            'checklist-item--small': props.small,
          },
        ]"
        @input="emitInput(checkbox)">
        {{ checkbox.name }}
        <span
          v-if="checkbox.count"
          class="text-gray"
          v-text="`(${checkbox.count})`" />
      </FormCheckbox>
      <div
        v-if="index !== checkboxesVisible.length - 1 && props.small"
        class="checklist-item__separator m-2 bg-gray-lighter" />
    </template>
    <div
      v-if="searchTermNotFound"
      :class="[
        'checklist-info',
        {
          'mx-0': !props.small,
          'text-center': props.small,
        },
      ]"
      v-text="t('filters.notFound')" />
    <div
      v-if="!props.modelValue.length"
      :class="[
        'checklist-info',
        {
          'mx-0': !props.small,
          'text-center': props.small,
        },
      ]"
      v-text="props.messageEmpty" />
    <div
      v-if="isToggleVisible"
      class="checklist-toggle pointer"
      @click.prevent="toggleShowAll">
      <Icon
        icon="arrow"
        :class="[
          'checklist-toggle-icon',
          {
            'checklist-toggle-icon--all': showAll,
          },
        ]" />
      <span v-text="t(`filters.${showAll ? 'less' : 'more'}`)" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.search-input {
  width: 100%;
  height: em(24px);
  padding: 0 em(30px) 0 em(10px);
  border: em(1px) solid color('gray-light');
  border-radius: em(12px);

  &__icon {
    position: absolute;
    top: 0;
    right: em(12px);
    height: 100%;
    color: color('gray-light');
  }

  &:focus {
    border: em(1px) solid color('gray-dark');

    & + .search-input__icon {
      color: color('gray-dark');
    }
  }
}

.checklist-item {
  display: block;
  margin-bottom: em(16px);
  cursor: pointer;

  &--small {
    margin: em(4px) 0 em(8px);
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__separator {
    height: 1px;
  }
}

.checklist-info {
  margin: em(16px 16px 8px);
  color: color('gray');
}

.checklist-toggle {
  position: relative;
  display: block;
  padding-left: em(20px);
  line-height: em(16px);
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.checklist-toggle-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: em(16px);
  height: em(16px);

  &--all {
    transform: rotate(180deg);
  }
}
</style>
