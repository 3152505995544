import { createOperation } from '@/services/apiv2'

import type { FrontApiEmptyResponse } from '../../frontApi/frontApiTypes'
import { modulePath } from './notificationsTypes'

type NotificationsStateRequest = {
  notificationIds?: string[]
  state: string
}

type NotificationsStateParam = {
  customerId: number
}

const notificationsState = createOperation<
  FrontApiEmptyResponse,
  FrontApiEmptyResponse,
  undefined,
  NotificationsStateRequest,
  NotificationsStateParam
>(`${modulePath}/state`, {
  method: 'put',
})

export default notificationsState
