import { t } from '@/services/translations'

import logApmError from '../logApmError'
import { toastError } from '../toast'
import AuthError from './AuthError'
import getMsalInstance from './getMsalInstance'
import getMsalRedirectResponse from './getMsalRedirectResponse'
import msalState from './msalState'

/**
 * Triggers logout redirect.
 * @param redirectUri URI to redirect after successful logout.
 * If not provided, default `auth.redirectUri` value from msalConfig.ts is used.
 */
async function aadLogout(redirectUri?: string) {
  if (msalState.inProgress) return
  msalState.inProgress = true

  try {
    const msalInstance = await getMsalInstance()
    await getMsalRedirectResponse() // Clear response from previous redirect without processing it
    const logoutRedirectOptions = redirectUri ? { postLogoutRedirectUri: redirectUri } : undefined
    await msalInstance.logoutRedirect(logoutRedirectOptions)
    msalState.inProgress = false
  } catch (error) {
    logApmError(new AuthError('logout_error', error))
    toastError(t('auth.error.general'))
  }
}

export default aadLogout
