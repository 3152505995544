import { dataLayerPush } from './utils'

function experimentViewed(experimentId: string, variationId: string) {
  dataLayerPush({
    event: 'experiment_viewed',
    experimentId,
    variationId,
  })
}

export { experimentViewed as gtmEventExperimentViewed }
