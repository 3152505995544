import dayjs, { type Dayjs } from 'dayjs'

/**
 * Checks if given date is the future.
 */
function isFuture(date: Dayjs) {
  return date.isAfter(dayjs())
}

export default isFuture
