<script setup lang="ts">
type GiftsClubRibbonProps = {
  theme: 'success' | 'warning' | 'disabled'
}

const props = defineProps<GiftsClubRibbonProps>()
</script>

<!-- Ribbon used to wrap Gifts club components -->
<template>
  <div>
    <div :class="`ribbon ribbon--${props.theme}`">
      <p :class="`title title--${props.theme} text-2`">
        <slot name="title" />
      </p>
    </div>

    <!-- Slot with content -->
    <div class="content">
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.ribbon {
  height: 60px;
  border-top-left-radius: var(--borderRadiusBig);
  border-top-right-radius: var(--borderRadiusBig);

  &--success {
    background-color: color('contrast-green');
  }

  &--warning {
    background-color: color('accent-orange');
  }

  &--disabled {
    background-color: color('neutrals-80');
  }
}

.title {
  padding-top: 8px;
  margin: 0;
  font-weight: var(--fontWeightBold);
  text-align: center;

  &--success,
  &--disabled {
    color: color('white');
  }

  &--warning {
    color: color('text-paragraph');
  }
}

.content {
  margin-top: -28px;
}
</style>
