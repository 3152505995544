import { useToast } from 'vue-toastification'

import { SessionStorageKeys } from '@/constants/storageKeys'
import { storagePersistSession, storageRetrieveSession } from '@/services/storage'

import type { Toast } from './toastPersist'

// You can use composable outside of Vue components, see:
// https://github.com/Maronato/vue-toastification#using-with-vuex-or-outside-components
const toastificationInstance = useToast()

/**
 * Trigger all unfinished toasts and clear their session storage.
 * This function should be called before pushing unfinished toasts.
 * @param newVendorId - Navigate to this vendorId.
 */
function clearToasts(newVendorId: number) {
  const toasts = storageRetrieveSession<Toast[]>(SessionStorageKeys.toast)
  if (!toasts) return

  // Dismiss the toasts which are unfit for the clicked vendor
  // If wanted vendors are not filled, mark as valid for new vendor
  const newVendorIdValidToasts = toasts.filter((toast) => {
    if (!toast.onlyForVendorIds) return true
    if (!toast.onlyForVendorIds.includes(newVendorId)) {
      if (toast.toastId) {
        toastificationInstance.dismiss(toast.toastId)
      }
      return false
    }
    return true
  })

  storagePersistSession(SessionStorageKeys.toast, newVendorIdValidToasts)
}

export default clearToasts
