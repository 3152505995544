import domainConfig from '__K3W_DOMAIN__/appConfig.json'

const windowConfig = window?.config ?? {}

const appConfigDefault = {
  // authorization settings object
  auth: windowConfig.auth,
  // API paths
  webApiBaseUrl: '/api/web/',
  frontApiBaseUrl: '/api/front/',
  orderTrackingApiPath: `https://${windowConfig.orderTrackingApiPath}/tracking/v1/orders/`,
  notificationsPath: `https://${windowConfig.inStoreNotificationApiPath}/api/in-store/v1/`,
  /**
   * Array of EOC auth services. DOP-316, DOP-489
   * @deprecated Will be replaced with configuration/web in the future.
   *
   */
  eocAuth: [
    {
      authCommonUri: 'https://auth.t.all.web.ne.kosik.systems/',
      domainSubstr: '.t.all.web.ne.kosik.systems',
    },
    {
      authCommonUri: 'https://auth-eoc.kosik.group/',
      domainSubstr: '-eoc.kosik.group',
    },
  ],
  // Social providers used for AAD login/registration
  authSocialProviders: ['facebook.com', 'apple.com', 'google.com'],
  // timeouts (in milliseconds)
  tooltipHideDelay: 3000,
  autoplayDuration: 5000,
  productSearch: {
    resultsSuggestionThrottle: 1000,
    suggesterAnalyticsDebouncing: 1200,
  },
  inputFormThrottleTime: 400,
  inputFormDebouncingTime: 200,
  productQuantityDebouncing: 750,
  // product count limits
  defaultPersonalizedWidgetProductCount: 40,
  defaultProductPageProductCount: 30,

  // On the PageProducts, products are displayed either in 'full' or 'horizontal' mode.
  // For each ProductBoxListing in 'horizontal' mode, this is the number of products to load and display per category.
  defaultProductPageCategoryProductCount: 12,
  defaultSuggestionsProductCount: 10,
  // other limits
  addressSuggestLimit: 11,
  // ids
  saleProductMinimalId: 1000000000,
  // feature flags
  enableWelcomePage: false,
  enableAddressSelectorPriceBanner: false,
  showMapForConfirmAddress: false,
  enableClubs: false,
  enableRecommendedPriceInfo: true,
  charityProduct: '',
  isFirstL1CategoryHighlighted: false,
  enableBigTip: false,
  enableCloselyWatched: false,
  enableReturnableCarrierInfo: false,
  enableMultibuyFaq: false,
  // names and classes
  modalBackdropLockCssClass: 'is-scroll-hidden',
  telemetry: {
    enabled: true,
    serviceName: 'web-fe',
    serverUrl: 'https://apm.kosik.group',
    logLevel: 'optimized',
    localhostName: null,
  },
  // ids of warehouses where pharmacy (dr.max) is available.
  // in Czech Republic, it is `[1]`
  pharmacyAvailableInWarehouses: [] as number[],
  categoryBannersLimit: 6,
  growthbook: {
    prodKey: 'sdk-PC8E1l6304oDWPs2',
    stageKey: 'sdk-NX7LYP4P6NBpMEuI',
    eocKey: 'sdk-t0AOPIDgkrfJKiE',
    localKey: 'sdk-QTxkjQvSjIoLjPW',
    apiHost: 'https://cdn.growthbook.io',
  },
  earlyDeliveryCheckbox: false,
  openAddressWhenAddThirdToCart: false,
  alternativeBenefits: false,
} as const

const appConfig = {
  ...appConfigDefault,
  ...domainConfig,
}

export default appConfig
