<script setup lang="ts">
import Icon from '@/components/Icon/Icon.vue'

type BenefitItemProps = {
  icon: string
  iconGroup?: string
  title?: string
}

const props = withDefaults(defineProps<BenefitItemProps>(), {
  iconGroup: 'clubs',
  title: '',
})
</script>

<template>
  <div class="benefit-item-wrap text-center flex-grow-1 px-3">
    <div class="benefit-item-icon-background mx-auto mb-2">
      <Icon
        class="benefit-item-icon flex-shrink-0"
        :icon="props.icon"
        :group="props.iconGroup" />
    </div>
    <p
      v-if="props.title"
      class="text-gray-darker font-weight-bold text-5 text-uppercase mb-2"
      v-text="props.title" />
    <p class="text-gray-dark text-4 m-0">
      <slot />
    </p>
  </div>
</template>

<style lang="scss" scoped>
.benefit-item {
  &-icon {
    $size: 36px;

    width: $size;
    height: $size;
  }
}
</style>
