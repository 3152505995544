import appConfig from '@/services/appConfig'

import { fetchGet } from '../fetch'
import type {
  BrandProductResponse,
  ProductByParamResponse,
  ReplacementsProductResponse,
  SimilarProductResponse,
  ProductSearchResponse,
  ProductVariantsResponse,
} from './productTypes'

function getProductById(id: number) {
  return fetchGet<ProductByParamResponse>(appConfig.frontApiBaseUrl + 'product/' + id)
}

function getProductBySlug(slug: string, isSale: boolean) {
  return fetchGet<ProductByParamResponse>(
    appConfig.frontApiBaseUrl + 'product/slug/' + slug,
    isSale ? { sale: 1 } : undefined,
  )
}

function getProductBrandProducts(id: number, limit: number) {
  return fetchGet<BrandProductResponse>(
    appConfig.frontApiBaseUrl + 'product/brand-products/' + id,
    limit ? { limit } : undefined,
  )
}

function getProductSimilarProducts(id: number, limit: number) {
  return fetchGet<SimilarProductResponse>(
    appConfig.frontApiBaseUrl + 'product/similars/' + id,
    limit ? { limit } : undefined,
  )
}

function getProductReplacements(id: number, limit: number) {
  return fetchGet<ReplacementsProductResponse>(
    appConfig.frontApiBaseUrl + 'product/replacements/' + id,
    limit ? { limit } : undefined,
  )
}

function getProductSearch(query: string, limit: number, excludeCategoryId: number[] = []) {
  return fetchGet<ProductSearchResponse>(appConfig.frontApiBaseUrl + 'product/search', {
    query,
    limit,
    excludeCategoryId,
  })
}

function getProductVariants(id: number, associationCode: string) {
  return fetchGet<ProductVariantsResponse>(
    appConfig.frontApiBaseUrl + `product/${id}/variants/${associationCode}`,
  )
}

export {
  getProductById as frontApiProductGetById,
  getProductBySlug as frontApiProductGetBySlug,
  getProductBrandProducts as frontApiProductGetBrandProducts,
  getProductSimilarProducts as frontApiProductGetSimilarProducts,
  getProductReplacements as frontApiProductGetReplacements,
  getProductSearch as frontApiProductGetSearch,
  getProductVariants as frontApiProductGetVariants,
}
