import type { Product } from '@/composables/useProductItem'

/**
 * Get total discount of products in cart
 * @param {array} [productsData]
 * @returns {number} Total cart discount.
 */
function getTotalProductsDiscount(productsData: Product[]) {
  return productsData.reduce(
    (totalDiscount, ref) =>
      totalDiscount + (ref.computed.recommendedPrice - ref.computed.price) * ref.computed.quantity,
    0,
  )
}

export { getTotalProductsDiscount }
