import dayjs from 'dayjs'

import type { OrderDetailEnriched } from '@pages/PageThankYou/PageThankYou.vue'

import { t } from '@/services/translations'
import roundToDecimals from '@/utils/roundToDecimals'

import { dataLayerPush, mapProductGtm } from './utils'

/**
 * Event sent after order is placed by customer.
 * @param {Object} order Object containing info about currently created order.
 * @returns {void}
 */
function purchase(order: OrderDetailEnriched) {
  const { orderCount, orderProducts, payment, transport, orderPrices, number, gifts, orderType } =
    order

  const mappedProducts = orderProducts.map((product) => {
    return mapProductGtm({
      id: product.product.id,
      name: product.product.name,
      price: product.product.price,
      quantity: product.deliveredQuantity,
    })
  })

  const mappedGifts = gifts.map((gift) => {
    return mapProductGtm(gift, [], {
      isGift: true,
    })
  })

  const items = [...mappedProducts, ...mappedGifts]

  dataLayerPush(
    {
      event: 'purchase',
      checkout: {
        step_number: 5,
        order_type: orderType,
        credits_amount: orderPrices.credit,
        courier_tip_amount: orderPrices.tip,
        voucher_amount: Math.abs(orderPrices.voucher),
        order_count_k3w: orderCount,
        weighted_deposit: orderPrices.weightedDeposit,
        returnable_deposit: orderPrices.returnablePackages,
        payment_id: payment.id,
        shipping: orderPrices.transport,
        shipping_slot_time: {
          start: dayjs(transport.start).toISOString(),
          end: dayjs(transport.end).toISOString(),
        },
      },
      ecommerce: {
        transaction_id: number,
        value: roundToDecimals(
          orderPrices.productsTotal + orderPrices.weightedDeposit + orderPrices.returnablePackages,
        ),
        paid_value: roundToDecimals(orderPrices.priceWhenFinishingOrder),
        currency: t('general.currencyCode'),
        items,
      },
    },
    false,
  )
}

export { purchase as gtmEventPurchase }
