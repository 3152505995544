import { createOperation } from '@/services/apiv2'

import type { ConfigurationWebResponse } from './configurationTypes'

/**
 * Gets configuration for web
 * @see https://sasandboxstdhotapidocs.z1.web.core.windows.net/#/Configuration/readConfiguration
 */
const web = createOperation<ConfigurationWebResponse>('/configuration/web', {
  method: 'get',
})

export default web
