import { useQueryClient } from '@tanstack/vue-query'
import dayjs from 'dayjs'

import { benjaminekQueryKeys } from '@/constants/queryKeys'
import api from '@/data/api'
import { type BenjaminekChild } from '@/data/api/frontApi/benjaminek/benjaminekTypes'

function useBenjaminekPostChildren() {
  const queryClient = useQueryClient()

  async function addChildren(children: BenjaminekChild[]) {
    const childrenWithBirthDatesFormatted = children.map((child) => ({
      name: child.name.trim(),
      birthDate: dayjs(child.birthDate, 'D.M.YYYY').format('YYYY-MM-DD'),
    }))

    const response = await api.front.benjaminek.addChildren({
      body: { children: childrenWithBirthDatesFormatted },
    })
    // Do not refetch if response is empty (child already exists)
    if (response.children.length) {
      queryClient.invalidateQueries({ queryKey: benjaminekQueryKeys.children() })
    }

    return response
  }

  return addChildren
}

export default useBenjaminekPostChildren
