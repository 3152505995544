/**
 * All posible environments. "tit" is about to be removed.
 */
const environments = ['production', 'stage', 'tit', 'eoc', 'localhost'] as const

/**
 * All posible environments. "tit" is about to be removed.
 */
type Environment = (typeof environments)[number]

export default environments

export type { Environment }
