<script setup>
import { computed } from 'vue'

const props = defineProps({
  min: {
    type: Number,
    required: true,
  },
  max: {
    type: Number,
    required: true,
  },
  step: {
    type: Number,
    default: 1,
  },
  modelValue: {
    type: Number,
    default: 1,
  },
  bgColor: {
    type: String,
    default: 'gray-light',
  },
})

const emit = defineEmits(['update:modelValue'])

const inputValue = computed({
  get: () => props.modelValue,
  set: (newValue) => emit('update:modelValue', newValue),
})
</script>

<template>
  <div class="input-range">
    <div class="background-wrap">
      <slot class="background">
        <div :class="['background', `bg-${props.bgColor}`]" />
      </slot>
    </div>
    <input
      v-model.number="inputValue"
      data-tid="input-range"
      type="range"
      :min="props.min"
      :max="props.max"
      :step="props.step" />
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

$input-width: 100%;
$track-width: $input-width;
$track-height: 8px;
$thumb-width: 32px;
$thumb-height: $thumb-width;
$thumb-img-name: 'slider-thumb';

@mixin slider() {
  appearance: none;
}

@mixin slider-thumb($width: $thumb-width, $height: $thumb-height) {
  @include slider;

  width: $width;
  height: $height;
  background: url('/img/icons/#{$thumb-img-name}.svg?v=1') no-repeat center/cover;
  border: 0;
  border-radius: 50%;
}

@mixin slider-track($width: $track-width, $height: $thumb-height) {
  @include slider;

  width: $width;
  height: $height;
  background: transparent;
}

.input-range {
  position: relative;
}

[type='range'] {
  @include slider;

  position: relative;
  display: block;
  width: $input-width;
  height: $thumb-height;
  cursor: pointer;
  background: transparent;
}

input::-moz-range-thumb {
  @include slider-thumb;

  border: 0;
}

input::-webkit-slider-thumb {
  @include slider-thumb;
}

input::-moz-range-track {
  @include slider-track;
}

input::-webkit-slider-runnable-track {
  @include slider-track;
}

input::-moz-focus-outer {
  border: 0;
}

.background-wrap {
  position: absolute;
  top: 50%;
  width: $track-width;
  height: $track-height;
  margin-top: $track-height * -0.5;
  border-radius: var(--borderRadiusMedium);
}

.background {
  height: 100%;
  border-radius: inherit;
}
</style>
