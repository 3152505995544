<script setup lang="ts">
import { computed } from 'vue'

import Icon from '@/components/Icon/Icon.vue'
import type { Product } from '@/composables/useProductItem'
import { t } from '@/services/translations'
import useProductDetailStore from '@/store/pinia/useProductDetailStore'
import localizePrice from '@/utils/localizePrice'

type DiscountInfoProps = {
  product: Product | null
  discountOnly?: boolean
  savingsCssClass?: string
  nextStepCssClass?: string
}

const props = withDefaults(defineProps<DiscountInfoProps>(), {
  product: null,
  discountOnly: false,
  savingsCssClass: 'text-contrast-green',
  nextStepCssClass: 'text-red',
})

const { getDiscount, getNextStepInfo } = useProductDetailStore()

const getCartProductDiscount = computed(() => (props.product ? getDiscount(props.product.id) : 0))
const getCumulativePriceStepsInfo = computed(() =>
  props.product ? getNextStepInfo(props.product.id) : null,
)
const isDiscountVisible = computed(() => !getCumulativePriceStepsInfo.value || props.discountOnly)
</script>

<template>
  <div
    :class="['discount-info', isDiscountVisible ? props.savingsCssClass : props.nextStepCssClass]">
    <template v-if="isDiscountVisible">
      <Icon
        class="icon-check"
        icon="checkbox-ok" />
      <span
        v-text="
          t('cumulativePrices.savingPrice', {
            price: localizePrice(getCartProductDiscount, { maximumFractionDigits: 2 }),
          })
        " />
    </template>
    <span
      v-else-if="getCumulativePriceStepsInfo"
      v-text="
        t('cumulativePrices.nextStep', {
          quantity: getCumulativePriceStepsInfo.remainingQuantity,
          price: localizePrice(getCumulativePriceStepsInfo.price, { maximumFractionDigits: 2 }),
        })
      " />
  </div>
</template>

<style lang="scss" scoped>
.discount-info {
  font-size: 11px;
  font-weight: var(--fontWeightBold);
}

.icon-check {
  width: 14px;
  height: 14px;
  margin: -2px -2px 0 0;
}
</style>
