import { computed } from 'vue'

import { useQuery } from '@tanstack/vue-query'

import { addressQueryKeys } from '@/constants/queryKeys'
import { frontApiGetAddress } from '@/services/api/front/address'

function useAddressGetAll() {
  const { data: addressData, suspense: addressSuspense } = useQuery({
    queryKey: addressQueryKeys.detail(),
    queryFn: frontApiGetAddress,
    select: (data) => data.data,
  })

  const deliveryAddresses = computed(() => addressData.value?.deliveryAddresses)
  const billingAddresses = computed(() => addressData.value?.billingAddresses)

  return {
    addressSuspense,
    deliveryAddresses,
    billingAddresses,
  }
}

export default useAddressGetAll
