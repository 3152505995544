<script setup lang="ts">
type ToastContentProps = {
  content: string
  flashMessageId?: string
}
const props = defineProps<ToastContentProps>()
</script>

<template>
  <div
    data-tid="ToastContent"
    :data-flash-message-id="props.flashMessageId"
    v-html="props.content" />
</template>
