import dayjs from 'dayjs'

import type { ApiTransportResponse, TransportResponse } from '@/services/api/front/transportTypes'
import { createOperation } from '@/services/apiv2'

import type { TransportWindowsQueryParams } from './deliveryTypes'

// @fixup https://mallfresh.atlassian.net/browse/KNW-18480 responseConverter convert all to dayjs
const transportWindows = createOperation<
  ApiTransportResponse,
  TransportResponse,
  undefined,
  undefined,
  TransportWindowsQueryParams
>('/transport/windows', {
  method: 'get',
  responseConverter: (data) => {
    return {
      ...data,
      earliestTimeslot: data.earliestTimeslot ? dayjs(data.earliestTimeslot) : null,
    }
  },
})

export default transportWindows
