import notificationsCount from './notificationsCount'
import notificationsDelete from './notificationsDelete'
import notificationsHistory from './notificationsHistory'
import notificationsState from './notificationsState'
import notificationsValid from './notificationsValid'

export default {
  notificationsCount,
  notificationsDelete,
  notificationsHistory,
  notificationsState,
  notificationsValid,
}
