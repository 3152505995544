import { apmBase } from '@elastic/apm-rum'

import logger from '@/services/logger'

/**
 * Reports an error to APM.
 * Inside Vue component scope use `useApmError()` composable instead.
 * @param error The error to report to APM.
 */
function logApmError(error: Error | string, scope = 'apmLogger') {
  const log = logger(scope)

  const errorObject = error instanceof Error ? error : new Error(error)

  if (apmBase.isActive()) apmBase.captureError(errorObject)
  log(errorObject)
}

export default logApmError
