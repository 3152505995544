import type { ComputedRef } from 'vue'

import { storeToRefs } from 'pinia'

import type { GPS } from '@/data/api/frontApi/address/addressTypes'
import useAddressStore from '@/store/pinia/useAddressStore'

import useValidateGps from './useValidateGps'

function useDeliveryOnLocationMap(address: ComputedRef<string>) {
  const { deliveryOnLocation } = storeToRefs(useAddressStore())

  const { gpsIsValid, saveIsDisabled, onMapMove, onMapMoveStop } = useValidateGps(
    address,
    deliveryOnLocation,
  )

  function onMapChanged(newLocation: GPS) {
    deliveryOnLocation.value = newLocation
    onMapMoveStop()
  }

  return {
    changedLocations: deliveryOnLocation,
    gpsIsValid,
    saveIsDisabled,
    onMapMove,
    onMapChanged,
  }
}

export default useDeliveryOnLocationMap
