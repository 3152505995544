<script setup lang="ts">
import Icon from '@/components/Icon/Icon.vue'

type BenjaminekSavingItemProps = {
  icon: 'calendar' | 'wallet'
  title: string
  savingsAmount: string
}
const props = defineProps<BenjaminekSavingItemProps>()
</script>

<template>
  <div class="border-box d-flex justify-content-center align-items-center p-4">
    <div class="icon-wrapper bg-pink-dark p-2 mr-3">
      <Icon
        :icon="icon"
        group="clubs"
        class="icon text-primary-lighter" />
    </div>

    <div class="savings-wrapper">
      <p class="text-5 m-0 p-0">{{ props.title }}</p>
      <p class="text-8 text-pink-dark font-weight-bold m-0 p-0">
        {{ props.savingsAmount }}
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.border-box {
  border: 1px solid color('pink-dark');
  border-radius: var(--borderRadiusMedium);
}

.icon {
  $size: 40px;

  width: $size;
  height: $size;

  @include media-breakpoint-up(sm) {
    width: $size * 1.6;
    height: $size * 1.6;
  }

  @include media-breakpoint-up(lg) {
    width: $size * 2;
    height: $size * 2;
  }
}

.icon-wrapper {
  border-radius: var(--borderRadiusMedium);
}

.savings-wrapper {
  flex-basis: 70%;

  @include media-breakpoint-up(sm) {
    flex-basis: 50%;
  }
}
</style>
