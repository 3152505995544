<script setup lang="ts">
import useConfig from '@/composables/useConfig'
import { t } from '@/services/translations'

type WarehouseTextProps = {
  isConfirmed: boolean
}

const props = defineProps<WarehouseTextProps>()

const configData = useConfig()
</script>
<template>
  <p
    v-if="props.isConfirmed"
    class="my-auto">
    {{ t('warehouseInfoBar.thanks') }}
  </p>
  <p
    v-else
    class="my-auto">
    {{ t('warehouseInfoBar.offerFor') }}
    <b class="d-block d-sm-inline-block">{{ configData.cart.priceZone.customerName }}</b>
    <span class="d-block d-md-inline-block mt-2 mt-md-0">{{
      t('warehouseInfoBar.disclaimer')
    }}</span>
  </p>
</template>
