<script setup lang="ts">
import { t } from '@/services/translations'

export type Certificate = {
  type: 'medical' | 'veterinary'
  url: string
}

type PharmacyCertificatesProps = {
  certificates: Certificate[]
}

const props = defineProps<PharmacyCertificatesProps>()
</script>

<template>
  <a
    v-for="(item, index) in props.certificates"
    :key="index"
    :href="item.url"
    :title="t(`vendors.certificates.certificateLinkTitles.${item.type}`)"
    target="_blank">
    <img
      :src="`/vue/img/pharmacy/certificates/${item.type}.svg?v=1`"
      :alt="t(`vendors.certificates.certificateAlts.${item.type}`)"
      :class="['certificate', index > 0 && 'ml-4']" />
  </a>
</template>

<style lang="scss">
@use '@/legacy' as *;

.certificate {
  width: 134px;

  @include media-breakpoint-up(sm) {
    width: 165px;
  }
}
</style>
