import { dataLayerPush } from './utils'

const paymentTypes = [
  {
    name: 'google_pay',
    type: '180',
  },
  {
    name: 'apple_pay',
    type: '179',
  },
] as const

type PaymentNames = (typeof paymentTypes)[number]['name']

function payment(paymentType: PaymentNames) {
  const currentPaymentType = paymentTypes.find((p) => p.name === paymentType)

  if (!currentPaymentType) return

  dataLayerPush({
    event: currentPaymentType.name,
    payment_type: currentPaymentType.type,
  })
}

export { payment as gtmEventPayment }
