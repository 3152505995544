import { useMutation, useQueryClient } from '@tanstack/vue-query'

import { addressQueryKeys } from '@/constants/queryKeys'
import { frontApiDeleteAddress } from '@/services/api/front/address'

function useAddressRemove() {
  const queryClient = useQueryClient()

  async function deleteAddress(id: number) {
    const response = await frontApiDeleteAddress(id)
    if (response.data.status === 'ok') {
      queryClient.invalidateQueries({ queryKey: addressQueryKeys.detail() })
    }
  }

  const { mutate } = useMutation({
    mutationFn: deleteAddress,
  })

  function removeAddress(id: number) {
    mutate(id)
  }

  return removeAddress
}

export default useAddressRemove
