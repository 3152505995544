import ky, { HTTPError } from 'ky'
import { isString, pickBy } from 'lodash-es'

import emitSilenceFetch, { apiV2List } from '../api/emitSilenceFetch'
import ApiError from './ApiError'
import type { PathParams, QueryParams, HttpMethod } from './apiTypes'
import parseEndpointPath from './parseEndpointPath'

async function fetchFromEndpoint<
  TData = any,
  TDataExposed = TData,
  TPath extends PathParams | undefined = undefined,
  TRequest = undefined,
  TQueryParams extends QueryParams | undefined = undefined,
  TRequestExposed = TRequest,
  TQueryParamsExposed = TQueryParams,
>(options: {
  baseUrl: string
  endpointPath: string
  method: HttpMethod
  queryParams?: TQueryParamsExposed
  requestData?: TRequestExposed
  pathParams?: TPath
  requestConverter?: (data: TRequestExposed) => TRequest
  responseConverter?: (data: TData) => TDataExposed
  queryParamsConverter?: (data: TQueryParamsExposed) => TQueryParams
  requestHeaders?: Record<string, string | null | undefined>
}): Promise<TDataExposed> {
  const {
    baseUrl,
    endpointPath,
    method,
    queryParams,
    requestData,
    pathParams,
    requestConverter,
    responseConverter,
    queryParamsConverter,
    requestHeaders,
  } = options
  const fetchIdentifier = `${baseUrl}${endpointPath}-${Date.now()}`
  apiV2List.add(fetchIdentifier)

  // Run user request through converter or just pass it through with type casting.
  const requestObject =
    requestConverter && requestData
      ? requestConverter(requestData)
      : (requestData as unknown as TRequest)

  // Run user queryParams through converter or just pass it through with type casting.
  const queryParamsObject =
    queryParamsConverter && queryParams
      ? queryParamsConverter(queryParams)
      : (queryParams as unknown as TQueryParams)

  const parsedEndpointPath = parseEndpointPath<TPath>(endpointPath, pathParams)

  try {
    const response = await ky(`${baseUrl}${parsedEndpointPath}`, {
      method,
      searchParams: queryParamsObject,
      json: requestObject,
      retry: 0,
      timeout: 30000,
      headers: pickBy(requestHeaders, isString),
    })
    //TODO better handling of 204
    if (response.status === 204) return {} as TDataExposed

    const responseJson = await response.json<TData>()
    // @todo check response against zod schema
    const responseConverted = responseConverter
      ? responseConverter(responseJson)
      : (responseJson as unknown as TDataExposed)

    return responseConverted
  } catch (error) {
    if (error instanceof HTTPError) {
      const errorJson = await error.response.json()
      const apiError = new ApiError(error, errorJson)

      throw apiError
    } else {
      throw error
    }
  } finally {
    apiV2List.delete(fetchIdentifier)
    emitSilenceFetch()
  }
}

export default fetchFromEndpoint
