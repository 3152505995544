import type { HTTPError } from 'ky'

/**
 * Error thrown in Kosik API framework.
 */
class ApiError extends Error {
  private readonly _responseBody: Record<string, any>
  private readonly _httpError: HTTPError

  constructor(error: HTTPError, _responseBody: Record<string, any>) {
    super(error.message)
    this._responseBody = _responseBody
    this._httpError = error
  }

  /**
   * Data returned from API during unsuccessful request, e.g. 401, 403, 404, ...
   * Usually contains additinal error data describing the problem.
   */
  public get responseBody() {
    return this._responseBody
  }

  /**
   * Original HTTP error object.
   */
  public get httpError() {
    return this._httpError
  }
}

export default ApiError
