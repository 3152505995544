<script setup>
import ClubSectionTitle from '@/components/Clubs/ClubSectionTitle/ClubSectionTitle.vue'
import { useClubSectionTitleProps } from '@/components/Clubs/ClubSectionTitle/useClubSectionTitle'

const props = defineProps(useClubSectionTitleProps)
</script>

<template>
  <div class="grid-wrap py-4 mb-3">
    <ClubSectionTitle
      :title="props.title"
      :title-color="props.titleColor" />
    <div class="list d-flex flex-wrap flex-md-nowrap px-4">
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.grid-wrap {
  background: rgba(color('pink-light'), 0.4);
  border-radius: 40px;
}

.list {
  gap: 20px;
}

:deep(.benefit-item-wrap) {
  padding: 20px;
  background-color: color('white');
  border-radius: 20px;
}

:deep(.benefit-item-icon) {
  $size: 50px;

  width: $size;
  height: $size;
  color: color('primary');
}
</style>
