import type { ApiEmptyResponse } from '@/services/api/types'
import appConfig from '@/services/appConfig'

import { fetchGet, fetchPost, fetchPut } from '../fetch'
import type {
  CreateRatingOrderRequest,
  LastOrderRatingResponse,
  RatingOrderResponse,
} from './ratingTypes'

const modulePath = 'rating'
const orderPath = `${modulePath}/order/`

/**
 * Sends POST request for sending order rating.
 * @see https://sasandboxstdhotapidocs.z1.web.core.windows.net/#/Rating/createRatingOrder
 * @param token Rating token.
 * @param data Object with order rating data.
 * @returns Promise of the API response with empty payload (only HTTP status codes differentiate success from error).
 */
function postOrder(token: string, data: any) {
  return fetchPost<CreateRatingOrderRequest>(
    appConfig.frontApiBaseUrl + orderPath + token,
    undefined,
    data,
  )
}

/**
 * Sends GET request for getting order rating data.
 * @see https://sasandboxstdhotapidocs.z1.web.core.windows.net/#/Rating/readRatingOrder
 * @param token Rating token.
 * @returns Promise of the API response with order rating data.
 */
function getOrder(token: string) {
  return fetchGet<RatingOrderResponse>(appConfig.frontApiBaseUrl + orderPath + token)
}

/**
 * Sends GET request for getting the last order rating data.
 * @see https://sasandboxstdhotapidocs.z1.web.core.windows.net/#/Rating/readLastOrder
 * @returns Promise of the API response with the last order rating data, or with empty payload if there is no last order.
 */
function getLastOrder() {
  return fetchGet<LastOrderRatingResponse | ApiEmptyResponse>(
    appConfig.frontApiBaseUrl + modulePath + '/last-order',
  )
}

/**
 * Sends PUT request for dismissing last order rating.
 * @see https://sasandboxstdhotapidocs.z1.web.core.windows.net/#/Rating/setLastOrderDismiss
 * @returns Promise of the API response with empty payload.
 */
function putDismissLastOrder() {
  return fetchPut<ApiEmptyResponse>(appConfig.frontApiBaseUrl + modulePath + '/last-order/dismiss')
}

export {
  postOrder as frontApiRatingPostOrder,
  getOrder as frontApiRatingGetOrder,
  getLastOrder as frontApiRatingGetLastOrder,
  putDismissLastOrder as frontApiRatingPutDismissLastOrder,
}
