import useConfig from './useConfig'

function useUser() {
  const config = useConfig()

  const isUserSignedIn = !!config.value.user.email
  const getUserId = config.value.user.luigisboxUserId ?? ''
  const getUserName = config.value.user.name || null
  const getUserEmail = config.value.user.email || null
  const getUserCredits = config.value.user.credits ?? 0
  const getUserCreditsEnable = config.value.general.creditsEnabled
  const getUserClubEligibility = config.value.user.clubEligibility

  return {
    isUserSignedIn,
    getUserId,
    getUserName,
    getUserEmail,
    getUserCredits,
    getUserCreditsEnable,
    getUserClubEligibility,
  }
}

export default useUser
