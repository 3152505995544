<script setup>
const props = defineProps({
  /**
   * Used by v-model. Do not use directly.
   */
  modelValue: {
    type: [Number, String],
    required: true,
  },
  /**
   * Showed on top of options list and chosen if no option is selected.
   */
  placeholder: {
    type: String,
    default: null,
  },
  /**
   * Array of all selectable options.
   * @type {Object[]} options
   * @param {(number|string)} options[].id - identification
   * @param {string} options[].text - visible text
   * @param {(number|string)} options[].value - option value
   * @param {boolean} options[].selected - defines if option is selected
   */
  options: {
    type: Array,
    required: true,
  },
})

const emit = defineEmits(['update:modelValue'])

const getSelectedOptionValue = (select) => {
  for (const [index, option] of Object.entries(select)) {
    if (!isNaN(index) && option.selected) {
      return option._value
    }
  }
}

const handleEvent = (event) => {
  emit('update:modelValue', getSelectedOptionValue(event.target))
}
</script>

<template>
  <div class="form-select">
    <select
      class="form-select__input"
      @input="handleEvent">
      <option
        v-if="props.placeholder"
        disabled
        :selected="(!props.modelValue && props.modelValue !== 0) || null"
        value=""
        v-text="props.placeholder" />
      <option
        v-for="option in props.options"
        v-bind="option"
        :key="option.id || option.value"
        :selected="option.selected || option.value === props.modelValue || null"
        v-text="option.text || option.value" />
    </select>
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

$em-font-base: 12px;

.form-select {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid color('gray-light');
  border-radius: em($borderRadiusSmall, $em-font-base);

  &::after {
    @include triangle(bottom, color('gray-dark'), em(4, $em-font-base), em(7, $em-font-base));

    position: absolute;
    top: 50%;
    right: em(4, $em-font-base);
    pointer-events: none;
    content: '';
    transform: translateY(-50%);
  }

  &__input {
    width: 100%;
    padding: em(6, $em-font-base) em(12, $em-font-base);
    font-size: inherit !important;
    appearance: none;
    cursor: inherit;
    background: color('white');
    border: 0 none;
    border-radius: inherit;
  }
}
</style>
