import { createRouter, createWebHistory } from 'vue-router'

import afterEachHook from '@/router/afterEachHook'
import convertObjToUrlParams from '@/utils/convertObjToUrlParams'
import convertUrlParamsToObj from '@/utils/convertUrlParamsToObj'

import beforeEachHook from './beforeEachHook'
import staticRoutes from './staticRoutes'

const router = createRouter({
  history: createWebHistory(),
  routes: staticRoutes,
  parseQuery(query) {
    return convertUrlParamsToObj(`?${query}`)
  },
  stringifyQuery(obj) {
    return convertObjToUrlParams(obj)
  },
  scrollBehavior(to, from) {
    if (to.path !== from.path || to.query.search !== from.query.search) {
      return { top: 0 }
    }
  },
})

/**
 * beforeEach, afterEach guards
 * @see https://router.vuejs.org/guide/advanced/navigation-guards.html#global-before-guards
 * @see https://router.vuejs.org/guide/advanced/navigation-guards.html#Global-After-Hooks
 */
router.beforeEach(beforeEachHook)
router.afterEach(afterEachHook)

export default router
