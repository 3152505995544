<script setup lang="ts">
import { computed, ref } from 'vue'

import ProgressBar from '@/components/Clubs/Benjaminek/BenjaminekProgressBar.vue'
import ClubSectionTitle from '@/components/Clubs/ClubSectionTitle/ClubSectionTitle.vue'
import Icon from '@/components/Icon/Icon.vue'
import { useClientGetSavings } from '@/composables/client'
import { frontApiGetProfileClubInfo } from '@/services/api/front/profile'
import { t } from '@/services/translations'
import localizePrice from '@/utils/localizePrice'

import BenjaminekSavingItem from './BenjaminekSavingItem.vue'

type BenjaminekMonthlySpentCardProps = {
  childrenSectionUrl: string
}
const props = defineProps<BenjaminekMonthlySpentCardProps>()

const amountSpentPerMonth = ref(0)
const requiredAmount = ref(0)
const isDataLoaded = ref(false)

async function getClubInfo() {
  const {
    data: {
      segments: { benjaminek },
    },
  } = await frontApiGetProfileClubInfo()
  amountSpentPerMonth.value = Math.floor(benjaminek.rules?.[0].current ?? 0)
  requiredAmount.value = benjaminek.rules?.[0]?.goal ?? 0
  isDataLoaded.value = true
}
getClubInfo()

const { data: savingsData } = useClientGetSavings({ type: 'benjaminek', content: false })

const amountSpentPerMonthToRender = computed(() => {
  return Math.min(amountSpentPerMonth.value, requiredAmount.value)
})

const requirementFulfilled = computed(() => {
  return isDataLoaded.value && amountSpentPerMonth.value >= requiredAmount.value
})
</script>

<template>
  <div class="wrapper p-3 text-center">
    <div class="row row-cols-1 row-cols-lg-2 m-0">
      <!-- Left side (on desktop) -->
      <div class="d-flex flex-column justify-content-between p-4">
        <ClubSectionTitle
          class="mb-4"
          :title="t('kidsClub.savings.title')" />
        <div class="d-flex flex-column h-100 justify-content-between">
          <BenjaminekSavingItem
            icon="calendar"
            :title="t('kidsClub.savings.previousMonth')"
            :savings-amount="
              localizePrice(savingsData?.savings[0].lastMonthSaving ?? 0, {
                maximumFractionDigits: 0,
              })
            "
            class="mb-4" />
          <BenjaminekSavingItem
            icon="wallet"
            :title="t('kidsClub.savings.total')"
            :savings-amount="
              localizePrice(savingsData?.savings[0].overallSaving ?? 0, {
                maximumFractionDigits: 0,
              })
            " />
        </div>
      </div>

      <!-- Right side (on desktop) -->
      <div class="d-flex flex-column justify-content-between p-4">
        <ClubSectionTitle
          class="mb-4"
          :title="t('kidsClub.fulfillingCondition.title')" />
        <div class="border-box p-4">
          <p class="text-gray-darker text-5 text-center mb-2">
            {{
              t('kidsClub.fulfillingCondition.description.beginning', { amount: requiredAmount })
            }}
            <br />
            {{ t('kidsClub.fulfillingCondition.description.end') }}
          </p>
          <div class="position-relative d-flex justify-content-center">
            <ProgressBar
              :amount-spent-per-month="amountSpentPerMonthToRender"
              :required-amount="requiredAmount" />
            <div
              v-if="requirementFulfilled"
              class="progress-bar-tag d-flex align-items-center justify-content-center text-green font-weight-bold">
              <p
                class="text-5 font-weight-bolder text-uppercase m-0"
                v-text="t('kidsClub.fulfillingCondition.fulfilled')" />
              <Icon
                icon="emoji"
                group="clubs"
                class="icon flex-shrink-0 ml-2" />
            </div>
          </div>
          <RouterLink
            :to="props.childrenSectionUrl"
            class="d-inline-block text-3 text-black font-weight-bold text-uppercase bg-secondary rounded px-3 py-2 mt-4"
            v-text="t('kidsClub.fulfillingCondition.buttonToChildrenCategory')" />
        </div>
      </div>
    </div>

    <small class="d-block text-1 font-italic mx-5">
      {{ `*${t('kidsClub.fulfillingCondition.footer', { amount: requiredAmount })}` }}
    </small>
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.wrapper {
  background-color: color('primary-lighter', 0.25);
  border-radius: var(--borderRadiusMedium);
}

.border-box {
  border: 1px solid color('pink-dark');
  border-radius: var(--borderRadiusMedium);
}

.icon {
  $size: 30px;

  width: $size;
  height: $size;
}

.progress-bar-tag {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 193px;
  height: 46px;
  background-color: color('white');
  border: var(--borderRadiusSmall) solid color('green');
  border-radius: var(--borderRadiusMedium);
  transform: translate(-50%, -15%) rotate(-10deg);
}
</style>
