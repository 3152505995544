import { computed, ref, type Ref } from 'vue'

import { defineStore } from 'pinia'

import { invalidatePreviewTimeslots } from '@/components/AddressSelector/usePreviewTimeslots'
import useConfig from '@/composables/useConfig'
import { DEFAULT_SHIPPER } from '@/constants/defaultShipper'
import modalIndex from '@/constants/modalIndex'
import { deliveryQueryKeys, transportQueryKeys } from '@/constants/queryKeys'
import type { GPS, PositionGPS } from '@/data/api/frontApi/address/addressTypes'
import { analyticsLoggerAddressSave } from '@/services/analytics/analyticsLogger'
import { frontApiPutCartAddress } from '@/services/api/front/cart'
import type { CartReloadPageInfo } from '@/services/api/front/cartTypes'
import queryClient from '@/services/queryClient'

import useCartStore from './useCartStore'
import useModalStore from './useModalStore'

type UserAddress = {
  street: string
  city: string
  zip: string
  gps: GPS | null
  address: string
  id?: number
  note: string | null
  wholeAddress?: boolean
  positionGps?: PositionGPS | null
}

const useAddressStore = defineStore('useAddressStore', () => {
  const userAddress: Ref<UserAddress> = ref({
    street: '',
    city: '',
    zip: '',
    gps: null,
    address: '',
    note: '',
  })

  const config = useConfig()
  const cartStore = useCartStore()

  const reloadPage = ref(false)
  const shoppingCartChange = ref(false)
  const productsAvailabilityChange = ref(false)
  const destinationId = ref(config.value.cart.destinationId)
  const subDestinationId = ref(config.value.cart.subDestinationId)
  const shipper = ref('')
  const userAddressStale = ref(false)
  const deliveryOnLocation = ref<GPS | null>(null)
  const isUserCityFilled = computed(() => !!userAddress.value.city)
  const isUserStreetFilled = computed(() => !!userAddress.value.street)
  const isUserStreetOrCityFilled = computed(
    () => isUserCityFilled.value || isUserStreetFilled.value,
  )

  const hasChangedDestination = computed(
    () => shoppingCartChange.value || productsAvailabilityChange.value,
  )
  const isDefaultShipper = computed(() => shipper.value === DEFAULT_SHIPPER)

  function initUserAddress(address: UserAddress) {
    // @todo maybe redundant Object assign
    Object.assign(userAddress.value, address)
  }

  async function changeUserAddress(payload: {
    address: UserAddress
    autoClose?: boolean
    forceRedirect?: boolean
    reloadPageInfo?: CartReloadPageInfo | null
  }) {
    const { address, autoClose = true, forceRedirect = false, reloadPageInfo = null } = payload

    // It is undesirable to change the address if it is the same as the current one and gps is also empty.
    // Otherwise the PUT /cart/address endpoint creates a wrong entry in DB in such case.
    if (userAddress.value.address === address.address && !address.positionGps) return

    analyticsLoggerAddressSave(userAddress.value.address ? 'address_changed' : 'new_address')

    const isCartProductOnHold = !!cartStore.onHoldProduct

    userAddressStale.value = true
    Object.assign(userAddress.value, address)

    if (isCartProductOnHold) {
      cartStore.continueOnHoldProduct()
    }

    const { data } = await frontApiPutCartAddress(
      address.address,
      address.positionGps,
      reloadPageInfo,
    )

    invalidatePreviewTimeslots()

    reloadPage.value = data.reloadPage

    shoppingCartChange.value = data.shoppingCartChange
    productsAvailabilityChange.value = forceRedirect || data.productsAvailabilityChange
    userAddressStale.value = false

    // refresh DeliveryOptions (for different address different transport prices)
    queryClient.invalidateQueries({ queryKey: deliveryQueryKeys.all })
    queryClient.invalidateQueries({ queryKey: transportQueryKeys.deliveryData() })

    if (autoClose) {
      const { closeModal } = useModalStore() // TODO refactor
      closeModal(modalIndex.addressSelector)
    }
  }

  function setShipper(newShipper: string) {
    shipper.value = newShipper
  }

  function setDeliveryOnLocation(location: GPS | null) {
    deliveryOnLocation.value = location
  }

  return {
    userAddress,
    isUserStreetFilled,
    isUserStreetOrCityFilled,
    hasChangedDestination,
    destinationId,
    subDestinationId,
    isDefaultShipper,
    userAddressStale,
    deliveryOnLocation,
    shoppingCartChange,
    productsAvailabilityChange,
    reloadPage,
    initUserAddress,
    changeUserAddress,
    setShipper,
    setDeliveryOnLocation,
  }
})

export default useAddressStore
export type { UserAddress }
