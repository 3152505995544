<script setup lang="ts">
import { computed } from 'vue'

import { useClipboard } from '@vueuse/core'

import { FormText } from '@/components/Form'
import { BaseButton } from '@/componentsPure'
import { t } from '@/services/translations'

import type { GiftVoucher } from './giftVouchersTypes'

type VoucherInputProps = {
  modelValue: GiftVoucher['voucher']['code']
}
const props = defineProps<VoucherInputProps>()

const voucherCode = computed(() => props.modelValue)

const { copy, copied } = useClipboard({
  source: props.modelValue,
  copiedDuring: 4000,
  legacy: true,
})
</script>

<template>
  <div class="d-flex align-items-baseline">
    <FormText
      v-model="voucherCode"
      disabled
      class="flex-grow-1 mr-1" />
    <BaseButton
      variant="brand"
      size="md"
      class="button flex-shrink-0"
      @click="copy(modelValue)">
      {{ t(`profile.giftsClub.${copied ? 'copiedButton' : 'copyButton'}`) }}
    </BaseButton>
  </div>
</template>

<style scoped lang="scss">
.button {
  width: 117px;
}
</style>
