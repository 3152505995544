import dayjs from 'dayjs'

import { createOperation } from '@/services/apiv2'

import type { ApiDeliveryData, DeliveryData } from './deliveryTypes'

const deliveryData = createOperation<
  ApiDeliveryData,
  DeliveryData,
  undefined,
  undefined,
  undefined
>('/transport/delivery-data', {
  method: 'get',
  responseConverter: (data) => ({
    ...data,
    earliestDeliveryTime: data.earliestDeliveryTime ? dayjs(data.earliestDeliveryTime) : null,
  }),
})

export default deliveryData
