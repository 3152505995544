import { useMutation, useQueryClient } from '@tanstack/vue-query'

import { benjaminekQueryKeys } from '@/constants/queryKeys'
import api from '@/data/api'
import type {
  BenjaminekChildrenResponse,
  BenjaminekChildResponse,
} from '@/data/api/frontApi/benjaminek/benjaminekTypes'

function useBenjaminekDeleteChild() {
  const queryClient = useQueryClient()

  const { mutate } = useMutation({
    mutationFn: (childId: BenjaminekChildResponse['id']) =>
      api.front.benjaminek.deleteChild({ path: { childId } }),

    onMutate: (childId) => {
      queryClient.setQueryData<BenjaminekChildrenResponse>(
        benjaminekQueryKeys.children(),
        (oldData) => {
          if (!oldData) return

          return {
            ...oldData,
            data: {
              ...oldData,
              children: oldData.children.filter((child) => child.id !== childId),
            },
          }
        },
      )
    },

    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: benjaminekQueryKeys.children() })
    },
  })

  function deleteChild(childId: BenjaminekChildResponse['id']) {
    return mutate(childId)
  }

  return deleteChild
}

export default useBenjaminekDeleteChild
