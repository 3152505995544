import type { PublicClientApplication } from '@azure/msal-browser'

import logApmError from '../logApmError'
import AuthError from './AuthError'
import msalConfig from './msalConfig'

let msalInstance: PublicClientApplication | null = null

class MsalImportError extends Error {
  constructor() {
    super()
    this.name = 'MsalImportError'
  }
}

/**
 * Asynchronously imports MSAL package and gets MSAL instance.
 * @returns {Promise<PublicClientApplication>} Pure MSAL instance (without redirectPromise handling).
 * See https://learn.microsoft.com/en-us/azure/active-directory/develop/msal-js-initializing-client-applications#initialize-msaljs-2x-apps
 * @throws {MsalImportError} If MSAL package could not be loaded.
 */
async function getMsalInstance() {
  if (msalInstance !== null) {
    return msalInstance
  }

  try {
    // This package is humongously large and with no tree-shaking,
    // so we import it only when needed.
    const { PublicClientApplication } = await import('@azure/msal-browser')
    msalInstance = new PublicClientApplication(msalConfig)
    await msalInstance.initialize() // @todo Not sure if necessary
    return msalInstance
  } catch (error) {
    logApmError(new AuthError('get_msal_instance_error', error))

    throw new MsalImportError()
  }
}

export default getMsalInstance

export { MsalImportError }
