import { unref } from 'vue'

import type { InteractionSourceRaw } from '@/composables/useInteractionSource'
import type { Product } from '@/composables/useProductItem'
import type { FlexibleProductGroup } from '@/services/api/front/pageTypes'

/**
 * Picks value by key from interaction source
 * @param key
 * @param source
 * @param defaultValue
 * @todo missing unit test
 */
function pick<T>(key: string, source: InteractionSourceRaw[], defaultValue: T): T {
  if (!source?.length) {
    return defaultValue
  }
  for (let i = source.length - 1; i >= 0; i--) {
    if (source[i][key] !== undefined) {
      return unref(source[i][key]) as T
    }
  }
  return defaultValue
}

/**
 * Picks product items from interaction source and transforms them to GTM format
 * @param source
 */
function pickProductItems(source: InteractionSourceRaw[]) {
  const products = pick<Product[]>('products', source, [])
  return products.map((product, index) => {
    return {
      item_id: product.id,
      item_name: product.origin.name,
      index: index + 1, //starts from 1 and not from 0
      price: product.origin.price,
      quantity: product.computed.quantity,
    }
  })
}

/**
 * Picks item list name from interaction source
 * It would be either widget name, productGroup name or 'default'
 * @param source
 */
function pickItemListName(source: InteractionSourceRaw[]) {
  const widgetName = pick<string>('code', source, '')
  const productGroup = pick<FlexibleProductGroup | null>('productGroup', source, null)
  return widgetName || productGroup?.name || 'default'
}

export { pick, pickProductItems, pickItemListName }
