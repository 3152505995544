import type { Dayjs } from 'dayjs'

import { t } from '@/services/translations'

/**
 * Transforms passed in date to a relevant weekday | time reference eg. today|tomorrow|Friday...
 * @param date Date to run a comparison against
 * @returns Weekday | time reference relevant to the passed in date
 */
function formatWeekday(date: Dayjs): string | null {
  if (!date?.isValid?.()) {
    return null
  }

  if (date.tz().isToday()) {
    return t('general.today')
  }
  if (date.tz().isTomorrow()) {
    return t('general.tomorrow')
  }

  return date.tz().format('dddd')
}

export default formatWeekday
