import { dataLayerPush } from './utils'

/**
 * both - if only login button is present
 * login - if user clicks on login and both buttons are present
 * registration - if user clicks on register
 */
type GTMLoginRegistrationClickIntention = 'login' | 'registration' | 'both'

type GTMLoginClickIntention = Exclude<GTMLoginRegistrationClickIntention, 'registration'>

function loginOrRegistrationClick(type: GTMLoginRegistrationClickIntention, socialLogin?: string) {
  dataLayerPush(
    socialLogin
      ? { event: 'login_start', method: socialLogin.split('.')[0] + '_oauth' } // e.g. google.com -> google_oauth
      : { event: 'login_registration_click', type },
  )
}

export { loginOrRegistrationClick as gtmEventLoginOrRegistrationClick }
export type { GTMLoginRegistrationClickIntention, GTMLoginClickIntention }
