import { dataLayerPush } from './utils'

function ckConsentToPreferences() {
  dataLayerPush({
    event: 'consent_interaction',
    modal_section: 'detailed_section',
  })
}

export { ckConsentToPreferences as gtmEventCkConsentToPreferences }
