import { createOperation } from '@/services/apiv2'

import type { FrontApiEmptyResponse } from '../frontApiTypes'
import type { ValidateGpsQueryParams } from './addressTypes'

const validateGps = createOperation<
  FrontApiEmptyResponse,
  boolean,
  undefined,
  undefined,
  ValidateGpsQueryParams
>('/address/validate/gps', {
  method: 'get',
  responseConverter: () => true,
})

export default validateGps
