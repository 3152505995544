function searchTermCleaner(term: string) {
  return (
    term
      // Replaces all occurrences of the character 'ˇ' with a space
      .replace(/ˇ/g, ' ')

      // Removes any characters at the beginning of the string that are not letters, numbers, or whitespace
      .replace(/^[^\p{L}\p{N}\p{Zs}]*/u, '')

      // Replaces any characters that are not letters, numbers, whitespace, apostrophe, ampersand, backtick, percent sign, hyphen, or em dash with a space
      .replace(/[^\p{L}\p{N}\p{Zs}'&`.%,\-—]/gu, ' ')

      // Replaces multiple consecutive whitespace characters with a single space
      .replace(/\s+/gu, ' ')

      // Removes any leading or trailing whitespace from the string
      .trim()
  )
}

export default searchTermCleaner
