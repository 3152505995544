import type { RouteRecordName } from 'vue-router'

import type { ResizeClubBannersType } from '@/components/BannerClub/resizeClubBanners'
import type {
  NotificationActionName,
  Notification,
  NotificationImpressionSource,
} from '@/components/Notification/notificationTypes'
import type { RatingValue } from '@/components/OrderRating/RatingCheckbox.vue'
import type { BannerThankYouPage } from '@/composables/banner/useBannerThankYouPage'
import type { InteractionSourceRaw } from '@/composables/useInteractionSource'
import type { Product } from '@/composables/useProductItem'
import type { VendorType } from '@/constants/vendors'
import type { Banner } from '@/data/api/frontApi/banners/bannerTypes'
import {
  gtmEventDeleteCart,
  gtmEventPageScroll,
  gtmEventViewItem,
  gtmEventViewItemList,
  gtmEventToggleModal,
  gtmEventAddressChosen,
  gtmEventAddressSelector,
  gtmEventNewsletterConsent,
  gtmEventPageView,
  gtmEventCkConsentImpression,
  gtmEventCkConsentToPreferences,
  gtmEventCkConsentUpdate,
  gtmEventViewCart,
  gtmEventAddContactInfo,
  gtmEventAddShippingInfo,
  gtmEventNotificationImpression,
  gtmEventNotificationBell,
  gtmEventNotificationClick,
  gtmEventPurchase,
  gtmEventReviewSent,
  gtmEventOutOfStock,
  gtmEventCheckoutItemConflict,
  gtmEventPayment,
  gtmEventLogin,
  gtmEventRedirectUrl,
  gtmEventViewSearchResults,
  gtmEventLoginOrRegistrationClick,
  gtmEventBannerClickOrImpression,
  gtmEventNewsletterFormImpression,
  gtmEventDeliveryWindow,
  gtmEventAddressPin,
  gtmEventEarlyDelivery,
  gtmEventAddressSave,
  gtmEventExperimentViewed,
  gtmEventAddToCart,
  gtmEventComponentInteraction,
  gtmEventSelectSlot,
} from '@/services/analytics/gtm'
import type { GTMEventPageData, GTMLoginRegistrationClickIntention } from '@/services/analytics/gtm'
import {
  luigisboxClickEvent,
  luigisboxPageview,
  luigisboxDebouncedSearchSuggester,
  luigisboxSearchPage,
  luigisboxRecommendationsAlternatives,
  luigisboxWidgetContent,
  luigisboxPurchase,
  type ContentQueryFiltersOptions,
  luigisboxGetDebouncedSearchSuggester,
} from '@/services/analytics/luigisbox'
import {
  yottlySetCartInteraction,
  yottlySetOrder,
  yottlySetProductInteraction,
  yottlySetProductId,
  isProductFromYottly,
} from '@/services/analytics/yottly'
import type { Widget } from '@/store/pinia/useProductsStore'
import type { SuggestResponse } from '@/store/pinia/useUserInterfaceStore'

import type { EmailProviderClaims, SocialProviderClaims } from '../auth/aadTypes'
import type { CookiesConsentMap } from '../privacy'
import type { AddressPinGtmData } from './gtm/addressPin'
import type { AddressSaveType } from './gtm/addressSave'
import type { AddressSelectorMessage, AddressSelectorType } from './gtm/addressSelector'
import type { AdminBannerType, PromotionName } from './gtm/bannerClickOrImpression'
import type { Initiator, ModalSection } from './gtm/ckConsentImpression'
import type { ConsentType } from './gtm/ckConsentUpdate'
import type { DeliveryWindowData } from './gtm/deliveryWindow'
import type { GtmEarlyDeliveryActivity } from './gtm/earlyDelivery'
import type { ModalInitiator } from './gtm/modal'
import transformProductToGtm, { type ProductForAnalytics } from './gtm/utils/transformProductToGtm'

/**
 * Delegates calling to specific analytic services.
 * Used for checkout tracking.
 * @param {string} checkoutStep Type of current checkout step.
 * @returns {void}
 */
function logCheckoutStep(checkoutStep) {
  if (checkoutStep === 'transportPayment') gtmEventAddContactInfo()

  if (['summary', 'summaryAdditional'].includes(checkoutStep)) gtmEventAddShippingInfo(checkoutStep)
}

/**
 * Delegates calling to specific analytic services.
 * Needs to be called on every pageview, virtual or not.
 * @param {Object} pageData Target route location in object format.
 * @param {boolean} isClientSideRouted False if navigation triggered reload.
 * @param {boolean} sendToLugisBox should we send also to luigisbox
 * @returns {void}
 */
function pageView(
  pageData: GTMEventPageData,
  isClientSideRouted: boolean,
  sendToLugisBox: boolean,
) {
  gtmEventPageView(pageData, isClientSideRouted)

  if (sendToLugisBox) {
    luigisboxPageview()
  }
}

/**
 * Delegates calling to specific analytic services.
 * Needs to be executed every time we choose item which will replace product from alternatives
 * @param {number} productId
 * @return {void}
 */
function alternativeReplaceableProductChosen(productId: number): void {
  luigisboxClickEvent(productId, 'buy')
}

/**
 * Delegates calling to specific analytic services.
 * Needs to be executed every time when we manipulate with product/products in cart.
 * @param increasing Direction of the quantity manipulation
 * @param productsForAnalytics Products which are added to analytics.
 * @param interactionSource Either array of interaction source or function which returns array of interaction source.
 * If you need to send a bulk of products to GTM via one common element (for example button 'add all'),
 * you have to specify interaction source of that trigger in this param.
 */
function setCartInteraction(
  increasing: boolean,
  productsForAnalytics: ProductForAnalytics[],
  interactionSource: InteractionSourceRaw[] | ((id: number) => InteractionSourceRaw[] | undefined),
) {
  yottlySetCartInteraction()

  let interactionSourceComputed: InteractionSourceRaw[] | undefined
  productsForAnalytics.forEach((cartInteraction) => {
    const { productId } = cartInteraction

    if (increasing) {
      luigisboxClickEvent(productId, 'buy')
    }

    if (Array.isArray(interactionSource)) {
      interactionSourceComputed = [...interactionSource]
    } else {
      interactionSourceComputed = interactionSource(productId)
    }

    if (isProductFromYottly(interactionSourceComputed)) {
      yottlySetProductInteraction(productId)
    }
  })

  const addToCartProps = transformProductToGtm(
    increasing ? 'add_to_cart' : 'remove_from_cart',
    productsForAnalytics,
    interactionSourceComputed || [],
  )

  gtmEventAddToCart(addToCartProps)
}

/**
 * Delegates calling to specific analytic services.
 * Needs to be executed every time the cart is emptied.
 * @returns {void}
 */
function deleteCart(
  interactionSource: InteractionSourceRaw[],
  products: readonly Product[],
  totalPrice: number,
) {
  yottlySetCartInteraction([])
  gtmEventDeleteCart(interactionSource, products, totalPrice)
}

/**
 * Delegates calling to specific analytic services.
 * Needs to be executed every time the cart is loaded when its page loaded from API.
 */
async function setCart(currentRouteName: RouteRecordName | null | undefined) {
  if (currentRouteName === 'Summary') {
    yottlySetCartInteraction(undefined, true)
  } else if (currentRouteName === 'PageThankYou') {
    yottlySetCartInteraction([])
  }
}

/**
 * Delegates calling to specific analytic services.
 * Needs to be executed every time the detail of product is opened.
 * @param {number} productId ID of the product being opened.
 * @param {string | undefined} productUrl optional url of the product to overwrite url from store.
 * @param {Array<Object>} interactionSource Interaction source tree.
 * @returns {void}
 */
function productDetailOpen(
  productId,
  productUrl?: string,
  interactionSource: InteractionSourceRaw[] = [],
) {
  yottlySetProductId(productId)
  luigisboxClickEvent(productId, 'click', productUrl)
  luigisboxPageview()

  if (isProductFromYottly(interactionSource)) {
    yottlySetProductInteraction(productId)
  }
}

/**
 * Delegates calling to specific analytic services.
 * Needs to be executed every time when detail of the product is fully loaded.
 * @param source InteractionSourceRaw[]
 * @param product Product whose detail is fully loaded.
 */
function productDetailLoaded(source: InteractionSourceRaw[], product: Product) {
  gtmEventViewItem(source, product)
}

function widgetShown(source) {
  gtmEventViewItemList(source)
}

function pageScroll(scrollCount: number) {
  gtmEventPageScroll(scrollCount)
}

function searchProductsSuggested(
  products,
  searchTerm,
  dataSourceEndpoint: SuggestResponse['dataSourceEndpoint'],
  filtersOptions?: ContentQueryFiltersOptions,
) {
  luigisboxDebouncedSearchSuggester(products, searchTerm, dataSourceEndpoint, filtersOptions)
}

function createSearchProductsSuggested() {
  const debouncedByVendor: Record<
    string,
    ReturnType<typeof luigisboxGetDebouncedSearchSuggester>
  > = {}

  return {
    byVendorType: (vendorType: VendorType) => {
      if (!debouncedByVendor[vendorType]) {
        debouncedByVendor[vendorType] = luigisboxGetDebouncedSearchSuggester()
      }

      return (
        products,
        searchTerm,
        dataSourceEndpoint: SuggestResponse['dataSourceEndpoint'],
        filtersOptions?: ContentQueryFiltersOptions,
      ) => debouncedByVendor[vendorType](products, searchTerm, dataSourceEndpoint, filtersOptions)
    },
  }
}

function productsPageLoaded(products, searchTerm, filtersOptions?: ContentQueryFiltersOptions) {
  if (searchTerm) {
    luigisboxSearchPage(products, searchTerm, filtersOptions)
  }
}

/**
 * Delegates calling to specific analytic services.
 * Needs to be executed every time alternates modal is loaded with alternatives for replaceable product
 * @param products
 * @param queryItem
 * @return {void}
 */
function alternativesModalLoaded(products, queryItem) {
  luigisboxRecommendationsAlternatives(products, queryItem)
}

function modalToggled(
  isOpen: boolean,
  analyticsId: string,
  initiator: ModalInitiator,
  activity = '',
) {
  gtmEventToggleModal(isOpen, analyticsId, initiator, activity)
}

function addressChosen(addressData) {
  gtmEventAddressChosen(addressData)
}

/**
 * Delegates calling to specific analytic services.
 * Needs to be executed every time when user submits consent newsletter form.
 */
function newsletterConsent(value: boolean) {
  gtmEventNewsletterConsent(value)
}

function addressSelector(
  message: AddressSelectorMessage,
  location: string | undefined = undefined,
  type: AddressSelectorType | undefined = undefined,
) {
  gtmEventAddressSelector(message, location, type)
}

function addressSave(addressSaveType: AddressSaveType) {
  gtmEventAddressSave(addressSaveType)
}

function deliveryWindow(deliveryWindowData: DeliveryWindowData) {
  gtmEventDeliveryWindow(deliveryWindowData)
}

/**
 * Delegates calling to specific analytic services.
 * Needs to be executed every time when consent newsletter form is shown to the user.
 */
function newsletterFormImpression() {
  gtmEventNewsletterFormImpression()
}

function consentImpression(section: ModalSection, initiator: Initiator) {
  gtmEventCkConsentImpression(section, initiator)
}

function consentToPreferences() {
  gtmEventCkConsentToPreferences()
}

function privacySettingsUpdate(cookies: CookiesConsentMap, type: ConsentType, section?: string) {
  gtmEventCkConsentUpdate(cookies, type, section)
}

/**
 * Delegates calling to specific analytic services.
 * Needs to be executed when Cart page is loaded.
 */
function viewCart() {
  gtmEventViewCart('view_cart')
}

/**
 * Delegates calling to specific analytic services.
 * Needs to be executed when user clicks on button "next" in Cart page, and thus begins checkout process.
 */
function beginCheckout(orderType: 'default_order' | 'additional_order' = 'default_order') {
  gtmEventViewCart('begin_checkout', orderType)
}

/**
 * Delegates calling to specific analytic services.
 * Needs to be executed every time when user is impressed by some in-store notification.
 */
function notificationsImpression(
  impressionSource: NotificationImpressionSource,
  notifications: Notification[],
) {
  gtmEventNotificationImpression(impressionSource, notifications)
}

/**
 * Delegates calling to specific analytic services.
 * Needs to be executed every time when user has alert with new in-store notification.
 */
function notificationBell(notificationCount: number) {
  gtmEventNotificationBell(notificationCount)
}

/**
 * Delegates calling to specific analytic services.
 * Needs to be executed every time when user clicks on in-store notification.
 */
function notificationClick(actionName: NotificationActionName, notification: Notification) {
  gtmEventNotificationClick(actionName, notification)
}

/**
 * Needs to be executed on "thank you" page, after order is placed.
 * @param {Object} order Object containing info about currently created order.
 * @returns {void}
 */
function purchase(order) {
  luigisboxPurchase(order)
  gtmEventPurchase(order)
  yottlySetOrder(order.orderProducts)
}

/**
 * Delegates calling to specific analytic services.
 * Needs to be executed every time when user sends order rating.
 * @param {(0|1|2|3)} ratingValue
 * @return {void}
 */
function orderRating(ratingValue: RatingValue) {
  gtmEventReviewSent(ratingValue)
}

/**
 * Needs to be executed on cart page when user has sold out products.
 */
function outOfStock(cartReplaceableProducts: { soldOut: Product[]; partiallySoldOut: Product[] }) {
  gtmEventOutOfStock(cartReplaceableProducts)
}

/**
 * Needs to be executed when user stumbles upon product with limited delivery in cart.
 */
function itemConflict() {
  gtmEventCheckoutItemConflict()
}

function payment(paymentType) {
  gtmEventPayment(paymentType)
}

function redirectUrl(redirectFrom: string) {
  gtmEventRedirectUrl(redirectFrom)
}

/**
 * Needs to be executed when user successfully logs in or registers.
 */
function loginOrRegistration(claims: EmailProviderClaims | SocialProviderClaims) {
  gtmEventLogin(claims)
}

/**
 * Needs to be executed when user clicks on login or registration button.
 */
function loginOrRegistrationClick(
  gtmClickTypeIntention: GTMLoginRegistrationClickIntention,
  socialLogin?: string,
) {
  gtmEventLoginOrRegistrationClick(gtmClickTypeIntention, socialLogin)
}

/**
 * Needs to be executed when user clicks on a banner or banner is in view.
 */
function bannerClickOrImpression(
  name: PromotionName,
  banner: Banner | BannerThankYouPage | ResizeClubBannersType[number],
  type: AdminBannerType,
  index?: number,
) {
  gtmEventBannerClickOrImpression(name, banner, type, index)
}

type SearchMethodEnum = 'voice' | 'multiple' | 'default'

/**
 * Needs to be executed when user searches for a product.
 * @param searchTerm
 * @param multipleSearchTerm
 * @param method
 */
function search(searchTerm: string, multipleSearchTerm: string, method: SearchMethodEnum) {
  gtmEventViewSearchResults(searchTerm, multipleSearchTerm, method)
}

function widgetContent(widget: Widget) {
  luigisboxWidgetContent(widget)
}

function addressPin(pinData: AddressPinGtmData) {
  gtmEventAddressPin(pinData)
}
/**
 * Needs to be executed when early delivery modal shows or user interacts with it.
 */
function earlyDelivery(activity: GtmEarlyDeliveryActivity) {
  gtmEventEarlyDelivery(activity)
}

export {
  pageView as analyticsLoggerPageView,
  setCart as analyticsLoggerSetCart,
  setCartInteraction as analyticsLoggerSetCartInteraction,
  deleteCart as analyticsLoggerDeleteCart,
  productDetailOpen as analyticsLoggerProductDetailOpen,
  productDetailLoaded as analyticsLoggerProductDetailLoaded,
  widgetShown as analyticsLoggerWidgetShown,
  pageScroll as analyticsLoggerPageScroll,
  productsPageLoaded as analyticsLoggerProductsPageLoaded,
  searchProductsSuggested as analyticsLoggerSearchProductsSuggested,
  createSearchProductsSuggested as analyticsLoggerCreateSearchProductsSuggested,
  alternativesModalLoaded as analyticsLoggerAlternativesModalLoaded,
  modalToggled as analyticsLoggerModalToggled,
  addressChosen as analyticsLoggerAddressChosen,
  addressSelector as analyticsLoggerAddressSelector,
  addressSave as analyticsLoggerAddressSave,
  deliveryWindow as analyticsLoggerDeliveryWindow,
  newsletterConsent as analyticsLoggerNewsletterConsent,
  newsletterFormImpression as analyticsLoggerNewsletterFormImpression,
  alternativeReplaceableProductChosen as analyticsLoggerAlternativeReplaceableProductChosen,
  privacySettingsUpdate as analyticsLoggerPrivacySettingsUpdate,
  viewCart as analyticsLoggerViewCart,
  beginCheckout as analyticsLoggerBeginCheckout,
  notificationBell as analyticsLoggerNotificationBell,
  notificationsImpression as analyticsLoggerNotificationsImpression,
  notificationClick as analyticsLoggerNotificationClick,
  purchase as analyticsLoggerPurchase,
  orderRating as analyticsLoggerOrderRating,
  outOfStock as analyticsLoggerOutOfStock,
  itemConflict as analyticsLoggerItemConflict,
  payment as analyticsLoggerPayment,
  loginOrRegistration as analyticsLoggerLoginOrRegistration,
  bannerClickOrImpression as analyticsLoggerBannerImpression,
  search as analyticsLoggerSearch,
  loginOrRegistrationClick as analyticsLoggerLoginOrRegistrationClick,
  widgetContent as analyticsLoggerWidgetContent,
  redirectUrl as analyticsLoggerRedirectUrl,
  addressPin as analyticsLoggerAddressPin,
  consentImpression as analyticsLoggerConsentImpression,
  consentToPreferences as analyticsLoggerConsentToPreferences,
  earlyDelivery as analyticsLoggerEarlyDelivery,
  gtmEventExperimentViewed as analyticsLoggerExperimentViewed,
  gtmEventComponentInteraction as analyticsLoggerComponentInteraction,
  gtmEventSelectSlot as analyticsLoggerSelectSlot,
  logCheckoutStep,
}

export type { SearchMethodEnum }
