import type { PositionGPS } from '@/data/api/frontApi/address/addressTypes'
import type { ApiEmptyResponse } from '@/services/api/types'
import appConfig from '@/services/appConfig'

import { fetchGet, fetchDelete, fetchPost, fetchPut } from '../fetch'
import type {
  CartAddressRequest,
  CartAddressResponse,
  CartProductResponse,
  CartProductsFromOrderResponse,
  CartResponse,
} from './cartTypes'

const modulePath = 'cart'

/**
 * Sends GET request for setting cart.
 * @see https://sasandboxstdhotapidocs.z1.web.core.windows.net/#/Cart/readCart
 * @returns Promise of the API response with cart payload.
 */
function getCart() {
  return fetchGet<CartResponse>(appConfig.frontApiBaseUrl + modulePath)
}

/**
 * Sends DELETE request for flushing cart.
 * @see https://sasandboxstdhotapidocs.z1.web.core.windows.net/#/Cart/deleteCart
 * @returns Promise of the API response with empty payload (only HTTP status codes differentiate success from error - for example, cart have not had any products before).
 */
function deleteCart() {
  return fetchDelete<ApiEmptyResponse>(appConfig.frontApiBaseUrl + modulePath)
}

/**
 * Sends PUT request for rejecting product replacements (replacements of sold-out products with similar ones after the order is created).
 * @param refuseReplacements Whether the customer refuses this functionality.
 * @returns Promise of the API response with empty payload (only HTTP status codes differentiate success from error).
 */
function putReplacements(refuseReplacements: boolean) {
  return fetchPut<ApiEmptyResponse>(appConfig.frontApiBaseUrl + modulePath + '/replacements', {
    refuseReplacements,
  })
}

/**
 * Sends POST request for rejecting a single gift (a product automatically added to some other bought product).
 * @see https://sasandboxstdhotapidocs.z1.web.core.windows.net/#/Cart/rejectProductGift
 * @param giftId ID of the gift product to be removed from customer's cart.
 * @returns Promise of the API response with empty payload (only HTTP status codes differentiate success from error - for example, non-existing or non-present gift).
 */
function postRejectProductGift(giftId: number) {
  return fetchPost<ApiEmptyResponse>(
    appConfig.frontApiBaseUrl + modulePath + '/reject-product-gift',
    undefined,
    {
      giftId,
    },
  )
}

/**
 * Sends PUT request for setting delivery address.
 * @see https://sasandboxstdhotapidocs.z1.web.core.windows.net/#/Cart/setCartDeliveryAddress
 * @param address Full address including street, street number, city and ZIP code.
 * @param positionGps Gps or null
 * @param reloadPageInfo.
 * @returns Promise of the API response with delivery address object.
 */
function putAddress(
  address: CartAddressRequest['address'],
  positionGps?: PositionGPS | null,
  reloadPageInfo: CartAddressRequest['reloadPageInfo'] | null = null,
) {
  return fetchPut<CartAddressResponse>(appConfig.frontApiBaseUrl + modulePath + '/address', {
    address,
    positionGps,
    reloadPageInfo,
  })
}

/**
 * Sends PUT request for adding a single product (or adding a returnable carrier) to customer cart.
 * @see https://sasandboxstdhotapidocs.z1.web.core.windows.net/#/Cart/setCartProduct
 * @param productId ID of product to add into cart.
 * @param quantity Desired quantity. Default is null.
 * @param returnableCarrier Whether to demand returnable carrier for goods (e.g. beer crate). Default is null.
 * @returns Promise of the API response with payload consisting of added product data, its quantity, its returnable carrier data and gift data if present.
 */
function putProduct(
  productId: number,
  quantity: number | null = null,
  returnableCarrier: boolean | null = null,
) {
  const productData: any = {}

  if (quantity !== null) {
    productData.quantity = quantity
  }

  if (returnableCarrier !== null) {
    productData.returnableCarrier = returnableCarrier
  }

  return fetchPut<CartProductResponse>(
    appConfig.frontApiBaseUrl + modulePath + '/product/' + productId,
    productData, // @TODO There's no info about possible additional parameters in the API docs.
    'setProduct' + productId,
  )
}

/**
 * Sends PUT request for copying products and their respective quantities into customer's cart from an existing order by its ID.
 * @see https://sasandboxstdhotapidocs.z1.web.core.windows.net/#/Cart/setProducts
 * @param orderId ID of order to get pruducts from.
 * @returns Promise of the API response. Contains payload with products that were put into the shopping cart partially or not at all (in case of HTTP status code 200). Does not contain any payload in case of complete success (all products with desired quantities added) or in case of error.
 */
function putProductsFromOrder(orderId: number) {
  return fetchPut<CartProductsFromOrderResponse>(
    appConfig.frontApiBaseUrl + modulePath + '/from-order/' + orderId + '/products',
  )
}

export {
  getCart as frontApiGetCart,
  deleteCart as frontApiDeleteCart,
  putReplacements as frontApiPutCartReplacements,
  postRejectProductGift as frontApiPostCartRejectProductGift,
  putAddress as frontApiPutCartAddress,
  putProduct as frontApiPutCartProduct,
  putProductsFromOrder as frontApiPutCartProductsFromOrder,
}
