import { storeToRefs } from 'pinia'

import { t } from '@/services/translations'
import useCartStore from '@/store/pinia/useCartStore'
import roundToDecimals from '@/utils/roundToDecimals'

import { dataLayerPush, mapProductGtm } from './utils'

type EventName = 'view_cart' | 'begin_checkout'
type OrderType = 'default_order' | 'additional_order'

/**
 * Sends either 'view_cart' or 'begin_checkout' event.
 * @param eventName Name of the event to be sent.
 * @param orderType Type of the order.
 */
function viewCart(eventName: EventName, orderType?: OrderType) {
  const cartStore = useCartStore()
  const { cartGetters } = storeToRefs(cartStore)
  const cartProducts = cartGetters.value.getCartProductsFullData

  dataLayerPush({
    event: eventName,
    checkout: {
      step_number: eventName === 'view_cart' ? 1 : 2,
      ...(orderType && { order_type: orderType }),
    },
    ecommerce: {
      currency: t('general.currencyCode'),
      value: roundToDecimals(cartGetters.value.getCartTotalPrice),
      items: cartProducts.map((product) => mapProductGtm(product.origin)),
    },
  })
}

export { viewCart as gtmEventViewCart }
