import { useToast, TYPE } from 'vue-toastification'

import { uniqueId } from 'lodash-es'

import {
  ToastContent,
  ToastIconInfo,
  ToastIconSuccess,
  ToastIconWarning,
  ToastIconError,
} from '@/components/Toast'

import { TIMEOUT, TIMEOUT_LONGER } from './config'
import toastPersist from './toastPersist'
import type { Toast } from './toastPersist'

// You can use composable outside of Vue components, see:
// https://github.com/Maronato/vue-toastification#using-with-vuex-or-outside-components
const toastificationInstance = useToast()

const iconsMap = {
  [TYPE.INFO]: ToastIconInfo,
  [TYPE.SUCCESS]: ToastIconSuccess,
  [TYPE.WARNING]: ToastIconWarning,
  [TYPE.ERROR]: ToastIconError,
}

type ToastTriggerOptions = PartiallyOptional<
  Omit<Toast, 'isUnfinished' | 'triggerTime' | 'toastId'>,
  'hasLongerTimeout'
>

/**
 * Triggers new toast of given type with given message.
 * @param {ToastTriggerOptions} options Configuration object
 * @param {TYPE} options.hasLongerTimeout Defaults to false
 */
function triggerToast({
  type,
  content,
  hasLongerTimeout = false,
  flashMessageId,
  onlyForVendorIds,
}: ToastTriggerOptions) {
  const toastId = uniqueId('toast-')

  toastPersist({
    type,
    content,
    toastId,
    hasLongerTimeout,
    triggerTime: new Date().getTime(),
    flashMessageId,
    onlyForVendorIds,
  })

  const contentComponent = {
    component: ToastContent,
    props: { content, flashMessageId },
  }

  toastificationInstance(contentComponent, {
    icon: iconsMap[type],
    id: toastId,
    timeout: hasLongerTimeout ? TIMEOUT_LONGER : TIMEOUT,
    type,
  })
}

export { triggerToast }

export type { ToastTriggerOptions }
