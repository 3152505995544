import { createOperation } from '@/services/apiv2'

import type { FrontApiEmptyResponse } from '../frontApiTypes'
import type { DeleteAddressPathParams } from './addressTypes'

const deleteAddress = createOperation<
  FrontApiEmptyResponse,
  FrontApiEmptyResponse,
  DeleteAddressPathParams
>('/address/:addressId', {
  method: 'delete',
})

export default deleteAddress
