import { ref, computed, type ComputedRef, type Ref } from 'vue'

import type { GPS } from '@/data/api/frontApi/address/addressTypes'

import useValidateGpsGet from './useValidateGpsGet'

function useValidateGps(address: ComputedRef<string>, location: Ref<GPS | null>) {
  const { data: gpsIsValid, isFetching } = useValidateGpsGet(address, location)

  const mapIsMoving = ref(false)

  const saveIsDisabled = computed(
    () => isFetching.value || mapIsMoving.value || gpsIsValid.value === false,
  )

  function onMapMove() {
    mapIsMoving.value = true
  }

  function onMapMoveStop() {
    mapIsMoving.value = false
  }

  return {
    gpsIsValid,
    saveIsDisabled,
    onMapMove,
    onMapMoveStop,
  }
}

export default useValidateGps
