<script setup lang="ts">
import { computed, ref } from 'vue'

import { storeToRefs } from 'pinia'

import Icon from '@/components/Icon/Icon.vue'
import type { NoticeSeverity } from '@/services/api/front/info'
import useDeviceDetectorStore from '@/store/pinia/useDeviceDetectorStore'

type StatusMessageSeverity = NoticeSeverity
type StatusMessageProps = {
  severity: StatusMessageSeverity
  message: string
  emphasized?: boolean
}

const deviceDetectorStore = useDeviceDetectorStore()
const { breakpointUpLg } = storeToRefs(deviceDetectorStore)

// Shorter messages than this amount are never collapsible.
const maxTextLength = 150
const props = defineProps<StatusMessageProps>()
const severityToClass = {
  success: 'bg-tint-green text-text-paragraph font-weight-bold',
  warning: 'bg-tint-orange text-text-paragraph font-weight-bold',
  error: 'bg-tint-red text-text-paragraph font-weight-bold',
} as const

const isCollapsed = ref(true)

const truncateText = computed(
  () =>
    props.message &&
    props.message.length > maxTextLength &&
    !breakpointUpLg.value &&
    isCollapsed.value,
)
const showButton = computed(
  () => props.message && props.message.length > maxTextLength && !breakpointUpLg.value,
)
</script>

<template>
  <div
    :class="[
      `message-layout my-1 px-3 py-2 rounded message-layout--${props.severity}`,
      severityToClass[props.severity],
    ]">
    <div class="icon-wrapper">
      <Icon
        :class="[`icon icon--${props.severity}`]"
        group="notification"
        :icon="props.severity" />
    </div>

    <div
      :class="[
        'mx-2 flex-grow-1 text-3 text-center pt-1 pt-lg-0 text',
        {
          'truncate-overflow': truncateText,
          'font-weight-bold': props.emphasized,
        },
      ]"
      v-html="props.message" />

    <div class="text-right">
      <slot name="controls">
        <button
          v-if="showButton"
          type="button"
          class="px-0 bg-transparent border-0"
          @click="() => (isCollapsed = !isCollapsed)">
          <Icon
            :class="['text-gray-darker icon', !isCollapsed ? 'arrow-up' : 'arrow-down']"
            icon="arrow-button" />
        </button>
      </slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.message-layout {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: start;
  border: 1px solid;

  @include media-breakpoint-up(lg) {
    align-items: center;
  }

  &--error {
    border-color: color('accent-red');
  }

  &--success {
    border-color: color('accent-green');
  }

  &--warning {
    border-color: color('accent-orange');
  }
}

.text {
  max-width: 936px;

  :deep(p) {
    text-align: center;
  }

  :deep(p:last-of-type) {
    margin-bottom: 0;
  }

  :deep(a) {
    color: inherit;
    text-decoration: underline;
  }
}

.truncate-overflow {
  max-height: 35px;
  overflow: hidden;
  white-space: nowrap;
}

.icon {
  $size: 26px;

  width: $size;
  height: $size;

  &--error {
    color: color('accent-red');
  }

  &--success {
    color: color('accent-green');
  }

  &--warning {
    color: color('accent-orange');
  }
}

.arrow-down {
  transform: rotate(90deg);
}

.arrow-up {
  transform: rotate(-90deg);
}
</style>
