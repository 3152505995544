import type { InteractionSourceRaw } from '@/composables/useInteractionSource'

type MapProductGtmOptions = {
  /**
   * If the product is a gift, the price is always 0
   * no matter the original price.
   */
  isGift: boolean
  /**
   * If null, cart quantity is used, otherwaise this value is used.
   * Used for add/remove events, where we want to know the difference
   * between the original quantity and the new quantity, not the total
   * quantity in cart.
   */
  quantityDiff: number | null
}

type MappedProduct = {
  item_id: number
  item_name: string
  price: number
  quantity: number
  index?: number
}

const defaultOptions: MapProductGtmOptions = {
  isGift: false,
  quantityDiff: null,
}

function mapProductGtm(
  productData,
  interactionSource: InteractionSourceRaw[] = [],
  extraOptions?: Partial<MapProductGtmOptions>,
) {
  const options = { ...defaultOptions, ...extraOptions }

  const mappedProduct: MappedProduct = {
    item_id: productData.id,
    item_name: productData.name,
    price: options.isGift ? 0 : productData.price,
    quantity: options.quantityDiff ?? productData.quantity,
  }

  const productOrder = Number(interactionSource.find((source) => source.order)?.order)
  if (productOrder) {
    mappedProduct.index = productOrder
  }

  return mappedProduct
}

export { mapProductGtm }
export type { MappedProduct }
