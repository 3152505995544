import type { LocationQueryValueRaw } from 'vue-router'

import convertObjToUrlParams from '@/utils/convertObjToUrlParams'

import emitSilenceFetch, {
  abortControllerList,
  abortPreviousRequest,
  clearRequestAbortControllerList,
} from './emitSilenceFetch'

/**
 * Single parametr in request query, specific for kosik.
 */
type RequestQueryParam = LocationQueryValueRaw | boolean

/**
 * List of request query parameters, specific for kosik.
 */
type RequestQueryParams = Record<
  string | number,
  RequestQueryParam | RequestQueryParam[] | Record<string | number, RequestQueryParam>
>

type RequestMethod = 'GET' | 'POST' | 'PATCH' | 'DELETE' | 'PUT'

interface MakeRequestParams {
  method: RequestMethod
  url: string
  params?: RequestQueryParams
  requestData?: any
  formData?: any
  nameController?: string
  isExternal?: boolean
}

function headersToObject(headers) {
  const headersObject = {}

  for (const [key, value] of headers) {
    headersObject[key] = value
  }

  return headersObject
}

type RequestOptions = {
  signal: any
  credentials: 'same-origin'
  method: RequestMethod
  headers: any
  body?: any
}

type ResponseObject<TData = any> = {
  headers: any
  data: TData
}

/**
 * Error for response with status code different than 200.
 */
class ResponseNotOKError<TData extends ResponseObject = ResponseObject> extends Error {
  public readonly headersJson: string
  public readonly dataJson: string
  public readonly url: string
  public readonly response: TData

  constructor(response: TData, method: string, url: string) {
    super(response.data.detail ?? response.data.type)
    this.name = 'ResponseNotOKError'
    this.response = response
    this.headersJson = JSON.stringify(response.headers)
    this.dataJson = JSON.stringify(response.data, null, 2)
    this.url = `${method} ${url}`
  }
}

/**
 * Error for failed response where browser intentionally obfuscate error details.
 */
class ResponseFailedError extends Error {
  public readonly url: string
  public readonly stacktrace?: string

  constructor(originalError: Error, method: string, url: string) {
    // webkit message unified to other browser messages
    super(originalError.message === 'Load failed' ? 'Failed to fetch' : originalError.message)
    this.name = 'ResponseFailedError'
    this.url = `${method} ${url}`
    this.stacktrace = originalError.stack
  }
}

function addParamsToUrl(url: string, params?: RequestQueryParams) {
  if (!params) {
    return url
  }

  const transformedParams = convertObjToUrlParams(params)
  return `${url}?${transformedParams}`
}

function makeRequest<TData = any>({
  method,
  url,
  params,
  requestData,
  formData,
  nameController,
}: MakeRequestParams) {
  url = addParamsToUrl(url, params)

  const nameControllerOrUrl = nameController ?? url

  abortPreviousRequest(nameControllerOrUrl)

  // eslint-disable-next-line no-async-promise-executor
  return new Promise<ResponseObject<TData>>((resolve, reject) => {
    const makeRequestFn = async () => {
      const headers = {
        'X-Requested-With': 'XMLHttpRequest',
      }

      const options: RequestOptions = {
        signal: abortControllerList[nameControllerOrUrl].signal,
        credentials: 'same-origin', // Fix send cookies in Chrome 67, Safari 11 and older versions
        method,
        headers,
      }

      if (formData) {
        options.body = formData
      }

      if (requestData) {
        options.headers['Content-Type'] = 'application/json'
        options.body = JSON.stringify(requestData)
      }

      try {
        const response = await fetch(url, options)
        let data: TData

        try {
          data = await response.json()
        } catch (err) {
          if (err instanceof Error && err.name === 'AbortError') {
            throw err
          }
          // @TODO: remove this hack, only used in CheckoutPrice.vue
          data = {
            status: 'ok',
          } as unknown as TData
        }

        const responseObject: ResponseObject<TData> = {
          headers: headersToObject(response.headers),
          data,
        }

        if (!response.ok) {
          reject(new ResponseNotOKError(responseObject, method, url))
        } else {
          resolve(responseObject)
        }
      } catch (err) {
        if (err instanceof Error && err.name !== 'AbortError') {
          reject(new ResponseFailedError(err, method, url))
        }
      } finally {
        clearRequestAbortControllerList(nameControllerOrUrl)
        emitSilenceFetch()
      }
    }
    makeRequestFn()
  })
}

export default makeRequest

export { ResponseNotOKError, addParamsToUrl }

export type { RequestQueryParams, RequestQueryParam }
