const promises: { [key: string]: Promise<void> } = {}

function loadScript(fileUrl: string) {
  return new Promise<void>((resolve, reject) => {
    const script = document.createElement('script')
    script.src = fileUrl
    script.async = true
    script.addEventListener('load', () => resolve(), false)
    script.addEventListener('error', (err) => reject(new Error(err.message)), false)
    document.body.appendChild(script)
  })
}

export default (fileUrl: string) => {
  if (!Object.hasOwn(promises, fileUrl)) {
    promises[fileUrl] = loadScript(fileUrl)
  }

  return promises[fileUrl]
}
