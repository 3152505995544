import dayjs from 'dayjs'

import '@/services/analytics/analyticsLogger'
import { setCookie, getCookie } from '@/services/cookies'

type CookieConsents = {
  [key: string]: 'denied' | 'granted'
}

const analyticsCookiesValues = {
  granted: 'granted',
  denied: 'denied',
} as const

const cookieName = 'consentPreferences'

/*
 * {Object} Base setting for privacy-managed cookies. Actual values are in the user's cookie preferences.
 */
const cookiesConsentMap: CookieConsents = {
  functional_storage: 'granted',
  analytics_storage: 'denied',
  personalization_storage: 'denied',
  ad_storage: 'denied',
  ad_user_data: 'denied',
  ad_personalization: 'denied',
}

export type CookiesConsentMap = typeof cookiesConsentMap

/**
 * Retrieves the consent values of a requested privacy-managed cookies type.
 * @see {@link cookiesConsentMap} for available privacy-managed cookie object.
 * @returns The state of user's consent.
 */
function getCookieConsents(): CookieConsents {
  if (!getCookie(cookieName)) {
    return cookiesConsentMap
  }
  const cookies = JSON.parse(getCookie(cookieName))
  const cookieConsents = {}
  Object.keys(cookiesConsentMap).forEach((key) => {
    cookieConsents[key] = cookies[key] as 'granted' | 'denied'
  })
  return cookieConsents
}

/**
 * Checks whether the cookie consent is granted.
 * @param cookieConsent Cookie consent preference.
 * @returns Boolean for user's consent for a particular preference.
 */
function isCookieConsentGranted(cookieConsent: keyof CookiesConsentMap) {
  return getCookieConsents()[cookieConsent] === 'granted'
}

/**
 * Sets the consent object
 * @see {@link cookiesConsentMap} for available keys of privacy-managed cookie types.
 * @param consent Cookies consent object
 */
function setCookieConsent(consent: CookiesConsentMap) {
  const cookies = {
    timestamp: dayjs(),
    version: 1,
    ...consent,
  }

  const domainName = window.location.hostname.split('.').slice(-2).join('.')
  setCookie(cookieName, JSON.stringify(cookies), 365, domainName)
}

export {
  analyticsCookiesValues,
  cookiesConsentMap,
  cookieName,
  setCookieConsent,
  getCookieConsents,
  isCookieConsentGranted,
}
