import { dataLayerPush } from './utils'

type DeliveryWindowSlotData = {
  time: string //"14:00-15:00"
  price: number
  is_available: boolean
  is_ecologic: boolean
}

type DeliveryWindowData = {
  day: string // d+0, d+1, d+2, ...
  date: string // YYYY-MM-DD
  slots: DeliveryWindowSlotData[]
}

function deliveryWindow(deliveryWindowData: DeliveryWindowData) {
  dataLayerPush({
    event: 'delivery_window',
    delivery: deliveryWindowData,
  })
}

export { deliveryWindow as gtmEventDeliveryWindow }

export type { DeliveryWindowData, DeliveryWindowSlotData }
