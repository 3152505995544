<script setup lang="ts">
type IconProps = {
  group?: string
  icon: string
}
const props = withDefaults(defineProps<IconProps>(), {
  group: 'general',
})
</script>

<template>
  <svg class="icon">
    <use :xlink:href="`/vue/icons/${props.group}.svg?v=6#icon-${props.icon}`" />
  </svg>
</template>
