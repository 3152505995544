import type { Ref, ComputedRef, MaybeRef } from 'vue'

import type { TransportWindowsCurrentCart } from '@/composables/transport/useTransportWindows'
import type { GPS } from '@/data/api/frontApi/address/addressTypes'
import type { BannerType } from '@/data/api/frontApi/banners/bannerTypes'
import type {
  ClientSavingsQueryParams,
  ClientFlagsPathParams,
} from '@/data/api/frontApi/client/clientTypes'
import type { ProfileCreditHistoryQueryParams } from '@/data/api/frontApi/profile/profileTypes'
import type { OrderListParams } from '@/services/api/front/profile'

const addressQueryKeys = {
  all: ['address'] as const,

  detail: () => [...addressQueryKeys.all, 'detail'] as const,
  suggestions: (search: Ref<string>) => [...addressQueryKeys.all, 'suggestion', search] as const,
  validate: (address: Ref<string>) => [...addressQueryKeys.all, 'validate', address] as const,
  validateGps: (address: ComputedRef<string>, location: Ref<GPS | null>) =>
    [...addressQueryKeys.all, 'validateGps', address, location] as const,
  validatePrelaunch: (address: Ref<string>) =>
    [...addressQueryKeys.all, 'validatePrelaunch', address] as const,
}

const phoneQueryKeys = {
  all: ['phone'] as const,

  validate: (phoneNumber: Ref<string>) => [...phoneQueryKeys.all, 'validate', phoneNumber] as const,
}

const benjaminekQueryKeys = {
  all: ['benjaminek'] as const,

  children: () => [...benjaminekQueryKeys.all, 'children'] as const,
}

const notificationQueryKeys = {
  all: ['notifications'] as const,
  theData: ['data'] as const,

  data: () => ['instore', ...notificationQueryKeys.all, ...notificationQueryKeys.theData] as const,
  valid: () =>
    ['instore', ...notificationQueryKeys.all, ...notificationQueryKeys.theData, 'valid'] as const,
  history: () =>
    ['instore', ...notificationQueryKeys.all, ...notificationQueryKeys.theData, 'history'] as const,
  count: () => ['instore', ...notificationQueryKeys.all, 'count'] as const,
}

const authQueryKeys = {
  all: ['auth'] as const,

  activeAccount: () => [...authQueryKeys.all, 'active-account'] as const,
}

type ClientFlagName = keyof ClientFlagsPathParams

const clientQueryKeys = {
  all: ['client'] as const,

  creditCards: () => [...clientQueryKeys.all, 'credit-card'] as const,
  savings: (queryParams: ClientSavingsQueryParams) =>
    [...clientQueryKeys.all, 'savings', queryParams] as const,
  flags: () => [...clientQueryKeys.all, 'flags'] as const,
  flagsUpdateByName: (flagName: ClientFlagName) => [...clientQueryKeys.flags(), flagName] as const,
}

const menuQueryKeys = {
  all: ['menu'] as const,

  main: () => [...menuQueryKeys.all, 'main'] as const,
}

const checkoutQueryKeys = {
  all: ['checkout'] as const,

  detail: () => [...checkoutQueryKeys.all, 'detail'] as const,
  payment: () => [...checkoutQueryKeys.all, 'payment'] as const,
  credit: () => [...checkoutQueryKeys.all, 'credit'] as const,
  updateVoucher: () => [...checkoutQueryKeys.all, 'voucher', 'update'] as const,
  removeVoucher: () => [...checkoutQueryKeys.all, 'voucher', 'remove'] as const,
}

const orderTrackingQueryKeys = {
  all: ['orderTracking'] as const,

  tracking: (orderNumber: string, customerPhone: string) =>
    [...orderTrackingQueryKeys.all, 'tracking', orderNumber, customerPhone] as const,
}

const bannerQueryKeys = {
  all: ['banners'] as const,

  type: (type: BannerType) => [...bannerQueryKeys.all, type] as const,
}

const productQueryKeys = {
  all: ['product'] as const,

  variants: (productId: Ref<number>, associationCode: Ref<string>) =>
    [...productQueryKeys.all, productId, 'variants', associationCode] as const,
}

const paymentQueryKeys = {
  all: ['payment'] as const,

  applePay: () => [...paymentQueryKeys.all, 'applepay'] as const,
  applePayEcho: () => [...paymentQueryKeys.applePay(), 'echo'] as const,
  googlePay: () => [...paymentQueryKeys.all, 'googlepay'] as const,
  googlePayEcho: () => [...paymentQueryKeys.googlePay(), 'echo'] as const,
}

const configurationQueryKeys = {
  all: ['configuration'] as const,
  uuid: () => [...configurationQueryKeys.all, 'uuid'] as const,
}

const profileQueryKeys = {
  all: ['profile'] as const,

  creditHistory: (queryParams: ProfileCreditHistoryQueryParams) =>
    [...profileQueryKeys.all, 'credit-history', queryParams] as const,
}

const orderQueryKeys = {
  all: ['order'] as const,

  list: (queryParams: MaybeRef<OrderListParams>) =>
    [...orderQueryKeys.all, 'list', queryParams] as const,
}

const privacyQueryKeys = {
  all: ['privacy'] as const,

  request: () => [...privacyQueryKeys.all, 'request', 'customer'] as const,
}

const deliveryQueryKeys = {
  all: ['delivery'] as const,
}

const transportQueryKeys = {
  all: ['transport'] as const,

  deliveryData: () => [...transportQueryKeys.all, 'delivery-data'] as const,
  windows: (currentCart: TransportWindowsCurrentCart) =>
    [...transportQueryKeys.all, 'windows', currentCart] as const,
}

const manifestQueryKeys = {
  all: ['manifest'] as const,
}

const staticBlockQueryKeys = {
  all: ['static-block'] as const,
  slug: (slug: string) => ['static-block', slug] as const,
}

export {
  addressQueryKeys,
  phoneQueryKeys,
  authQueryKeys,
  benjaminekQueryKeys,
  clientQueryKeys,
  menuQueryKeys,
  checkoutQueryKeys,
  orderTrackingQueryKeys,
  bannerQueryKeys,
  productQueryKeys,
  paymentQueryKeys,
  configurationQueryKeys,
  profileQueryKeys,
  orderQueryKeys,
  privacyQueryKeys,
  deliveryQueryKeys,
  transportQueryKeys,
  manifestQueryKeys,
  notificationQueryKeys,
  staticBlockQueryKeys,
}

export type { ClientFlagName }
