import { TYPE } from 'vue-toastification'

import { ResponseNotOKError } from '@/services/api/makeRequest'

import clearVendorSpecificToasts from './clearToasts'
import { triggerToast } from './triggerToast'
import type { ToastTriggerOptions } from './triggerToast'

type ToastOptions = Omit<ToastTriggerOptions, 'type' | 'content'>

/**
 * Triggers new toast with general informative message.
 */
function toastInfo(content: string, options?: ToastOptions) {
  triggerToast({ type: TYPE.INFO, content, ...options })
}

/**
 * Triggers new toast with positive message about successfully resolved process.
 */
function toastSuccess(content: string, options?: ToastOptions) {
  triggerToast({ type: TYPE.SUCCESS, content, ...options })
}

/**
 * Triggers new toast with warning message indicating problem which may cause any unwanted behavior.
 */
function toastWarning(content: string, options?: ToastOptions) {
  triggerToast({ type: TYPE.WARNING, content, ...options })
}

/**
 * Triggers new toast with error message in case of process failure.
 */
function toastError(content: string, options?: ToastOptions) {
  triggerToast({ type: TYPE.ERROR, content, ...options })
}

/**
 * Throws toast with translated detail from API response.
 * @param err Error from API response
 */
function toastApiErrorResponse(err: unknown, options?: ToastOptions) {
  if (err instanceof ResponseNotOKError) {
    const { translatedDetail } = err.response.data.context

    toastError(translatedDetail, options)
  }
}

export {
  toastInfo,
  toastSuccess,
  toastWarning,
  toastError,
  toastApiErrorResponse,
  clearVendorSpecificToasts,
}
