import { useRoute } from 'vue-router'

import { analyticsLoggerPageView } from '@/services/analytics/analyticsLogger'
import type { GTMEventPageData } from '@/services/analytics/gtm'

function usePageViewAnalytics() {
  const route = useRoute()

  function sendPageView(options: {
    pageData: Partial<GTMEventPageData>
    isClientSideRouted: boolean
    sendToLugisBox: boolean
  }) {
    const currentPageData = {
      ...{
        gtmPageType: route.meta.gtmPageType,
        fullPath: route.fullPath,
        checkoutStep: route.meta.checkoutStep,
      },
      ...(options.pageData ?? {}),
    }
    analyticsLoggerPageView(currentPageData, options.isClientSideRouted, options.sendToLugisBox)
  }

  return sendPageView
}

export default usePageViewAnalytics
