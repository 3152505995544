import { watch, type ComputedRef } from 'vue'

import dayjs from 'dayjs'

import type { Product } from '@/composables/useProductItem'
import { resizer } from '@/services/resizer'
import { t } from '@/services/translations'
import getScriptElementInHead from '@/utils/getScriptElementInHead'

type ProductStructuredData = {
  [key: string]: ProductStructuredData | string | string[] | number | null | undefined
}

/**
 * Basic and fast html strip function.
 */
function fastStripHtmlTags(html: string | undefined) {
  if (!html) return

  return html
    .replace(/<[^>]*>/g, '') // Strip HTML tags
    .replace(/&nbsp;/g, ' ') // Replace nbsp with regular space
    .replace(/\n/g, ' ') // Replace new lines with regular space
    .trim()
}

/**
 * Generate script element with JSON structured data for external services
 * that are capable of reading it.
 */
function useGenerateProductStructuredData(product: ComputedRef<Product>) {
  watch(
    () => product.value.origin.detail,
    () => {
      if (!product.value.origin.detail) return

      const origin = product.value.origin
      const detail = origin.detail

      const jsonData: ProductStructuredData = {
        '@context': 'https://schema.org',
        '@type': 'Product',
        name: origin.name,
        description: fastStripHtmlTags(detail?.description?.[0]?.value),
        image: detail?.photos?.map((photo) => resizer(photo, 500)),
        mpn: detail?.sapId,
        sku: product.value.id.toString(),
        category: origin.breadcrumbs.map((x) => x.name).join(' > '),
        offers: {
          '@type': 'Offer',
          ...(!detail?.unlisted && { price: origin.price }),
          priceCurrency: t('general.currencyCode'),
          url: window.location.origin + origin.url,
          ...(!detail?.unlisted && { availability: 'https://schema.org/InStock' }),
          itemCondition: 'https://schema.org/NewCondition',
          seller: {
            '@type': 'Organization',
            name: t('general.brand'),
          },
          priceValidUntil: dayjs().add(1, 'year').format('YYYY-MM-DD'),
        },
        brand: detail?.brand && {
          '@type': 'Brand',
          name: detail.brand.name,
          url: window.location.origin + detail.brand.url,
        },
        countryOfOrigin: detail?.origin?.[0]?.value,
      }

      const scriptElement = getScriptElementInHead('productDetail')
      scriptElement.textContent = JSON.stringify(jsonData)
    },
    { immediate: true },
  )
}

export default useGenerateProductStructuredData
