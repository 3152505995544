import type { PublicClientApplication, RedirectRequest } from '@azure/msal-browser'

import { queryStringEncode } from '@/utils/queryString'

import appConfig from '../appConfig'
import { scopes, MsalRedirectFlow } from './aadConstants'

type MsalRedirectFlowType = keyof typeof MsalRedirectFlow

type MsalExtraQueryParams = RedirectRequest['extraQueryParameters']

type MsalRedirectOptions = {
  /**
   * What type of our internal user-flow we use.
   */
  flow: MsalRedirectFlowType
  /**
   * URL to redirect to after success.
   */
  finalUrl?: string
  /**
   * URL of authority that processes the authorization request.
   */
  authority?: string
  /**
   * Extra query parameters to be passed.
   */
  extraQueryParameters?: MsalExtraQueryParams
}

/**
 * Initiates MSAL redirect.
 * @param flow Key of the flow to be passed.
 * @param finalUrl redirectUrl URL to redirect after success. If not set, the default is URL where you triggered the redirect from.
 * @param authority Authority to request. If not set, the default one from initial MSAL library settings is used.
 */
async function launchMsalRedirect(
  msalInstance: PublicClientApplication,
  { flow, finalUrl, authority, extraQueryParameters }: MsalRedirectOptions,
) {
  const redirectRequest: RedirectRequest = {
    scopes,
    state: queryStringEncode({
      finalUrl: finalUrl || window.location.href,
      flow,
    }),
    extraQueryParameters: {
      ...extraQueryParameters,
      appType: 'web',
      ui_locales: appConfig.langCode,
    },
    authority,
  }

  await msalInstance.loginRedirect(redirectRequest)
}

export default launchMsalRedirect

export type { MsalRedirectOptions, MsalRedirectFlowType, MsalExtraQueryParams }
