<script setup lang="ts">
import { computed, ref } from 'vue'

import { useElementHover } from '@vueuse/core'

import { t } from '@/services/translations'

type RowStepProps = {
  variant: string | null
  active?: boolean
  disabled?: boolean
}
const props = defineProps<RowStepProps>()

const buttonElement = ref()
const isHovered = useElementHover(buttonElement)

const available = computed(() => !props.active && !props.disabled)
</script>

<template>
  <button
    ref="buttonElement"
    :title="available ? t('cumulativePrices.applyDiscount') : ''"
    :disabled="disabled || active"
    :class="[
      'a-row-step',
      props.variant ? `a-row-step--${props.variant}` : null,
      {
        'a-row-step--available': available,
        'bg-green-light': props.active,
        'bg-gray-lighter text-gray': props.disabled,
      },
    ]">
    <strong
      :class="{
        'text-red': !props.disabled,
      }">
      <slot name="left" />
    </strong>

    <div
      v-if="!!$slots.center && !!$slots.center()"
      :class="[
        'text-1',
        {
          'text-green': props.active,
        },
      ]">
      <slot name="center" />
    </div>

    <div
      :class="{
        'text-gray': props.disabled,
      }">
      <slot
        name="right"
        :is-hovered="isHovered" />
    </div>
  </button>
</template>
