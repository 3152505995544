/**
 * Find object in array faster than Array.find()
 * @returns Object containing found item and its index. In case object not found, returned object is empty.
 */
function findObjectByKey<T = any>(items: T[], searchValue: any, searchKey = 'id') {
  const length = items.length
  for (let index = 0; index < length; index++) {
    const item = items[index]
    if (item[searchKey] === searchValue) {
      return { item, index }
    }
  }

  return null
}

export { findObjectByKey }

export default findObjectByKey
