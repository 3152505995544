/**
 * Convert neon path to vue router path
 * @param {string} netteSlug neon config path to convert
 * @returns {string} Vue router path
 * @todo Refactor this old code to be more readable and write a test.
 *
 * @example
 * convertNetteToVueSlug('stranky/<slug>') // returns 'stranky/:slug'
 */
function convertNetteToVueSlug(netteSlug: string) {
  const pathRegex = /([a-zA-Z]|-)+(\s\.\*)?/g // finds all kebab/pascal/camel cases with or without following string ' .*'
  const repeatingOptionalParameterRegex = /\s\.\*/ // finds string ' .*'
  const optionalParametersGroupRegex = /\[.+]/ // finds anything between [] brackets
  const dynamicParametersGroupRegex = /<.+>/ // finds anything between <> brackets

  if (optionalParametersGroupRegex.test(netteSlug)) {
    let optionalParameters = ''
    netteSlug
      .match(optionalParametersGroupRegex)![0]
      .match(pathRegex)!
      .forEach((item) => {
        optionalParameters += repeatingOptionalParameterRegex.test(item)
          ? `/:${item.replace(repeatingOptionalParameterRegex, '*')}`
          : `/:${item}?`
      })
    netteSlug = netteSlug.replace(optionalParametersGroupRegex, optionalParameters)
  }

  do {
    netteSlug = netteSlug.replace(/</, ':').replace(/>/, '')
  } while (dynamicParametersGroupRegex.test(netteSlug))

  return netteSlug
}

export default convertNetteToVueSlug
