import type { RouteLocationNormalized, NavigationFailure } from 'vue-router'

import { isSpaRoute } from '@/router/isSpaRoute'
import { setDocumentTitle } from '@/services/documentHeadControl'
import { t } from '@/services/translations'

function afterEachHook(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  failure: NavigationFailure | undefined | void,
) {
  if (failure) {
    return
  }

  if (isSpaRoute(to, from)) {
    const hasPathChanged = from.path !== to.path
    hasPathChanged && setDocumentTitle(t('document.defaultTitle'))
  }
}

export default afterEachHook
