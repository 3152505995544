<script setup lang="ts">
import { ref } from 'vue'

import { FormText } from '@/components/Form'
import BaseButton from '@/componentsPure/BaseButton/BaseButton.vue'
import { t } from '@/services/translations'
import useProductDetailStore from '@/store/pinia/useProductDetailStore'

const newListName = ref('')

const { createShoppingList } = useProductDetailStore()

function create() {
  createShoppingList(newListName.value)
  newListName.value = ''
}
</script>

<template>
  <form
    class="text-center"
    @submit.prevent="create">
    <FormText
      v-model="newListName"
      autofocus
      :placeholder="t('product.shoppingList.inputPlaceholder')" />
    <BaseButton
      type="submit"
      variant="primary"
      class="mt-3">
      {{ t('product.shoppingList.send') }}
    </BaseButton>
  </form>
</template>
