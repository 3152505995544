import affiliate from './affiliate'
import anonym from './anonym'
import flags from './flags'
import merge from './merge'
import savings from './savings'
import updateFlags from './updateFlags'
import type { UpdateClientFlagsPathParam, UpdateClientFlagsRequestBody } from './updateFlags'

export default { savings, flags, updateFlags, merge, anonym, affiliate }

export type { UpdateClientFlagsPathParam, UpdateClientFlagsRequestBody }
