<script setup lang="ts">
import { vIntersectionObserver } from '@vueuse/components'

import ImgResponsive from '@/components/stories/atoms/imgResponsive/ImgResponsive.vue'
import type { Banner } from '@/data/api/frontApi/banners/bannerTypes'
import { analyticsLoggerBannerImpression } from '@/services/analytics/analyticsLogger'
import onIntersectionObserver from '@/services/analytics/gtm/utils/intersectingBanner'
import { resizer } from '@/services/resizer'
import { getMaxMediaByAlias } from '@/services/responsiveMedia'

type MainBannerProps = {
  banner: Banner
}

const props = defineProps<MainBannerProps>()
</script>

<!-- TODO href: Can't change to RouterLink as all the urls in admin are absolute -->
<template>
  <a
    v-intersection-observer="onIntersectionObserver('view_promotion', banner, 'homepageMain')"
    :href="props.banner.link"
    class="mb-3 d-block"
    @click="analyticsLoggerBannerImpression('select_promotion', banner, 'homepageMain')">
    <ImgResponsive
      class="d-block h-100 banner-img mx-auto"
      class-img="banner-img-border w-100"
      fetch-priority
      :srcset="[
        {
          url: resizer(props.banner.images.main, 1306, 260, 'SHRINK_ONLY'),
        },
        {
          url: resizer(props.banner.images.main, 2612, 520, 'SHRINK_ONLY'),
          pixelRatio: 1.5,
        },
      ]"
      :variants="[
        {
          media: getMaxMediaByAlias('tablet'),
          srcset: [
            { url: resizer(props.banner.images.tablet || '', 992, 260, 'SHRINK_ONLY') },
            {
              url: resizer(props.banner.images.tablet || '', 1984, 520, 'SHRINK_ONLY'),
              pixelRatio: 1.5,
            },
          ],
        },
      ]" />
  </a>
</template>

<style scoped>
:deep(.banner-img-border) {
  border-radius: 8px;
}
</style>
