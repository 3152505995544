import { createOperation } from '@/services/apiv2'

import type { StaticPageResponse } from './staticContentTypes'

type StaticPagePath = {
  url: string
}

const staticPage = createOperation<StaticPageResponse, StaticPageResponse, StaticPagePath>(
  '/static-page/:url',
  {
    method: 'get',
  },
)

export default staticPage
