import { SessionStorageKeys } from '@/constants/storageKeys'
import { storageRemoveSession, storageRetrieveSession } from '@/services/storage'

import type { Toast } from './toastPersist'
import { triggerToast } from './triggerToast'

/**
 * Clear toast session storage and trigger them.
 */
function toastPushUnfinished() {
  const toasts = storageRetrieveSession<Toast[]>(SessionStorageKeys.toast)
  if (!toasts) return
  storageRemoveSession(SessionStorageKeys.toast)

  toasts.forEach((toast) => {
    if (toast.isUnfinished) {
      const { type, content, hasLongerTimeout, onlyForVendorIds } = toast

      triggerToast({ type, content, hasLongerTimeout, onlyForVendorIds })
    }
  })
}

export default toastPushUnfinished
