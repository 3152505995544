<script setup lang="ts">
import { computed } from 'vue'

import { capitalize } from 'lodash-es'
import { storeToRefs } from 'pinia'

import type { DeliverySlotsGroupName } from '@/components/Delivery'
import getSlotForAnalytics from '@/components/Delivery/Timeslots/getSlotForAnalytics'
import Icon from '@/components/Icon/Icon.vue'
import useDeliveryData from '@/composables/transport/useDeliveryData'
import useFeatureFlag from '@/composables/useFeatureFlag'
import { analyticsLoggerSelectSlot } from '@/services/analytics/analyticsLogger'
import type { DeliveryData } from '@/services/analytics/gtm/selectSlot'
import appConfig from '@/services/appConfig'
import { t } from '@/services/translations'
import useCartStore from '@/store/pinia/useCartStore'
import type { TimeslotTime } from '@/store/pinia/useTimeslotsStore'
import { formatTime } from '@/utils/date'
import localizePrice from '@/utils/localizePrice'

import EcoLabel from './EcoLabel.vue'

type DeliverySlotProps = {
  groupName: DeliverySlotsGroupName
  timeslot: TimeslotTime
  tabular?: boolean // Tabular layout with heading-like time above price.
  compact?: boolean // Compact content. Has effect in tabular layout only.
  readonly?: boolean // Non-selectable presentational variant.
  multiline?: boolean // breaks time and price on two lines
  isInGrid?: boolean // Slot is in grid layout.
}

const props = defineProps<DeliverySlotProps>()

const { data: deliveryData } = useDeliveryData()
// only for cz
const ecoSlotFeature = useFeatureFlag('eco-slot', false) && appConfig.domain === 'cz'

const cartStore = useCartStore()
const { cartGetters } = storeToRefs(cartStore)

const getPrice = computed(() => {
  if (props.timeslot.closed) {
    return t('delivery.slotClosed')
  }
  if (props.timeslot.unavailable) {
    return t('delivery.slotUnavailable')
  }
  const MINIMUM_PRICE = 0.1
  // show float only when needed
  return props.timeslot.price > MINIMUM_PRICE
    ? localizePrice(props.timeslot.price, { minimumFractionDigits: 0, maximumFractionDigits: 2 })
    : capitalize(t('general.gratis'))
})

const getNameAttr = computed(() => `${props.groupName}-DeliverySlot`)
const getIdAttr = computed(() => `${getNameAttr.value}-${props.timeslot.id}`)
const getTime = computed(
  () => `${formatTime(props.timeslot.start)} - ${formatTime(props.timeslot.end)}`,
)

const isEcoPresented = computed(
  () =>
    props.timeslot.isEco &&
    !props.timeslot.closed &&
    (!props.timeslot.unavailable || props.readonly),
)
const isClosingSoonPresented = computed(
  () => props.timeslot.isClosingSoon && !props.timeslot.closed && !props.timeslot.unavailable,
)

const isSelectable = computed(
  () => !props.readonly && !props.timeslot.closed && !props.timeslot.unavailable,
)
const isHoverable = computed(() => isSelectable.value && !props.tabular)

const multilineLabels = computed(() => props.multiline && props.timeslot.level > 1)
// slots renders with headers in tabular layout
const isCompactTabular = computed(() => props.tabular && props.compact)

const featureFlagEco = computed(() => ecoSlotFeature && isEcoPresented.value)
const isDisabled = computed(() => props.timeslot.closed || props.timeslot.unavailable)

function sendSelectedSlotAnalytics() {
  const selectedSlot = getSlotForAnalytics(props.timeslot)

  const delivery: DeliveryData = {
    day: `d+${props.timeslot.level - 1}`,
    date: props.timeslot.start.format('YYYY-MM-DD'),
  }

  analyticsLoggerSelectSlot(selectedSlot, delivery)
}

function handleSelect() {
  const { id, start, end, price, isEco } = props.timeslot
  cartStore.cartActions.setSelectedTimeslot({ id, start, end, price, isEco })
  sendSelectedSlotAnalytics()
}
</script>

<template>
  <div
    data-tid="delivery-slot-level-1"
    :title="getPrice"
    @keydown.up.prevent=""
    @keydown.down.prevent="">
    <!-- Key events prevented in order to avoid accidentally selecting other slot instead of page scroll -->
    <input
      v-if="!props.readonly"
      :id="getIdAttr"
      :name="getNameAttr"
      :value="timeslot.id"
      :disabled="isDisabled"
      type="radio"
      data-tid="delivery-day-timeslot-radio"
      class="radio-input sr-only"
      :checked="timeslot.id === cartGetters.getSelectedTimeslot?.id"
      @input="handleSelect" />
    <div :class="['slot-container', { 'slot-container--eco': featureFlagEco && !props.tabular }]">
      <div
        :class="[
          'slot',
          {
            'slot--presentational': props.readonly,
            'slot--disabled': isDisabled,
            'slot--tabular': props.tabular,
            'slot--hoverable': isHoverable,
            'slot--selectable': isSelectable,
            'slot--multiline': multilineLabels,
          },
        ]">
        <label
          :for="getIdAttr"
          :class="[
            'time',
            {
              'time--presentational': props.readonly,
              'time--disabled': isDisabled,
              'time--cell': props.tabular,
              'time--selectable': isSelectable,
              'time--feature': ecoSlotFeature,
              'text-1': isCompactTabular,
            },
          ]"
          :data-tid="isSelectable ? 'delivery-slot' : null">
          {{ getTime }}
        </label>
        <EcoLabel
          v-if="featureFlagEco && !props.isInGrid"
          class="eco-label" />
        <div
          :class="[
            'price',
            {
              'price--eco': isEcoPresented,
              'justify-content-center overflow-hidden p-0': props.tabular,
              'price--multiline': multilineLabels,
            },
          ]">
          <div
            :class="[
              'd-flex align-items-center overflow-hidden',
              {
                'flex-column text-2': isCompactTabular,
              },
            ]">
            <div :class="isCompactTabular ? 'my-1' : 'mr-1 d-flex'">
              <Icon
                v-if="isClosingSoonPresented"
                :aria-label="t('delivery.closingSoon.title')"
                role="img"
                group="welcome"
                icon="clock"
                class="badge badge--clock" />
              <Icon
                v-if="isEcoPresented && (!ecoSlotFeature || props.isInGrid)"
                :aria-label="t('delivery.ecoTransport.title')"
                role="img"
                icon="eco-badge"
                :class="[
                  'badge',
                  {
                    'badge--eco-feature': props.isInGrid && ecoSlotFeature,
                  },
                ]" />
            </div>
            <strong
              v-if="!deliveryData?.forAnonymousCustomer"
              class="text-truncate">
              {{ getPrice }}
            </strong>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.slot-container {
  height: 100%;

  &--eco {
    padding-top: 4px;
    background: color('contrast-green', 0.7);
    border-radius: var(--borderRadiusSmall);

    .time {
      flex: 1;
      padding: 6px 12px 10px;
      text-align: left;
    }

    .price {
      &--eco {
        flex: 1;
      }
    }
  }

  .slot {
    @include make-font-scale(4);

    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    overflow: hidden;
    color: color('text-paragraph');
    background: color('tint-green');
    border-radius: var(--borderRadiusSmall);

    &--tabular {
      flex-direction: column;
      align-items: stretch;

      &::before {
        $size: 14px;

        top: 8px;
        left: 4px;
        width: $size;
        height: $size;
        transform: none;
      }
    }

    &--presentational {
      background: color('tint-green');
      box-shadow: inset 0 0 0 1px color('contrast-green', 0.4);

      &::before {
        display: none;
      }
    }

    &--disabled {
      color: color('neutrals-60');
      background: color('background-disabled');

      &::before {
        border: 0;
        opacity: 0.5;
      }
    }

    &--presentational#{&}--disabled {
      box-shadow: inset 0 0 0 1px color('gray-lighter');
    }

    &--hoverable:hover {
      background: color('light-green');

      &::before {
        border: 1px solid color('green');
      }
    }

    &--multiline {
      flex-direction: column;
      justify-content: center;
    }

    .radio-input:checked ~ & {
      &::before {
        border: 1px solid color('light-green');
      }

      &--hoverable {
        background: color('light-green');
      }

      &--tabular {
        box-shadow: inset 0 0 0 1px color('green');

        .time--feature {
          color: color('white');
          background: color('contrast-green');
        }
      }
    }
  }

  .eco-label {
    text-align: center;
  }

  .price {
    position: relative;
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: flex-end;
    padding-right: 12px;
    overflow: hidden;
    pointer-events: none;

    &--eco {
      color: color('contrast-green');
    }

    &--multiline {
      padding-right: 0;
    }

    .badge {
      $size: 20px;

      width: $size;
      height: $size;
      opacity: 0.8;

      &--eco-feature {
        $small-size: 13px;

        width: $small-size;
        height: $small-size;
        stroke-width: 1px;
      }

      &--clock {
        fill: color('blue');
      }
    }
  }

  .radio-input:checked + .slot {
    .badge--clock {
      fill: color('white');
    }
  }

  .time {
    flex-shrink: 0;
    padding: 10px 12px;

    &--cell {
      @include make-font-scale(2);

      padding: 8px;
      color: color('text-paragraph');
      background: color('green', 0.2);
    }

    &--cell#{&}--disabled {
      color: color('red') !important;
      background: color('gray-lighter');
    }

    &--selectable::after {
      position: absolute;
      top: 0;
      left: 0;
      z-index: $zix-base;
      width: 100%;
      height: 100%;
      cursor: pointer;
      content: '';
    }

    &--presentational {
      padding-left: 8px;
    }

    .radio-input:checked ~ .slot--selectable & {
      background: color('light-green');
    }
  }
}
</style>
