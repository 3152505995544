import type { Product } from '@/composables/useProductItem'

type TotalPriceOptions = {
  includeReturnables?: boolean
  includeDeposits?: boolean
  includeElicence?: boolean
}

/**
 * @param products Array of products from which we calculate total price.
 * @param calculationOptions
 * @returns Summarized total price.
 */
function calculateTotalPrice(products: readonly Product[], calculationOptions?: TotalPriceOptions) {
  const options = {
    includeReturnables: false,
    includeDeposits: false,
    includeElicence: true,
    ...calculationOptions,
  }

  const reducer = (totalPrice: number, product: Product) => {
    const productPrice = product.computed.price
    const productQuantity = product.computed.quantity
    const returnablePackagePrice = product.origin.returnablePackagePrice
    const returnableCarrierPrice = product.origin.returnableCarrier?.price ?? 0
    const returnableCarrierQuantity = product.cart.carrierQuantity ?? 0
    const weightedProductDeposit = product.origin.weightedProductDeposit ?? 0

    if (product.origin.eLicence && !options.includeElicence) {
      return totalPrice
    }

    return (
      totalPrice +
      productPrice * productQuantity +
      (options.includeReturnables
        ? returnablePackagePrice * productQuantity +
          returnableCarrierPrice * returnableCarrierQuantity
        : 0) +
      (options.includeDeposits ? weightedProductDeposit * productQuantity : 0)
    )
  }

  return products.reduce(reducer, 0)
}

export { calculateTotalPrice }
