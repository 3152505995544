<script setup lang="ts">
import { RouterLink } from 'vue-router'

import type { CategoryTreeItem } from '@/services/api/front/productTypes'
import { t } from '@/services/translations'

type CategoriesListProps = {
  categoryTree: CategoryTreeItem[]
}

const props = defineProps<CategoriesListProps>()
</script>

<template>
  <section class="px-lg-5 px-2">
    <div class="title-wrapper">
      <h4 class="title">{{ t('product.categoriesListTitle') }}</h4>
    </div>

    <ul class="list">
      <li
        v-for="category in props.categoryTree"
        :key="category.id"
        class="list-item">
        <RouterLink
          class="link"
          :to="category.url">
          {{ category.name }}
        </RouterLink>
      </li>
    </ul>
  </section>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.title-wrapper {
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;
  text-align: center;

  &::before,
  &::after {
    flex: 1;
    content: '';
    border-top: 1px solid color('gray-light');
  }
}

.title {
  @include make-font-scale(4);

  width: 50%;
  margin: 0;
  line-height: 1em;
  color: #898989;
  text-transform: uppercase;

  @include media-breakpoint-up(sm) {
    width: fit-content;
  }
}

.list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 0;
  list-style-type: none;
}

.link {
  @include make-font-scale(4);

  display: flex;
  gap: 8px;
  font-weight: 600;
  line-height: 22px;
  color: color('primary');
  text-decoration: underline;

  &::after {
    display: block;
    color: color('neutrals-40');
    content: '|';
  }
}

.list-item:last-of-type {
  .link::after {
    display: none;
  }
}
</style>
