import { createOperation } from '@/services/apiv2'

import type { FrontApiEmptyResponse } from '../frontApiTypes'
import type { ClientAffiliateRequest } from './clientTypes'

const affiliate = createOperation<
  FrontApiEmptyResponse,
  FrontApiEmptyResponse,
  undefined,
  ClientAffiliateRequest
>('/client/affiliate', {
  method: 'post',
})

export default affiliate
