import { createOperation } from '@/services/apiv2'

import type { BenjaminekTermsAndConditionsResponse } from './benjaminekTypes'

/**
 * Sends GET request for getting Benjaminek club's terms and conditions.
 * @see https://sasandboxstdhotapidocs.z1.web.core.windows.net/#/Benjaminek/readTermsAndConditions
 * @returns Promise of the API response with Benjaminek club's terms and conditions.
 */
const termsAndConditions = createOperation<BenjaminekTermsAndConditionsResponse>(
  '/benjaminek/terms-and-conditions',
  {
    method: 'get',
  },
)

export default termsAndConditions
