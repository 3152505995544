import type { Context } from '@growthbook/growthbook'

import appConfig from '../appConfig'
import { currentEnv } from '../environment'

const { prodKey, stageKey, eocKey, localKey, apiHost } = appConfig.growthbook

function getClientKey() {
  switch (currentEnv) {
    case 'production':
      return prodKey
    case 'stage':
      return stageKey
    case 'eoc':
      return eocKey
    default:
      return localKey
  }
}

const config: Context = {
  apiHost: apiHost,
  clientKey: getClientKey(),
  enableDevMode: ['localhost', 'eoc', 'stage'].includes(currentEnv ?? ''),
}

export default config
