import { fetchPost } from '@/services/api/fetch'
import appConfig from '@/services/appConfig'

const modulePath = 'shopping-list'
const actionShoppingList = 'shopping-list'

type CreateShoppingListResponse = {
  status: string // ok
  shoppingListId: number
}

function postShoppingList(name: string) {
  return fetchPost<CreateShoppingListResponse>(
    `${appConfig.webApiBaseUrl + modulePath}/${actionShoppingList}`,
    undefined,
    {
      name,
    },
  )
}

function postShoppingListAdd(shoppingListId: number, productId: number) {
  return fetchPost(
    `${appConfig.webApiBaseUrl + modulePath}/${actionShoppingList}/${shoppingListId}/add`,
    undefined,
    {
      productId,
    },
  )
}

function postShoppingListRemove(shoppingListId: number, productId: number) {
  return fetchPost(
    `${appConfig.webApiBaseUrl + modulePath}/${actionShoppingList}/${shoppingListId}/remove`,
    undefined,
    {
      productId,
    },
  )
}

export {
  postShoppingList as webApiPostShoppingList,
  postShoppingListAdd as webApiPostShoppingListAdd,
  postShoppingListRemove as webApiPostShoppingListRemove,
}
