import { useQuery } from '@tanstack/vue-query'

import { transportQueryKeys } from '@/constants/queryKeys'
import frontApi from '@/data/api/frontApi'

function useDeliveryData() {
  return useQuery({
    queryKey: transportQueryKeys.deliveryData(),
    queryFn: () => frontApi.transport.deliveryData(),
  })
}

export default useDeliveryData
