import { createOperation } from '@/services/apiv2'

import type { ApplePayEchoResponse } from './paymentTypes'

/**
 * @see https://sasandboxstdhotapidocs.z1.web.core.windows.net/#/Payment/readApplePayEcho
 */
const applePayEcho = createOperation<ApplePayEchoResponse>('payment/applepay/echo', {
  method: 'get',
})

export default applePayEcho
