<script setup lang="ts">
import type { FieldListItem } from '@/composables/useProductItem'
import { t } from '@/services/translations'

type ProductDetailFieldListProps = {
  items: FieldListItem[]
  inline?: boolean
}

const props = defineProps<ProductDetailFieldListProps>()

const isLink = (item: FieldListItem) => {
  return item.type === 'url'
}
const getTitle = (item: FieldListItem) => {
  return `${item.title}${props.inline ? ':' : ''}`
}
</script>

<template>
  <dl
    v-for="(item, index) in items"
    :key="index"
    class="text-4">
    <dt
      :class="[
        'font-weight-bold',
        {
          'd-inline': inline,
        },
      ]">
      {{ isLink(item) ? t('productDetail.downloads') : getTitle(item) }}
    </dt>
    <dd :class="inline ? 'd-inline' : 'mt-2 mb-3'">
      <a
        v-if="isLink(item)"
        class="a-link-underline"
        :href="item.value"
        target="_blank">
        {{ getTitle(item) }}
      </a>
      <span
        v-else
        v-html="item.value" />
    </dd>
  </dl>
</template>
