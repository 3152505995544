import type {
  DeliveryAddress,
  AddressesResponse,
  PostDeliveryAddressRequest,
  AddressSuggestResponse,
  AddressValidateResponse,
  PostBillingAddressRequest,
  PutDeliveryAddressRequest,
  PutBillingAddressRequest,
  BillingAddress,
} from '@/data/api/frontApi/address/addressTypes'
import type { ApiEmptyResponse } from '@/services/api/types'
import appConfig from '@/services/appConfig'

import { fetchGet, fetchPost, fetchPut, fetchDelete } from '../fetch'

const modulePath = 'address'

/**
 * Get all user addresses
 * @see https://sasandboxstdhotapidocs.z1.web.core.windows.net/#/Address/readUserAddress
 */
function getAddresses() {
  return fetchGet<AddressesResponse>(`${appConfig.frontApiBaseUrl}${modulePath}`)
}

/**
 * Creates new delivery address
 * @param address Delivery address to create
 * @see https://sasandboxstdhotapidocs.z1.web.core.windows.net/#/Address/createDeliveryAddress
 */
function postDeliveryAddress(address: PostDeliveryAddressRequest) {
  return fetchPost<DeliveryAddress>(
    `${appConfig.frontApiBaseUrl}${modulePath}/delivery`,
    undefined,
    address,
  )
}

/**
 * Get suggested addresses for passed in text
 * @param text Text for address suggestion
 * @see https://sasandboxstdhotapidocs.z1.web.core.windows.net/#/Address/readAddressSuggest
 */
function getAddressSuggest(text: string) {
  return fetchGet<AddressSuggestResponse>(`${appConfig.frontApiBaseUrl}${modulePath}/suggest`, {
    address: text,
    limit: 10,
  })
}

/**
 * Validates address
 * @param address Adddress to validate
 * @see https://sasandboxstdhotapidocs.z1.web.core.windows.net/#/Address/readAddressValidate
 */
function getAddressValidate(address: string) {
  return fetchGet<AddressValidateResponse>(`${appConfig.frontApiBaseUrl}${modulePath}/validate`, {
    address,
  })
}

/**
 * Creates new billing address
 * @param address Billing address to create
 * @see https://sasandboxstdhotapidocs.z1.web.core.windows.net/#/Address/createBillingAddress
 */
function postBillingAddress(address: PostBillingAddressRequest) {
  return fetchPost<BillingAddress>(
    `${appConfig.frontApiBaseUrl}${modulePath}/billing`,
    undefined,
    address,
  )
}

/**
 * Updates delivery address
 * @param address Updated address data
 * @param addressId Id of address to update
 * @see https://sasandboxstdhotapidocs.z1.web.core.windows.net/#/Address/updateDeliveryAddress
 */
function putDeliveryAddress(address: PutDeliveryAddressRequest, addressId: number) {
  return fetchPut<DeliveryAddress>(
    `${appConfig.frontApiBaseUrl}${modulePath}/delivery/${addressId}`,
    address,
  )
}

/**
 * Updates billing address
 * @param address Updated address data
 * @param addressId Id of address to update
 * @see https://sasandboxstdhotapidocs.z1.web.core.windows.net/#/Address/updateBillingAddress
 */
function putBillingAddress(address: PutBillingAddressRequest, addressId: number) {
  return fetchPut<BillingAddress>(
    `${appConfig.frontApiBaseUrl}${modulePath}/billing/${addressId}`,
    address,
  )
}

/**
 * Delete address
 * @param addressId Id of address to delete
 * @see https://sasandboxstdhotapidocs.z1.web.core.windows.net/#/Address/deleteAddress
 */
function deleteAddress(addressId: number) {
  return fetchDelete<ApiEmptyResponse>(`${appConfig.frontApiBaseUrl}${modulePath}/${addressId}`)
}

export {
  getAddresses as frontApiGetAddress,
  postDeliveryAddress as frontApiPostDeliveryAddress,
  getAddressSuggest as frontApiGetAddressSuggest,
  getAddressValidate as frontApiGetAddressValidate,
  postBillingAddress as frontApiPostBillingAddress,
  putDeliveryAddress as frontApiPutDeliveryAddress,
  putBillingAddress as frontApiPutBillingAddress,
  deleteAddress as frontApiDeleteAddress,
}

export type { DeliveryAddress }
