import type { PathParams } from './apiTypes'

/**
 * Replace path parameters in endpoint path with values from replacements object.
 * E.g. `/products/:productId` with `{ productId: 123 }` will become `/products/123`.
 * @param slug URL slug, e.g. `/products/:productId`
 * @param replacements Dynamic path parameters, that should be replace by another value, e.g. `{ productId: 123 }`
 */
function parseEndpointPath<TPath extends PathParams | undefined = undefined>(
  slug: string,
  replacements?: TPath,
) {
  if (!replacements) return slug

  // Find all segments with ":" prefix
  const segments = slug.match(/:\w+/g)

  if (!segments || Object.keys(replacements).length < segments.length) {
    throw new Error(`Not all path path parameters were provided for endpoint "${slug}".`)
  }

  // Replace each segment with its corresponding value from the replacements object
  let finalUrlPath = slug
  segments.forEach((segment) => {
    const segmentKey = segment.slice(1) // Remove ":"
    const replacement = replacements[segmentKey] || segment
    finalUrlPath = finalUrlPath.replace(segment, replacement.toString())
  })

  return finalUrlPath
}

export default parseEndpointPath
