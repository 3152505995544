import type { Ref } from 'vue'
import { watch } from 'vue'

import { useQuery } from '@tanstack/vue-query'
import { cloneDeep } from 'lodash-es'

import { productQueryKeys } from '@/constants/queryKeys'
import { frontApiProductGetVariants } from '@/services/api/front/product'
import useProductsStore from '@/store/pinia/useProductsStore'

const staleTime = 1000 * 5

function useProductVariants(productId: Ref<number>, associationCode: Ref<string>) {
  const { setProducts } = useProductsStore()

  const { data } = useQuery({
    queryKey: productQueryKeys.variants(productId, associationCode),
    queryFn: () => frontApiProductGetVariants(productId.value, associationCode.value),
    select: (responseObject) => responseObject.data.productVariants,
    staleTime,
  })

  watch(data, () => {
    // data is readonly, so when removeNullDetailFromProducts function in setProducts mutates the object, it throws an error
    const newData = cloneDeep(data.value) ?? []
    setProducts(newData)
  })

  return data
}

export default useProductVariants
