import type { TYPE } from 'vue-toastification'
import type { ToastID } from 'vue-toastification/dist/types/types'

import { SessionStorageKeys } from '@/constants/storageKeys'
import { storagePersistSession, storageRetrieveSession } from '@/services/storage'

import { MAX_TOASTS } from './config'

type Toast = {
  /**
   * Internal id of the toast, used for dismissing the toast.
   */
  toastId?: ToastID
  /**
   * One of the toast types.
   */
  type: TYPE
  /**
   * Content to show in the toast.
   */
  content: string
  /**
   * Time when the toast was triggered.
   */
  triggerTime: number
  /**
   * Display toast for longer time.
   */
  hasLongerTimeout: boolean
  /**
   * Indicates that the toast was triggered but not read by user yet.
   */
  isUnfinished?: boolean
  /**
   * ID of the BE-fired flash message. Has a value of UUID.
   */
  flashMessageId?: string
  /**
   * For vendor specific toasts, mark on which ids this should show on.
   */
  onlyForVendorIds?: [number]
}

/**
 * Saves toast to session storage.
 */
const toastPersist = (toast: Toast) => {
  const toasts = storageRetrieveSession<Toast[]>(SessionStorageKeys.toast) ?? []

  if (toasts.length >= MAX_TOASTS) {
    toasts.shift()
  }

  toasts.push(toast)
  storagePersistSession(SessionStorageKeys.toast, toasts)
}

export default toastPersist
export type { Toast }
