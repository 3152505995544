<script setup lang="ts">
import { computed, ref } from 'vue'

import dayjs from 'dayjs'

import Icon from '@/components/Icon/Icon.vue'
import { SMILES } from '@/components/OrderRating/constants'
import useUser from '@/composables/useUser'
import {
  frontApiRatingGetLastOrder,
  frontApiRatingPutDismissLastOrder,
} from '@/services/api/front/rating'
import type { LastOrderRatingResponse } from '@/services/api/front/ratingTypes'
import type { ApiEmptyResponse } from '@/services/api/types'
import { t } from '@/services/translations'
import useDeviceDetectorStore from '@/store/pinia/useDeviceDetectorStore'
import { formatDate } from '@/utils/date'

const deviceDetectorStore = useDeviceDetectorStore()
const { isUserSignedIn } = useUser()

const lastOrderRating = ref<LastOrderRatingResponse>()
const isLastOrderRatingShown = ref(true)

function isOrderRating(
  orderRating: LastOrderRatingResponse | ApiEmptyResponse,
): orderRating is LastOrderRatingResponse {
  return !Object.hasOwn(orderRating, 'status')
}

async function fetchLastOrder() {
  if (!isUserSignedIn) return

  const { data } = await frontApiRatingGetLastOrder()
  if (!isOrderRating(data)) return

  lastOrderRating.value = { ...data }
}
fetchLastOrder()

const getOrderCreatedDate = computed(() => {
  if (!lastOrderRating.value) {
    return ''
  }
  return formatDate(dayjs(lastOrderRating.value.created), { showYear: true, noWrap: true })
})

async function dismissLastOrderRating() {
  await frontApiRatingPutDismissLastOrder()

  isLastOrderRatingShown.value = false
}
</script>

<template>
  <div
    v-if="lastOrderRating && isLastOrderRatingShown"
    class="container my-2">
    <div class="wrapper">
      <h3 class="rating-title text-5 font-weight-bold my-2 mt-lg-3">
        {{ t('orderRating.homepage.title') }}
      </h3>
      <p
        class="rating-description text-3 m-2"
        v-html="t('orderRating.homepage.description', { date: getOrderCreatedDate })" />

      <div class="d-flex justify-content-center my-2">
        <RouterLink
          v-for="(smile, index) in SMILES"
          :key="smile.rating"
          :to="{
            name: 'OrderRating',
            params: { token: lastOrderRating.token, rating: smile.rating },
          }"
          :class="[
            `smile-btn d-flex align-items-center text-${smile.color}`,
            {
              'ml-3': index > 0,
            },
          ]"
          :data-tid="`order-rating-homepage-${smile.rating}`">
          <Icon
            class="smile-icon flex-shrink-0 mr-1"
            group="emoji"
            :icon="smile.iconName"
            :title="lastOrderRating.orderRatings[index].description" />
          <p class="text-4 m-0">
            {{ lastOrderRating.orderRatings[index].description }}
          </p>
        </RouterLink>
      </div>

      <template v-if="lastOrderRating.canBeComplained">
        <RouterLink
          :to="{ name: 'NewComplaint', params: { orderNumber: lastOrderRating.orderNumber } }"
          class="text-3 text-gray-dark a-link-underline d-inline-block mb-2"
          data-tid="complaint-button">
          {{ t('orderRating.makeComplaint') }}
        </RouterLink>
      </template>

      <button
        class="close-btn"
        data-tid="order-rating-close-button"
        @click="dismissLastOrderRating">
        <Icon
          icon="cross"
          class="close-icon" />
        <span
          v-if="deviceDetectorStore.breakpointUpLg"
          class="close-label"
          >{{ t('general.close') }}</span
        >
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.wrapper {
  position: relative;
  color: color('black');
  text-align: center;
  border: 1px solid color('gray-light');
  border-radius: var(--borderRadiusSmall);
}

.close {
  &-btn {
    position: absolute;
    top: 6px;
    right: 6px;
    display: flex;
    align-items: center;
    padding: 0;
    color: color('black');
    background: none;
    border: none;

    @include media-breakpoint-up(lg) {
      top: auto;
      right: 18px;
      bottom: 8px;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &-icon {
    width: 24px;
    height: 24px;
    margin-right: 4px;
    color: color('gray-darker');
  }

  &-label {
    @include make-font-scale(2);

    padding-top: 4px;
  }
}

.smile {
  &-btn {
    &:focus {
      text-decoration: none;
    }
  }

  &-icon {
    $size: 24px;

    width: $size;
    height: $size;

    @include media-breakpoint-up(sm) {
      $size: 36px;

      width: $size;
      height: $size;
    }
  }
}

.rating {
  &-title {
    padding: 0 40px;
  }

  &-description {
    line-height: 1.4;
  }
}
</style>
