const SMILES = [
  {
    iconName: 'positive',
    color: 'green',
    rating: 1,
  },
  {
    iconName: 'neutral',
    color: 'orange',
    rating: 2,
  },
  {
    iconName: 'negative',
    color: 'contrast-red',
    rating: 3,
  },
] as const

export { SMILES }
