import type { DeliveryWindowSlotData } from './deliveryWindow'
import { dataLayerPush } from './utils'

type DeliveryData = {
  day: string // d+0, d+1, d+2, ...
  date: string // YYYY-MM-DD
}

function selectSlot(selectedSlotData: DeliveryWindowSlotData, deliveryData: DeliveryData) {
  dataLayerPush({
    event: 'select_slot',
    selected_slot: selectedSlotData,
    delivery: deliveryData,
  })
}

export { selectSlot as gtmEventSelectSlot }

export type { DeliveryData }
