import { createOperation } from '@/services/apiv2'

import { modulePath, type NotificationsCountParams } from './notificationsTypes'

type NotificationsCountData = {
  count: number
}

const notificationsCount = createOperation<
  NotificationsCountData,
  number,
  undefined,
  undefined,
  NotificationsCountParams
>(`${modulePath}/count`, {
  method: 'get',
  responseConverter: (data) => data.count,
})

export default notificationsCount
