<script setup lang="ts">
import { BaseButton } from '@/componentsPure'
import { t } from '@/services/translations'

type DeliveryOnLocationMapProps = {
  submitDisabled: boolean
}

const props = defineProps<DeliveryOnLocationMapProps>()
const emit = defineEmits<{
  save: []
  cancel: []
}>()
</script>

<template>
  <div class="button-container">
    <BaseButton
      :disabled="props.submitDisabled"
      :variant="props.submitDisabled ? 'gray-dark' : 'primary'"
      class="mr-2"
      :class="{
        'save-btn--disabled': submitDisabled,
      }"
      @click.prevent="emit('save')">
      {{ t('delivery.deliveryLocation.saveButton') }}
    </BaseButton>
    <BaseButton
      variant="neutral-light"
      class="btn"
      @click.prevent="emit('cancel')">
      {{ t('delivery.deliveryLocation.cancelButton') }}
    </BaseButton>
  </div>
</template>

<style scoped lang="scss">
.button-container {
  padding: 16px 12px;

  .save-btn--disabled {
    opacity: 0.2 !important;
  }
}
</style>
