// @TODO Remove dayjs.ConfigType everywhere and use pure dayjs instead.
import dayjs from 'dayjs'

import config from '@/services/appConfig'

/**
 * Creates object describing given date for GUI purposes.
 * @param dateString Date represented by a string.
 * @param lang Language to format locale string of given date. If not set, default language from app configuration is used.
 * @returns Data about given day.
 * @TODO Refactor to use dayjs i18n capabilities.
 * @deprecated Use pure dayjs instead.
 */
const getDateDetailed = (
  dateString: dayjs.ConfigType,
  lang: string = config.defaultLang,
): { date: string; dayPart: string } => {
  const date = dayjs(dateString).toDate()

  return {
    date: date.toLocaleString(lang, { day: 'numeric', month: 'numeric' }),
    dayPart: date.getHours() < 12 ? 'forenoon' : 'afternoon',
  }
}

export { getDateDetailed }
