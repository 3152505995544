import type { RouteLocationNormalized } from 'vue-router'

import { isSpaRoute } from '@/router/isSpaRoute'
import { setMetaTagsForRobots } from '@/services/documentHeadControl'
import useUserInterfaceStore from '@/store/pinia/useUserInterfaceStore'

// we need to store previousUrl in a global variable to use it in ga analytics
let previousUrl: string = window.document.referrer

function setPreviousUrl(url: string) {
  previousUrl = url
}

function beforeEachHook(to: RouteLocationNormalized, from: RouteLocationNormalized) {
  setMetaTagsForRobots(to)

  // Closes PreviewCart on selected routes
  if (to.meta.closePreviewCart) {
    const userInterfaceStore = useUserInterfaceStore()
    userInterfaceStore.setCartPreviewOpenDesktop(false)
  }

  // Resolve hook when it's called on no path or same path. To avoid infinite redirect.
  // This is called for refreshing
  if (!from.name || from.path === to.path) {
    return
  }

  setPreviousUrl(new URL(window.location.href).origin + from.fullPath)

  // Prevents page reload for SPA routes.
  if (isSpaRoute(to, from)) {
    return
  }

  // Resolve hook when target route is product page.
  if (to.name === 'PageProductDetail') {
    return
  }

  window.location.href = to.fullPath
  return false
}

export default beforeEachHook
export { setPreviousUrl, previousUrl }
