<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useRoute } from 'vue-router'

import DeliveryOnLocationMap from '@/components/DeliveryOnLocationMap'
import Icon from '@/components/Icon/Icon.vue'
import { BaseTag } from '@/componentsPure'
import { useAddressPrelaunchValidate } from '@/composables/address'
import type { GPS, ValidatePhase } from '@/data/api/frontApi/address/addressTypes'
import { analyticsLoggerAddressSelector } from '@/services/analytics/analyticsLogger'
import { t } from '@/services/translations'
import type { UserAddress } from '@/store/pinia/useAddressStore'

import { type AddressInputBoxSuggestInputProps } from './AddressInputBoxSuggestInput.vue'
import AddressSuggestInput from './AddressInputBoxSuggestInput.vue'

type AddressInputBoxProps = AddressInputBoxSuggestInputProps & {
  showMap?: boolean
  editableMap?: boolean
  showStatusMessages?: boolean
  isSuccess?: boolean
  prefiled?: boolean
}

const props = defineProps<AddressInputBoxProps>()
const addressModel = defineModel<UserAddress>({ required: true })
const emit = defineEmits<{
  addressValidated: [phase: ValidatePhase]
}>()

const validateInputValue = ref('')
const isAddressAcceptable = computed(
  () =>
    props.acceptPartialAddress || (!props.acceptPartialAddress && addressModel.value.wholeAddress),
)
const { data: addressValidationResponse } = useAddressPrelaunchValidate(
  validateInputValue,
  !props.acceptPartialAddress,
)

const route = useRoute()
const isPrelaunchPage = computed(() => route.name === 'PagePreLaunchLandingPage')

const reachable = computed(() => addressValidationResponse.value?.phase === 'available')
const addressValidated = computed(() => !!addressValidationResponse.value)
const shouldRenderLinkToPrelaunchPage = computed(
  () =>
    !isPrelaunchPage.value &&
    !reachable.value &&
    addressValidationResponse.value?.prelaunchPageEnabled,
)
const addressErrorMessage = computed(() => {
  if (!isAddressAcceptable.value) {
    return t('addressSelector.addressError')
  } else if (addressValidationResponse.value?.prelaunchPageEnabled) {
    return t(`addressInputBox.${addressValidationResponse.value.phase}`)
  } else if (reachable.value) {
    return t('addressInputBox.available')
  } else {
    return t('addressInputBox.notAvailable')
  }
})

const currentGps = computed<GPS | null>(() => {
  if (addressModel.value.positionGps?.lat && addressModel.value.positionGps?.lng) {
    return {
      lat: addressModel.value.positionGps.lat,
      lng: addressModel.value.positionGps.lng,
    }
  }

  return addressModel.value.gps
})

const canValidateGps = computed(() => {
  return (
    (validateInputValue.value === addressModel.value.address && addressValidated.value) ||
    props.prefiled
  )
})

watch(addressValidationResponse, () => {
  if (addressValidationResponse.value === undefined) {
    return
  }

  addressModel.value.gps = addressValidationResponse.value.gps

  emit('addressValidated', addressValidationResponse.value.phase)

  const isWholeAddress = addressModel.value.wholeAddress

  if (addressValidationResponse.value.phase === 'available') {
    analyticsLoggerAddressSelector(
      'address_found_delivering',
      isWholeAddress ? undefined : addressModel.value.city,
      isWholeAddress ? 'whole_address' : 'unspecific_address',
    )
  } else {
    analyticsLoggerAddressSelector('address_found_not_delivering')
  }
})

function validateAddress() {
  validateInputValue.value = addressModel.value.address
}

function updateGPS(positionGps: GPS | null) {
  addressModel.value.positionGps = positionGps ?? undefined
}

if (route.query.validate) {
  validateAddress()
}
</script>

<template>
  <div>
    <div class="address-input-box__form">
      <div class="address-input-box__input-wrapper">
        <Suspense>
          <AddressSuggestInput
            v-model="addressModel"
            :accept-partial-address="props.acceptPartialAddress"
            :autofocus="props.autofocus"
            :show-user-addresses="props.showUserAddresses"
            @address-changed="validateAddress" />
        </Suspense>

        <BaseTag
          v-if="props.showStatusMessages && addressValidated"
          :modifier="isSuccess || reachable ? 'strong green' : 'strong red'"
          size="big"
          class="tag-box">
          <Icon
            :icon="isSuccess || reachable ? 'success' : 'error'"
            group="notification"
            class="flash-icon" />
          <span class="tag-box__message">
            <slot name="status-message">
              {{ addressErrorMessage }}
              <RouterLink
                v-if="shouldRenderLinkToPrelaunchPage"
                :to="{
                  name: 'PagePreLaunchLandingPage',
                  query: { validate: addressModel.address },
                }"
                class="tag-box__link">
                {{ t('addressInputBox.link') }}
                <Icon icon="arrow-button" />
              </RouterLink>
            </slot>
          </span>
        </BaseTag>
      </div>

      <div
        v-if="$slots.submit"
        class="address-input-box__submit">
        <slot name="submit" />
      </div>
    </div>

    <DeliveryOnLocationMap
      v-if="props.showMap"
      :current-gps="currentGps"
      :address-gps="addressModel.gps"
      :editable="props.editableMap"
      :has-polygons="!props.editableMap"
      :address="addressModel.address"
      :street="addressModel.street"
      :zip="addressModel.zip"
      :can-validate-gps="canValidateGps"
      :class="{
        'mt-5': !props.editableMap,
      }"
      @save="updateGPS" />
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.address-input-box {
  &__form {
    display: flex;
    flex-flow: row wrap;
  }

  &__input-wrapper {
    width: 100%;
  }

  &__submit {
    flex-shrink: 0;
    align-self: flex-start;

    @include media-breakpoint-up(md) {
      margin: 0 0.5rem;
    }
  }
}

.tag-box {
  padding-block: 5px;
  margin-bottom: 10px;
  white-space: normal;

  &__message {
    margin-left: 0.5rem;
    font-size: 14px;
  }

  &__link {
    display: inline-flex;
    gap: 4px;
    align-items: center;
    color: inherit;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.flash-icon {
  flex-shrink: 0;
}
</style>
