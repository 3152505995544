<script setup lang="ts">
import { defineAsyncComponent } from 'vue'

import { BaseButton } from '@/componentsPure'
import type { GPS } from '@/data/api/frontApi/address/addressTypes'
import { t } from '@/services/translations'
import useDeviceDetectorStore from '@/store/pinia/useDeviceDetectorStore'

import LocationMapInfo from './LocationMapInfo.vue'

const LocationMapEdit = defineAsyncComponent(
  () => import('@/componentsPure/LocationMap/LocationMapEdit.vue'),
)

type DeliveryOnLocationMapProps = {
  gps: GPS | null
  locationError: boolean
  edit: boolean
  onMapMove: () => void
}
const props = defineProps<DeliveryOnLocationMapProps>()
const emit = defineEmits<{
  'map-changed': [newValue: GPS]
  'reset-location': []
  'cancel-edit': []
}>()

const deviceDetectorStore = useDeviceDetectorStore()

function onMapChanged(newLocation: GPS) {
  emit('map-changed', newLocation)
}
</script>

<template>
  <div class="location-map-edit-wrapper">
    <div
      class="location-map-edit-wrapper__edit-buttons"
      data-tid="location-map-btn">
      <BaseButton
        v-if="props.edit"
        variant="tertiary"
        size="xs"
        class="delivery-map-reset-btn"
        @click.prevent="emit('reset-location')">
        {{ t('delivery.deliveryLocation.resetButton') }}
      </BaseButton>
      <slot
        v-else
        name="edit-btn" />
    </div>
    <div
      v-if="edit"
      class="delivery-map-info">
      <LocationMapInfo
        v-if="props.locationError"
        class="location-map-message" />
      <slot name="info-append" />
      <!--for mobile save btn-->
    </div>
    <LocationMapEdit
      v-if="props.gps"
      :location="props.gps"
      :edit="edit"
      :locked="!edit"
      :hide-zoom="deviceDetectorStore.breakpointDownMd"
      @map-changed="onMapChanged"
      @map-move="props.onMapMove" />
  </div>
</template>

<style scoped lang="scss">
@use '@/legacy' as *;

.location-map-edit-wrapper {
  position: relative;
  width: 100%;
  background: color('gray-lighter');

  &__edit-buttons {
    position: absolute;
    top: 10px;
    right: 12px;
    z-index: $zix-map__controls;
  }

  .delivery-map-info {
    position: absolute;
    bottom: 0;
    z-index: $zix-map__controls;
    display: flex;
    flex-direction: column;
    width: 100%;

    @include media-breakpoint-up(md) {
      align-items: start;
      width: unset;
    }

    .location-map-message {
      margin: 8px 4px;

      @include media-breakpoint-up(md) {
        margin-bottom: 6px;
        margin-left: 16px;
      }
    }
  }
}
</style>
