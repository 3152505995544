<script setup>
import { computed, ref } from 'vue'

import {
  calculateCashbackPerMonth,
  calculateCashbackPerYear,
  calculateDeliverySavingsPerMonth,
  calculateDeliverySavingsPerYear,
  calculateFreeOrdersCountPerYear,
  calculateMonthsCountWithFreeNappiesPerYear,
} from '@/components/Clubs/Benjaminek/calculations'
import ClubSectionTitle from '@/components/Clubs/ClubSectionTitle/ClubSectionTitle.vue'
import Icon from '@/components/Icon/Icon.vue'
import InputRange from '@/components/global/InputRange.vue'
import { BaseButton, BaseTag } from '@/componentsPure'
import { t, tc } from '@/services/translations'
import localizePrice from '@/utils/localizePrice'

const ORDERS_COUNT_INPUT_MIN = 1
const ORDERS_COUNT_INPUT_MAX = 50
const ORDERS_COUNT_INPUT_STEP = 1
const SPENDING_INPUT_MIN = 0
const SPENDING_INPUT_MAX = 60000
const SPENDING_INPUT_STEP = 500
const SPENDING_RANGE_VALUES = [2000, 5000, 7000]

const ordersCountPerMonth = ref(8)
const ordersCountPerMonthFormatted = computed({
  get() {
    return ordersCountPerMonth.value
  },
  set(newValue) {
    ordersCountPerMonth.value = Math.abs(parseInt(newValue)) || null
  },
})
const spendingPerMonth = ref(12000)
const spendingPerMonthFormatted = computed({
  get() {
    return localizePrice(spendingPerMonth.value, { maximumFractionDigits: 0, style: 'decimal' })
  },
  set(newValue) {
    const newValueAsNumber = parseInt(
      newValue.replace(/\D*/g, '').slice(0, SPENDING_INPUT_MAX.toString().length),
    )
    const newValueInRanges = Math.min(newValueAsNumber, SPENDING_INPUT_MAX) || SPENDING_INPUT_MIN
    // eslint-disable-next-line no-new-wrappers -- new operator always forces recompute getter and fix output in input
    spendingPerMonth.value = Number(newValueInRanges)
  },
})

const incrementSpendingPerMonth = () => {
  if (spendingPerMonth.value > SPENDING_INPUT_MAX) {
    return
  }

  if (spendingPerMonth.value + SPENDING_INPUT_STEP >= SPENDING_INPUT_MAX) {
    spendingPerMonth.value = SPENDING_INPUT_MAX
  } else {
    spendingPerMonth.value += SPENDING_INPUT_STEP
  }
}
const decrementSpendingPerMonth = () => {
  if (spendingPerMonth.value < SPENDING_INPUT_MIN) {
    return
  }

  if (spendingPerMonth.value - SPENDING_INPUT_STEP <= SPENDING_INPUT_MIN) {
    spendingPerMonth.value = SPENDING_INPUT_MIN
  } else {
    spendingPerMonth.value -= SPENDING_INPUT_STEP
  }
}
const setSpendingPerMonth = (value) => {
  spendingPerMonth.value = value
}

const calculatedValues = ref({})
const makeCalculations = () => {
  calculatedValues.value = {
    deliverySavingsPerMonth: calculateDeliverySavingsPerMonth(ordersCountPerMonth.value),
    deliverySavingsPerYear: calculateDeliverySavingsPerYear(ordersCountPerMonth.value),
    cashbackPerMonth: calculateCashbackPerMonth(spendingPerMonth.value),
    cashbackPerYear: calculateCashbackPerYear(spendingPerMonth.value),
    freeOrdersCountPerYear: calculateFreeOrdersCountPerYear(spendingPerMonth.value),
    monthsCountWithFreeNappiesPerYear: calculateMonthsCountWithFreeNappiesPerYear(
      spendingPerMonth.value,
    ),
  }
}
makeCalculations()
</script>

<template>
  <div class="text-center">
    <ClubSectionTitle :title="t('kidsClub.calculator.title')" />
    <div class="row m-0">
      <div class="input-block col-12 col-sm-6 px-2 pl-lg-3 pr-lg-4">
        <p
          class="text-gray-darker font-weight-bold text-8 m-0 py-3 pt-lg-4 px-2"
          v-text="t('kidsClub.calculator.ordersAmount')" />
        <input
          v-model="ordersCountPerMonthFormatted"
          type="number"
          class="calculator-input text-gray-dark text-3 mb-3 px-2 w-100"
          autocomplete="off"
          :min="ORDERS_COUNT_INPUT_MIN"
          :max="ORDERS_COUNT_INPUT_MAX"
          :step="ORDERS_COUNT_INPUT_STEP" />
        <div class="bg-primary-lighter mb-4 mb-md-5 rounded">
          <p
            class="text-primary font-weight-bold text-6 m-0 px-4 pt-2 pt-md-3 rounded"
            v-text="t('kidsClub.calculator.ordersSpending')" />
          <div class="d-flex align-items-center justify-content-center my-3 px-3">
            <span
              class="calculator-button"
              @click="decrementSpendingPerMonth">
              <Icon
                icon="minus"
                class="calculator-button__icon" />
            </span>
            <div class="px-2 px-xl-4 position-relative">
              <input
                v-model="spendingPerMonthFormatted"
                type="text"
                class="calculator-input calculator-input--text text-gray-darker font-weight-bold text-6 text-center w-100"
                autocomplete="off" />
              <span
                class="currency font-weight-bold text-6 text-center"
                :data-currency="t('general.currency')"
                v-text="spendingPerMonthFormatted" />
            </div>
            <span
              class="calculator-button"
              @click="incrementSpendingPerMonth">
              <Icon
                icon="plus"
                class="calculator-button__icon" />
            </span>
          </div>
          <div class="px-4">
            <InputRange
              v-model="spendingPerMonth"
              :min="SPENDING_INPUT_MIN"
              :max="SPENDING_INPUT_MAX"
              :step="SPENDING_INPUT_STEP">
              <div class="input-range-background" />
            </InputRange>
          </div>
          <div class="d-flex flex-wrap justify-content-around my-3 px-3 px-xl-5">
            <BaseTag
              v-for="(spendingValue, index) in SPENDING_RANGE_VALUES"
              :key="index"
              modifier="border red"
              size="big"
              class="input-tag text-primary font-weight-bold text-2 mb-2"
              @click="setSpendingPerMonth(spendingValue)"
              v-text="localizePrice(spendingValue, { maximumFractionDigits: 0 })" />
          </div>
          <BaseButton
            variant="primary"
            size="sm"
            class="mb-3"
            @click="makeCalculations"
            v-text="t('kidsClub.calculator.calculateSavings')" />
        </div>
      </div>
      <div class="output-block col-12 col-sm-6 px-2 pl-lg-4 pr-lg-3">
        <p
          class="text-gray-darker font-weight-bold text-8 m-0 py-3 pt-lg-4 px-2"
          v-text="t('kidsClub.calculator.savings.title')" />
        <div class="bg-white mb-2 rounded">
          <p
            class="output-title text-primary m-0 pt-3"
            v-text="t('kidsClub.calculator.savings.delivery')" />
          <div class="row pt-2 pb-3">
            <span class="col">
              <p
                class="output-subtitle"
                v-text="t('kidsClub.calculator.savings.perMonth')" />
              <p
                class="output-value"
                v-text="
                  localizePrice(calculatedValues.deliverySavingsPerMonth, {
                    maximumFractionDigits: 0,
                  })
                " />
            </span>
            <span class="col">
              <p
                class="output-subtitle"
                v-text="t('kidsClub.calculator.savings.perYear')" />
              <p
                class="output-value"
                v-text="
                  localizePrice(calculatedValues.deliverySavingsPerYear, {
                    maximumFractionDigits: 0,
                  })
                " />
            </span>
          </div>
        </div>
        <div class="bg-white mb-2 rounded">
          <p
            class="output-title text-primary m-0 pt-3"
            v-text="t('kidsClub.calculator.savings.cashback')" />
          <div class="row pt-2 pb-3">
            <span class="col">
              <p
                class="output-subtitle"
                v-text="t('kidsClub.calculator.savings.perMonth')" />
              <p
                class="output-value"
                v-text="
                  localizePrice(calculatedValues.cashbackPerMonth, { maximumFractionDigits: 0 })
                " />
            </span>
            <span class="col">
              <p
                class="output-subtitle"
                v-text="t('kidsClub.calculator.savings.perYear')" />
              <p
                class="output-value"
                v-text="
                  localizePrice(calculatedValues.cashbackPerYear, { maximumFractionDigits: 0 })
                " />
            </span>
          </div>
        </div>
        <div class="bg-white mb-4 mb-md-5 rounded border border-primary-light">
          <p
            class="output-title text-gray-darker m-0 mb-2 pt-3 px-2"
            v-text="t('kidsClub.calculator.savings.canBuy')" />
          <div class="output-row">
            <Icon
              icon="cart"
              class="calculator-icon" />
            <span
              class="text-primary text-5"
              v-html="
                t('kidsClub.calculator.savings.freeOrdersCount', {
                  freeOrdersCount: calculatedValues.freeOrdersCountPerYear,
                })
              " />
          </div>
          <div class="output-row">
            <Icon
              icon="positive"
              group="clubs"
              class="calculator-icon" />
            <span
              class="text-primary text-5"
              v-html="
                tc(
                  'kidsClub.calculator.savings.monthsCountWithFreeNappies',
                  calculatedValues.monthsCountWithFreeNappiesPerYear,
                  {
                    monthsCountWithFreeNappies: calculatedValues.monthsCountWithFreeNappiesPerYear,
                  },
                )
              " />
          </div>
        </div>
      </div>
    </div>
    <div class="p-2 text-gray text-3 m-0">
      <small
        class="calculator-footer"
        data-asterisks="*"
        v-text="t('kidsClub.calculator.footer.delivery')" />
      <small
        class="calculator-footer"
        data-asterisks="**"
        v-text="t('kidsClub.calculator.footer.cashback')" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

$border-radius: 6px;

.calculator {
  &-input {
    height: 44px;
    background-color: color('white');
    border: 1px solid color('gray-light');
    border-radius: var(--borderRadiusSmall);

    &--text {
      max-width: 170px;
    }
  }

  &-icon {
    $size: 23px;

    flex-shrink: 0;
    width: $size;
    height: $size;
    margin-right: 18px;
    color: color('primary');
  }

  &-footer {
    @include make-font-scale(3);

    display: block;
    line-height: 16px;

    &::before {
      margin-right: 4px;
      content: attr(data-asterisks);
    }
  }

  &-button {
    $size: 35px;

    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: $size;
    height: $size;
    background-color: color('primary');
    border-radius: var(--borderRadiusSmall);

    &__icon {
      $size: 17px;

      width: $size;
      height: $size;
      color: color('white');
    }
  }
}

.currency {
  position: absolute;
  top: 50%;
  left: 50%;
  color: transparent;
  pointer-events: none;
  transform: translate(-50%, -50%);

  &::after {
    position: absolute;
    top: -1px;
    left: calc(100% + 5px);
    color: color('gray-darker');
    content: attr(data-currency);
  }
}

.input {
  &-block {
    background-color: color('gray-lighter', 0.4);
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }

  &-range-background {
    height: 100%;
    background: linear-gradient(to right, color('white') 50%, color('green-light'));
    border-radius: inherit;
  }

  &-tag {
    line-height: 16px;
  }
}

.output {
  &-block {
    background-color: color('gray-lighter', 0.8);
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }

  &-row {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 30px 14px;
  }

  &-title {
    @include make-font-scale(5);

    font-weight: var(--fontWeightBold);
    line-height: 20px;
  }

  &-subtitle {
    padding-bottom: 10px;
    margin: 0;
    font-size: 13px;
    font-weight: var(--fontWeightBold);
    color: color('primary');
  }

  &-value {
    margin: 0;
    font-size: 16px;
    font-weight: var(--fontWeightBold);
    color: color('gray-darker');
  }
}
</style>
