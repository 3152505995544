import type { AuthError } from '@azure/msal-browser'

// During catch block processing we cannot directly use AuthError class
// and "instanceof" check to preserve asynchronous nature of MSAL package load.
function isMsalError(error: unknown): error is AuthError {
  return (
    typeof error === 'object' &&
    error !== null &&
    Object.hasOwn(error, 'errorCode') &&
    Object.hasOwn(error, 'errorMessage')
  )
}

export default isMsalError
