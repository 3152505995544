<script setup lang="ts">
import { computed } from 'vue'

import dayjs from 'dayjs'

import { GiftsClubWrapper } from '@/componentsPure'
import GiftsClubRibbon from '@/componentsPure/GiftsClubRibbon'
import { t } from '@/services/translations'
import { formatDate, isFuture } from '@/utils/date'

import VoucherConditionsModal from './VoucherConditionsModal.vue'
import VoucherItem from './VoucherItem.vue'
import type { GiftVoucher, GiftVoucherContent } from './giftVouchersTypes'

type VoucherListProps = {
  vouchers: GiftVoucherContent
}

const props = defineProps<VoucherListProps>()

const voucherItems = computed(() => {
  return props.vouchers.items.map<GiftVoucher>((item) => {
    const voucherStart = dayjs(item.voucher.start)

    return {
      ...item,
      voucher: {
        ...item.voucher,
        start: voucherStart,
        end: dayjs(item.voucher.end),
        isFuture: isFuture(voucherStart),
        formattedStartDate: formatDate(voucherStart) ?? '',
      },
    }
  })
})
</script>

<template>
  <GiftsClubWrapper
    v-if="props.vouchers.items.length"
    :title="props.vouchers.title"
    :description="props.vouchers.subtitle">
    <div class="list d-flex flex-wrap align-items-start justify-content-center">
      <GiftsClubRibbon
        v-for="(voucher, index) in voucherItems"
        :key="`${index}-${voucher.voucher.code}`"
        :theme="voucher.voucher.isFuture ? 'disabled' : 'success'"
        class="voucher">
        <template #title>
          {{
            voucher.voucher.isFuture
              ? `${t('profile.giftsClub.ribbon.voucherWillBeValidFrom')} ${voucher.voucher.formattedStartDate}`
              : t('profile.giftsClub.ribbon.voucherIsActive')
          }}
        </template>

        <VoucherItem :voucher="voucher" />
      </GiftsClubRibbon>
    </div>

    <VoucherConditionsModal />
  </GiftsClubWrapper>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.list {
  gap: 16px;
}

.voucher {
  width: 100%;
  max-width: 400px;

  @include media-breakpoint-up(lg) {
    max-width: 320px;
  }
}
</style>
