import type { ApiEmptyResponse } from '@/services/api/types'
import { createOperation } from '@/services/apiv2'

import type { DeleteBenjaminekChildPathParams } from './benjaminekTypes'

/**
 * Sends DELETE request for removing a child from Benjaminek club.
 * @see https://sasandboxstdhotapidocs.z1.web.core.windows.net/#/Benjaminek/deleteBenjaminekChildren
 * @returns Promise of the API response with empty payload.
 */
const deleteChild = createOperation<
  ApiEmptyResponse,
  ApiEmptyResponse,
  DeleteBenjaminekChildPathParams
>('/benjaminek/children/:childId', {
  method: 'delete',
})

export default deleteChild
