<script setup lang="ts">
import ImgResponsive from '@/components/stories/atoms/imgResponsive/ImgResponsive.vue'
import type { Gift } from '@/services/api/front/gift'
import { resizer } from '@/services/resizer'
import { t } from '@/services/translations'

type GiftDetailProps = {
  gift: Gift
}

const props = defineProps<GiftDetailProps>()
</script>

<template>
  <article
    class="d-flex align-items-start"
    :aria-labelledby="`gift-detail-${props.gift.id}`">
    <ImgResponsive
      v-if="props.gift.image"
      class="flex-shrink-0 border border-gray-lighter"
      :alt="t('general.gift')"
      :srcset="[
        {
          url: resizer(props.gift.image, 75, 75),
        },
        {
          url: resizer(props.gift.image, 150, 150),
          pixelRatio: 1.5,
        },
      ]" />
    <p
      v-if="props.gift.description"
      class="gift-info mb-0 px-3 py-2">
      <strong
        :id="`gift-detail-${props.gift.id}`"
        class="text-primary"
        v-text="props.gift.name" />
      {{ props.gift.description }}
    </p>
  </article>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.gift-info {
  background-color: color('primary-lighter', 0.5);
}
</style>
