<script setup lang="ts">
import { computed } from 'vue'

import Icon from '@/components/Icon/Icon.vue'
import useInteractionSource from '@/composables/useInteractionSource'
import type { Product } from '@/composables/useProductItem'
import type { CumulativePrices } from '@/services/api/front/productTypes'
import { t } from '@/services/translations'
import useCartStore from '@/store/pinia/useCartStore'
import useDeviceDetectorStore from '@/store/pinia/useDeviceDetectorStore'
import localizePrice from '@/utils/localizePrice'

import ARowStep from './components/RowStep.vue'

type CumulativePriceProps = {
  product: Product
  secondary?: boolean
  showTitleActionPrice?: boolean
}

const props = defineProps<CumulativePriceProps>()

const { cartActions } = useCartStore()

const maxInCart = computed(() => props.product.origin.maxInCart)

const reversedCumulativePrices = computed(() =>
  props.product.origin.cumulativePrices.slice().reverse(),
)
const activeStepIndex = computed(() => {
  const index = reversedCumulativePrices.value.findIndex(
    (step) => step.quantity <= props.product.computed.effectiveQuantity,
  )

  return index === -1 ? Infinity : index
})
const getInteractionSource = useInteractionSource('CumulativePrice')

function handleApplyDiscount(step: CumulativePrices) {
  if (props.product.computed.effectiveQuantity >= step.quantity) return

  const quantity =
    step.quantity - (props.product.computed.effectiveQuantity - props.product.computed.quantity)

  cartActions.setCartProductQuantity({
    product: {
      id: props.product.id,
      quantity,
    },
    interactionSource: getInteractionSource(),
    increasing: true,
    carrierChange: true,
    step: step.quantity,
  })
}

function stepTitle(step: CumulativePrices) {
  const count = step.quantity - props.product.computed.effectiveQuantity
  return t('cumulativePrices.addItemsToCart', { count })
}

const { isTouch } = useDeviceDetectorStore()
</script>

<template>
  <div>
    <ARowStep
      v-for="(cumulativePrice, index) in reversedCumulativePrices"
      :key="`step-${index}`"
      :variant="secondary ? 'secondary' : ''"
      :active="index === activeStepIndex"
      :disabled="cumulativePrice.quantity > maxInCart || index > activeStepIndex"
      :class="{
        'm-table-steps__container': true,
        'm-table-steps__space': index > 0 && secondary,
        'flex-column align-items-baseline flex-nowrap':
          !secondary && props.product.origin.cumulativePrices.length < 4,
      }"
      data-tid="cumulative-price-step"
      @click="handleApplyDiscount(cumulativePrice)">
      <template #left>
        <span
          :class="{
            'm-table-steps__check-wrap': index === activeStepIndex,
          }">
          <Icon
            v-if="index === activeStepIndex"
            icon="checkbox-ok"
            class="m-table-steps__check-icon" />
          <span
            v-if="showTitleActionPrice && index === 0"
            class="d-block"
            v-text="t('cumulativePrices.titleActionPrice')" />

          {{ localizePrice(cumulativePrice.price) }}
        </span>
      </template>
      <template #right="{ isHovered }">
        <span
          v-if="isHovered && !isTouch"
          class="text-red">
          {{ stepTitle(cumulativePrice) }}
        </span>
        <span
          v-else
          v-text="t('cumulativePrices.fromPcs', { quantity: cumulativePrice.quantity })" />
      </template>
    </ARowStep>
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.m-table-steps {
  &__container {
    margin: 4px;
  }

  &__check-wrap {
    display: flex;
  }

  &__check-icon {
    margin-right: 4px;
  }
}
</style>
