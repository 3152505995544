import { t } from '@/services/translations'

import appConfig from '../appConfig'
import logApmError from '../logApmError'
import { toastError } from '../toast'
import AuthError from './AuthError'
import { MsalRedirectFlow } from './aadConstants'
import getMsalInstance from './getMsalInstance'
import getMsalRedirectResponse from './getMsalRedirectResponse'
import launchMsalRedirect from './launchMsalRedirect'
import msalState from './msalState'

/**
 * Triggers redirect to reset password.
 * @param finalUrl URL to redirect after success. If not set, trigger URL is used.
 */
async function aadResetPassword(finalUrl?: string) {
  if (msalState.inProgress) return
  msalState.inProgress = true

  try {
    const msalInstance = await getMsalInstance()
    await getMsalRedirectResponse() // Clear response from previous redirect without processing it

    await launchMsalRedirect(msalInstance, {
      flow: MsalRedirectFlow.passwordReset,
      authority: appConfig.auth.authorityPasswordReset,
      finalUrl,
    })
    msalState.inProgress = false
  } catch (error) {
    logApmError(new AuthError('reset_password_error', error))
    toastError(t('auth.error.general'))
  }
}

export default aadResetPassword
