import { ref } from 'vue'

import dayjs from 'dayjs'
import { defineStore } from 'pinia'

import type { Certificate } from '@/components/PharmacyCertificates/PharmacyCertificates.vue'
import useConfig from '@/composables/useConfig'
import type { Product } from '@/composables/useProductItem'
import { amountSyncUpdateDom } from '@/services/amountSyncDom'
import { yottlyGetPersonaliser } from '@/services/analytics/yottly'
import {
  frontApiDeleteFavoritesProduct,
  frontApiPostFavoritesProduct,
} from '@/services/api/front/favorites'
import { frontApiGetPageProductsFlexible } from '@/services/api/front/page'
import type { FlexibleProductsResponse } from '@/services/api/front/pageTypes'
import {
  frontApiProductGetBrandProducts,
  frontApiProductGetReplacements,
  frontApiProductGetSearch,
  frontApiProductGetSimilarProducts,
} from '@/services/api/front/product'
import type {
  CumulativePrices,
  ProductListItem,
  ProductOrigin,
} from '@/services/api/front/productTypes'
import { frontApiPostProductsMore } from '@/services/api/front/products'
import { frontApiGetWidget } from '@/services/api/front/widget'
import type { WidgetResponse } from '@/services/api/front/widgetTypes'
import appConfig from '@/services/appConfig'
import store from '@/store'
import type { CartProduct } from '@/store/modules/cart'
import findObjectByKey from '@/utils/findObjectByKey'
import { removeDuplicateObjectByKey } from '@/utils/removeDuplicateObjectByKey'

import { getProductPrice } from '../utils/discountedPrice'
import useUserInterfaceStore from './useUserInterfaceStore'

// @todo https://mallfresh.atlassian.net/browse/KNW-16170
type ProductComputed = {
  id: number
  quantity: number
  price: number
  pricePerUnit: {
    price: number
    unit: string
  }
  recommendedPrice: number
  // actual cumulative price step
  currentStep: CumulativePrices | null
  nextStep: CumulativePrices | null
  // quantity to a next step
  remainingQuantity: number | null
  // quantity used for calculation of price
  effectiveQuantity: number
  // what product groups is product part of
  groups: null | string[]
}

type Widget = Partial<WidgetResponse> & {
  products: Product[]
}

type FetchPageProductsResponse = Omit<
  FlexibleProductsResponse,
  'products' | 'widgets' | 'vendorId' | 'pharmacyCertificates'
> & {
  products: Product[]
  cursor: string | null
  widgets: any[]
}

const productStructure = {
  id: null,
  name: undefined,
  image: undefined,
  url: undefined,
  price: null,
  returnablePackagePrice: null,
  unit: null,
  recommendedPrice: null,
  percentageDiscount: null,
  cumulativePrices: [],
  productQuantity: undefined,
  labels: undefined,
  actionLabel: undefined,
  countryCode: null,
  pictographs: undefined,
  maxInCart: null,
  limitInCart: null,
  plannedStock: undefined,
  firstOrderDay: undefined,
  lastOrderDay: undefined,
  relatedProduct: undefined,
  mainCategory: undefined,
  pricePerUnit: undefined,
  quantity: null,
  returnableCarrier: undefined,
  vendorId: null,
  weightedProductDeposit: undefined,
  favorite: undefined,
  giftIds: [],
  breadcrumbs: [],
  marketplaceVendor: null,
}

const relatedProductsApiMap = {
  brandProducts: frontApiProductGetBrandProducts,
  replacements: frontApiProductGetReplacements,
  similarProducts: frontApiProductGetSimilarProducts,
}

/**
 * Extract product slug from url
 * @param productUrl
 * @returns product's slug
 */
// @todo move to utils, test
function getProductUrlSlug(productUrl: string) {
  return productUrl.replace(/\//, '')
}

/**
 * Remove 'detail' entry from provided products objects when its value equals to null.
 * The reason is that products data from API always include 'detail' key, either with some data in its value or with value null. Without removing those null entries (in the latter case), product detail data would be inaccurately overwritten.
 * @param {Array.<Object>} products
 * @returns {Array.<Object>} Products with null details removed
 */
// @todo refactor to immutable, type, test
function removeNullDetailFromProducts(products: any[]) {
  return products.map((product) => {
    product.detail === null && delete product.detail
    return product
  })
}

// @todo remove from store, types!, test
function calcProductComputed(
  newOriginProduct: Partial<ProductOrigin>,
  cartProduct: Partial<CartProduct>,
  oldOriginProduct: Partial<ProductOrigin> = {},
) {
  // @todo type fix
  const tempProduct: ProductOrigin & { quantity: number } = {
    ...oldOriginProduct,
    ...newOriginProduct,
    ...cartProduct,
  } as unknown as ProductOrigin & { quantity: number }

  const {
    price,
    pricePerUnit,
    currentStep,
    nextStep,
    groups,
    remainingQuantity,
    effectiveQuantity,
  } = getProductPrice(tempProduct)
  return {
    id: tempProduct.id,
    quantity: tempProduct.quantity ?? 0,
    price,
    pricePerUnit,
    // short-circuit evaluation can be removed after swagger doc recommendedPrice will be changed to 'not nullable'
    recommendedPrice: tempProduct.recommendedPrice ?? price,
    currentStep,
    nextStep,
    groups,
    remainingQuantity,
    effectiveQuantity,
  } as ProductComputed
}

function getProductsIds(products) {
  return products.map((product) => product.id)
}

const useProductsStore = defineStore('useProductsStore', () => {
  const productsOrigin = ref<ProductOrigin[]>([])
  const productsComputed = ref<ProductComputed[]>([])
  const pharmacyCertificates = ref<Certificate[]>([])

  const config = useConfig()

  function getProducts(productIds: number[]): Product[] {
    const cartProducts = store.getters.cart.getCartProductsByIds(productIds)
    const products = productsOrigin.value // @todo smazat zbytecny const
    const productsLength = products.length // @todo smazat zbytecny const
    const productIdsLength = productIds.length // @todo smazat zbytecny const
    const foundProducts: Product[] = []
    let findProductIterator = 0

    // @todo dont use indexes
    for (let i = 0; i < productsLength; i++) {
      const positionInProductIds = productIds.indexOf(products[i].id)

      if (positionInProductIds >= 0) {
        const product = products[i]
        // @TODO Because of badly written PageProducts.vue, we must return product directly from
        // state in order to preserve reactivity. That's why we save firstOrderDay (converted to dayjs)
        // in a computed property and previously used code (see next line) is temporarily commented out.
        //  product.firstOrderDay &&= dayjs(product.firstOrderDay)
        const cartProduct = findObjectByKey(cartProducts, product.id)?.item

        // @todo type fix
        foundProducts[positionInProductIds] = {
          id: product.id,
          // @todo type fix
          origin: product as any,
          // @todo type fix
          cart: cartProduct as CartProduct,
          computed: productsComputed.value[i],
          firstOrderDay: dayjs(product.firstOrderDay),
        } as any
        findProductIterator++
      }

      if (productIdsLength === findProductIterator) {
        break
      }
    }

    return foundProducts.map(Object).filter(Boolean)
  }

  function getProduct(productId: number): Product | null {
    return getProducts([productId])[0] ?? null
  }

  function getProductOrThrow(productId: number): Product {
    const product = getProduct(productId)
    if (!product) {
      throw new Error('getProductOrThrow - product with id:${productId} does not exists in store`)')
    }
    return product
  }

  function getProductBySlug(productSlug: string): Product | null {
    const productOrigin = productsOrigin.value.find((product) => {
      return product.url && getProductUrlSlug(product.url) === getProductUrlSlug(productSlug)
    })
    return productOrigin ? (getProduct(productOrigin.id) ?? null) : null
  }

  //@todo rename
  function updateProduct(payload: {
    newOriginProduct: ProductOrigin
    newProductComputed: Partial<ProductComputed>
    positionState: number
  }) {
    const { newOriginProduct, newProductComputed, positionState } = payload
    Object.assign(productsOrigin.value[positionState], newOriginProduct)
    Object.assign(productsComputed.value[positionState], newProductComputed)
  }

  function addProduct(newOriginProduct: ProductOrigin, newProductComputed: ProductComputed) {
    productsOrigin.value.push({
      ...productStructure,
      ...newOriginProduct,
    })
    productsComputed.value.push(newProductComputed)
  }

  // @todo type newProducts
  function setProducts(newProducts: any[] | undefined) {
    if (!Array.isArray(newProducts)) return
    newProducts = removeNullDetailFromProducts(removeDuplicateObjectByKey(newProducts))

    const cartProducts = store.getters.cart.getCartProductsByIds(
      newProducts.map((product) => product.id),
    )
    const productsOriginLength = productsOrigin.value.length
    const newProductsLength = newProducts.length
    const updatedProducts: any[] = []

    for (let i = 0; i < productsOriginLength; i++) {
      const newOriginProduct = findObjectByKey(newProducts, productsOrigin.value[i].id)?.item

      if (newOriginProduct) {
        updatedProducts.push(newOriginProduct)
        updateProduct({
          newOriginProduct,
          newProductComputed: calcProductComputed(
            newOriginProduct,
            findObjectByKey(cartProducts, newOriginProduct.id)?.item as CartProduct,
            productsOrigin.value[i],
          ),
          positionState: i,
        })
      }

      if (updatedProducts.length === newProductsLength) {
        break
      }
    }

    if (updatedProducts.length < newProductsLength) {
      for (let i = 0; i < newProductsLength; i++) {
        if (!updatedProducts.includes(newProducts[i])) {
          addProduct(
            newProducts[i],
            // @todo type fix
            calcProductComputed(
              newProducts[i],
              findObjectByKey(cartProducts, newProducts[i].id) as any,
            ),
          )
        }
      }
    }

    updateGroupPrices()
  }

  // @todo jesust christ!
  function setProduct(newProduct: any) {
    setProducts([newProduct])
  }

  function setPharmacyCertificates(certificates: Certificate[]) {
    pharmacyCertificates.value = certificates
  }

  // @todo type
  function syncProductComputedWithCartProduct(cartProduct) {
    const product = findObjectByKey(productsOrigin.value, cartProduct.id)

    if (product) {
      const newProductComputed = calcProductComputed(product.item, cartProduct)
      Object.assign(productsComputed.value[product.index], newProductComputed)
    }

    updateGroupPrices()
  }

  function syncProductsWithCartProducts(cartProducts) {
    const cartProductsLength = cartProducts.length

    for (let i = 0; i < cartProductsLength; i++) {
      const product = findObjectByKey(productsOrigin.value, cartProducts[i].id)
      if (product) {
        updateProduct({
          newOriginProduct: { ...product.item, ...cartProducts[i] },
          newProductComputed: calcProductComputed(product.item, cartProducts[i]),
          positionState: product.index,
        })
      }
    }

    // recalculate group prices for multibuy products
    updateGroupPrices()
  }

  function syncProductDom(productId: number) {
    amountSyncUpdateDom(
      productId,
      config.value.general.maxInCartLimit,
      config.value.general.maxInCartLimitPharmacy,
    )
  }

  function updateGroupPrices() {
    productsComputed.value.forEach((product) => {
      const productOrigin = productsOrigin.value.find((origin) => origin.id === product.id)
      const { price, pricePerUnit, currentStep, nextStep, remainingQuantity, effectiveQuantity } =
        getProductPrice({
          ...productOrigin,
          ...{ quantity: product.quantity },
        } as ProductOrigin & { quantity: number })

      if (product.price !== price) {
        product.price = price
        product.pricePerUnit = pricePerUnit
        product.currentStep = currentStep
        product.nextStep = nextStep
      }
      if (product.remainingQuantity !== remainingQuantity) {
        product.remainingQuantity = remainingQuantity
      }
      if (product.effectiveQuantity !== effectiveQuantity) {
        product.effectiveQuantity = effectiveQuantity
      }
    })
  }

  function setFavoriteProduct(product) {
    setProduct(product)
    sendApiFavoriteProduct(product)
  }

  async function sendApiFavoriteProduct(product) {
    const apiMethod = product.favorite
      ? frontApiPostFavoritesProduct
      : frontApiDeleteFavoritesProduct

    try {
      const {
        data: { status },
      } = await apiMethod(product.id)

      if (status !== 'ok') {
        throw new Error('status not ok')
      }
    } catch (e) {
      setProduct({
        ...product,
        favorite: !product.favorite,
      })
    }
  }

  async function fetchProductWidget({
    code,
    mergeProducts,
  }: {
    code: string
    mergeProducts?: Product[]
  }): Promise<Widget> {
    const userInterfaceStore = useUserInterfaceStore()
    const vendorId = userInterfaceStore.currentVendorId
    try {
      const { data } = await frontApiGetWidget(code, vendorId, mergeProducts)
      setProducts(data.products)

      return {
        ...data,
        products: getProducts(data.products.map((product) => product.id)),
      }
    } catch (e) {
      // @TODO
    }

    return {
      products: [],
    }
  }

  // @todo type
  async function fetchPersonalizedProducts(type): Promise<any> {
    try {
      const codeWidget = `${type}Yottly`
      const yottlyProductIds = await yottlyGetPersonaliser[type]()
      const { products } = await fetchProductWidget({
        code: codeWidget,
        mergeProducts: yottlyProductIds,
      })

      return {
        products,
        yottlyProductIds,
      }
    } catch (e) {
      // @TODO
    }

    return {
      products: [],
    }
  }

  async function fetchProductsSearch({
    query,
    limit,
    excludeCategoryId,
  }: {
    query: string
    limit?: number
    excludeCategoryId?: any[]
  }): Promise<{
    products: Product[]
    totalCount: number
    cursor: string | null
  }> {
    // if query is empty string, return empty search result
    if (query.trim().length === 0) {
      return {
        products: [],
        totalCount: 0,
        cursor: null,
      }
    }
    const {
      data: { products, totalCount, cursor },
    } = await frontApiProductGetSearch(
      query,
      limit ?? appConfig.defaultProductPageProductCount,
      excludeCategoryId,
    )

    setProducts(products)

    return {
      products: getProducts(products.map((product) => product.id)),
      totalCount,
      cursor,
    }
  }

  async function fetchPageProducts({
    slug,
    limit,
    orderBy,
    filters,
    search,
    nameController,
    actionType,
    displayType,
  }): Promise<FetchPageProductsResponse> {
    const fullResponse = await frontApiGetPageProductsFlexible(
      slug,
      limit || appConfig.defaultProductPageProductCount,
      orderBy,
      filters,
      search,
      nameController,
      actionType,
      displayType,
    )

    const {
      data: {
        products: responseProducts,
        widgets,
        vendorId,
        pharmacyCertificates: responsePharmacyCertificates,
        breadcrumbs,
        ...response
      },
    } = fullResponse

    const products = responseProducts || {
      items: [],
      cursor: null,
      totalCount: 0,
    }

    setPharmacyCertificates(responsePharmacyCertificates)
    const { setVendorId } = useUserInterfaceStore()
    setVendorId(vendorId)

    setProducts([
      ...products.items,
      ...widgets.reduce(
        (accumulator, currentWidget) => [...accumulator, ...currentWidget.products],
        [] as ProductListItem[],
      ),
    ])

    const preparedWidgets = widgets.map((widget) => {
      return {
        title: widget.title,
        products: getProducts(widget.products.map((product) => product.id)),
        code: widget.code,
      }
    })

    return {
      ...response,
      breadcrumbs,
      products: getProducts(products.items.map((product) => product.id)),
      totalCount: products.totalCount,
      cursor: products.cursor,
      widgets: preparedWidgets,
    }
  }

  async function fetchMoreProducts({
    cursor,
    nameController = '', // edited, unsupported undefined
    limit = appConfig.defaultProductPageProductCount,
  }: {
    cursor: any
    nameController?: string
    limit?: number
  }): Promise<any> {
    if (!cursor) {
      return {
        products: [],
        newCursor: null,
      }
    }

    const {
      data: { products, cursor: newCursor },
    } = await frontApiPostProductsMore(cursor, limit, nameController)

    setProducts(products)

    return {
      products: getProducts(products.map((product) => product.id)),
      newCursor,
    }
  }

  async function fetchProductRelatedProducts({
    productId,
    relatedProductsKey,
    limit = undefined,
  }): Promise<any> {
    const {
      data: { products, replacements, similars },
    } = await relatedProductsApiMap[relatedProductsKey](productId, limit)
    const productItems = products || replacements || similars // @todo: Ah shit, here we go again.
    setProducts(productItems)
    return getProducts(getProductsIds(productItems))
  }

  return {
    productsOrigin,
    productsComputed,
    pharmacyCertificates,
    getProducts,
    getProduct,
    getProductOrThrow,
    getProductBySlug,
    setProducts,
    setProduct,
    setPharmacyCertificates,
    syncProductComputedWithCartProduct,
    syncProductsWithCartProducts,
    syncProductDom,
    setFavoriteProduct,
    fetchProductWidget,
    fetchPersonalizedProducts,
    fetchProductsSearch,
    fetchPageProducts,
    fetchMoreProducts,
    fetchProductRelatedProducts,
  }
})

export default useProductsStore

export type { ProductOrigin, ProductComputed, Widget, FetchPageProductsResponse }
