import type { RequestQueryParam, RequestQueryParams } from '@/services/api/makeRequest'

function isNotNull<T>(value: T): value is NonNullable<T> {
  return !!value
}

/**
 * Converts query params to URL string.
 * Nullish values are ignored, arrays are converted to PHP format.
 * @param params All request params that will be transformed to URL string.
 */
function convertObjToUrlParams(params: RequestQueryParams) {
  const searchParams = new URLSearchParams()

  Object.keys(params).forEach((key) => {
    const currentKey = params[key]
    if (typeof currentKey === 'undefined' || currentKey === null) {
      return
    }

    if (Array.isArray(currentKey)) {
      currentKey.filter<NonNullable<RequestQueryParam>>(isNotNull).forEach((itemArray) => {
        searchParams.append(`${key}[]`, itemArray.toString())
      })
      return
    }

    if (currentKey instanceof Object) {
      for (const [prop, value] of Object.entries(currentKey)) {
        if (value !== null && value !== undefined) {
          searchParams.append(`${key}[${prop}]`, value.toString())
        }
      }
      return
    }

    searchParams.append(key, currentKey?.toString())
  })

  const paramsAsString = searchParams.toString()

  return paramsAsString
}

export default convertObjToUrlParams
