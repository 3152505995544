<script setup lang="ts">
import { computed } from 'vue'

import { t } from '@/services/translations'

type BenjaminekProgressBarProps = {
  amountSpentPerMonth: number
  requiredAmount: number
}
const props = defineProps<BenjaminekProgressBarProps>()

const spentPercentage = computed(() => (props.amountSpentPerMonth / props.requiredAmount) * 100)

// Percentage of a progress bar's filling (used in styles)
const filling = computed(() => spentPercentage.value + '%')
// Smoother for filling's edge in progress bar if spentPercentage is 1% or more (used in styles)
const smoother = computed(
  () => (spentPercentage.value ? spentPercentage.value + 0.2 : spentPercentage.value) + '%',
)

const requirementNotFulfilled = computed(() => props.amountSpentPerMonth < props.requiredAmount)
</script>

<template>
  <div class="outer-circle d-flex align-items-center justify-content-center">
    <div class="inner-circle d-flex align-items-center justify-content-center">
      <p class="d-flex align-items-baseline text-pink-dark text-8 font-weight-bold m-0">
        <span
          v-if="requirementNotFulfilled"
          v-text="`${props.amountSpentPerMonth}/`" />
        <span
          :class="requirementNotFulfilled ? 'required-amount text-4 font-weight-normal' : 'mb-4'"
          v-text="`${props.requiredAmount} ${t('general.currency')}`" />
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

$circle-border: 1px solid color('pink-dark');
$circle-border-radius: 50%;
$outer-circle-size: 150px;
$circle-filling-width: 50px;
$radial-gradient: 67%;

.outer-circle {
  $background-color: #fef6f5; // the same as color("primary-lighter", 0.25) but directly with the opacity

  width: $outer-circle-size;
  height: $outer-circle-size;
  background: radial-gradient(
      closest-side,
      $background-color $radial-gradient,
      transparent $radial-gradient
    ),
    conic-gradient(color('pink-dark') v-bind(filling), transparent v-bind(smoother));
  border: $circle-border;
  border-radius: $circle-border-radius;
}

.inner-circle {
  $size: calc($outer-circle-size - $circle-filling-width);

  width: $size;
  height: $size;
  border: $circle-border;
  border-radius: $circle-border-radius;
}

.required-amount {
  position: relative;
  top: 2px;
}
</style>
