import { createOperation } from '@/services/apiv2'

import type { ProfileOrderListQueryParams, ProfileOrderListResponse } from './profileTypes'

const orderList = createOperation<
  ProfileOrderListResponse,
  ProfileOrderListResponse,
  undefined,
  Partial<ProfileOrderListQueryParams>
>('profile/order-list', {
  method: 'get',
})

export default orderList
