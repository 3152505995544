<script setup lang="ts">
import { computed, ref } from 'vue'

type TooltipProps = {
  wrapperClass?: string
  messageClass?: string
  tooltipClass?: string
  visible?: boolean
  disabled?: boolean
  bgColor?: string
  textColor?: string
  shadow?: boolean
  nowrap?: boolean
  direction?:
    | 'top'
    | 'right'
    | 'bottom'
    | 'left'
    | 'top-left'
    | 'top-right'
    | 'bottom-left'
    | 'bottom-right'
}

const props = withDefaults(defineProps<TooltipProps>(), {
  wrapperClass: undefined,
  messageClass: undefined,
  tooltipClass: undefined,
  visible: undefined,
  disabled: false,
  bgColor: 'gray-dark',
  textColor: 'white',
  shadow: false,
  nowrap: false,
  direction: 'top',
})

const hover = ref(false)

const isVisible = computed({
  get: () => props.visible ?? hover.value,
  set: (visibility: boolean) => {
    if (typeof props.visible === 'undefined') {
      hover.value = visibility
    }
  },
})

const generalDirection = computed(() => props.direction.split('-')[0])
</script>

<template>
  <span>
    <span
      v-if="!props.disabled"
      :class="['tooltip-wrapper', props.wrapperClass]"
      @mouseover="isVisible = true"
      @mouseleave="isVisible = false">
      <slot />
      <template v-if="isVisible && !!($slots.message && $slots.message())">
        <div
          :class="[
            `tooltip tooltip--${generalDirection} tooltip--${props.direction}`,
            props.tooltipClass,
          ]">
          <div
            :class="[
              `tooltip__message bg-${props.bgColor} text-${props.textColor}`,
              {
                'tooltip__message--shadow': props.shadow,
                'text-nowrap': props.nowrap,
              },
              props.messageClass,
            ]">
            <slot name="message" />
          </div>
        </div>
        <div :class="`triangle triangle--${generalDirection} text-${props.bgColor}`" />
      </template>
    </span>
    <template v-else>
      <slot />
    </template>
  </span>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;
@use 'sass:list';

$triangle-width: 12px;
$triangle-height: 9px;

@mixin center($direction) {
  @if list.index(top bottom, $direction) {
    left: 50%;
    transform: translateX(-50%);
  } @else if list.index(right left, $direction) {
    top: 50%;
    transform: translateY(-50%);
  }
}

@mixin direction($direction, $triangle: false) {
  $opposite-direction: opposite-direction($direction);

  @if $triangle {
    @include triangle($opposite-direction, currentColor, $triangle-height, $triangle-width);
  } @else {
    padding-#{$opposite-direction}: $triangle-height;
  }

  @include center($direction);

  #{$direction}: auto;
  #{$opposite-direction}: 100%;
}

.tooltip-wrapper {
  position: relative;
  display: inline-block;
  vertical-align: bottom;
  cursor: auto;
}

.close,
.triangle,
.tooltip {
  position: absolute;
}

.close {
  top: 7px;
  right: 7px;
  padding: 0;
  line-height: 1;
  color: inherit;
  cursor: pointer;
  background-color: transparent;
  border: none;

  @include make-font-scale(2);
}

.triangle {
  z-index: $zix-tooltip-triangle;

  &--top {
    @include direction(top, true);
  }

  &--left {
    @include direction(left, true);
  }

  &--bottom {
    @include direction(bottom, true);
  }

  &--right {
    @include direction(right, true);
  }
}

.tooltip {
  z-index: $zix-tooltip;

  &__message {
    padding: 7px;
    border-radius: var(--borderRadiusSmall);

    &--shadow {
      box-shadow: 0 0 28px 4px color('black', 0.18);
    }
  }

  &--top {
    @include direction(top);
  }

  &--left {
    @include direction(left);
  }

  &--bottom {
    @include direction(bottom);
  }

  &--right {
    @include direction(right);
  }

  &--top-right {
    right: auto;
    left: 0;
    transform: none;
  }

  &--top-left {
    right: 0;
    left: auto;
    transform: none;
  }

  &--bottom-right {
    right: auto;
    left: 0;
    transform: none;
  }

  &--bottom-left {
    right: 0;
    left: auto;
    transform: none;
  }
}
</style>
