export const useClubSectionTitleProps = {
  title: {
    type: String,
    required: true,
  },
  titleColor: {
    type: String,
    default: 'primary',
  },
}
