import { computed, ref } from 'vue'

import dayjs, { type Dayjs } from 'dayjs'
import { defineStore } from 'pinia'

import api from '@/data/api'
import type {
  TransportLimitationHour,
  TransportTimeslotDay,
  TransportTimeslotTime,
} from '@/services/api/front/transportTypes'
import { ResponseNotOKError } from '@/services/api/makeRequest'

import hasClosingSoonTimeslot from '../utils/hasClosingSoonTimeslot'
import refineTimeslots from '../utils/refineTimeslots'
import useAddressStore from './useAddressStore'
import useProductsStore from './useProductsStore'

type TimeslotTime = ReplaceRecordKeys<
  TransportTimeslotTime,
  {
    start: Dayjs
    end: Dayjs
  }
>

type RefinedTimeslot = {
  day: string
  date: Dayjs
  times: TimeslotTime[]
  dateString: string
  hasFreeDeliveryPrice?: boolean
  cheapestPrice: number | null
  hasFreeTimeslots?: boolean
  unavailable?: boolean
}
type LimitedTimeRange = {
  start: Dayjs
  end: Dayjs
}

type LimitedDay = {
  date: Dayjs
  isAvailable: boolean
  limitedTimeRanges?: LimitedTimeRange[]
}

type TimeslotsLimitedProduct = {
  id: number
  vendorId: number
  days: LimitedDay[]
}

// @fixup https://mallfresh.atlassian.net/browse/KNW-18480 move to api -> responseConverter
function getLimitedTimeRanges(hourParts: TransportLimitationHour[]) {
  return hourParts.map<LimitedTimeRange>((hourPart) => ({
    start: dayjs(hourPart.from),
    end: dayjs(hourPart.to),
  }))
}

const useTimeslotsStore = defineStore('useTimeslotsStore', () => {
  const cartTimeslots = ref<TransportTimeslotDay[]>([])
  const cartTimeslotsStale = ref(false)
  const limitedProducts = ref<TimeslotsLimitedProduct[]>([])

  const getCartTimeslots = computed(() => refineTimeslots(cartTimeslots.value))
  const hasCartClosingSoonTimeslot = computed(() => hasClosingSoonTimeslot(getCartTimeslots.value))
  const hasCartTimeslots = computed(() => !!cartTimeslots.value.length)

  function isCartTimeslotAvailable(id: number) {
    return cartTimeslots.value.some((day) =>
      day.times.some((timeslot) => timeslot.id === id && !timeslot.unavailable),
    )
  }

  function markCartTimeslotsStale() {
    cartTimeslotsStale.value = true
  }

  // @todo use useTransportWindows (vueQuery)
  async function fetchCartTimeslots() {
    try {
      const { timeslots, productsLimitations, shipper } =
        await api.front.transport.transportWindows({ queryParams: { 'current-cart': 1 } })

      const { setShipper } = useAddressStore()
      const { setProducts } = useProductsStore()
      setShipper(shipper)
      cartTimeslots.value = timeslots

      const conflictingProducts: any[] = []
      const conflictingProductsLimitations: TimeslotsLimitedProduct[] = []

      productsLimitations.forEach((entry) => {
        const limitedProduct = entry.shoppingCartProduct.product
        conflictingProducts.push(limitedProduct)

        const id = limitedProduct.id
        const vendorId = limitedProduct.vendorId
        const days = entry.limitations.map((limitation) => ({
          date: dayjs(limitation.date),
          isAvailable: !!limitation.hourParts.length,
          ...(limitation.hourParts.length && {
            limitedTimeRanges: getLimitedTimeRanges(limitation.hourParts),
          }),
        }))

        conflictingProductsLimitations.push({ id, vendorId, days })
      })

      conflictingProductsLimitations.sort((a, b) => a.vendorId - b.vendorId)

      setProducts(conflictingProducts)
      limitedProducts.value = conflictingProductsLimitations
      cartTimeslotsStale.value = false
    } catch (error) {
      if (
        error instanceof ResponseNotOKError &&
        error.response.data.type === 'checkout/minimal-price'
      ) {
        location.reload()
      }
    }
  }

  return {
    limitedProducts,
    cartTimeslotsStale,
    getCartTimeslots,
    hasCartClosingSoonTimeslot,
    hasCartTimeslots,
    isCartTimeslotAvailable,
    markCartTimeslotsStale,
    fetchCartTimeslots,
  }
})

export default useTimeslotsStore
export type { LimitedTimeRange, TimeslotTime, RefinedTimeslot, TimeslotsLimitedProduct }
