import { createOperation } from '@/services/apiv2'

type DeliveryOption = {
  orderPrice: number
  deliveryPrice: number
}

type DeliveryOptions = {
  priceSteps: DeliveryOption[]
}

/**
 * Gets delivery options for modal
 */
const getDeliveryOptions = createOperation<DeliveryOptions>('/transport/price-steps', {
  method: 'get',
})

export default getDeliveryOptions
