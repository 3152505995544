import type { RatingValue } from '@/components/OrderRating/RatingCheckbox.vue'

import { dataLayerPush } from './utils'

function reviewSent(ratingValue: RatingValue) {
  dataLayerPush({
    event: 'review_sent',
    type: ratingValue,
  })
}

export { reviewSent as gtmEventReviewSent }
