import { useQuery } from '@tanstack/vue-query'

import { clientQueryKeys } from '@/constants/queryKeys'
import api from '@/data/api'

function useClientGetFlags() {
  return useQuery({
    queryKey: clientQueryKeys.flags(),
    queryFn: () => api.front.client.flags(),
  })
}

export default useClientGetFlags
