import type { ImgSrc } from '@/components/stories/atoms/imgResponsive/types'
import { resizer, type ResizeTypeName } from '@/services/resizer'

const useSrcset = (
  imageUrl: string,
  width: number,
  height: number,
  resizeTypeName?: ResizeTypeName,
): ImgSrc[] => {
  return [
    {
      url: resizer(imageUrl, width, height, resizeTypeName),
    },
    {
      url: resizer(imageUrl, width * 2, height * 2, resizeTypeName),
      pixelRatio: 1.5,
    },
  ]
}

export { useSrcset }
