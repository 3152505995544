type AuthErrorType =
  | 'logout_error'
  | 'logout_mobile_error'
  | 'login_error'
  | 'unknown_flow_error'
  | 'merge_anonymous_user_error'
  | 'reset_password_error'
  | 'get_access_token_error'
  | 'get_msal_instance_error'
  | 'msal_total_failure_error'

/**
 * Error class for authentication errors.
 * @param message Custom error message.
 * @param originalError The original error thrown by the authentication service.
 */
class AuthError extends Error {
  public readonly originalError: Error | null

  constructor(message: AuthErrorType, originalError?: unknown) {
    const errorMessage =
      originalError instanceof Error ? `${message}: ${originalError.message}` : message

    super(errorMessage)
    this.name = 'AuthError'
    this.originalError = originalError instanceof Error ? originalError : null
  }
}

export default AuthError
