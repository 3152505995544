<script setup lang="ts">
import { ref, computed, provide } from 'vue'

import debounce from 'lodash-es/debounce'
import { storeToRefs } from 'pinia'

import Icon from '@/components/Icon/Icon.vue'
import appConfig from '@/services/appConfig'
import useDeviceDetectorStore from '@/store/pinia/useDeviceDetectorStore'

import intersectionObserverKey from '../intersectionObserverKey'

type TimeoutIdentifier = ReturnType<typeof setTimeout>

type CarouselWrapperProps = {
  step?: number
  visibilityRatio?: number
  type?: string
  hiddenControl?: boolean
  autoplay?: boolean | number
  loop?: boolean
  controlVariant?: string | null
  controlSize?: 'sm' | 'md'
  controlBoxShadow?: boolean
  fadedBorders?: boolean
  carouselClass?: string
  isBanners?: boolean
}

const props = withDefaults(defineProps<CarouselWrapperProps>(), {
  step: 3,
  visibilityRatio: 0.9,
  type: undefined,
  hiddenControl: false,
  autoplay: false,
  loop: false,
  controlVariant: null,
  controlSize: 'md',
  controlBoxShadow: false,
  fadedBorders: false,
  carouselClass: '',
  isBanners: false,
})

const emit = defineEmits<{
  slideActivated: [index: number]
}>()

const deviceDetectorStore = useDeviceDetectorStore()
const { breakpointDownXs } = storeToRefs(deviceDetectorStore)

const isTouch = deviceDetectorStore.isTouch

const observedItems = ref<IntersectionObserver>()
const visibilityMap = ref<boolean[]>([])
const autoplayTimer = ref<TimeoutIdentifier | null>(null)
const root = ref<HTMLElement>()
const items = ref<HTMLElement>()

const countSliders = computed(() => visibilityMap.value.length)
const isShowButton = computed(
  () => !(breakpointDownXs.value && isTouch) && !props.hiddenControl && visibilityMap.value.length,
)
const isStart = computed(() => !!visibilityMap.value[0])
const isEnd = computed(() => !!visibilityMap.value[countSliders.value - 1])
const getVisibilityCount = computed(() => visibilityMap.value.filter((item) => item).length)
const getFirstVisibilityIndex = computed(() => visibilityMap.value.indexOf(true))
const getStep = computed(() => Math.min(props.step, getVisibilityCount.value))
const getAutoplayDuration = computed(() =>
  typeof props.autoplay === 'number' ? props.autoplay : appConfig.autoplayDuration,
)

const isHtmlElement = (element: Element): element is HTMLElement => {
  return element instanceof HTMLElement
}
// Methods
const getItemElements = () => {
  if (!items.value) return []
  const allItems = [...items.value.getElementsByClassName('carousel-item')]
  const htmlOnlyItems: HTMLElement[] = allItems.filter(isHtmlElement)

  return htmlOnlyItems
}
const initIntersectionObserver = () => {
  observedItems.value = new IntersectionObserver(onChangeIntersection, {
    root: root.value,
    threshold: props.visibilityRatio,
  })

  getItemElements().forEach((item, index) => {
    item.dataset.index = index.toString()
    observedItems.value?.observe(item)
  })
}

const onChangeIntersection = (entries: IntersectionObserverEntry[]) => {
  entries.forEach((entry) => {
    const target = entry.target as HTMLElement
    visibilityMap.value.splice(
      Number(target.dataset.index),
      1,
      entry.intersectionRatio >= props.visibilityRatio,
    )
  })
  emit('slideActivated', getFirstVisibilityIndex.value)
}

const setSlide = async (index: number) => {
  stopAutoplay()
  const itemElements = getItemElements()
  const item = itemElements[index]
  if (!item) return

  const rectItem = item.getBoundingClientRect()
  const wrap = items

  if (wrap.value) {
    const rectWrap = wrap.value.getBoundingClientRect()

    wrap.value.scrollTo({
      left: rectItem.left + wrap.value.scrollLeft - rectWrap.left,
      behavior: 'smooth',
    })
  }

  startAutoplay()
}
const goPrev = () => {
  const prev =
    isStart.value && props.loop
      ? Math.max(countSliders.value - 1, 0)
      : Math.max(getFirstVisibilityIndex.value - getStep.value, 0)

  setSlide(prev)
}
const goNext = () => {
  const next =
    isEnd.value && props.loop
      ? 0
      : Math.min(getFirstVisibilityIndex.value + getStep.value, Math.max(countSliders.value - 1, 0))

  setSlide(next)
}
const startAutoplay = () => {
  if (props.autoplay && autoplayTimer.value === null && (props.loop || !isEnd.value)) {
    autoplayTimer.value = setInterval(goNext, getAutoplayDuration.value)
  }
}
const stopAutoplay = () => {
  if (props.autoplay && autoplayTimer.value) {
    clearInterval(autoplayTimer.value)
    autoplayTimer.value = null
  }
}

const resetIntersectionObserver = debounce(() => {
  if (observedItems.value) {
    observedItems.value.disconnect()
    visibilityMap.value = []
  }
  initIntersectionObserver()
  startAutoplay()
}, 50)

provide(intersectionObserverKey, resetIntersectionObserver)
defineExpose({
  goPrev,
  goNext,
  setSlide,
})
</script>

<template>
  <div
    ref="root"
    :class="[
      'carousel-wrapper',
      type ? `carousel-wrapper--${type}` : null,
      controlVariant ? `carousel-wrapper--${controlVariant}` : null,
      {
        'carousel-wrapper--touch': isTouch,
        'carousel-wrapper--faded': fadedBorders,
      },
    ]"
    @mouseenter="stopAutoplay"
    @mouseleave="startAutoplay"
    @touchstart="stopAutoplay"
    @touchend="startAutoplay">
    <div
      ref="items"
      :class="['carousel-content', props.carouselClass]">
      <slot />
    </div>
    <template v-if="fadedBorders">
      <div
        v-if="!isStart && !loop"
        :class="[
          'carousel-wrapper__shadow',
          {
            'carousel-wrapper__shadow--under-button': isShowButton,
          },
        ]" />
      <div
        v-if="!isEnd && !loop"
        :class="[
          'carousel-wrapper__shadow carousel-wrapper__shadow--right',
          {
            'carousel-wrapper__shadow--under-button': isShowButton,
          },
        ]" />
    </template>

    <button
      v-if="isShowButton"
      :class="[
        `control control--prev control--${controlSize}`,
        {
          'control--banners': props.isBanners,
          'control--hide': isStart && !loop,
          'control--dark': controlVariant === 'dark',
          'control--shadow': controlBoxShadow,
        },
      ]"
      data-tid="banner__button-prev"
      @click="goPrev">
      <Icon
        class="control-icon"
        icon="arrow-button" />
    </button>
    <button
      v-if="isShowButton"
      :class="[
        `control control--${controlSize}`,
        {
          'control--banners': props.isBanners,
          'control--hide': isEnd && !loop,
          'control--dark': controlVariant === 'dark',
          'control--shadow': controlBoxShadow,
        },
      ]"
      data-tid="banner__button-next"
      @click="goNext">
      <Icon
        class="control-icon"
        icon="arrow-button" />
    </button>
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.carousel-wrapper {
  position: relative;
  z-index: $zix-carousel-wrapper;
  height: 100%;
  overflow: hidden;
  user-select: none;

  &__shadow {
    position: absolute;
    top: 0;
    left: -12px;
    z-index: $zix-base;
    width: 50px;
    height: 100%;
    pointer-events: none;
    background: linear-gradient(to right, color('gray-lighter'), color('gray-lighter', 0));

    &--under-button {
      background: linear-gradient(to right, color('gray-lighter') 66%, color('gray-lighter', 0));
    }

    &--right {
      right: -12px;
      left: initial;
      transform: rotateY(180deg);
    }
  }
}

.carousel-content {
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: calc(100% + 30px);
  overflow: hidden;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch; // fix scroll iOS Safari
}

.control {
  position: absolute;
  top: 50%;
  right: 0;
  z-index: $zix-carousel__control;
  background: color('primary');
  border: 0;
  border-radius: 50%;
  transition:
    background var(--baseTransitionTime),
    opacity var(--baseTransitionTime);
  transform: translateY(-50%);

  &-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    color: color('white');
    transform: translate(-50%, -50%);

    .control--prev & {
      transform: translate(-60%, -50%) rotate(180deg);
    }

    .control--dark & {
      width: 11px;
      height: 11px;
      color: color('white');
    }
  }

  &--md {
    $size: 44px;

    width: $size;
    height: $size;
  }

  &--sm {
    $size: 26px;

    width: $size;
    height: $size;

    .control-icon {
      width: 14px;
      height: 14px;
    }
  }

  &--prev {
    right: auto;
    left: 0;
  }

  &--hide {
    pointer-events: none;
    opacity: 0;
  }

  &--shadow {
    box-shadow: -3px 0 8px 0 color('gray-lighter');
  }

  &--dark {
    $dark: 26px;

    width: $dark;
    height: $dark;
    background: color('gray-darker');
  }

  &--banners {
    background: color('neutrals-10');

    & .control-icon {
      color: color('neutrals-90');
    }
  }

  &:hover {
    background: color('off-primary-mid');

    .control-icon {
      color: color('secondary');
    }
  }
}

.control--shadow.control--prev {
  box-shadow: 3px 0 8px 0 color('gray-lighter');
}

.carousel-wrapper--brand-list {
  padding: 0 50px;
  transition: padding var(--baseTransitionTime);

  .carousel-item {
    width: 130px;

    @include media-breakpoint-up(md) {
      width: 150px;
    }
  }
}

.carousel-wrapper--faded {
  padding: 0;
}

.carousel-wrapper--touch {
  @include media-breakpoint-down(xs) {
    padding: 0;
  }
}
</style>
