import { computed, watch } from 'vue'

import useTransportWindows, {
  invalidateTransportWindows,
} from '@/composables/transport/useTransportWindows'
import useAddressStore from '@/store/pinia/useAddressStore'
import hasClosingSoonTimeslot from '@/store/utils/hasClosingSoonTimeslot'
import refineTimeslots from '@/store/utils/refineTimeslots'

const currentCart = 0

function invalidatePreviewTimeslots() {
  invalidateTransportWindows(currentCart)
}

function usePreviewTimeslots() {
  const { setShipper } = useAddressStore()
  const { data, isFetching: isPreviewTimeslostsFetching } = useTransportWindows(currentCart)

  // @fixup https://mallfresh.atlassian.net/browse/KNW-18480 transform with refineTimeslots in api responseConverter
  const previewTimeslots = computed(() => refineTimeslots(data.value?.timeslots ?? []))
  const hasPreviewTimeslots = computed(() => previewTimeslots.value.length > 0)

  // it means that there are open slots (not free delivery)
  const hasPreviewFreeTimeslots = computed(() =>
    previewTimeslots.value.some((day) => day.hasFreeTimeslots),
  )
  const hasPreviewClosingSoonTimeslot = computed(() =>
    hasClosingSoonTimeslot(previewTimeslots.value),
  )

  watch(data, (newVal) => newVal && setShipper(newVal.shipper))

  return {
    previewEarliestTimeslotDate: data.value?.earliestTimeslot ?? null,
    previewTimeslots,
    hasPreviewTimeslots,
    hasPreviewFreeTimeslots,
    hasPreviewClosingSoonTimeslot,
    isPreviewTimeslostsFetching,
  }
}

export default usePreviewTimeslots
export { invalidatePreviewTimeslots }
