<script setup>
import { ref, computed, watch } from 'vue'

import { storeToRefs } from 'pinia'

import Icon from '@/components/Icon/Icon.vue'
import useDeviceDetectorStore from '@/store/pinia/useDeviceDetectorStore'
import useModalStore from '@/store/pinia/useModalStore'

import { usePopupProps, usePopup } from './usePopup'

const props = defineProps({
  ...usePopupProps,
})

const emits = defineEmits(['open', 'close'])

const deviceDetectorStore = useDeviceDetectorStore()
const { isModalOpen } = useModalStore()

const { breakpointDownSm } = storeToRefs(deviceDetectorStore)

const isVisibleWithoutPopup = computed(() => props.onlyMobile && !breakpointDownSm.value)
const isVisibleAsPopup = computed(
  () => state.value && (!props.onlyMobile || breakpointDownSm.value),
)
const isVisible = computed(() => !!state.value || (props.onlyMobile && !breakpointDownSm.value))
const isCanBeOpenAsPopup = computed(() => breakpointDownSm.value || !props.onlyMobile)

const transitionNameForPopup = computed(() =>
  isCanBeOpenAsPopup.value ? props.transitionName : '',
)

const idPopup = computed(() => `popup-id-${props.name}`)
const idPopupContent = computed(() => `${idPopup.value}-content`)

const state = computed(() => isModalOpen(props.name))

const { setClose, setState, createModal } = usePopup(props)

const closeOnEsc = (event) => {
  setState(event.key !== 'Escape', props.analyticsId)
}

const addListenerIfPopupIsOpen = () => {
  document.addEventListener('keydown', closeOnEsc)
}

const removeListenerIfPopupIsClose = () => {
  document.removeEventListener('keydown', closeOnEsc)
}

const refs = {
  [idPopup.value]: ref(null),
  [idPopupContent.value]: ref(null),
}

const movePopupUp = () => {
  document.body.appendChild(refs[idPopupContent.value].value)
}

const movePopupHome = () => {
  refs[idPopup.value].value.appendChild(refs[idPopupContent.value].value)
}

const backdropEventTypes = ref([])
const addBackdropEventType = ({ type }) => {
  if (backdropEventTypes.value.length > 10) {
    backdropEventTypes.value.shift()
  }
  backdropEventTypes.value.push(type)
}

const setCloseBackdrop = (e) => {
  addBackdropEventType(e)
  const validClickEventSequence = ['mousedown', 'mouseup', 'click']
  const isValidClick = backdropEventTypes.value
    .toString()
    .includes(validClickEventSequence.toString())

  if (isValidClick && !props.disableClose) {
    setClose(props.analyticsId)
  }

  backdropEventTypes.value = []
}

watch(
  () => state.value,
  (newVal) => {
    if (isCanBeOpenAsPopup.value && !props.disableClose) {
      if (newVal) {
        movePopupUp()
        addListenerIfPopupIsOpen()
      } else {
        removeListenerIfPopupIsClose()
      }
    }
  },
)

watch(
  () => breakpointDownSm.value,
  () => {
    if (props.onlyMobile && !breakpointDownSm.value) {
      setClose(props.analyticsId)
      movePopupHome()
    }
  },
)

watch(
  () => isVisible.value,
  (newState) => {
    emits(newState ? 'open' : 'close')
  },
)

createModal({
  id: props.name,
  isOpen: false,
  ...(props.collisionGroup
    ? {
        collisionGroup: props.collisionGroup,
      }
    : {}),
})
</script>

<template>
  <div :ref="refs[idPopup]">
    <div :ref="refs[idPopupContent]">
      <template v-if="isVisibleWithoutPopup">
        <slot />
      </template>
      <Transition :name="transitionNameForPopup">
        <div
          v-if="isVisibleAsPopup"
          :class="`popup popup--${props.size} ${props.classCss}`"
          @click="setCloseBackdrop">
          <div
            ref="popupAlign"
            class="popup__wrap-align"
            @mousedown.left.self="addBackdropEventType"
            @mouseup.left.self="addBackdropEventType">
            <div
              ref="popupContent"
              class="popup__content"
              @click.stop>
              <div
                :class="`popup__box js-area-click-outside ${props.boxClassCss}`"
                data-tid="modal-wrapper">
                <button
                  v-if="!props.disableClose"
                  type="button"
                  class="popup__close"
                  :class="props.classCloseCross"
                  :data-tid="props.dataTidCloseCross"
                  @click="() => setClose(props.analyticsId)">
                  <Icon
                    class="popup__close-icon"
                    icon="cross" />
                </button>
                <slot :close="() => setClose(props.analyticsId)" />
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </div>
  </div>
</template>

<style lang="scss">
@use '@/legacy' as *;

.popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zix-modal;
  width: 100%;
  height: 100%;
  overflow: hidden;
  color: color('gray-darker');
  background: color('black', 0.8);

  .is-scroll-hidden & {
    overflow: auto;
  }

  &__wrap-align {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: center;
    outline: none;

    &::before {
      display: inline-block;
      height: 100%;
      vertical-align: middle;
      content: '';
    }
  }

  &__content {
    position: relative;
    display: inline-block;
    max-width: 100%;
    margin: 12px;
    text-align: left;
    vertical-align: middle;
  }

  &__box {
    position: relative;
    padding: 24px 12px;
    margin: 0 auto;
    background: color('white');

    @include media-breakpoint-up(sm) {
      padding: 24px;
    }
  }

  &__close {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: $zix-above + $zix-modal;
    width: 35px;
    height: 36px;
    padding: 6px;
    background: none;
    border: 0;
  }

  & &__close-icon {
    width: 25px;
    height: 25px;
  }

  &--medium &__box {
    max-width: 880px;
  }

  &--large &__content {
    width: 1000px;
    max-width: calc(100% - 30px);
  }

  &--small &__box {
    max-width: 500px;
  }

  &--x-small &__box {
    max-width: 420px;
  }

  &--voucher-modal &__box {
    width: 90vw;

    @include media-breakpoint-up(sm) {
      width: 420px;
    }
  }

  &--multiple-search &__box {
    max-width: 484px;
  }

  &--cmp-modal,
  &--detailed-cookie-modal {
    z-index: $zix-cookie-modal;
  }

  &--cmp-modal &__content {
    @include media-breakpoint-up(sm) {
      margin: 12px;
    }
  }

  &--cmp-modal &__box {
    max-width: 800px;
    padding: 24px 16px;
    border-radius: var(--borderRadiusMedium);

    @include media-breakpoint-up(md) {
      padding: 40px;
    }
  }

  &--detailed-cookie-modal &__content {
    @include media-breakpoint-up(sm) {
      height: auto;
      margin: 12px;
      vertical-align: middle;
    }
  }

  &--detailed-cookie-modal &__box {
    display: grid;
    grid-template-rows: 42px 1fr 57px;
    max-width: 600px;
    height: calc(100dvh - 24px);
    max-height: 810px;
    padding: 16px 0;
    border-radius: var(--borderRadiusMedium);

    @supports not (height: 100dvh) {
      height: calc(80vh - 24px);
    }

    @include media-breakpoint-up(sm) {
      grid-template-rows: 52px 1fr 66px;
      height: 90vh;
      padding: 24px 0;
    }

    @include media-breakpoint-up(md) {
      padding: 32px 8px;
    }
  }

  &--full-screen & {
    &__wrap-align {
      &::before {
        display: none;
      }
    }

    &__content {
      display: block;
      width: 100%;
      height: 100%;
      margin: 0;
    }

    &__box {
      display: table;
      width: 100%;
      height: 100%;
      padding: 0;
    }
  }
}
</style>
