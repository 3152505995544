<script setup lang="ts">
import { ref, watch } from 'vue'

import { useIntersectionObserver } from '@vueuse/core'

import ProductCarousel from '@/components/Product/ProductCarousel.vue'
import TitleSection from '@/components/global/TitleSection.vue'
import useInteractionSource from '@/composables/useInteractionSource'
import type { Product } from '@/composables/useProductItem'
import { analyticsLoggerWidgetShown } from '@/services/analytics/analyticsLogger'
import { t } from '@/services/translations'

type WidgetProps = {
  products: Product[]
  title?: string
  link?: string
  ghostTitle?: boolean
  border?: boolean
  visibilityRatio?: number
  noProductMsg?: string
}

const props = withDefaults(defineProps<WidgetProps>(), {
  products: () => [],
  visibilityRatio: undefined,
  title: '',
  link: '',
  noProductMsg: '',
})

const emit = defineEmits<{
  intersecting: []
}>()

const rootElement = ref(null)

useIntersectionObserver(rootElement, ([{ isIntersecting }]) => {
  if (isIntersecting) {
    emit('intersecting')
  }
})

const dummyProducts = new Array(8).fill(null)

const source = useInteractionSource('Widget')
watch(
  () => props.products,
  (newValue) => {
    if (newValue.length > 0) {
      analyticsLoggerWidgetShown(source({ products: props.products }))
    }
  },
  {
    immediate: true,
  },
)
</script>

<template>
  <div ref="rootElement">
    <TitleSection
      v-if="props.ghostTitle || props.title || props.link"
      :title="props.title"
      :link="props.link"
      :btn-text="t('general.showAll')"
      data-tid="widget__title" />
    <ProductCarousel
      :class="{
        'slides-border': props.border,
      }"
      :products="props.products.length ? props.products : dummyProducts"
      :ghost="!props.products.length"
      :visibility-ratio="props.visibilityRatio"
      :no-product-msg="props.noProductMsg" />
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.slides-border {
  margin-top: -1px;
  border: 1px solid color('gray-light');
}
</style>
