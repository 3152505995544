import { computed } from 'vue'

import type { ImgSrc, ImgVariant } from '@/components/stories/atoms/imgResponsive/types'
import { useSrcset } from '@/composables/useSrcset'
import type { Banner } from '@/data/api/frontApi/banners/bannerTypes'
import { getMaxMedia } from '@/services/responsiveMedia'

import useBanners from './useBanners'

type BannerThankYouPage = {
  id: Banner['id']
  name: Banner['name']
  caption: Banner['caption']
  link: Banner['link']
  imgResponsive: {
    srcset: ImgSrc[]
    variants: ImgVariant[]
  }
}

const BANNER_THANK_YOU_PAGE_SIZE = {
  base: {
    width: 645,
    height: 230,
  },
  maxXs: {
    width: 280,
    height: 140,
  },
  maxSm: {
    width: 364,
    height: 180,
  },
} as const

const RESIZE_TYPE = 'SHRINK_ONLY'

function createBannerSrcset(type: keyof typeof BANNER_THANK_YOU_PAGE_SIZE, urlBanner: string) {
  return useSrcset(
    urlBanner,
    BANNER_THANK_YOU_PAGE_SIZE[type].width,
    BANNER_THANK_YOU_PAGE_SIZE[type].height,
    RESIZE_TYPE,
  )
}

function setVariantsImage(newBanners: Banner[]): BannerThankYouPage[] {
  return newBanners.map((banner) => {
    const urlBannerImage = banner.images.main

    return {
      ...banner,
      imgResponsive: {
        srcset: createBannerSrcset('base', urlBannerImage),
        variants: [
          {
            media: getMaxMedia('xs'),
            srcset: createBannerSrcset('maxXs', urlBannerImage),
          },
          {
            media: getMaxMedia('sm'),
            srcset: createBannerSrcset('maxSm', urlBannerImage),
          },
        ],
      },
    }
  })
}

function useBannerThankYouPage() {
  const { banners: newBanners } = useBanners('thankYouPage')

  const banners = computed(() => setVariantsImage(newBanners.value))

  return banners
}

export { useBannerThankYouPage, BANNER_THANK_YOU_PAGE_SIZE }

export type { BannerThankYouPage }
