const ResizeType = {
  FIT: 1,
  SHRINK_ONLY: 8,
} as const

type ResizeTypeName = keyof typeof ResizeType

/**
 *
 * @param imgUrl url of image to resizer.
 * @param width required width of image
 * @param height required height of image
 * @param resizeTypeName required resizing type of image
 * @returns
 */
const resizer = (
  imgUrl: string,
  width: number,
  height: number = width,
  resizeTypeName: ResizeTypeName = 'FIT',
) => {
  const resizeTypeNumber = ResizeType[resizeTypeName] ?? null

  return imgUrl.replace('WIDTHxHEIGHTx1', `${width}x${height}x${resizeTypeNumber}`)
}

export { resizer }

export type { ResizeTypeName }
