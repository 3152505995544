import type { Dayjs } from 'dayjs'

type FormatDateOptions = {
  showYear?: boolean
  noWrap?: boolean
  customFormat?: string
}

const defaultFormatDateOptions = {
  showYear: false,
  noWrap: false,
  customFormat: '',
}

/**
 * Formats date into Czech format. Eg. "1. 2. 2023".
 * Returns null if date is invalid.
 * @param dayjsDate Dayjs object.
 * @param options Object with formatting options.
 *   - showYear (false): Show year in the result.
 *   - noWrap (false): If true, spaces are replaced with non-breaking spaces HTML entity.
 *                     Eg. "1.&nbsp;2.&nbsp;2023."
 *   - customFormat: Custom format of a date. Overrides default format and showYear options.
 * @returns Formatted date or null.
 */
function formatDate(dayjsDate: Dayjs | null | undefined, options?: FormatDateOptions) {
  const { showYear, noWrap, customFormat } = { ...defaultFormatDateOptions, ...options }

  // All invalid values return null.
  if (!dayjsDate?.isValid?.()) {
    return null
  }

  let resultFormat: string

  if (customFormat.length) {
    resultFormat = customFormat
  } else {
    const basicDateFormat = 'D. M.'
    const yearFormat = ' YYYY'
    resultFormat = basicDateFormat
    if (showYear) {
      resultFormat += yearFormat
    }
  }
  if (noWrap) {
    resultFormat = resultFormat.replace(/ /g, ' ')
  }

  return dayjsDate.tz().format(resultFormat)
}

export default formatDate
