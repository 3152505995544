import debounce from 'lodash-es/debounce'

class Tooltip {
  parentElement: Element
  element: HTMLDivElement
  delay: number
  autoHide: () => void

  constructor(parentElement: Element, element: HTMLDivElement, delay: number) {
    this.parentElement = parentElement
    this.element = element
    this.delay = delay
    this.autoHide = debounce(() => {
      this.hide()
    }, this.delay)
  }

  getVisibleElement(): HTMLElement | null {
    const elementQuery = '.' + this.element.className.split(' ').join('.')
    return this.parentElement.querySelector(elementQuery)
  }

  show() {
    if (this.getVisibleElement()) return

    this.parentElement.appendChild(this.element)
    if (this.delay) {
      this.autoHide()
    }
  }

  hide() {
    const element = this.getVisibleElement()
    if (element) {
      element.parentNode?.removeChild(element)
    }
  }
}

export default Tooltip
