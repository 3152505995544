import { configurationQueryKeys } from '@/constants/queryKeys'
import type { ConfigurationWebResponse } from '@/data/api/frontApi/configuration/configurationTypes'
import { frontApiPutPrivacySettings } from '@/services/api/front/privacy'
import queryClient from '@/services/queryClient'

type PrivacySettingsPayload = {
  code: string
  value: boolean
}

function useChangePrivacySettings() {
  function adjustPrivacySettings(payload: PrivacySettingsPayload) {
    const { code, value } = payload
    frontApiPutPrivacySettings(code, value)

    if (code === 'newsletter') {
      queryClient.setQueryData(configurationQueryKeys.all, (oldData: ConfigurationWebResponse) => ({
        ...oldData,
        user: { ...oldData.user, newsletterEnabled: value },
      }))
    }
  }

  return adjustPrivacySettings
}

export default useChangePrivacySettings
