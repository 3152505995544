import type {
  Notification,
  NotificationImpressionSource,
} from '@/components/Notification/notificationTypes'

import { dataLayerPush, mapInStoreNotification } from './utils'

function notificationImpression(
  impressionSource: NotificationImpressionSource,
  notifications: Notification[],
) {
  dataLayerPush({
    event: 'notification_impression',
    type: impressionSource,
    notifications: notifications.map(mapInStoreNotification),
  })
}

export { notificationImpression as gtmEventNotificationImpression }
