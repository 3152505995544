<script setup lang="ts">
import { ref, computed } from 'vue'

import useInteractionSource from '@/composables/useInteractionSource'
import type { Product } from '@/composables/useProductItem'
import WidgetsAdmin from '@/constants/widgetsAdmin'
import { analyticsLoggerWidgetContent } from '@/services/analytics/analyticsLogger'
import { t } from '@/services/translations'
import useProductsStore from '@/store/pinia/useProductsStore'

import Widget from './components/Widget.vue'

type WidgetAdminProps = {
  code: string
  border?: boolean
  customTitle?: string
  ghostTitle?: boolean
  visibilityRatio?: number
}

const props = withDefaults(defineProps<WidgetAdminProps>(), {
  customTitle: '',
  ghostTitle: true,
  visibilityRatio: undefined,
})

const { fetchProductWidget } = useProductsStore()

const title = ref('')
const link = ref('')
const products = ref<Product[]>([])
const isLoaded = ref(false)

const loadProducts = async () => {
  if (isLoaded.value) return

  const fetchedProduct = await fetchProductWidget({ code: props.code })
  analyticsLoggerWidgetContent(fetchedProduct)

  title.value = fetchedProduct.title ?? ''
  link.value = fetchedProduct.link ?? ''
  products.value = fetchedProduct.products

  isLoaded.value = true
}

const interactionSource = computed(() => ({
  code: props.code,
}))

const computedTitle = computed(() => {
  /**
   * Widget is also visible on PageProducts but with different title from Admin
   * @property {string} props.code - Stored in database, can be changed by migrations, is same for all domains
   */
  if (props.code === WidgetsAdmin.MULTIBUY) {
    return t('widget.multibuy.title')
  }

  return props.customTitle || title.value
})

useInteractionSource('WidgetAdmin', interactionSource)
</script>

<template>
  <Widget
    v-if="!isLoaded || products.length > 0"
    :data-tid="`widget-${props.code}`"
    :products="products"
    :title="computedTitle"
    :link="link"
    :border="props.border"
    :ghost-title="props.ghostTitle"
    :visibility-ratio="props.visibilityRatio"
    @intersecting="loadProducts" />
</template>
