import { createOperation } from '@/services/apiv2'

import type { MenuMainQueryParams, MenuMainResponse } from './menuTypes'

const main = createOperation<
  MenuMainResponse,
  MenuMainResponse['categories'],
  undefined,
  undefined,
  MenuMainQueryParams
>('/menu/main', {
  method: 'get',
  responseConverter: (data) => data.categories,
})
export default main
