<script setup lang="ts">
import Icon from '@/components/Icon/Icon.vue'
import ImgResponsive from '@/components/stories/atoms/imgResponsive/ImgResponsive.vue'
import appConfig from '@/services/appConfig'
import { t } from '@/services/translations'
import useDeviceDetectorStore from '@/store/pinia/useDeviceDetectorStore'

const deviceDetectorStore = useDeviceDetectorStore()
</script>

<template>
  <div
    v-if="appConfig.mobileAppLinks && deviceDetectorStore.breakpointUpMd"
    class="container app-banner d-flex justify-content-between align-items-center my-4 pl-4 pr-0 px-lg-4">
    <div class="app-banner__text">
      <h4
        class="text-9 text-white mb-3 font-weight-bold"
        v-html="t('appBanner.text')" />
      <div class="d-flex align-items-center">
        <a
          :href="appConfig.mobileAppLinks.googlePlay"
          target="_blank">
          <img
            :src="`/vue/img/welcome/google-play-${appConfig.domain}.webp`"
            alt="googleplay"
            class="googleplay"
            lazyload />
        </a>
        <a
          :href="appConfig.mobileAppLinks.appStore"
          target="_blank">
          <img
            :src="`/vue/img/welcome/app-store-${appConfig.domain}.webp`"
            alt="appstore"
            class="appstore ml-2"
            lazyload />
        </a>
      </div>
    </div>
    <div class="app-banner__img h-100">
      <ImgResponsive
        :srcset="[
          {
            url: `/vue/img/app-banner/phones-${appConfig.domain}.webp?v=1`,
          },
          {
            pixelRatio: 1.5,
            url: `/vue/img/app-banner/phones-${appConfig.domain}@2x.webp?v=1`,
          },
        ]"
        alt="kosik-app"
        class="app-banner__picture h-100"
        lazyload />
    </div>
    <div class="d-none d-lg-flex qrcode-container p-3 bg-white">
      <Icon
        group="welcome"
        :icon="`qrcode-${appConfig.domain}`"
        class="text-black" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.app-banner {
  height: 180px;
  background-color: color('primary');
  border-radius: var(--borderRadiusMedium);

  &__text,
  &__qr {
    height: min-content;
  }

  &__img {
    overflow: hidden;
  }

  &__picture {
    width: 440px;
    aspect-ratio: 440/180;
    margin-right: -33px;

    @include media-breakpoint-up(lg) {
      margin-right: 0;
    }
  }
}

.appstore {
  max-width: 144px;
}

.googleplay {
  max-width: 160px;
}

.icon {
  $size: 8em;

  width: $size;
  height: $size;
}

.qrcode-container {
  border-radius: var(--borderRadiusMedium);
}
</style>
