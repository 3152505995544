import { createOperation } from '@/services/apiv2'

import type { PrelaunchValidateQueryParams, PrelaunchValidateResponse } from './addressTypes'

const getPrelaunchValidate = createOperation<
  PrelaunchValidateResponse,
  PrelaunchValidateResponse,
  undefined,
  undefined,
  PrelaunchValidateQueryParams
>('/address/validate/prelaunch', {
  method: 'get',
})

export default getPrelaunchValidate
