import { ref } from 'vue'

import type { ResizeClubBannersType } from '@/components/BannerClub/resizeClubBanners'
import type { BannerThankYouPage } from '@/composables/banner/useBannerThankYouPage'
import type { Banner } from '@/data/api/frontApi/banners/bannerTypes'

import { analyticsLoggerBannerImpression } from '../../analyticsLogger'
import type { AdminBannerType, PromotionName } from '../bannerClickOrImpression'

type bannersArray = (Banner | BannerThankYouPage | ResizeClubBannersType[number])[]

const alreadySeenBanners = ref<bannersArray>([])

// Used with vIntersectionObserver, observed banner returns information.
// If banner is intersecting, then send event to gtm, add it to array so that the event is send only once per banner
function intersectingBanner(
  name: PromotionName,
  banner: Banner | BannerThankYouPage | ResizeClubBannersType[number],
  type: AdminBannerType,
  index?: number,
) {
  return function (visibleBanner: IntersectionObserverEntry[]) {
    if (!visibleBanner[0].isIntersecting || alreadySeenBanners.value.includes(banner)) return
    alreadySeenBanners.value = [...alreadySeenBanners.value, banner]
    analyticsLoggerBannerImpression(name, banner, type, index)
  }
}

export default intersectingBanner
