<script setup lang="ts">
import { ref, watch } from 'vue'

import { animationFrame } from '@/utils/animationFrame'

type AnimationSwitchContentProps = {
  showSecondary: boolean
}

const props = defineProps<AnimationSwitchContentProps>()

const initShowSecondary = ref(props.showSecondary)
const elementOut = ref<HTMLDivElement | null>(null)
const heightOut = ref<number | null>(null)
const elementIn = ref<HTMLDivElement | null>(null)
const heightIn = ref<number | null>(null)

const wrapPrimary = ref<HTMLDivElement | null>(null)
const wrapSecondary = ref<HTMLDivElement | null>(null)
const wrap = ref<HTMLDivElement | null>(null)

watch(() => props.showSecondary, animate)

async function animate() {
  beforeAnimation()
  await animationFrame()
  processAnimation()
  const callAfterAnimation = () => {
    elementOut.value?.removeEventListener('transitionend', callAfterAnimation)
    afterAnimation()
  }
  elementOut.value?.addEventListener('transitionend', callAfterAnimation)
}

function beforeAnimation() {
  elementOut.value = props.showSecondary ? wrapPrimary.value : wrapSecondary.value
  elementIn.value = props.showSecondary ? wrapSecondary.value : wrapPrimary.value

  if (elementOut.value) {
    elementOut.value.style.opacity = '1'
    elementOut.value.style.position = 'absolute'
    heightOut.value = elementOut.value.offsetHeight
  }

  if (elementIn.value) {
    elementIn.value.style.opacity = '0'
    elementIn.value.classList.remove('d-none')
    elementIn.value.style.position = 'absolute'
    heightIn.value = elementIn.value.offsetHeight
  }

  if (wrap.value) {
    wrap.value.style.height = `${heightOut.value}px`
  }
}

function processAnimation() {
  if (elementOut.value) {
    elementOut.value.style.opacity = '0'
  }
  if (elementIn.value) {
    elementIn.value.style.opacity = '1'
  }
  if (wrap.value) {
    wrap.value.style.height = `${heightIn.value}px`
  }
}

function afterAnimation() {
  if (elementOut.value) {
    elementOut.value.classList.add('d-none')
    elementOut.value.style.position = ''
    elementOut.value.style.opacity = ''
  }
  if (elementIn.value) {
    elementIn.value.style.position = ''
    elementIn.value.style.opacity = ''
  }
  if (wrap.value) {
    wrap.value.style.height = ''
  }
}
</script>

<template>
  <div
    ref="wrap"
    class="animation-switch-content">
    <div
      ref="wrapPrimary"
      :class="[
        'content',
        {
          'd-none': initShowSecondary,
        },
      ]">
      <slot name="primary" />
    </div>
    <div
      ref="wrapSecondary"
      :class="[
        'content',
        {
          'd-none': !initShowSecondary,
        },
      ]">
      <slot name="secondary" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.animation-switch-content {
  position: relative;
  overflow: hidden;
  transition: height var(--baseTransitionTime);
}

.content {
  top: 0;
  left: 0;
  width: 100%;
  transition: opacity var(--baseTransitionTime);
}
</style>
