<script setup lang="ts">
import type { ImgSrc, ImgVariant } from '@/components/stories/atoms/imgResponsive/types'

type ImgResponsiveProps = {
  srcset: ImgSrc[]
  alt?: string
  variants?: ImgVariant[]
  lazyload?: boolean
  classImg?: string
  fetchPriority?: boolean
}
const props = defineProps<ImgResponsiveProps>()

function stringifySrcset(srcset: ImgSrc[]) {
  return srcset
    .map((value) => value.url + (value.pixelRatio ? ` ${value.pixelRatio}x` : ''))
    .join(', ')
}
</script>

<template>
  <picture class="img-responsive">
    <source
      v-for="variant in props.variants"
      :key="stringifySrcset(variant.srcset)"
      :srcset="stringifySrcset(variant.srcset)"
      :media="variant.media" />
    <img
      :key="stringifySrcset(props.srcset)"
      :loading="props.lazyload ? 'lazy' : undefined"
      :fetchpriority="props.fetchPriority ? 'high' : undefined"
      :srcset="stringifySrcset(props.srcset)"
      :alt="props.alt"
      :class="['mh-100', props.classImg]" />
  </picture>
</template>

<style lang="scss" scoped>
.img-responsive {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
</style>
