import { createOperation } from '@/services/apiv2'

import type { ValidateSubdestinationParams, ValidateSubdestinationResponse } from './addressTypes'

const validateSubdestination = createOperation<
  ValidateSubdestinationResponse,
  boolean,
  undefined,
  undefined,
  ValidateSubdestinationParams
>('/address/validate/sub-destination', {
  method: 'get',
  responseConverter: (data) => data.isSubDestinationOrderThresholdsChange,
})

export default validateSubdestination
