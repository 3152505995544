import appConfig from '../appConfig'
import { MsalErrorCode, MsalRedirectFlow } from './aadConstants'
import type { AuthenticationResultWithClaims } from './aadTypes'
import getMsalInstance from './getMsalInstance'
import isMsalError from './isMsalError'
import launchMsalRedirect from './launchMsalRedirect'
import selectActiveAccount from './selectActiveAccount'

let memoizedPromise: Promise<AuthenticationResultWithClaims | null> | null = null

async function msalRedirectResponse() {
  const msalInstance = await getMsalInstance() // throws error if MSAL package is not loaded

  try {
    // This is very important to be awaited before any MSAL action that needs token!
    // See https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/acquire-token.md#always-wait-for-promises-to-resolve
    const response =
      (await msalInstance.handleRedirectPromise()) as AuthenticationResultWithClaims | null

    if (response) {
      if (response.account) {
        msalInstance.setActiveAccount(response.account)
      }

      return response
    }

    selectActiveAccount(msalInstance)

    return null
  } catch (error) {
    // check error has property called errorMessage
    if (isMsalError(error)) {
      // AAD B2C throws errors with property errorMessage containing both error code and message.
      if (error.errorMessage.includes(MsalErrorCode.userCanceled)) {
        return null
      }

      if (error.errorMessage.includes(MsalErrorCode.forgottenPassword)) {
        await launchMsalRedirect(msalInstance, {
          flow: MsalRedirectFlow.passwordReset,
          authority: appConfig.auth.authorityPasswordReset,
        })
        return null
      }

      if (error.message.includes(MsalErrorCode.redirectUriMismatch)) {
        return null
      }
    }

    throw error
  }
}

async function getMsalRedirectResponse() {
  if (memoizedPromise === null) {
    memoizedPromise = msalRedirectResponse()
  }

  return memoizedPromise
}

export default getMsalRedirectResponse
