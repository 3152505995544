<script setup lang="ts">
import { computed } from 'vue'

import dayjs, { type Dayjs } from 'dayjs'

import Icon from '@/components/Icon/Icon.vue'
import { t } from '@/services/translations'
import formatDate from '@/utils/date/formatDate'

type FutureDeliveryTagProps = {
  firstOrderDay: Dayjs
}
const props = defineProps<FutureDeliveryTagProps>()

const translation = computed(() => {
  const firstOrderDay = props.firstOrderDay

  if (firstOrderDay.tz().isTomorrow()) {
    return t('product.canBeDeliveredTomorrow')
  } else if (firstOrderDay.tz().isSame(dayjs().add(2, 'day'), 'day')) {
    return t('product.canBeDeliveredInTwoDays')
  } else {
    return t('product.canBeDeliveredIn', { date: formatDate(firstOrderDay) })
  }
})
</script>

<template>
  <div
    v-if="firstOrderDay.isValid()"
    class="tw-inline-flex tw-items-center tw-justify-center tw-gap-1 tw-rounded tw-bg-ds-tint-orange tw-px-4 tw-py-1 tw-text-ds-paragraph tw-text-ds-sm">
    <Icon
      class="tw-h-5 tw-w-5 tw-min-w-5"
      icon="add-alert-clock"
      group="product" />
    <div v-text="translation" />
  </div>
</template>
