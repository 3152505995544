<script setup lang="ts">
import { ref } from 'vue'

import useAuth from '@/composables/useAuth'
import type { GTMLoginClickIntention } from '@/composables/useAuth'

type UserLoginProps = {
  /**
   * Name of a router path to redirect to after successful login.
   * Leave empty to redirect to the current page.
   */
  redirectName?: string
  /**
   * Click intention for GTM.
   */
  gtmClickIntention?: GTMLoginClickIntention
}

const props = withDefaults(defineProps<UserLoginProps>(), {
  redirectName: undefined,
  gtmClickIntention: 'both',
})

const redirectNameRef = ref(props.redirectName)

const { handleLoginClick } = useAuth(redirectNameRef)
</script>

<template>
  <button
    class="btn p-0"
    @click.prevent="handleLoginClick(props.gtmClickIntention)">
    <slot />
  </button>
</template>
