import { computed, ref } from 'vue'

import { defineStore } from 'pinia'

import { useSelectAddressModal } from '@/composables/address'
//import type { Product } from '@/composables/useProductItem'
import store from '@/store'
import type { ProductQuantityProps } from '@/store/modules/cart'

import useAddressStore from '../useAddressStore'
import calculateGroups from './calculateGroups'

const useCartStore = defineStore('useCartStore', () => {
  const addressStore = useAddressStore()
  const openAddressModal = useSelectAddressModal()

  const onHoldProduct = ref<ProductQuantityProps | null>(null)

  function continueOnHoldProduct() {
    if (!onHoldProduct.value) return

    store.dispatch.cart.setCartProductQuantity(onHoldProduct.value)
    onHoldProduct.value = null
  }

  // @todo composable mozna
  function checkAddressAndHoldProductIfNeeded(productQuantityProps: ProductQuantityProps) {
    if (!addressStore.isUserStreetOrCityFilled) {
      onHoldProduct.value = { ...productQuantityProps }
      openAddressModal({}, 'add-to-cart')
      return true
    }
    return false
  }

  const assocGroups = computed(() => calculateGroups(store.getters.cart.getCartProductsFullData))

  const cartGetters = computed(() => ({
    getSelectedTimeslot: store.getters.cart.getSelectedTimeslot,
    getCartProducts: store.getters.cart.getCartProducts,
    getCartProductsByIds: store.getters.cart.getCartProductsByIds,
    getCartProduct: store.getters.cart.getCartProduct,
    getCartProductsFullData: store.getters.cart.getCartProductsFullData,
    getCartProductFullData: store.getters.cart.getCartProductFullData,
    getCartProductsInCategories: store.getters.cart.getCartProductsInCategories,
    getCartReplaceableProducts: store.getters.cart.getCartReplaceableProducts,
    getCartProductsSubsetFullData: store.getters.cart.getCartProductsSubsetFullData,
    getCartProductsCount: store.getters.cart.getCartProductsCount,
    getCartReplaceableProductsCount: store.getters.cart.getCartReplaceableProductsCount,
    getCartProductsPrice: store.getters.cart.getCartProductsPrice,
    getCartProductsRealPrice: store.getters.cart.getCartProductsRealPrice,
    getCartProductsTotalPrice: store.getters.cart.getCartProductsTotalPrice,
    getCartTotalPrice: store.getters.cart.getCartTotalPrice,
    getCartTotalPriceWithDeposits: store.getters.cart.getCartTotalPriceWithDeposits,
    getCartTotalDiscount: store.getters.cart.getCartTotalDiscount,
    getCalcCarriersQuantity: store.getters.cart.getCalcCarriersQuantity,
    getCartGifts: store.getters.cart.getCartGifts,
    getCartGift: store.getters.cart.getCartGift,
    isCartLoaded: store.getters.cart.isCartLoaded,
    isReplacementsRefusalVisible: store.getters.cart.isReplacementsRefusalVisible,
    isRefusedReplacement: store.getters.cart.isRefusedReplacement,
    isAllowedCheckout: store.getters.cart.isAllowedCheckout,
    getInteractionSource: store.getters.cart.getInteractionSource,
    getCurrentlyReplacedProduct: store.getters.cart.getCurrentlyReplacedProduct,
    getCartCheckoutPrices: store.getters.cart.getCartCheckoutPrices,
    getCartAdditionalOrders: store.getters.cart.getCartAdditionalOrders,
    hasELicenceOrReturnablesProducts: store.getters.cart.hasELicenceOrReturnablesProducts,
    hasAdditionalOrders: store.getters.cart.hasAdditionalOrders,
    hasMoreAdditionalOrders: store.getters.cart.hasMoreAdditionalOrders,
    hasBaseVendorProduct: store.getters.cart.hasBaseVendorProduct,
    hasNonBaseVendorProduct: store.getters.cart.hasNonBaseVendorProduct,
    hasMarketPlaceProducts: store.getters.cart.hasMarketPlaceProducts,
    hasFreeDelivery: store.getters.cart.hasFreeDelivery,
  }))

  const cartActions = computed(() => ({
    setCartProduct: store.dispatch.cart.setCartProduct,
    initCart: store.dispatch.cart.initCart,
    setCartGifts: store.dispatch.cart.setCartGifts,
    removeCartGift: store.dispatch.cart.removeCartGift,
    setRefuseReplacement: store.dispatch.cart.setRefuseReplacement,
    updateCartPrices: store.dispatch.cart.updateCartPrices,
    setSelectedTimeslot: store.dispatch.cart.setSelectedTimeslot,
    setCartQuantityToMaxAvailable: store.dispatch.cart.setCartQuantityToMaxAvailable,
    setCartProductQuantity: store.dispatch.cart.setCartProductQuantity,
    sendToCartApiDebounce: store.dispatch.cart.sendToCartApiDebounce,
    sendToCartApi: store.dispatch.cart.sendToCartApi,
    setErrorsAfterSendToCartApi: store.dispatch.cart.setErrorsAfterSendToCartApi,
    setCartProductsFromOrder: store.dispatch.cart.setCartProductsFromOrder,
    revertCartProduct: store.dispatch.cart.revertCartProduct,
    removeCartProduct: store.dispatch.cart.removeCartProduct,
    useReturnableCarrier: store.dispatch.cart.useReturnableCarrier,
    removeReturnableCarrier: store.dispatch.cart.removeReturnableCarrier,
    clearCart: store.dispatch.cart.clearCart,
    setCurrentlyReplacedProduct: store.dispatch.cart.setCurrentlyReplacedProduct,
    clearCurrentlyReplacedProduct: store.dispatch.cart.clearCurrentlyReplacedProduct,
  }))

  return {
    assocGroups,
    cartGetters,
    cartActions,
    onHoldProduct,
    checkAddressAndHoldProductIfNeeded,
    continueOnHoldProduct,
  }
})

export default useCartStore
