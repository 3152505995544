import type { InteractionSourceRaw } from '@/composables/useInteractionSource'
import type { Product } from '@/composables/useProductItem'
import { pick, pickItemListName } from '@/services/analytics/gtm/utils/pick'
import { t } from '@/services/translations'

import { dataLayerPush } from './utils'

function viewItem(source: InteractionSourceRaw[], product: Product) {
  const itemListName = pickItemListName(source)
  const isModalOpen = pick<boolean>('isModalOpen', source, false)
  dataLayerPush({
    event: 'view_item',
    item_list_name: itemListName,
    ecommerce: {
      currency: t('general.currencyCode'),
      value: product.origin.price,
      modal: isModalOpen,
      vendor: product.origin.vendorId,
      items: [
        {
          item_id: product.id,
          item_name: product.origin.name,
          price: product.origin.price,
          quantity: 1, // fixedQuantity for this event
        },
      ],
      item_group_id: product.origin.detail?.associationCode,
    },
  })
}

export { viewItem as gtmEventViewItem }
