import type { ApiEmptyResponse } from '@/services/api/types'
import appConfig from '@/services/appConfig'
import type { SelectedTimeslot } from '@/store/modules/cart'

import { fetchPost, fetchPut, fetchGet } from '../fetch'
import type {
  CheckoutGetPaymentsResponse,
  CheckoutSetPaymentRequest,
  CheckoutSetPaymentResponse,
  CheckoutTransportRequest,
  CheckoutTransportResponse,
  CheckoutFingerprintRequest,
  CheckoutPayAppleRequest,
  CheckoutFingerprintResponse,
  CheckoutFinishResponse,
  CheckoutPayGoogleRequest,
  CheckoutDetailResponse,
  CheckoutDeliveryAddressResponse,
} from './checkoutTypes'

const modulePath = 'checkout'

/**
 * Sends PUT request for setting transport slot.
 * @see https://sasandboxstdhotapidocs.z1.web.core.windows.net/#/Checkout/setTransport
 * @param id Id of a chosen timeslot.
 * @param start Start date and time of chosen timeslot.
 * @param end End date and time of a chosen timeslot.
 * @returns Promise of the API response with cart prices object.
 */
function putTransport(timeslot: SelectedTimeslot) {
  const putData: CheckoutTransportRequest = {
    id: timeslot.id,
    start: timeslot.start.format('YYYY-MM-DDTHH:mm:ssZ'),
    end: timeslot.end.format('YYYY-MM-DDTHH:mm:ssZ'),
  }

  return fetchPut<CheckoutTransportResponse>(
    appConfig.frontApiBaseUrl + modulePath + '/transport',
    putData,
  )
}

/**
 * Sends POST request with fingerprint data.
 * @param orderId Id of current order.
 * @param data Object with fingerprint data.
 * @see https://github.com/csob/paymentgateway/wiki/Methods-for-OneClick-payment#fingerprintbrowser-data-
 * @returns
 */
function postFingerprint(orderId: number, data: CheckoutFingerprintRequest) {
  return fetchPost<CheckoutDetailResponse>(
    `${appConfig.frontApiBaseUrl}${modulePath}/fingerprint/${orderId}`,
    undefined,
    data,
  )
}

/**
 * Used to complete the order.
 * @see https://sasandboxstdhotapidocs.z1.web.core.windows.net/#/Checkout/createFinish
 * @returns Promise of the API response.
 */
function postFinish() {
  return fetchPost<CheckoutFinishResponse>(appConfig.frontApiBaseUrl + modulePath + '/finish')
}

/**
 * Used to complete the additional order.
 * @param parentOrderId
 * @see https://sasandboxstdhotapidocs.z1.web.core.windows.net/#/Checkout/createAdditionalOrderCheckoutFinish
 * @returns Promise of the API response.
 */
function postAdditionalOrderFinish(parentOrderId: number) {
  return fetchPost<CheckoutFinishResponse>(
    `${appConfig.frontApiBaseUrl}${modulePath}/additional-order/finish/${parentOrderId}`,
  )
}

/**
 * Endpoint is used to pay orders on iOS devices with ApplePay as the selected payment method
 * @param paymentData
 * @param orderId Id of created order. Obtained from {@link postFinish} or {@link postAdditionalOrderFinish}
 * @see https://sasandboxstdhotapidocs.z1.web.core.windows.net/#/Checkout/applePay
 */
function postPayApple(paymentData: CheckoutPayAppleRequest, orderId: number) {
  return fetchPost<CheckoutFingerprintResponse>(
    `${appConfig.frontApiBaseUrl}${modulePath}/pay/apple/${orderId}`,
    undefined,
    paymentData,
  )
}

/**
 * Check paid payments by orderId
 * @param orderId Id of created order. Obtained from {@link postFinish} or {@link postAdditionalOrderFinish}
 * @see https://sasandboxstdhotapidocs.z1.web.core.windows.net/#/Checkout/readPayCheck
 */
function getPayCheck(orderId: number) {
  return fetchGet<CheckoutFingerprintResponse>(
    `${appConfig.frontApiBaseUrl}${modulePath}/pay/check/${orderId}`,
  )
}

/**
 * Used to process ČSOB payment with provided PaymentData from Google Pay Wallet.
 * Also returns redirectUrl, that is then used to redirect client to either fingerprint, thank you page or rollback.
 * @see https://sasandboxstdhotapidocs.z1.web.core.windows.net/#/Checkout/googlePay
 * @param paymentData Data
 * @param orderId Id of the created order. Obtained from {@link postFinish} {@link postAdditionalOrderFinish}
 * @returns Promise of the API response.
 */
function postPayGoogle(paymentData: CheckoutPayGoogleRequest, orderId: number) {
  return fetchPost<CheckoutFingerprintResponse>(
    `${appConfig.frontApiBaseUrl}${modulePath}/pay/google/${orderId}`,
    undefined,
    paymentData,
  )
}

/**
 * Return all the necessary data to complete the order
 * @see https://sasandboxstdhotapidocs.z1.web.core.windows.net/#/Checkout/readCheckoutDetail
 */
function getCheckoutDetail() {
  return fetchGet<CheckoutDetailResponse>(appConfig.frontApiBaseUrl + modulePath + '/detail')
}

/**
 * Return all the payment methods the user has available for use
 * @see https://sasandboxstdhotapidocs.z1.web.core.windows.net/#/Checkout/readPayment
 */
function getPayments() {
  return fetchGet<CheckoutGetPaymentsResponse>(appConfig.frontApiBaseUrl + modulePath + '/payment')
}

/**
 * Set payment method for checkout
 * @see https://sasandboxstdhotapidocs.z1.web.core.windows.net/#/Checkout/setPayment
 */
function putCheckoutPayment(paymentSettings: CheckoutSetPaymentRequest) {
  return fetchPut<CheckoutSetPaymentResponse>(
    appConfig.frontApiBaseUrl + modulePath + '/payment',
    paymentSettings,
  )
}

/**
 * Set delivery address for checkout
 * @param deliveryAddress Id of address
 * @see https://sasandboxstdhotapidocs.z1.web.core.windows.net/#/Checkout/setDeliveryAddress
 */
function putCheckoutDeliveryAddress(deliveryAddressId: number) {
  return fetchPut<CheckoutDeliveryAddressResponse>(
    appConfig.frontApiBaseUrl + modulePath + '/delivery-address',
    {
      id: deliveryAddressId,
    },
  )
}

/**
 * Set billing address for checkout
 * @param billingAddressId Id of address. If null,  then billing address is deselected.
 * @see https://sasandboxstdhotapidocs.z1.web.core.windows.net/#/Checkout/setBillingAddress
 */
function putCheckoutBillingAddress(billingAddressId: number | null) {
  return fetchPut<ApiEmptyResponse>(appConfig.frontApiBaseUrl + modulePath + '/billing-address', {
    id: billingAddressId,
  })
}

/**
 * Make rollback of order
 * @param orderId Id of order to be rolled back
 */
function putCheckoutRollback(orderId: number) {
  return fetchPut<ApiEmptyResponse>(
    `${appConfig.frontApiBaseUrl}${modulePath}/v2/rollback/${orderId}`,
  )
}

export {
  putTransport as frontApiPutCheckoutTransport,
  postFingerprint as frontApiPostCheckoutFingerprint,
  postFinish as frontApiPostCheckoutFinish,
  postAdditionalOrderFinish as frontApiPostCheckoutAdditionalOrderFinish,
  postPayApple as frontApiPostCheckoutPayApple,
  getPayCheck as frontApiGetCheckoutPayCheck,
  postPayGoogle as frontApiPostCheckoutPayGoogle,
  getCheckoutDetail as frontApiGetCheckoutDetail,
  getPayments as frontApiGetCheckoutPayments,
  putCheckoutPayment as frontApiPutCheckoutPayment,
  putCheckoutDeliveryAddress as frontApiPutCheckoutDeliveryAddress,
  putCheckoutBillingAddress as frontApiPutCheckoutBillingAddress,
  putCheckoutRollback as frontApiPutCheckoutRollback,
}
