/**
 * Remove duplicate objects from array by duplicate value in set key.
 * @returns Filtered array.
 */
const removeDuplicateObjectByKey = (items: any[], key = 'id') => {
  return items.filter((item, index) => {
    const itemKeyValue = item[key]
    for (let i = 0; i < index; i++) {
      if (items[i][key] === itemKeyValue) {
        return false
      }
    }
    return true
  })
}

export { removeDuplicateObjectByKey }
