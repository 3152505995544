const modalIndex = {
  mobileCart: 'MobileCart',
  mobileNavigation: 'MobileNavigation',
  mobileHeader: 'MobileHeader',
  productSearch: 'ProductSearch',
  notifications: 'Notifications',
  benjaminekAddChild: 'BenjaminekAddChild',
  pharmacyWelcome: 'PharmacyWelcome',
  cartAlternatives: 'CartAlternatives',
  addressSelector: 'AddressSelector',
  multipleSearch: 'MultipleSearch',
  navL1: 'NavL1',
  filterRecipes: 'FilterRecipes',
  checkoutDestinationSwitch: 'CheckoutDestinationSwitch',
  checkoutSubDestinationSwitch: 'CheckoutSubDestinationSwitch',
  addressSelectorSubDestinationSwitch: 'AddressSelectorSubDestinationSwitch',
  productsFilter: 'ProductsFilter',
  productDetail: 'ProductDetail',
  deliveryOnLocation: 'DeliveryOnLocation',
  login: 'login',
  cookieModal: 'CookieModal',
  detailedCookieModal: 'DetailedCookieModal',
  voucherConditionsModal: 'VoucherConditionsModal',
  multibuyFaqModal: 'MultibuyFaqModal',
  closelyWatchedModal: 'CloselyWatchedModal',
} as const

export default modalIndex
