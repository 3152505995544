<script setup lang="ts">
import { computed, ref } from 'vue'

import { vIntersectionObserver } from '@vueuse/components'
import { storeToRefs } from 'pinia'

import { CarouselWrapper, CarouselItem } from '@/components/global/Carousel'
import ImgResponsive from '@/components/stories/atoms/imgResponsive/ImgResponsive.vue'
import type { Banner } from '@/data/api/frontApi/banners/bannerTypes'
import { analyticsLoggerBannerImpression } from '@/services/analytics/analyticsLogger'
import onIntersectionObserver from '@/services/analytics/gtm/utils/intersectingBanner'
import { resizer } from '@/services/resizer'
import useDeviceDetectorStore from '@/store/pinia/useDeviceDetectorStore'

type CarouselBannersProps = {
  banners: Banner[]
}

const props = defineProps<CarouselBannersProps>()

const deviceDetectorStore = useDeviceDetectorStore()
const { breakpointUpSm, breakpointUpLg } = storeToRefs(deviceDetectorStore)

const carousel = ref<InstanceType<typeof CarouselWrapper> | null>(null)
function goToIndex(index: number) {
  carousel.value?.setSlide(index)
}

const activeIndex = ref(0)

function setActive(index: number) {
  activeIndex.value = index
}

// First banner has wrong img for mobile in 'banner.images.main'
// so we need to pick it from 'banner.images.mobile'
function bannerFilter(banner: Banner) {
  return banner.images.mobile ? banner.images.mobile : banner.images.main
}

const carouselClass = computed(() => {
  return props.banners.length === 1 ? 'justify-content-center' : ''
})
</script>

<template>
  <div class="home-page-banners">
    <CarouselWrapper
      v-if="banners.length"
      ref="carousel"
      class="carousel-wrapper"
      :carousel-class="carouselClass"
      :visibility-ratio="0.5"
      :hidden-control="!breakpointUpSm"
      :step="1"
      is-banners
      @slide-activated="setActive">
      <CarouselItem
        v-for="(banner, index) in props.banners"
        :key="index"
        v-intersection-observer="
          onIntersectionObserver('view_promotion', banner, 'homepageSecondary', index)
        "
        :class="[
          'mh-100',
          {
            'carousel-item__sm': breakpointUpSm,
            'carousel-item__lg': breakpointUpLg,
          },
        ]">
        <!-- TODO href: can't change to RouterLink as all the urls in admin are absolute -->
        <a
          :href="banner.link"
          @click="
            analyticsLoggerBannerImpression('select_promotion', banner, 'homepageSecondary', index)
          ">
          <ImgResponsive
            class="d-block h-100"
            class-img="banner-img-border w-100"
            fetch-priority
            :srcset="[
              {
                url: resizer(bannerFilter(banner), 700, 300, 'SHRINK_ONLY'),
              },
              {
                url: resizer(bannerFilter(banner), 1400, 600, 'SHRINK_ONLY'),
                pixelRatio: 1.5,
              },
            ]"
            lazyload />
        </a>
      </CarouselItem>
    </CarouselWrapper>
    <div
      v-if="!breakpointUpSm && props.banners.length !== 1"
      class="navigation"
      data-tid="banner__navigation">
      <button
        v-for="(banner, index) in props.banners"
        :key="`banner-hp-nav-${index}`"
        :class="[
          'navigation-item',
          {
            'navigation-item--active': activeIndex === index,
            'navigation-item--hoverable': !deviceDetectorStore.isTouch,
          },
        ]"
        type="button"
        @click="goToIndex(index)">
        <span
          class="sr-only"
          v-text="banner.name" />
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.home-page-banners {
  position: relative;
  margin: 0 auto;

  @include media-breakpoint-up(xl) {
    max-width: $container-width - $grid-gutter-width;
  }

  &::after {
    display: block;
    padding-bottom: calc-height-percentage-by-ratio(485, 180);
    content: '';

    @include media-breakpoint-up(sm) {
      padding-bottom: calc-height-percentage-by-ratio(576, 100);
    }

    @include media-breakpoint-up(lg) {
      padding-bottom: calc-height-percentage-by-ratio(992, 129);
    }
  }
}

.carousel-wrapper {
  position: absolute;
  inset: 0 0 -30px 0;
}

.carousel-item {
  scroll-snap-align: center;
  width: 85%;

  &__sm {
    width: 40%;
    scroll-snap-align: start;
  }

  &__lg {
    width: 30%;
  }
}

:deep(.banner-img-border) {
  border-radius: 8px;
}

:deep(.carousel-content) {
  gap: 16px;
}

.navigation {
  position: absolute;
  bottom: -25px;
  left: 50%;
  z-index: $zix-base;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-50%);
}

.navigation-item {
  position: relative;
  z-index: $zix-base;
  width: 16px;
  height: 24px;
  padding: 0;
  color: transparent;
  cursor: pointer;
  background: transparent;
  border: none;

  &::after {
    position: absolute;
    top: 8px;
    left: 5px;
    width: 8px;
    height: 8px;
    content: '';
    background: color('gray-light');
    border: 1px solid color('gray-light');
    border-radius: 50%;
    transition:
      background var(--baseTransitionTime),
      transform var(--baseTransitionTime);
  }

  &--hoverable:hover {
    &::after {
      background: color('primary-light');
      transform: scale(1.2);
    }
  }

  &--active {
    &::after {
      background: color('white');
      transform: scale(1.4);
    }

    &.navigation-item--hoverable:hover {
      &::after {
        background: color('primary-light');
        transform: scale(1.4);
      }
    }
  }

  @include media-breakpoint-up(lg) {
    width: 24px;
    height: 36px;

    &::after {
      top: 12px;
      left: 6px;
      width: 12px;
      height: 12px;
    }
  }
}

:deep(.control) {
  right: 14px;
}

:deep(.control--prev) {
  left: 14px;
}
</style>
