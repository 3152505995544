import appConfig from '@/services/appConfig'

import { fetchPost } from '../fetch'
import type { PharmacyCertificate, ProductListItem } from './productTypes'

type ProductsMoreResponse = {
  cursor: string | null
  pharmacyCertificates: PharmacyCertificate[] | null
  products: ProductListItem[]
}

function postProductsMore(cursor: string, limit: number, nameController: string) {
  if (!nameController) {
    nameController = 'postProductsMore' + cursor
  }

  return fetchPost<ProductsMoreResponse>(
    `${appConfig.frontApiBaseUrl}products/more`,
    undefined,
    { cursor, limit },
    undefined,
    nameController,
  )
}

export { postProductsMore as frontApiPostProductsMore }
