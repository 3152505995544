import addChildren from './addChildren'
import deleteChild from './deleteChild'
import getChildren from './getChildren'
import registration from './registration'
import termsAndConditions from './termsAndConditions'

export default {
  registration,
  termsAndConditions,
  getChildren,
  addChildren,
  deleteChild,
}
