import { dataLayerPush } from './utils'

type ModalSection = 'main' | 'detailed_section'
type Initiator = 'no_preferences' | 'change_preferences'

function ckConsentImpression(section: ModalSection, initiator: Initiator) {
  dataLayerPush({
    event: 'consent_impression',
    modal_section: section,
    initiator,
  })
}

export { ckConsentImpression as gtmEventCkConsentImpression }
export type { ModalSection, Initiator }
