import type { Ref } from 'vue'
import { onUnmounted, watch } from 'vue'

import type { NavBreadcrumbItem } from '@/componentsPure/NavBreadcrumb'
import useUserInterfaceStore from '@/store/pinia/useUserInterfaceStore'

/**
 * Watch changes in breadcrumb items and in case that current category is from L1 or L2
 * menu, set it to the store.
 * @param items Breadcrumb items
 */
function useCurrentCategoryId(items: Ref<NavBreadcrumbItem[]>) {
  const { setCurrentL1CategoryId, setCurrentL2CategoryId } = useUserInterfaceStore()

  // Watch items changes, that usually means user clicked on different category.
  watch(
    items,
    (newItems) => {
      const l1Item = newItems[1]?.id ?? 0
      const l2Item = newItems[2]?.id ?? 0
      setCurrentL1CategoryId(l1Item)
      setCurrentL2CategoryId(l2Item)
    },
    { immediate: true },
  )

  // Reset current category on unmount, that usually means user navigated to different page.
  onUnmounted(() => {
    setCurrentL1CategoryId(0)
    setCurrentL2CategoryId(0)
  })
}

export default useCurrentCategoryId
export type { NavBreadcrumbItem }
