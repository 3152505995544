<script setup lang="ts">
type AlertSeverity = 'success' | 'warning'

type AlertProps = {
  severity: AlertSeverity
}

const props = defineProps<AlertProps>()
</script>
<template>
  <div
    :class="{
      'alert text-5 text-brand p-3 py-lg-2': true,
      'bg-green-light': props.severity === 'success',
      'bg-brand-peach': props.severity === 'warning',
    }">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.alert {
  border-radius: var(--borderRadiusMedium);
  box-shadow:
    0 0 4px 0 color('black', 0.04),
    0 2px 4px 0 color('black', 0.1);
}
</style>
