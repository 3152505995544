import type { Dayjs } from 'dayjs'

/**
 * Formats given date to Czech time format.
 * Returns null if date is invalid.
 * @param dayjsDate Dayjs object.
 * @returns Time formated in Czech format (H:mm) or null.
 */
function formatTime(dayjsDate: Dayjs) {
  if (!dayjsDate?.isValid?.()) {
    return null
  }

  return dayjsDate.tz().format('H:mm')
}

export default formatTime
