import { createApi } from '@/services/apiv2'
import appConfig from '@/services/appConfig'

// Create API instance for each API used in the app.
const frontApiInstance = createApi('/api/front/')
const orderTrackingApiInstance = createApi(appConfig.orderTrackingApiPath)
const notificationApiInstance = createApi(appConfig.notificationsPath)

const apiInstances = {
  front: frontApiInstance,
  orderTracking: orderTrackingApiInstance,
  notifications: notificationApiInstance,
}

export default apiInstances
