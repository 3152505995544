<script setup lang="ts">
import { ref, computed, onMounted, onUpdated, onUnmounted, nextTick } from 'vue'

type BaseCarpetProps = {
  /**
   * What element will the carpet wrapper be rendered as.
   */
  entity?: string
  /**
   * Max height of the content in pixels when the carpet is closed (rolled up).
   */
  heightLimit?: number
  /**
   * Can be used to override the default case, in which the carpet is initially closed .
   */
  visible?: boolean
}

const props = withDefaults(defineProps<BaseCarpetProps>(), {
  entity: 'div',
  heightLimit: Infinity,
  visible: false,
})

const emit = defineEmits<{
  'update:visible': [value: boolean]
}>()

const isVisible = ref(props.visible)
const currentHeight = ref(Infinity)
const maxHeight = ref('none')

const isContentVisible = computed({
  get: () => {
    return props.visible ?? isVisible.value
  },
  set: (isVisibleParam: boolean) => {
    isVisible.value = isVisibleParam
    setContentMaxHeight()
    emit('update:visible', isVisible.value)
  },
})

const isHeightLimit = computed(() => {
  return props.heightLimit && props.heightLimit <= currentHeight.value
})

onUpdated(() => {
  setContentCurrentHeight()
})

onMounted(() => {
  setContentCurrentHeight()
  window.addEventListener('resize', setContentCurrentHeight)
})

onUnmounted(() => {
  window.removeEventListener('resize', setContentCurrentHeight)
})

const contentEl = ref<Element | null>(null)
const setContentCurrentHeight = () => {
  if (props.heightLimit) {
    nextTick(() => {
      currentHeight.value = contentEl.value?.scrollHeight ?? 0
    })
  }
  setContentMaxHeight()
}

const setContentMaxHeight = () => {
  if (isHeightLimit.value) {
    maxHeight.value = `${!isContentVisible.value ? props.heightLimit : currentHeight.value}px`
  } else {
    maxHeight.value = 'none'
  }
}
</script>

<template>
  <Component :is="props.entity">
    <div
      ref="contentEl"
      class="carpet-content"
      :style="`max-height:${maxHeight}`">
      <!-- @slot The content to be limited in its height -->
      <slot />
    </div>
    <div
      v-if="isHeightLimit"
      @click.prevent="isContentVisible = !isContentVisible">
      <!-- @slot The control element/content  -->
      <slot name="control" />
    </div>
  </Component>
</template>

<style lang="scss" scoped>
.carpet-content {
  overflow: hidden;
  transition: max-height var(--baseTransitionTime);
}
</style>
