import { ref } from 'vue'
import type { Ref } from 'vue'

import { debounce } from 'lodash-es'
import { defineStore } from 'pinia'

type CartAlertParams = {
  name: string
  quantity?: number
  unit?: string
}

type CartAlert = {
  messageKey: string
  params: CartAlertParams
}
/**
 * Display alerts after adding products to cart, cart has to be closed.
 */
const useCartAlertsStore = defineStore('useCartAlertsStore', () => {
  /**
   * Currently changed product id.
   */
  const currentlyChangedProductId = ref<number | null>(null)
  /**
   * Currently added gifts ids.
   */
  const currentlyAddedGiftsIds = ref<number[]>([])
  const cartAlert: Ref<CartAlert | null> = ref(null)

  // actions
  /**
   * Clear currently changed product id, after 2s.
   */
  const clearCurrentlyChangedProduct = debounce(() => {
    currentlyChangedProductId.value = null
  }, 2000)

  /**
   * Clear currently added gifts ids, after 2s.
   */
  const clearCurrentlyAddedGifts = debounce(() => {
    currentlyAddedGiftsIds.value = []
  }, 2000)

  /**
   * Clear cart alert, after 5s.
   */
  const clearCartAlert = debounce(() => {
    cartAlert.value = null
  }, 5000)

  /**
   * Set currently changed product id, clear it after 2s.
   * @param id - product id
   */
  function setCurrentlyChangedProduct(id: number) {
    currentlyChangedProductId.value = id
    clearCurrentlyChangedProduct()
  }

  /**
   * Set currently added gifts ids, clear it after 2s.
   * @param ids - array of product ids
   */
  function setCurrentlyAddedGifts(ids: number[]) {
    currentlyAddedGiftsIds.value = ids
    clearCurrentlyChangedProduct()
    clearCurrentlyAddedGifts()
  }

  /**
   * Set cart alert, clear it after 5s.
   * @param payload - cart alert
   */
  function setCartAlert(payload: CartAlert) {
    cartAlert.value = payload
    clearCartAlert()
  }

  return {
    currentlyChangedProductId,
    currentlyAddedGiftsIds,
    cartAlert,
    setCurrentlyChangedProduct,
    setCurrentlyAddedGifts,
    setCartAlert,
  }
})

export default useCartAlertsStore
