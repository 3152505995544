import { computed, ref } from 'vue'

import { defineStore } from 'pinia'

import { frontApiGetProfileOrder } from '@/services/api/front/profile'
import type { ProfileOrderDetailResponse } from '@/services/api/front/profileTypes'

import { prepareOrderDetail } from '../utils/prepareOrder'
import useProductsStore from './useProductsStore'

const useOrdersStore = defineStore('useOrdersStore', () => {
  const order = ref<ProfileOrderDetailResponse | null>(null)

  const getOrder = computed(() => prepareOrderDetail(order.value))

  //@todo https://mallfresh.atlassian.net/browse/KNW-15681
  async function fetchOrder(id: number) {
    const { getProducts, setProducts } = useProductsStore()

    if (order.value?.id !== id) {
      order.value = null
    }
    const { data } = await frontApiGetProfileOrder(id)
    const originProductsData = data.orderProducts.map((orderProduct) => orderProduct.product)
    // get only replacements
    const originReplacementsData = data.orderProducts
      .filter((orderProduct) => orderProduct.replacements)
      .map((orderProduct) => orderProduct.replacements)
      .flat()
      .map((replacement) => replacement.product)

    const allOriginProducts = [...(originReplacementsData ?? []), ...originProductsData]
    setProducts(allOriginProducts)

    const productIds = allOriginProducts.map((product) => product.id)
    const storedProducts = getProducts(productIds)

    data.orderProducts.forEach((orderProduct) => {
      const product = storedProducts.find((p) => p.id === orderProduct.product.id)!
      if (product) {
        orderProduct.product = product
      }
      // need quantity and price of replacements because transformed replacements from product store has none
      orderProduct.replacementsMeta = orderProduct.replacements?.map((replacement) => {
        return {
          productId: replacement.product.id,
          quantity: replacement.deliveredQuantity,
          price: replacement.price,
        }
      })

      // @todo type fix
      orderProduct.replacements = storedProducts.filter((storedProduct) => {
        return orderProduct.replacements?.some(
          (replacement) => replacement.product.id === storedProduct.id,
        )
      }) as any
    })
    order.value = data
  }

  return {
    getOrder,
    fetchOrder,
  }
})

export default useOrdersStore
