import { type Ref, computed } from 'vue'

import { useQuery } from '@tanstack/vue-query'

import { addressQueryKeys } from '@/constants/queryKeys'
import api from '@/data/api'

function useAddressPrelaunchValidate(address: Ref<string>, wholeAddressRequired?: boolean) {
  const enabledValidation = computed(() => !!address.value)

  return useQuery({
    queryKey: addressQueryKeys.validatePrelaunch(address),
    queryFn: () =>
      api.front.address.getPrelaunchValidate({
        queryParams: {
          address: address.value,
          ...(wholeAddressRequired ? { wholeAddressRequired: true } : {}),
        },
      }),
    enabled: enabledValidation,
  })
}

export default useAddressPrelaunchValidate
