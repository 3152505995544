import { createOperation } from '@/services/apiv2'

import type { PutCheckoutVoucherRequest, PutCheckoutVoucherResponse } from './checkoutTypes'

/**
 * Sets name of voucher used for the order
 * @see https://sasandboxstdhotapidocs.z1.web.core.windows.net/#/Checkout/updateVoucher
 */
const updateVoucher = createOperation<
  PutCheckoutVoucherResponse,
  undefined,
  undefined,
  PutCheckoutVoucherRequest
>('/checkout/voucher', {
  method: 'put',
})

export default updateVoucher
