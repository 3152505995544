import appConfig from '@/services/appConfig'
import { t } from '@/services/translations'

const defaultPriceOptions = {
  style: 'currency',
  currencyDisplay: 'symbol',
  currency: t('general.currencyCode'),
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
} as const

// We need to adjust minimumFractionDigits when you set maximumFractionDigits less than minimumFractionDigits
function getMinimumFractionDigits(formatOptions?: Omit<Intl.NumberFormatOptions, 'currency'>) {
  const minimumFractionDigits =
    formatOptions?.minimumFractionDigits ?? defaultPriceOptions.minimumFractionDigits
  const maximumFractionDigits =
    formatOptions?.maximumFractionDigits ?? defaultPriceOptions.maximumFractionDigits

  return Math.min(minimumFractionDigits, maximumFractionDigits)
}

function localizePrice(price: number, formatOptions?: Omit<Intl.NumberFormatOptions, 'currency'>) {
  const adjustedOptions = {
    ...defaultPriceOptions,
    ...formatOptions,
    minimumFractionDigits: getMinimumFractionDigits(formatOptions),
  }

  return new Intl.NumberFormat(appConfig.defaultLang, adjustedOptions).format(price)
}

export default localizePrice
