import { defineAsyncComponent, ref } from 'vue'
import { useRouter } from 'vue-router'

import ErrorPage from '@pages/Error/ErrorPage.vue'
import ForceReload from '@pages/ForceReload'

import useConfig from '@/composables/useConfig'
import logger from '@/services/logger'

import convertNetteToVueSlug from './convertNetteToVueSlug'
import phpMap from './phpMap'

const asyncComponents = {
  Auth: defineAsyncComponent(() => import('@pages/Auth.vue')),
  BrandList: defineAsyncComponent(() => import('@pages/BrandList.vue')),
  Credits: defineAsyncComponent(() => import('@pages/Credits.vue')),
  ForceReload,
  KidsClub: defineAsyncComponent(() => import('@pages/KidsClub.vue')),
  MultiSearchResults: defineAsyncComponent(() => import('@pages/MultiSearchResults.vue')),
  NewComplaint: defineAsyncComponent(() => import('@pages/NewComplaint.vue')),
  Notifications: defineAsyncComponent(() => import('@pages/Notifications.vue')),
  OrderRating: defineAsyncComponent(() => import('@pages/OrderRating.vue')),
  PageCart: defineAsyncComponent(() => import('@pages/PageCart')),
  PageCheckout: defineAsyncComponent(() => import('@pages/PageCheckout')),
  PageCheckout2: defineAsyncComponent(() => import('@pages/PageCheckout2')),
  PageLogoutMobileApp: defineAsyncComponent(() => import('@pages/PageLogoutMobileApp.vue')),
  PageMyCart: defineAsyncComponent(() => import('@pages/PageMyCart.vue')),
  PageOrderTracking: defineAsyncComponent(() => import('@pages/PageOrderTracking.vue')),
  PagePreLaunchLandingPage: defineAsyncComponent(
    () => import('@pages/PagePreLaunchLandingPage.vue'),
  ),
  PageBigTip: defineAsyncComponent(() => import('@pages/PageBigTip.vue')),
  PageProducts: defineAsyncComponent(() => import('@pages/PageProducts')),
  PageProfileClub: defineAsyncComponent(() => import('@pages/PageProfileClub.vue')),
  PageRecipeDetail: defineAsyncComponent(() => import('@pages/PageRecipeDetail.vue')),
  PageRecipes: defineAsyncComponent(() => import('@pages/PageRecipes')),
  PageStatic: defineAsyncComponent(() => import('@pages/PageStatic')),
  PageThankYou: defineAsyncComponent(() => import('@pages/PageThankYou')),
  PageWelcome: defineAsyncComponent(() => import('@pages/PageWelcome.vue')),
  PrivacySettings: defineAsyncComponent(() => import('@pages/PrivacySettings.vue')),
  PrivacyPolicy: defineAsyncComponent(() => import('@pages/PrivacyPolicy.vue')),
  PrivacyRequest: defineAsyncComponent(() => import('@pages/PrivacyRequest')),
  Profile: defineAsyncComponent(() => import('@pages/Profile.vue')),
  Savings: defineAsyncComponent(() => import('@pages/Savings.vue')),
  Gifts: defineAsyncComponent(() => import('@pages/Gifts.vue')),
  Newsletter: defineAsyncComponent(() => import('@pages/Newsletter')),
  ErrorPage,
}

const log = logger('router:dynamicRoutes')
const skippedRoutes: string[] = []

// Defaults for 404 links.
const notFoundRoute = {
  path: '/404',
  component: asyncComponents.ErrorPage,
  meta: {
    gtmPageType: 'error',
    component: 'ErrorPage',
  },
}

/**
 * Reads routes from config/web endpoint and dynamically adds them to the router.
 * Run this before app init, otherwise some routes may not be available.
 * @warning Dynamic routes are currently not reactive. In case of route changes,
 * the app must be reloaded.
 */
function useDynamicRoutes(callbackFn?: () => void) {
  const config = useConfig()
  const router = useRouter()
  const isRoutesLoaded = ref(false)

  for (const [nettePresenter, route] of Object.entries(phpMap)) {
    const netteRoute = config.value.routes[nettePresenter]

    if (!netteRoute) {
      // No slug for this route found. We fallback to 404 route.
      // Having 404 rout is necessary for undefined routes to "lead somewhere".
      // Undefined named routes could break the app.
      router.addRoute({
        name: route.name,
        ...notFoundRoute,
      })
      skippedRoutes.push(`${nettePresenter}: ${route.name}`)
      continue
    }

    const convertedRoute = convertNetteToVueSlug(netteRoute)
    router.addRoute({
      name: route.name,
      path: convertedRoute,
      component: asyncComponents[route.component],
      meta: {
        ...route.meta,
        component: route.component,
      },
    })
  }

  if (skippedRoutes.length) {
    log({
      message: [skippedRoutes],
      severity: 'warn',
    })
  }

  // Soft re-render current route to apply potential changes.
  router.replace(router.currentRoute.value.fullPath).then(() => {
    isRoutesLoaded.value = true
    callbackFn?.()
  })

  return isRoutesLoaded
}

export default useDynamicRoutes
