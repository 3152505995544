<script setup lang="ts">
import { defineComponent, onMounted, ref, watch } from 'vue'

import BannerClub from '@/components/BannerClub'
import BenjaminekMonthlySpentCard from '@/components/BenjaminekMonthlySpentCard'
import BenefitItem from '@/components/Clubs/BenefitItem.vue'
import BenjaminekBenefitList from '@/components/Clubs/Benjaminek/BenjaminekBenefitList.vue'
import BenjaminekChildList from '@/components/Clubs/Benjaminek/BenjaminekChildList.vue'
import Calculator from '@/components/Clubs/Benjaminek/Calculator.vue'
import CoverBenjaminek from '@/components/Clubs/Benjaminek/CoverBenjaminek.vue'
import ClubSlot from '@/components/Clubs/ClubSlot.vue'
import FullcareBenefitList from '@/components/Clubs/Fullcare/FullcareBenefitList.vue'
import FullcareCover from '@/components/Clubs/Fullcare/FullcareCover.vue'
import FullcareRegister from '@/components/Clubs/Fullcare/FullcareRegister.vue'
import Icon from '@/components/Icon/Icon.vue'
import UtilScript from '@/components/Util/Script/UtilScript.vue'
import WidgetAdmin from '@/components/Widget/WidgetAdmin.vue'
import { CarouselWrapper, CarouselItem } from '@/components/global/Carousel'
import Popup from '@/components/global/Popup/Popup.vue'
import { BaseButton, BaseLoader } from '@/componentsPure'
import { fetchStaticBlock } from '@/services/api/front/staticBlocks'
import type { StaticBlock } from '@/services/api/front/staticBlocks'
import appConfig from '@/services/appConfig'

type StaticBlockProps = {
  slug: string
  loader?: boolean
}

const props = withDefaults(defineProps<StaticBlockProps>(), {
  loader: false,
})

const loaded = ref(false)

let renderer: any = null

const emit = defineEmits<{
  loaded: [user: StaticBlock]
}>()

async function createContent() {
  try {
    loaded.value = false
    const block = (await fetchStaticBlock(props.slug)).data
    loaded.value = true
    renderer = defineComponent({
      components: {
        CarouselItem,
        CarouselWrapper,
        Icon,
        Popup,
        UtilScript,
        WidgetAdmin,
        BenefitItem,
        BenjaminekBenefitList,
        BenjaminekChildList,
        BenjaminekMonthlySpentCard,
        Calculator,
        CoverBenjaminek,
        ClubSlot,
        FullcareBenefitList,
        FullcareCover,
        FullcareRegister,
        BannerClub,
        BaseButton,
      },
      computed: {
        appConfig() {
          return appConfig
        },
      },
      template: block.html,
    })
    emit('loaded', block)
  } catch (e) {
    // @todo handle missing static block
  }
}

watch(
  () => props.slug,
  () => {
    createContent()
  },
)

onMounted(() => {
  createContent()
})
</script>

<template>
  <BaseLoader v-if="loader && !loaded" />
  <Component
    :is="renderer"
    v-else-if="loaded" />
  <div v-else>
    <slot />
  </div>
</template>
