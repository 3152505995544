<script setup>
import { computed, onMounted, ref, useSlots } from 'vue'

import { nanoid } from 'nanoid'
import { Field as VeeField } from 'vee-validate'

const props = defineProps({
  modelValue: {
    type: String,
    default: null,
  },
  placeholder: {
    type: String,
    default: null,
  },
  label: {
    type: String,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  id: {
    type: String,
    default: () => `input-${nanoid()}`,
  },
  autofocus: {
    type: Boolean,
    default: false,
  },
  /**
   * Name for input, used in VeeField component, must be unique.
   */
  name: {
    type: String,
    default: null,
  },
  /**
   * Validation rules for VeeField component.
   */
  rules: {
    type: [String, Object, Function],
    default: null,
  },
  email: {
    type: Boolean,
    default: false,
  },
  dataTid: {
    type: String,
    default: undefined,
  },
})

const emit = defineEmits(['update:modelValue'])

const inputValue = computed({
  get() {
    return props.modelValue
  },
  set(newValue) {
    emit('update:modelValue', newValue)
  },
})

const componentSlots = useSlots()
// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
const isLabel = computed(() => !!(componentSlots.default?.() || props.label))

const input = ref(null)
onMounted(() => {
  if (props.autofocus) {
    input.value.focus()
  }
})
</script>

<template>
  <div>
    <label
      v-if="isLabel"
      class="label"
      :for="id">
      <slot v-if="!!componentSlots.default?.()" />
      <template v-else>
        {{ label }}
      </template>
    </label>
    <VeeField
      v-if="rules"
      :id="id"
      v-slot="slots"
      v-model="inputValue"
      :name="name ?? id"
      :data-tid="dataTid"
      :rules="rules">
      <input
        v-if="slots"
        ref="input"
        v-bind="slots.field"
        :class="[
          'form-text',
          {
            'validation-errors__input': slots.errors[0],
          },
        ]"
        :disabled="disabled || null"
        :placeholder="placeholder"
        :type="props.email ? 'email' : 'text'" />
      <span
        v-if="slots"
        class="validation-errors"
        v-text="slots.errors[0]" />
    </VeeField>

    <input
      v-else
      :id="id"
      ref="input"
      v-model="inputValue"
      :data-tid="dataTid"
      type="text"
      :class="[
        'form-text',
        {
          'form-text--disabled': props.disabled,
        },
      ]"
      :placeholder="placeholder"
      :disabled="disabled || null" />
  </div>
</template>

<style src="../styles.scss" lang="scss" />
<style src="./styles.scss" lang="scss" scoped />
