<script setup lang="ts">
import { GiftsClubWrapper } from '@/componentsPure'

import HowDoesItWorkItem from './HowDoesItWorkItem.vue'

type HowDoesItWorkProps = {
  title: string
  condition: string
  reward: string
  description: string
}

const props = defineProps<HowDoesItWorkProps>()
</script>

<!-- 'How does it work' component used on Gifts club page in Profile -->
<template>
  <GiftsClubWrapper :title="props.title">
    <div
      class="items d-flex flex-column flex-lg-row justify-content-center align-items-center mt-3 mt-lg-4">
      <HowDoesItWorkItem
        :title="props.condition"
        image-path="/vue/img/clubs/how-does-it-work-order.svg?v=1"
        alt="Order image"
        class="item px-2" />
      <HowDoesItWorkItem
        :title="props.reward"
        image-path="/vue/img/clubs/how-does-it-work-gifts.svg?v=1"
        alt="Gifts image"
        class="item px-2" />
    </div>

    <footer class="footer text-2 text-gray-dark text-center mx-auto my-3 my-lg-4">
      {{ props.description }}
    </footer>
  </GiftsClubWrapper>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.items {
  gap: 32px;
}

.item {
  max-width: 330px;
}

.footer {
  max-width: 506px;
}
</style>
