import { getCssVariable } from '@/utils/cssVariable'

type BreakpointWithMinWidth = {
  name: string
  min: number
}

const widthAliasNames = {
  mobile: ['xs'],
  tablet: ['sm', 'md'],
  desktopResizable: ['lg'],
  main: ['xl'],
} as const

const breakpoints = parseWidthsCss('breakpoints')

const allWidths = breakpoints.concat(parseWidthsCss('containerWidth'))

function getWidthNamesByAlias(aliasName: keyof typeof widthAliasNames) {
  return widthAliasNames[aliasName]
}

function getBreakpointNames() {
  return breakpoints.map((breakpoint) => breakpoint.name)
}

function getWidth(widthName: string) {
  return allWidths.find((width) => width.name === widthName) ?? allWidths[0]
}

function parseWidthsCss(category: 'breakpoints' | 'containerWidth') {
  const parsedWidths: BreakpointWithMinWidth[] = []

  if (category === 'breakpoints') {
    const breakpointAbbreviations = Object.values(widthAliasNames).flat()

    for (const breakpointAbbreviation of breakpointAbbreviations) {
      parsedWidths.push({
        name: breakpointAbbreviation,
        min: getCssVariable(`--breakpoint-${breakpointAbbreviation}`)!,
      })
    }
  } else {
    parsedWidths.push({
      name: category,
      min: getCssVariable(`--${category}`)!,
    })
  }

  return parsedWidths
}

function getMinMedia(widthName: string) {
  const width = getWidth(widthName)

  return `(min-width: ${width.min}px)`
}

function getMaxMedia(widthName: string) {
  const widthNextIndex = allWidths.indexOf(getWidth(widthName)) + 1
  const widthNext = allWidths[widthNextIndex]

  return `(max-width: ${widthNext.min - 1}px)`
}

function getMaxMediaByAlias(aliasName: keyof typeof widthAliasNames) {
  const maxWidthName = getWidthNamesByAlias(aliasName).slice(-1)[0]

  return getMaxMedia(maxWidthName)
}

export { getBreakpointNames, getMinMedia, getMaxMedia, getMaxMediaByAlias }
