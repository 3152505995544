import appConfig from '@/services/appConfig'

import { fetchGet } from '../fetch'

type Gift = {
  description: string | null
  id: number
  image: string | null
  name: string
}

type GiftResponse = {
  gifts: Gift[]
}

function getGift(ids: number[]) {
  return fetchGet<GiftResponse>(appConfig.frontApiBaseUrl + 'gift', {
    // TODO https://mallfresh.atlassian.net/browse/KNW-3860
    // move array to string conversion to url service as soon
    // as last web api endpoint using array parameters (recipes)
    // is rewritten to front api
    ids: ids.join(),
  })
}

export { getGift as frontApiGetGift }

export type { Gift }
