import { getCookieConsents } from '@/services/privacy'

import { dataLayerPush } from './utils'

function ckConsentStatus() {
  const cookies = getCookieConsents()

  dataLayerPush({
    event: 'consent_status',
    preferences: { ...cookies },
  })
}

export { ckConsentStatus as gtmEventCkConsentStatus }
