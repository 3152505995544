/**
 * Mathematically rounds a number to passed in decimal places.
 * @param number A number to round.
 * @param Number of decimal places. 2 is default.
 * @returns A rounded number with passed in decimal places.
 */
function roundToDecimals(number: number, decimalPlaces = 2) {
  let multiplicator = 1

  for (let i = 1; i <= decimalPlaces; i++) {
    multiplicator = multiplicator * 10
  }

  return Math.round(number * multiplicator) / multiplicator
}

export default roundToDecimals
