import creditHistory from './creditHistory'
import orderDetail from './orderDetail'
import orderList from './orderList'
import profileDetail from './profileDetail'

export default {
  orderList,
  profileDetail,
  orderDetail,
  //clubInformation: null,
  creditHistory,
}
