<script setup lang="ts">
import { computed, type Ref, ref } from 'vue'

import { useIntersectionObserver } from '@vueuse/core'
import { storeToRefs } from 'pinia'

import BenjaminekChildCard from '@/components/Clubs/Benjaminek/BenjaminekChildCard.vue'
import ClubSectionTitle from '@/components/Clubs/ClubSectionTitle/ClubSectionTitle.vue'
import { BaseButton, BaseLoader } from '@/componentsPure'
import { useBenjaminekDeleteChild, useBenjaminekGetChildren } from '@/composables/benjaminek'
import modalIndex from '@/constants/modalIndex'
import type { BenjaminekChildResponse } from '@/data/api/frontApi/benjaminek/benjaminekTypes'
import { toastApiErrorResponse } from '@/services/toast'
import { t } from '@/services/translations'
import useDeviceDetectorStore from '@/store/pinia/useDeviceDetectorStore'
import useModalStore from '@/store/pinia/useModalStore'
import useUserInterfaceStore from '@/store/pinia/useUserInterfaceStore'

const deviceDetectorStore = useDeviceDetectorStore()
const { breakpointUpLg } = storeToRefs(deviceDetectorStore)

const { scrollToElement } = useUserInterfaceStore()

const maxChildrenCount = 15 // TODO: get later from config
const defaultChildrenCountOnMobile = 3

const isListCollapsed = ref(!breakpointUpLg.value)

const { children, isLoading } = useBenjaminekGetChildren()

// A child can be removed only if there are 2+ children
const isChildRemovable = computed(() => !!(children.value && children.value.length > 1))

const childrenToDisplay = computed(() => {
  // if desktop breakpoint or list is expanded, show all children
  if (breakpointUpLg.value || !isListCollapsed.value) return children.value

  // if mobile/tablet breakpoint or list is collapsed, show only 3 children
  return children.value?.slice(0, defaultChildrenCountOnMobile)
})

const deleteChild = useBenjaminekDeleteChild()
const { openModal } = useModalStore()

function handleDeleteChild(childId: BenjaminekChildResponse['id']) {
  try {
    deleteChild(childId)
  } catch (error) {
    toastApiErrorResponse(error)
  }
}

const titleRef: Ref<HTMLElement | null> = ref(null)
const isTitleVisible = ref(false)
const childrenListTitleId = 'children-list-title'

useIntersectionObserver(titleRef, ([{ isIntersecting }]) => {
  isTitleVisible.value = isIntersecting
})

function handleCollapseListButton() {
  const offsetTop = 24

  isListCollapsed.value = !isListCollapsed.value

  // if title is outside of viewport, scroll to it while wrapping the list
  if (isListCollapsed.value && !isTitleVisible.value) {
    scrollToElement({
      targetElementId: childrenListTitleId,
      offsetTop,
    })
  }
}

const collapseListButtonText = computed(() => {
  return t(`kidsClub.children.list.${isListCollapsed.value ? 'showMore' : 'showLess'}`)
})

function openBenjaminekChildListModal() {
  openModal(modalIndex.benjaminekAddChild)
}
</script>

<template>
  <div
    v-if="isLoading"
    class="position-relative py-5 my-5">
    <BaseLoader />
  </div>

  <div v-else-if="children?.length">
    <ClubSectionTitle
      :id="childrenListTitleId"
      ref="titleRef"
      :title="t('kidsClub.children.title')" />

    <div class="children-list d-flex flex-wrap justify-content-center">
      <BenjaminekChildCard
        v-for="(child, index) in childrenToDisplay"
        :key="index"
        :name="child.name"
        :age="child.age"
        :show-cross-button="isChildRemovable"
        class="flex-grow-1"
        @remove="handleDeleteChild(child.id)" />
    </div>

    <div
      v-if="!breakpointUpLg && children.length > defaultChildrenCountOnMobile"
      class="my-4">
      <button
        class="btn a-link-underline text-primary text-3 font-weight-bold w-100 mx-auto"
        @click="handleCollapseListButton">
        {{ collapseListButtonText }}
      </button>
    </div>

    <div class="text-center mt-4 mb-5">
      <BaseButton
        v-if="children.length < maxChildrenCount"
        variant="red"
        size="sm"
        @click="openBenjaminekChildListModal">
        {{ t('kidsClub.children.addChild.button') }}
      </BaseButton>
      <p
        v-else
        class="text-gray-dark text-4 m-0">
        {{ t('kidsClub.children.addChild.limitExceeded') }}
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.children-list {
  gap: 16px;

  @include media-breakpoint-up(md) {
    gap: 24px;
  }
}
</style>
