<script setup lang="ts">
import { computed } from 'vue'

import { t } from '@/services/translations'

type ItemValues = {
  title: string
  value: string
  prefix?: string
  unit?: string
}

type ProductDetailTableWithValues = {
  title?: string
  values: ItemValues[]
  items: never
}

type ProductDetailTableWithItems = {
  title?: string
  items: ItemValues[]
  values: never
}

type ProductDetailTableProps = {
  content: ProductDetailTableWithValues | ProductDetailTableWithItems
}

const props = defineProps<ProductDetailTableProps>()

const getRows = computed(() => props.content.items ?? props.content.values)
</script>

<template>
  <table
    :aria-describedby="props.content.title"
    data-tid="product-detail__nutrition_table">
    <thead>
      <tr v-if="props.content.title">
        <th scope="col">
          <h2
            class="text-4 text-left"
            data-tid="product-detail__nutrition_title"
            v-text="props.content.title" />
        </th>
        <th
          scope="col"
          class="invisible"
          v-text="t('general.value')" />
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(row, index) in getRows"
        :key="`row-${index}`"
        :class="[
          'content-table-row',
          {
            'bg-gray-lighter border-top border-bottom border-gray-light': index % 2 === 0,
            'border-bottom-0': index === getRows.length - 1,
          },
        ]">
        <td
          class="px-3"
          v-text="row.title" />
        <td
          class="px-3 text-right"
          v-text="`${row.prefix || ''} ${row.value} ${row.unit || ''}`" />
      </tr>
    </tbody>
  </table>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.content-table-row {
  height: 40px;
}

// Bootstrap 4 like utility
.border-gray-light {
  border-color: color('gray-light') !important;
}
</style>
