import { createDirectStore } from 'direct-vuex'

import cart from './modules/cart'

const { store, rootActionContext, moduleActionContext, rootGetterContext, moduleGetterContext } =
  createDirectStore({
    modules: {
      cart,
    },
  })

export default store

export { store, rootActionContext, moduleActionContext, rootGetterContext, moduleGetterContext }

export type AppStore = typeof store
