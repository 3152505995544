import { Vendors } from '@/constants/vendors'
import appConfig from '@/services/appConfig'
import { t } from '@/services/translations'
import useCartStore from '@/store/pinia/useCartStore'
import useProductsStore from '@/store/pinia/useProductsStore'
import { getCumulativePriceStep } from '@/store/utils/discountedPrice'
import localizePrice from '@/utils/localizePrice'

import Tooltip from './Tooltip'

function _updateCumulativePrice(product, quantity, wrapperElement) {
  const wrapperCumulativePrice = wrapperElement?.querySelector('.js-cumulative-prices')

  if (!wrapperCumulativePrice) {
    return
  }
  const cumulativePriceRows = wrapperCumulativePrice.querySelectorAll('.cumulative-price')
  const reversedCumulativePrices = [...product.origin.cumulativePrices].reverse()
  const currentCumulativePrice = getCumulativePriceStep({
    quantity,
    cumulativePrices: product.origin.cumulativePrices,
  })

  reversedCumulativePrices.forEach((cumulativePrice, index) => {
    const cumulativePriceRow = cumulativePriceRows[index]
    cumulativePriceRow.classList[cumulativePrice === currentCumulativePrice ? 'add' : 'remove'](
      'active-price',
    )
  })

  const isCumulativePriceActive = product.computed.price !== product.origin.price
  wrapperElement.querySelectorAll("[data-js='stepPriceFormatted']").forEach((stepPriceEl) => {
    stepPriceEl.classList[isCumulativePriceActive ? 'add' : 'remove']('text-red')
    stepPriceEl.innerHTML = localizePrice(product.computed.price)
  })
  wrapperElement.querySelectorAll("[data-js='unitPriceFormatted']").forEach((unitPriceEl) => {
    unitPriceEl.innerHTML = localizePrice(
      currentCumulativePrice?.pricePerUnit?.price || product.origin.pricePerUnit.price,
    )
  })

  if (product.origin.recommendedPrice === product.origin.price) {
    const recommendedPricePlaceholder = wrapperElement.querySelector(
      "[data-js='recommendedPricePlaceholder']",
    )
    wrapperElement
      .querySelectorAll("[data-js='recommendedPriceFormatted']")
      .forEach((recommendedPriceEl) => {
        recommendedPricePlaceholder?.classList[isCumulativePriceActive ? 'add' : 'remove']('d-none')
        recommendedPriceEl.innerHTML = isCumulativePriceActive
          ? localizePrice(product.origin.recommendedPrice)
          : ''
      })
  }
}

function updateDom(productId, maxInCartLimitNumber, maxInCartLimitPharmacyNumber) {
  const { getProduct } = useProductsStore()
  const product = getProduct(productId)
  const elements = document.querySelectorAll(`[product-id="${productId}"]`)

  elements.forEach((el) => {
    const amount = el.querySelector('.amount__adjust input')
    const amountAdd = el.querySelector('.amount__add')
    const amountAdjust = el.querySelector('.amount__adjust')
    const amountLimitMsg = t('amount.maxInCart.tooltip', { amount: product.origin.maxInCart })
    const btnUp = el.querySelector('.amount__btn--up')
    const amountTooltip = document.createElement('div')
    amountTooltip.setAttribute('class', 'tooltip-amount')
    amountTooltip.innerHTML = amountLimitMsg
    const lTooltip = new Tooltip(amountAdjust, amountTooltip, appConfig.tooltipHideDelay)

    amount.value = product.computed.quantity
    amountAdd.style.display = product.computed.quantity === 0 ? 'block' : 'none'
    amountAdjust.style.display = product.computed.quantity === 0 ? 'none' : 'block'

    if (product.computed.quantity >= product.origin.maxInCart) {
      btnUp.classList.add('btn__amount--is-disabled')

      let maxInCartLimit = maxInCartLimitNumber
      if (product.origin.vendorId === Vendors.PHARMACY) {
        maxInCartLimit = maxInCartLimitPharmacyNumber
      }

      if (
        product.origin.limitInCart !== null &&
        product.origin.limitInCart < maxInCartLimit &&
        product.computed.quantity >= product.origin.limitInCart
      ) {
        lTooltip.show()
      }
    } else {
      btnUp.classList.remove('btn__amount--is-disabled')
      lTooltip.hide()
    }
  })

  const wrapperProductBox = document.querySelectorAll(
    `.product-box[data-product-id='${productId}']`,
  )

  if (wrapperProductBox) {
    wrapperProductBox.forEach((wrapper) => {
      wrapper.classList[product.computed.quantity ? 'add' : 'remove']('is-in-cart')
      wrapper.querySelector("[data-js='quantity']").innerHTML = product.computed.quantity
      wrapper.querySelector("[data-js='price']").innerHTML = localizePrice(
        product.computed.price * product.computed.quantity,
      )

      _updateCumulativePrice(product, product.computed.quantity, wrapper)
    })
  }
}

function bindSetToCartElements() {
  const { setProduct } = useProductsStore()
  const { cartActions } = useCartStore()

  window.setToCartElements = (action, element) => {
    const elementRoot = element.parentElement.parentElement
    const elementData = JSON.parse(elementRoot.getAttribute('product-data'))

    if (action === 'push') {
      setProduct({
        id: elementData.id,
        name: elementData.itemName,
        image: elementData.itemPhoto,
        url: elementData.itemDetailUrl,
        price: elementData.stepPrice,
        recommendedPrice: elementData.recommendedPrice,
        unit: elementData.unit,
        maxInCart: elementData.itemMax,
        limitInCart: elementData.limitInCart,
        mainCategory: elementData.mainCategory,
        cumulativePrices: elementData.cumulativePrices,
        pricePerUnit: elementData.pricePerUnit,
        vendorId: elementData.vendorId,
      })
    }

    const inputValue = parseInt(elementRoot.querySelector('[data-js="quantity"]').value)
    let quantity

    if (action === 'set') {
      quantity = inputValue
    } else if (action === 'decrease') {
      quantity = inputValue - 1
    } else if (action === 'increase') {
      quantity = inputValue + 1
    } else {
      quantity = 1
    }

    cartActions.setCartProductQuantity({
      product: {
        id: elementData.id,
        quantity,
      },
    })
  }
}

export { updateDom as amountSyncUpdateDom, bindSetToCartElements }
