<script setup lang="ts">
import ProductItem from '@/components/ProductItemBox/ProductItem.vue'
import { type Product } from '@/composables/useProductItem'

type ProductItemBoxProps = {
  product: Product | null
  order?: number
  separated?: boolean
}

const props = defineProps<ProductItemBoxProps>()
</script>

<template>
  <div
    v-if="props.product === null"
    class="product">
    <div class="content">
      <div class="name">
        <span class="a-placeholder-text">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </div>
      <button class="add d-block mx-auto" />
    </div>
    <div class="image-container image-container--placeholder" />
  </div>

  <ProductItem
    v-else
    :product="props.product"
    :order="props.order"
    :separated="props.separated" />
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

$name-line-height: 16px;
$name-padding-bottom: 2px;
$content-line-height: 14px;
$content-spacing: 6px;
$content-separator-height: 1px;
$attributes-height: 22px;
$base-size: 14px;
$border-width: 1px;

.add {
  width: em(120, $base-size);
  min-height: em(32, $base-size);
  background: transparent;
  border: $border-width solid color('gray-light');
  border-radius: em(4, $base-size);
}

.product {
  position: relative;
  width: $productItemBoxWidth;
  height: $productItemBoxHeight;
  background: color('white');
  transition:
    box-shadow var(--baseTransitionTime),
    z-index var(--baseTransitionTime);

  @include media-breakpoint-up(lg) {
    height: $productItemBoxHeightLg;
  }

  @include media-breakpoint-only(xl) {
    &:hover {
      z-index: $zix-product--xl;
      box-shadow: 5px 5px 30px 0 color('black', 0.1);
    }
  }
}

.image-container {
  @include image-container($placeholder: true);
}

.content {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: (($attributes-height * 0.5) + $content-spacing) 8px 8px;
  background: color('white', 0.9);
}

.name {
  margin-bottom: $content-line-height + (2 * $content-separator-height) + (3 * $content-spacing);
  text-align: center;
}
</style>
