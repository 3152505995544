import type { Product } from '@/composables/useProductItem'
import appConfig from '@/services/appConfig'

import { fetchGet } from '../fetch'
import type { WidgetResponse } from './widgetTypes'

/**
 * @param vendorId
 * `vendorId` is transformed to `vendor` query parameter.
 * The parameter is called `vendorId` instead of just `vendor`,
 * because that would be inconsistent with the rest of the codebase
 * and rest of the api responses which uses `vendorId` consistently.
 */
function getWidget(code: string, vendorId: number | undefined, products?: Product[]) {
  const params: any = {}

  if (products) {
    // TODO https://mallfresh.atlassian.net/browse/KNW-3860 - move array to string conversion to url service as soon as last web api endpoint using array parameters (recipes) is rewritten to front api
    params.products = products.join()
  }

  if (vendorId) {
    params.vendor = vendorId
  }

  return fetchGet<WidgetResponse>(appConfig.frontApiBaseUrl + 'widget/' + code, params)
}

export { getWidget as frontApiGetWidget }
