import { computed } from 'vue'

import { storeToRefs } from 'pinia'

import useDeliveryData from '@/composables/transport/useDeliveryData'
import modalIndex from '@/constants/modalIndex'
import type { ModalInitiator } from '@/services/analytics/gtm/modal'
import { t } from '@/services/translations'
import useAddressStore from '@/store/pinia/useAddressStore'
import useCartStore from '@/store/pinia/useCartStore'
import useModalStore from '@/store/pinia/useModalStore'
import { formatTime, formatWeekday } from '@/utils/date'

function useAddressSelectorButton() {
  const { openModal } = useModalStore()
  const { data: deliveryData } = useDeliveryData()
  const { isUserStreetOrCityFilled } = storeToRefs(useAddressStore())

  const cartStore = useCartStore()
  const { cartGetters } = storeToRefs(cartStore)

  const earliestTimeslot = computed(() => deliveryData.value?.earliestDeliveryTime ?? null)
  const earliestTimeslotsValid = computed(() => earliestTimeslot.value?.isValid() ?? false)

  const buttonText = computed(() => {
    if (!earliestTimeslot.value || !earliestTimeslotsValid.value)
      return t('addressSelector.buttonEmptyTitle')

    const rawDay = formatWeekday(earliestTimeslot.value)
    const day = rawDay ?? ''
    const time = formatTime(earliestTimeslot.value)

    if (earliestTimeslot.value.tz().isToday() || earliestTimeslot.value.tz().isTomorrow()) {
      return t('addressSelector.buttonFromTitle', { day, time })
    }
    return t('addressSelector.buttonTitle', { day, time })
  })

  const hasFreeDeliveryTag = computed(() => cartGetters.value.hasFreeDelivery)

  function open(initiator: ModalInitiator, triggerOptions?: { hideTimeslots?: boolean }) {
    openModal(
      { id: modalIndex.addressSelector, triggerOptions },
      isUserStreetOrCityFilled.value ? 'TimeSlots' : 'AddressSelector',
      initiator,
    )
  }

  return {
    buttonText,
    earliestTimeslotsValid,
    hasFreeDeliveryTag,
    open,
  }
}

export default useAddressSelectorButton
