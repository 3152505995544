import appConfig from '@/services/appConfig'
import useUserInterfaceStore from '@/store/pinia/useUserInterfaceStore'

import { fetchGet } from '../fetch'
import type { RequestQueryParams } from '../makeRequest'
import type {
  FlexibleProductsResponse,
  PageProductsGroupsResponse,
  PageProductsResponse,
} from './pageTypes'

const modulePath = 'page'

/**
 * Get content for product list page.
 * @param slug Part of the URL that identifies the web resource.
 * @param limit Maximum product count per page.
 * @param orderBy Product sorting type.
 * @param filters Active filters in custom parsed string.
 * @param search Search term.
 * @param nameController API call identifier.
 * @returns API response.
 */
async function getProducts(
  slug: string,
  limit?: number,
  orderBy?: string,
  filters?: string,
  search?: string,
  nameController?: string,
) {
  const params: RequestQueryParams = {
    slug,
    limit,
    order_by: orderBy,
    filters,
    search_term: search,
  }

  const data = await fetchGet<PageProductsResponse>(
    `${appConfig.frontApiBaseUrl}${modulePath}/products`,
    params,
    nameController,
  )

  return data
}

/**
 * Get content for product groups list.
 * @param slug Part of the URL that identifies the web resource.
 * @returns API response.
 */
function getProductsGroups(slug: string) {
  return fetchGet<PageProductsGroupsResponse>(
    `${appConfig.frontApiBaseUrl}${modulePath}/products/groups`,
    { slug },
  )
}

/**
 * Get content for product list page in flexible way for horizontal scroll.
 * @param slug Part of the URL that identifies the web resource.
 * @param limit Maximum product count per page.
 * @param orderBy Product sorting type.
 * @param filters Active filters in custom parsed string.
 * @param search Search term.
 * @param nameController API call identifier.
 * @param actionType
 * @param pageDisplay
 * @returns API response.
 */
async function getFlexible(
  slug: string,
  limit?: number,
  orderBy?: string,
  filters?: string,
  search?: string,
  nameController?: string,
  actionType?: number,
  pageDisplay?: 'default' | 'horizontal' | 'full',
) {
  const userInterfaceStore = useUserInterfaceStore()

  const activePageDisplay = pageDisplay ?? 'default'

  const params: RequestQueryParams = {
    vendor: userInterfaceStore.currentVendorId,
    slug,
    limit,
    order_by: orderBy,
    filters,
    search_term: search,
  }

  // We are sending this parameter only if it is set to 1, 2 or 3.
  // @see https://mallfresh.atlassian.net/browse/KNW-11553
  if (actionType && [1, 2, 3].includes(actionType)) {
    params.action_type = actionType
  }

  if (activePageDisplay !== 'default') {
    params.page_display = activePageDisplay
  }

  return fetchGet<FlexibleProductsResponse>(
    `${appConfig.frontApiBaseUrl}${modulePath}/products/flexible`,
    params,
    nameController,
  )
}

export {
  getProducts as frontApiGetPageProducts,
  getProductsGroups as frontApiGetPageProductsGroups,
  getFlexible as frontApiGetPageProductsFlexible,
}

export type { PageProductsGroupsResponse }
