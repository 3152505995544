import { dataLayerPush } from './utils'

type AddressPinGtmData = {
  activity: 'pin_opened' | 'pin_closed' | 'pin_saved' | 'error_message'
  error?: {
    message: string
  }
}
/**
 * Is called when the user edit, closes edit or saves delivery location map settings, is also called when error occures during GPS validation
 * @param pinData - Object with activity and error message
 * @param pinData.activity - 'pin_opened' | 'pin_closed' | 'pin_saved' | 'error_message'
 * @param pinData.error - Error object with message
 */
function addressPin(pinData: AddressPinGtmData) {
  const gtmObj = {
    event: 'address_pin',
    activity: pinData.activity,
  }

  const withError = { ...gtmObj, error: pinData.error }

  dataLayerPush(pinData.error ? withError : gtmObj)
}

export { addressPin as gtmEventAddressPin }

export type { AddressPinGtmData }
