import { keepPreviousData, QueryClient } from '@tanstack/vue-query'

import QueryTime from '@/constants/QueryTime'
import { configurationQueryKeys } from '@/constants/queryKeys'
import api from '@/data/api'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      gcTime: QueryTime.TEN_MINUTES,
      staleTime: QueryTime.HOUR,
    },
  },
})

// @todo This should be in /data/query/ folder, but we don't use that folder yet.
const configQuery = {
  queryKey: configurationQueryKeys.all,
  queryFn: () => api.front.configuration.web(),
  // Try to keep any data for a long time so "undefined" is never returned.
  gcTime: QueryTime.MAX,
  staleTime: QueryTime.HOUR,
  placeholderData: keepPreviousData,
}

// This query is critical for the app to work. That's why we prefetch it.
queryClient.prefetchQuery(configQuery)

export default queryClient
export { configQuery }
