<script setup lang="ts">
import { ref } from 'vue'
import { useRoute } from 'vue-router'

import componentMap from '@/components/CustomContent/componentMap'
import api from '@/data/api'
import type {
  CustomContentItem,
  CustomContentPageType,
} from '@/data/api/frontApi/customContent/customContentTypes'

type CustomContentTypeProps = {
  type: CustomContentPageType
}
const props = defineProps<CustomContentTypeProps>()

const route = useRoute()

const loading = ref(false)
const customContentData = ref<CustomContentItem[]>()
async function fetchCustomContentData() {
  loading.value = true

  const customContentPath = {
    page: props.type,
  }
  const dateFrom = route.query.dateFrom?.toString()
  const dateTo = route.query.dateTo?.toString()
  const customContentQueryParams = dateFrom && dateTo ? { dateFrom, dateTo } : {}
  customContentData.value = (
    await api.front.customContent.getCustomContent({
      path: customContentPath,
      queryParams: customContentQueryParams,
    })
  ).content
  loading.value = false
}
fetchCustomContentData()

const getComponent = (item: CustomContentItem) => {
  const component = componentMap[item.type]
  if (typeof component === 'function') {
    return component(item)
  }
  return component
}
</script>

<template>
  <slot
    v-if="loading"
    name="loading">
    <div class="container loading">
      <span class="a-placeholder-text d-inline-block text-10 my-4">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
    </div>
  </slot>

  <template
    v-for="(item, index) in customContentData"
    v-else
    :key="index">
    <template v-if="item.type in componentMap">
      <slot
        name="options"
        :component="getComponent(item)"
        :component-name="item.type"
        :index="index">
        <Component
          :is="getComponent(item).name"
          v-if="!(getComponent(item).hideOnPlatform === 'web')"
          v-bind="getComponent(item).props"
          class="mb-2 mb-md-4" />
      </slot>
    </template>
  </template>
</template>

<style scoped>
.loading {
  min-height: 90vh;
}
</style>
