import dayjs from 'dayjs'

import { createOperation } from '@/services/apiv2'

import type {
  ProfileCreditHistoryResponse,
  ProfileCreditHistory,
  ProfileCreditHistoryQueryParams,
} from './profileTypes'

const creditHistory = createOperation<
  ProfileCreditHistoryResponse,
  ProfileCreditHistory,
  undefined,
  undefined,
  ProfileCreditHistoryQueryParams
>('profile/credit-history', {
  method: 'get',
  responseConverter: (data) => {
    return {
      ...data,
      credits: data.credits.map((credit) => ({
        ...credit,
        createdAt: dayjs(credit.createdAt),
        validity: dayjs(credit.validity),
      })),
    }
  },
})

export default creditHistory
