<script setup>
import ClubSectionTitle from '@/components/Clubs/ClubSectionTitle/ClubSectionTitle.vue'
import { useClubSectionTitleProps } from '@/components/Clubs/ClubSectionTitle/useClubSectionTitle'

const props = defineProps(useClubSectionTitleProps)
</script>

<template>
  <ClubSectionTitle
    :title="props.title"
    :title-color="props.titleColor" />
  <div class="list d-flex flex-wrap mb-3">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.list {
  row-gap: 20px;
}

:deep(.benefit-item-wrap) {
  flex-basis: 50%;

  @include media-breakpoint-up(lg) {
    flex-basis: 20%;
  }
}

:deep(.benefit-item-icon-background) {
  $size: 80px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: $size;
  height: $size;
  border: 1px solid color('gray');
  border-radius: 50%;
}

:deep(.benefit-item-icon) {
  $size: 45px;

  width: $size;
  height: $size;
  color: color('primary');
}
</style>
