import { dataLayerPush } from './utils'

type GtmEarlyDeliveryActivity = 'options_shown' | 'positive_answer' | 'negative_answer'

function earlyDelivery(activity: GtmEarlyDeliveryActivity) {
  dataLayerPush({
    event: 'early_delivery',
    activity,
  })
}

export { earlyDelivery as gtmEventEarlyDelivery }
export type { GtmEarlyDeliveryActivity }
