/**
 * General simple function to choose right plural form for slavic languages.
 * @see https://kazupon.github.io/vue-i18n/guide/pluralization.html#custom-pluralization
 * @param choice A choice index  (e.g. the volume or count) given by the input to tc (`tc('path.to.rule', choiceIndex)`).
 * @returns Index of the form to be applied.
 */
function pluralizationSlavic(choice: number) {
  if (!Number.isInteger(choice)) {
    return 2
  }

  switch (choice) {
    case 0:
      return 0
    case 1:
      return 1
    case 2:
    case 3:
    case 4:
      return 2
    default:
      return 3
  }
}

export default pluralizationSlavic
