/**
 * Return promise, resolved on requestAnimationFrame
 * @returns {Promise}
 */
function animationFrame(): Promise<unknown> {
  let promiseResolve: FrameRequestCallback = () => void 0
  const promise = new Promise((resolve) => {
    promiseResolve = resolve
  })
  window.requestAnimationFrame(promiseResolve)
  return promise
}

export { animationFrame }
