import type { InteractionSourceRaw } from '@/composables/useInteractionSource'
import type { Product } from '@/composables/useProductItem'
import { pick } from '@/services/analytics/gtm/utils/pick'
import { t } from '@/services/translations'

import { dataLayerPush, getGtmElementType } from './utils'

function deleteCart(
  interactionSource: InteractionSourceRaw[],
  products: readonly Product[],
  totalPrice: number,
) {
  const updatedProducts = products.map((product) => {
    return {
      item_id: product.id,
      item_name: product.origin.name,
      price: product.origin.price,
      quantity: 0,
    }
  })

  dataLayerPush({
    event: 'delete_cart',
    position: {
      name: interactionSource,
      type: getGtmElementType(interactionSource),
    },
    ecommerce: {
      currency: t('general.currencyCode'),
      value: totalPrice,
      items: updatedProducts,
      widget_name: pick('name', interactionSource, ''),
      widget_full_path: interactionSource.map((i) => i.name).join('/'),
      widget_code: pick('code', interactionSource, '').toString(),
      search_term: pick('searchTerm', interactionSource, '').toString(),
    },
  })
}

export { deleteCart as gtmEventDeleteCart }
