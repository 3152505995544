/**
 * Current internal state of the MSAL authentication process.
 * It's just memoization of the state, not needed outside of "/auth" folder.
 */
const msalState = {
  /**
   * Helps prevent duplicate code execution (user double-clicks on something).
   * Should be always set to true after redirectRequest has been processed.
   */
  inProgress: false,
}

export default msalState
