import type { CookiesConsentMap } from '@/services/privacy'

import { dataLayerPush } from './utils'

type ConsentType = 'accept_all' | 'denied_all' | 'save_preferences'

function ckConsentUpdate(cookies: CookiesConsentMap, type: ConsentType, section?: string) {
  dataLayerPush({
    event: 'consent_update',
    preferences: { ...cookies },
    method: type,
    modal_section: section ? 'main' : 'detailed_section',
  })
}

export { ckConsentUpdate as gtmEventCkConsentUpdate }

export type { ConsentType }
