import type { InteractionSourceRaw } from '@/composables/useInteractionSource'
import { pick, pickItemListName } from '@/services/analytics/gtm/utils/pick'
import { t } from '@/services/translations'
import useProductsStore from '@/store/pinia/useProductsStore'

import type { AddToCartProps } from '../addToCart'
import { getGtmElementType } from './getGtmElementType'
import { mapProductGtm } from './mapProductGtm'
import type { MappedProduct } from './mapProductGtm'

type ProductForAnalytics = {
  productId: number
  carrierChange: boolean
  originalQuantity: number
}

type CartTransformedProduct = {
  mappedProductsGtm: MappedProduct[]
  value: number //sum mappedProductGtm.price * mappedProductGtm.quantity
}

function productIsNotUndefined(value: MappedProduct | undefined): value is MappedProduct {
  return !!value
}

function transformProductToGtm(
  event: 'add_to_cart' | 'remove_from_cart',
  productsForAnalytics: ProductForAnalytics[],
  interactionSource: InteractionSourceRaw[],
): AddToCartProps {
  const { getProductOrThrow } = useProductsStore()

  const dirtyMappedProducts = productsForAnalytics.map((analyticsProduct) => {
    const storedProduct = getProductOrThrow(analyticsProduct.productId)

    // @todo type fix
    // @todo probably real bug!
    const product: any = analyticsProduct.carrierChange
      ? storedProduct.origin.returnableCarrier
      : storedProduct.origin

    if (!product) {
      return
    }
    const quantityDiff = Math.abs(product.quantity - analyticsProduct.originalQuantity)

    return mapProductGtm(product, interactionSource, {
      quantityDiff,
    })
  })

  const mappedProducts = dirtyMappedProducts.filter(productIsNotUndefined)

  const mappedProductsGtm: CartTransformedProduct = {
    mappedProductsGtm: mappedProducts,
    value: mappedProducts.reduce(
      (accumulator, product) => accumulator + product.price * product.quantity,
      0,
    ),
  }

  return {
    event,
    item_list_name: pickItemListName(interactionSource),
    position: {
      name: interactionSource,
      type: getGtmElementType(interactionSource ?? []),
    },
    ecommerce: {
      currency: t('general.currencyCode'),
      value: mappedProductsGtm.value,
      items: mappedProductsGtm.mappedProductsGtm,
      widget_name: pick('name', interactionSource, ''),
      widget_full_path: interactionSource.map((i) => i.name).join('/'),
      widget_code: pick('code', interactionSource, '').toString(),
      search_term: pick('searchTerm', interactionSource, '').toString(),
    },
  }
}

export default transformProductToGtm
export type { ProductForAnalytics }
