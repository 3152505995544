import { ref, watch, type ComputedRef, type Ref } from 'vue'

import { keepPreviousData, useQuery } from '@tanstack/vue-query'

import { addressQueryKeys } from '@/constants/queryKeys'
import frontApi from '@/data/api/frontApi'
import type { GPS } from '@/data/api/frontApi/address/addressTypes'
import { analyticsLoggerAddressPin } from '@/services/analytics/analyticsLogger'
import ApiError from '@/services/apiv2/ApiError'

async function validateGps(address: string, location: GPS | null) {
  if (!address || !location?.lat) return false

  try {
    await frontApi.address.validateGps({
      queryParams: {
        address,
        lat: location?.lat.toString(),
        lng: location?.lng.toString(),
      },
    })

    return true
  } catch (error) {
    if (error instanceof ApiError) {
      analyticsLoggerAddressPin({
        activity: 'error_message',
        error: {
          message: error.responseBody.detail,
        },
      })
    }

    return false
  }
}

function useValidateGpsGet(address: ComputedRef<string>, location: Ref<GPS | null>) {
  // we dont need to call validate GPS if location was not set before
  const enabled = ref(false)
  const unwatch = watch(location, (_, oldVal) => {
    if (oldVal) {
      enabled.value = true
      unwatch()
    }
  })

  return useQuery({
    queryKey: addressQueryKeys.validateGps(address, location),
    queryFn: () => validateGps(address.value, location.value),
    placeholderData: keepPreviousData,
    gcTime: 3000,
    enabled,
  })
}

export default useValidateGpsGet
