import { useMutation } from '@tanstack/vue-query'

import { clientQueryKeys } from '@/constants/queryKeys'
import type { ClientFlagName } from '@/constants/queryKeys'
import api from '@/data/api'
import type {
  UpdateClientFlagsPathParam,
  UpdateClientFlagsRequestBody,
} from '@/data/api/frontApi/client'

type MutationFnArguments = UpdateClientFlagsPathParam & UpdateClientFlagsRequestBody

function useClientUpdateFlags(flagName: ClientFlagName) {
  const { mutate } = useMutation({
    mutationKey: clientQueryKeys.flagsUpdateByName(flagName),
    mutationFn: ({ flag, value }: MutationFnArguments) =>
      api.front.client.updateFlags({
        path: {
          flag,
        },
        body: {
          value,
        },
      }),
  })

  return {
    mutate,
  }
}

export default useClientUpdateFlags
