import { createOperation } from '@/services/apiv2'

import type {
  CustomContentPathParams,
  CustomContentQueryParams,
  CustomContentResponse,
} from './customContentTypes'

const getCustomContent = createOperation<
  CustomContentResponse,
  CustomContentResponse,
  CustomContentPathParams,
  undefined,
  CustomContentQueryParams
>('/custom-content/:page', {
  method: 'get',
})

export default getCustomContent
