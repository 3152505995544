import { useQuery } from '@tanstack/vue-query'

import { benjaminekQueryKeys } from '@/constants/queryKeys'
import api from '@/data/api'

function useBenjaminekGetChildren() {
  const { data, isLoading } = useQuery({
    queryKey: benjaminekQueryKeys.children(),
    queryFn: () => api.front.benjaminek.getChildren(),
    select: (responseObject) => responseObject.children,
  })

  return {
    children: data,
    isLoading,
  }
}

export default useBenjaminekGetChildren
