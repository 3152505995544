<script setup lang="ts">
import { computed } from 'vue'

import Popup from '@/components/global/Popup/Popup.vue'
import modalIndex from '@/constants/modalIndex'
import { t } from '@/services/translations'
import useModalStore from '@/store/pinia/useModalStore'

import VoucherInput from './VoucherInput.vue'
import type { GiftVoucher } from './giftVouchersTypes'

type VoucherConditionsModalTriggerOptions = {
  conditions: GiftVoucher['conditions']
  voucherCode: GiftVoucher['voucher']['code']
  showVoucherCode?: boolean
}
const { getModal } = useModalStore()

const modalTriggerOptions = computed(() => {
  const modal = getModal<VoucherConditionsModalTriggerOptions>(modalIndex.voucherConditionsModal)

  return modal?.triggerOptions ?? null
})

const conditions = computed(() => modalTriggerOptions.value?.conditions ?? '')
const voucherCode = computed(() => modalTriggerOptions.value?.voucherCode ?? '')
const showVoucherCode = computed(() => modalTriggerOptions.value?.showVoucherCode ?? false)
</script>

<template>
  <Popup
    :name="modalIndex.voucherConditionsModal"
    size="voucher-modal"
    content>
    <p class="text-7 font-weight-bold mb-4">
      {{ t('profile.giftsClub.conditionsTitle') }}
    </p>

    <div
      class="text-4"
      v-html="conditions" />

    <VoucherInput
      v-if="showVoucherCode"
      :model-value="voucherCode"
      class="mt-4" />
  </Popup>
</template>
