<script setup lang="ts">
import { computed } from 'vue'

import Icon from '@/components/Icon/Icon.vue'
import ProductBadgePopover from '@/components/Product/ProductBadgePopover.vue'
import { resizer } from '@/services/resizer'
import { t } from '@/services/translations'
import useDeviceDetectorStore from '@/store/pinia/useDeviceDetectorStore'
import useGiftsStore from '@/store/pinia/useGiftsStore'

type ProductControlGiftProps = {
  giftId: number
  showMoreLink?: string
  mobileCharLimit?: number
  desktopCharLimit?: number
  open?: boolean
}

const props = withDefaults(defineProps<ProductControlGiftProps>(), {
  showMoreLink: '#',
  mobileCharLimit: 70,
  desktopCharLimit: 150,
})

const emit = defineEmits<{
  'update:open': [value: boolean]
  showMore: []
}>()

const deviceDetectorStore = useDeviceDetectorStore()
const { getGiftById } = useGiftsStore()

const gift = computed(() => getGiftById(props.giftId))

const isOpen = computed({
  get() {
    return props.open
  },
  set(value) {
    emit('update:open', value)
  },
})

function cutStringByLimit(string: string, limit: number) {
  return limit >= string.length ? string : `${string.substring(0, limit)}&hellip;`
}

const giftDescription = computed(() =>
  cutStringByLimit(gift.value?.description ?? '', getCharLimit.value),
)
const isGiftDescriptionShortened = computed(
  () => gift.value?.description && giftDescription.value !== gift.value.description,
)
const getCharLimit = computed(
  () =>
    (deviceDetectorStore.breakpointDownMd ? props.mobileCharLimit : props.desktopCharLimit) -
    (gift.value?.name?.length ?? 0),
)
</script>

<template>
  <ProductBadgePopover
    v-model:open="isOpen"
    class="text-right"
    :icon="{
      name: 'gift',
    }"
    :control="{
      class: 'text-white bg-primary',
      title: t('general.gift'),
    }">
    <div
      :class="{
        'gift-info': !!gift,
      }">
      <template v-if="!!gift">
        <img
          v-if="gift.image"
          class="d-block mx-auto mb-2"
          :srcset="`${resizer(gift.image, 150, 150)} 1.5x, ${resizer(gift.image, 75, 75)} 1x`"
          :alt="gift.name" />
        <Icon
          v-else
          icon="gift"
          class="gift-info__img-placeholder mx-auto mb-2" />
        <strong
          class="text-primary"
          v-text="gift.name" />
        <span v-html="giftDescription" />
        <!-- TODO href: If RouterLink is used, product detail doesn't open in popup -->
        <a
          v-if="isGiftDescriptionShortened"
          class="gift-info__more a-link-underline"
          :href="showMoreLink"
          @click.prevent="emit('showMore')"
          v-text="t('general.more')" />
      </template>
    </div>
  </ProductBadgePopover>
</template>

<style lang="scss" scoped>
@use 'sass:color' as sassColor;
@use '@/legacy' as *;

.gift-info {
  @include make-font-scale(2);

  padding: 8px;
  line-height: 1.1;
  text-align: left;
  background: sassColor.adjust(color('primary-light'), $lightness: 48.5%);

  &__img-placeholder {
    display: block;
    font-size: 75px;
    color: color('gray-light');
  }

  &__more {
    color: color('primary-light');
  }
}
</style>
