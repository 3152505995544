import appConfig from '../appConfig'

const scopes = ['openid', 'offline_access', appConfig.auth.scopeCustomer]

/**
 * AAD B2C error codes.
 * @see {@link https://docs.microsoft.com/en-us/azure/active-directory-b2c/error-codes}
 */
const MsalErrorCode = {
  userCanceled: 'AADB2C90091',
  forgottenPassword: 'AADB2C90118',
  interactionInProgress: 'interaction_in_progress',
  redirectUriMismatch: 'redirect_uri_mismatch',
} as const

/**
 * Our internal identifier of user flow that will be passed to MSAL
 * and then back to our app after authorization in AAD is done.
 */
const MsalRedirectFlow = {
  signUpSignIn: 'signUpSignIn',
  passwordReset: 'passwordReset',
} as const

export { scopes, MsalRedirectFlow, MsalErrorCode }
