/* @todo This all should be rafactored to use events. */
import debounce from 'lodash-es/debounce'

// Legacy code use abort controller to cancel request. Also, if there is any
// request in progress, window.emitSilenceFetch will be false.
const abortControllerList: Record<string, AbortController> = {}
// New code simply checks if there is any request in progress and sets window.emitSilenceFetch
// accordingly. No abort controller is used.
const apiV2List = new Set<string>()

// setup side effect - window global function is added to window
// @see https://mallfresh.atlassian.net/browse/KNW-2354
window.emitSilenceReset = () => {
  window.emitSilenceFetch = false
}

const emitSilenceFetch = debounce(() => {
  if (!window.emitSilenceFetch && !Object.keys(abortControllerList).length && !apiV2List.size) {
    window.emitSilenceFetch = true
  }
}, 150)

function abortPreviousRequest(nameController: string) {
  fetchAbortRequestByName(nameController)
  abortControllerList[nameController] = new AbortController()
}

function fetchAbortRequestByName(nameController: string) {
  if (abortControllerList[nameController]) {
    abortControllerList[nameController].abort()
    clearRequestAbortControllerList(nameController)
  }
}

function clearRequestAbortControllerList(nameController: string) {
  delete abortControllerList[nameController]
}

export default emitSilenceFetch

export {
  abortPreviousRequest,
  fetchAbortRequestByName,
  clearRequestAbortControllerList,
  abortControllerList,
  apiV2List,
}
