import { useMutation } from '@tanstack/vue-query'

import { addressQueryKeys } from '@/constants/queryKeys'
import { frontApiPutDeliveryAddress } from '@/services/api/front/address'
import queryClient from '@/services/queryClient'

// TODO: doresit tenhle TYP!!!!!
type AddressForm = any

function useAddressUpdate() {
  async function saveAddress(form: AddressForm) {
    const { id, ...address } = form
    return frontApiPutDeliveryAddress(address, id)
  }

  const { mutate } = useMutation({
    mutationFn: saveAddress,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: addressQueryKeys.detail() })
    },
  })

  function updateAddress(form: AddressForm) {
    mutate(form)
  }

  return updateAddress
}

export default useAddressUpdate
