import { computed, unref } from 'vue'
import type { MaybeRef } from 'vue'
import { useRouter } from 'vue-router'

import { analyticsLoggerLoginOrRegistrationClick } from '@/services/analytics/analyticsLogger'
import type { GTMLoginClickIntention } from '@/services/analytics/gtm'
import { aadLogin } from '@/services/auth'

/**
 * Composable for handling authentication.
 * @param redirectName Name of a router path to redirect to after successful login.
 * Leave empty to redirect to the current page.
 */
function useAuth(redirectName?: MaybeRef<string | undefined>) {
  const router = useRouter()

  // Passed to "AAD login page" as a redirect URL after successful login.
  // Must be full URI, not relative path!
  const finalUrl = computed(() => {
    if (!redirectName) return window.location.href

    const base = window.location.origin
    const pathUrl = router.resolve({ name: unref(redirectName) }).href
    return new URL(pathUrl, base).href
  })

  /**
   * Redirects to AAD registration page.
   * * Calls analytics logger.
   */
  function handleRegistrationClick() {
    analyticsLoggerLoginOrRegistrationClick('registration')

    aadLogin(finalUrl.value, {
      start_from: 'signup',
    })
  }

  /**
   * Redirects to AAD login page.
   * Calls analytics logger.
   */
  function handleLoginClick(gtmClickTypeIntention: GTMLoginClickIntention, socialLogin?: string) {
    analyticsLoggerLoginOrRegistrationClick(gtmClickTypeIntention, socialLogin)

    const loginOptions = {
      ...(socialLogin && { domain_hint: socialLogin }),
    }

    aadLogin(finalUrl.value, loginOptions)
  }

  return {
    finalUrl,
    handleRegistrationClick,
    handleLoginClick,
  }
}

export default useAuth

export type { GTMLoginClickIntention }
