import { SessionStorageKeys } from '@/constants/storageKeys'
import { storagePersistSession, storageRetrieveSession } from '@/services/storage'

import { TIMEOUT } from './config'
import type { Toast } from './toastPersist'

/**
 * Checks all latest pushed toast and mark them as unfinished if they weren't visible until timeout runs out.
 */
function toastCheckUnfinished() {
  const toasts = storageRetrieveSession<Toast[]>(SessionStorageKeys.toast)
  if (!toasts) return

  const checkTriggerTime = new Date().getTime()
  const unfinishedToasts: Toast[] = []
  toasts.forEach((toast) => {
    const hasToastUnfinishedTimeout = checkTriggerTime - toast.triggerTime < TIMEOUT
    if (!toast.isUnfinished && hasToastUnfinishedTimeout) {
      unfinishedToasts.push({
        ...toast,
        isUnfinished: true,
      })
    }
  })

  if (unfinishedToasts.length) {
    storagePersistSession(SessionStorageKeys.toast, unfinishedToasts)
  }
}
toastCheckUnfinished()
