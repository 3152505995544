/**
 * Returns element for ld+json structured data.
 * Creates element if it doesn't exist.
 * @param name - Unique name of the element.
 * @returns HTMLScriptElement for further manipulation.
 * @example In HTML, it looks like this:
 * <script type="application/ld+json" name="productDetail" id="js-ldjson-productDetail">
 */
function getScriptElementInHead(name: string) {
  const elementId = 'js-ldjson-' + name
  const tryElement = document.getElementById(elementId)
  if (tryElement) return tryElement

  const scriptElement = document.createElement('script')
  scriptElement.setAttribute('type', 'application/ld+json')
  scriptElement.setAttribute('name', name)
  scriptElement.setAttribute('id', elementId)

  document.head.appendChild(scriptElement)

  return scriptElement
}

export default getScriptElementInHead
