<script setup>
import { ref, computed } from 'vue'

import Icon from '@/components/Icon/Icon.vue'
import { t } from '@/services/translations'
import useDeviceDetectorStore from '@/store/pinia/useDeviceDetectorStore'

const PICTOGRAPHS_LIMIT = 4

const props = defineProps({
  product: {
    type: Object,
    default: null,
  },
})

const { isTouch } = useDeviceDetectorStore()

const getPictographs = computed(() => {
  return props.product.origin.countryCode
    ? [props.product.origin.countryCode, ...props.product.origin.pictographs]
    : props.product.origin.pictographs
})

const getVisiblePictographs = computed(() => {
  return getPictographs.value.length > PICTOGRAPHS_LIMIT
    ? getPictographs.value.slice(0, PICTOGRAPHS_LIMIT - 1)
    : getPictographs.value
})

const getHiddenPictographs = computed(() => {
  return getPictographs.value.length > PICTOGRAPHS_LIMIT
    ? getPictographs.value.slice(PICTOGRAPHS_LIMIT - 1)
    : null
})

const pictographInfo = ref({
  label: null,
  showHidden: false,
})

const isPictographInfoVisible = computed(() => {
  return pictographInfo.value.label ?? pictographInfo.value.showHidden
})

const getPictographInfoOpeningEvent = computed(() => {
  return isTouch ? 'click' : 'mouseenter'
})

const getPictographInfoClosingEvent = computed(() => {
  return isTouch ? null : 'mouseleave'
})

const getHiddenPictographsLabel = computed(() => {
  return getHiddenPictographs.value.map((pictograph) => t(`pictographs.${pictograph}`)).join(', ')
})

const setPictographInfoContent = (label = null, showHidden = false) => {
  pictographInfo.value = {
    label,
    showHidden,
  }
}
</script>

<template>
  <div class="product-attributes">
    <div class="content">
      <span
        v-if="props.product.origin.productQuantity"
        class="attribute-cell attribute-cell--quantity">
        <span
          v-if="props.product.origin.productQuantity.prefix"
          class="quantity-prefix"
          v-text="props.product.origin.productQuantity.prefix" />
        {{ props.product.origin.productQuantity.value }}
        {{ props.product.origin.productQuantity.unit }}
      </span>
      <span
        v-if="getPictographs.length"
        class="attribute-cell">
        <img
          v-for="pictographCode in getVisiblePictographs"
          :key="`pictograph-${pictographCode}`"
          class="pictograph pointer"
          :src="`/vue/img/pictographs/${pictographCode}.svg?v=1`"
          :alt="t(`pictographs.${pictographCode}`)"
          @[getPictographInfoOpeningEvent]="setPictographInfoContent(pictographCode)"
          @[getPictographInfoClosingEvent]="setPictographInfoContent(null)" />
        <span
          v-if="getHiddenPictographs"
          class="control control--more pointer"
          role="img"
          :aria-label="getHiddenPictographsLabel"
          @[getPictographInfoOpeningEvent]="setPictographInfoContent(null, true)"
          @[getPictographInfoClosingEvent]="setPictographInfoContent(null)">
          <Icon icon="arrow-button" />
        </span>
      </span>
    </div>
    <div
      v-if="isPictographInfoVisible"
      class="pictograph-info"
      aria-hidden="true">
      <div class="pictograph-info__content">
        <template v-if="pictographInfo.showHidden">
          <div
            v-for="pictographCode in getHiddenPictographs"
            :key="`pictograph-hidden-${pictographCode}`"
            class="pictograph-row">
            <img
              class="pictograph-row__img pictograph"
              :src="`/vue/img/pictographs/${pictographCode}.svg?v=1`"
              alt="" />
            {{ t(`pictographs.${pictographCode}`) }}
          </div>
        </template>
        <template v-else>
          {{ t(`pictographs.${pictographInfo.label}`) }}
        </template>
        <button
          v-if="isTouch"
          class="pictograph-info__close control"
          :aria-label="t('general.close')"
          @click="setPictographInfoContent(null)">
          <Icon icon="cross" />
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

$wrapper-height: 22px;

.product-attributes {
  position: relative;
}

.content {
  display: flex;
  justify-content: center;
  height: $wrapper-height;
}

.attribute-cell {
  display: flex;
  align-items: center;
  height: $wrapper-height;
  padding: 0 3px;
  background: color('gray-lighter');
  border-radius: $wrapper-height * 0.5;

  &--quantity {
    padding: 0 8px;
    margin-right: 2px;
    white-space: nowrap;
  }
}

.quantity-prefix {
  @include make-font-scale(1);

  margin-right: 1px;
  color: color('gray');
}

.pictograph {
  width: 16px;
  height: 16px;

  & + & {
    margin-left: 1px;
  }
}

.control {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  padding: 0;
  color: color('white');
  background: color('gray');
  border: 0;
  border-radius: 50%;

  .icon {
    width: 16px;
    height: 16px;
  }

  &--more {
    width: 16px;
    height: 16px;
    margin-left: 1px;

    .icon {
      width: 13px;
      margin-left: 1px;
    }
  }
}

.pictograph-info {
  position: absolute;
  bottom: $wrapper-height + 2;
  left: 50%;
  width: 100%;
  text-align: center;
  transform: translateX(-50%);

  &__content {
    position: relative;
    display: inline-block;
    padding: 4px 8px;
    margin: 0 16px;
    color: color('white');
    text-align: left;
    background: color('gray-dark');
    border-radius: var(--borderRadiusSmall);
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
  }
}

.pictograph-row {
  position: relative;
  padding-left: 19px;
  margin-top: 3px;

  &__img {
    position: absolute;
    top: -1px;
    left: 0;
  }
}
</style>
