import type { FrontApiEmptyResponse } from '@/data/api/frontApi/frontApiTypes'
import { createOperation } from '@/services/apiv2'

import type { PostPrivacyRequest } from './privacyTypes'

/**
 * Post privacy request based on GDPR policy for a logged in customer
 * @see https://sasandboxstdhotapidocs.z1.web.core.windows.net/#/Privacy/createCustomerPrivacyRequest
 */
const request = createOperation<FrontApiEmptyResponse, undefined, undefined, PostPrivacyRequest>(
  '/privacy/request/customer',
  {
    method: 'post',
  },
)
export default request
