<script setup lang="ts">
import { ref } from 'vue'

import Icon from '@/components/Icon/Icon.vue'
import { t } from '@/services/translations'
import useProductsStore from '@/store/pinia/useProductsStore'

type ProductControlFavoriteProps = {
  productId: number
  isFavorite?: boolean
  highlight?: boolean
}

const props = defineProps<ProductControlFavoriteProps>()

const { setFavoriteProduct } = useProductsStore()

const hover = ref(false)
const currentlyInteracted = ref(false)
const lastInteraction = ref('')

function toggle() {
  currentlyInteracted.value = true
  lastInteraction.value = props.isFavorite ? 'removed' : 'added'

  setFavoriteProduct({
    id: props.productId,
    favorite: !props.isFavorite,
  })
}

function handleMouseHover(state: boolean) {
  hover.value = state
  if (!state) {
    currentlyInteracted.value = false
  }
}
</script>

<template>
  <button
    type="button"
    :class="[
      'wrap',
      {
        'text-primary': props.isFavorite || props.highlight,
        'text-black': props.isFavorite && hover && !currentlyInteracted,
      },
    ]"
    :title="t(`general.${props.isFavorite ? 'removeFromFavorite' : 'addToFavorite'}`)"
    @mouseenter="handleMouseHover(true)"
    @mouseleave="handleMouseHover(false)"
    @click="toggle">
    <Icon
      :icon="props.isFavorite || (hover && !currentlyInteracted) ? 'heart-full' : 'heart'"
      :class="lastInteraction" />
  </button>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.wrap {
  $size: 27px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: $size;
  height: $size;
  padding: 0;
  color: color('gray-light');
  background: color('white');
  border: 0;
  border-radius: 50%;
}

.icon {
  $size: 25px;

  width: $size;
  height: $size;
}

@include make-animation-pulse(1.2);

.added {
  animation: pulse-1p2 0.25s ease 2 alternate;
}

@include make-animation-pulse(1.1);

.removed {
  animation: pulse-1p1 0.25s ease 2 alternate;
}
</style>
