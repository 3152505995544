import { useQuery } from '@tanstack/vue-query'

import queryClient, { configQuery } from '@/services/queryClient'

let hasBeenInitialized = false

/**
 *  Ensures configuration is loaded.
 *  Use this function outside Vue context.
 */
async function ensureConfig() {
  return queryClient.ensureQueryData(configQuery)
}

/**
 * Get basic user configuration.
 * @throws "config_not_initialized" when configuration has not been initialized.
 * Always run `useConfigInit()` first and ensure data has been loaded before using this composable.
 * @throws "config_error" when configuration is not available.
 */
function useConfig() {
  if (!hasBeenInitialized) {
    throw new Error('config_not_initialized')
  }

  const { data } = useQuery(configQuery)

  if (!data.value) {
    // This should never happen.
    throw new Error('config_error')
  }

  return data
}

/**
 * Initializes configuration data. You must run this before using `useConfig()`.
 * @returns Query object for configuration endpoint.
 */
function useConfigInit() {
  hasBeenInitialized = true
  return useQuery(configQuery)
}

export default useConfig
export { useConfig, useConfigInit, ensureConfig }
