import { storeToRefs } from 'pinia'

import useProductsStore from '@/store/pinia/useProductsStore'
import useTimeslotsStore from '@/store/pinia/useTimeslotsStore'
import { formatDate } from '@/utils/date'

import { dataLayerPush } from './utils'

function checkoutItemConflict() {
  const { limitedProducts } = storeToRefs(useTimeslotsStore())
  const { getProductOrThrow } = useProductsStore()

  const limitedProductsFullData = limitedProducts.value.map((limitedProduct) => {
    const product = getProductOrThrow(limitedProduct.id)
    const unavailableDays = limitedProduct.days.map((day) =>
      formatDate(day.date, {
        showYear: true,
        customFormat: 'YYYY-MM-DD',
      }),
    )

    return {
      id: product.id,
      name: product.origin.name,
      unavailable_days: unavailableDays,
    }
  })

  dataLayerPush({
    event: 'checkout_item_conflict',
    error: {
      type: 'delivery_conflict',
    },
    checkout: {
      step_number: 4,
    },
    items: limitedProductsFullData,
  })
}

export { checkoutItemConflict as gtmEventCheckoutItemConflict }
