const LocalStorageKeys = {
  isMobileAppSuggested: 'app-suggested',
  voucher: 'voucher',
  charityCheckbox: 'charity',
  additionalOrdersNotified: 'additionalOrdersNotified',
  userUuid: 'userUuid',
  warehouseClick: 'warehouseClick',
  identityProvider: 'identityProvider',
} as const

const SessionStorageKeys = {
  toast: 'toast',
  userInterfaceVendorId: 'userInterface.vendor_id',
  firstProductAdded: 'firstProductAdded',
  liveChatOpenInSession: 'liveChatOpenInSession',
  mobilAppLogout: 'mobile-app-logout',
  openPreviewCart: 'openPreviewCart',
  userPreferredProductDisplayMode: 'user.preferredProductDisplayMode',
} as const

export { LocalStorageKeys, SessionStorageKeys }
