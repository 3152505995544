<script setup lang="ts">
import ImgResponsive from '@/components/stories/atoms/imgResponsive/ImgResponsive.vue'
import { t } from '@/services/translations'

type GiftsClubCoverProps = {
  title: string
  description: string
}

const props = defineProps<GiftsClubCoverProps>()
</script>

<template>
  <div class="wrapper">
    <div class="background-container" />

    <div class="content-container">
      <img
        :src="'/vue/img/clubs/gifts-club-logo.svg?v=1'"
        :alt="t('userMenu.labels.clubGifts')"
        class="logo" />
      <div class="text-container">
        <p class="title">{{ props.title }}</p>
        <p class="description">{{ props.description }}</p>
      </div>
    </div>

    <div class="image-container">
      <ImgResponsive
        :srcset="[
          {
            url: '/vue/img/clubs/gifts-club.webp',
          },
          {
            pixelRatio: 1.5,
            url: '/vue/img/clubs/gifts-club@x2.webp',
          },
        ]"
        class="image"
        lazyload
        :alt="t('userMenu.labels.clubGifts')" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.wrapper {
  display: grid;
}

.background-container {
  $border-radius: 40px;

  grid-row: 1 / 3;
  grid-column: 1 / 3;
  height: 80%;
  background-color: color('gray-lighter');
  border-top-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;

  @include media-breakpoint-up(sm) {
    height: 100%;
  }

  @include media-breakpoint-up(xl) {
    grid-row: 2 / 3;
  }
}

.content-container {
  grid-row: 1 / 2;
  grid-column: 1 / 2;

  @include media-breakpoint-up(sm) {
    grid-column: 1;
  }

  @include media-breakpoint-up(xl) {
    grid-row: 2 / 3;
    min-height: 300px;
  }
}

.logo {
  width: auto;
  height: 93px;

  @include media-breakpoint-up(sm) {
    height: 71px;
  }

  @include media-breakpoint-up(xl) {
    height: 104px;
  }
}

.text-container {
  margin: 16px;

  @include media-breakpoint-up(sm) {
    margin-left: 24px;
  }

  @include media-breakpoint-up(xl) {
    margin-left: 50px;
  }
}

.title {
  padding: 10px 0;
  margin: 0;
  font-size: get-font-size(8);
  font-weight: var(--fontWeightBold);
  color: color('primary');
}

.description {
  margin: 0;
  font-size: get-font-size(6);
  color: color('text-paragraph');
}

.image-container {
  grid-row: 2;
  grid-column: 1 / 2;
  align-content: center;
  margin: 24px auto;

  @include media-breakpoint-up(sm) {
    grid-row: 1 / 2;
    grid-column: 2;
    margin: 24px;
  }

  @include media-breakpoint-up(xl) {
    grid-row: 1 / 4;
    padding: unset;
    margin: 0 50px 0 0;
  }
}

.image {
  width: 198px;
  height: auto;
  border-radius: 24px;

  @include media-breakpoint-up(sm) {
    min-width: 239px;
  }

  @include media-breakpoint-up(xl) {
    width: 386px;
  }
}
</style>
