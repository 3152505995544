<script setup>
import { computed, onMounted } from 'vue'

import useInteractionSource from '@/composables/useInteractionSource'
import useDeviceDetectorStore from '@/store/pinia/useDeviceDetectorStore'
import useModalStore from '@/store/pinia/useModalStore'

import PopupContent from './PopupContent.vue'
import { usePopupProps, usePopup } from './usePopup'

const props = defineProps({
  ...usePopupProps,
  control: {
    type: Boolean,
    default: false,
  },
  content: {
    type: Boolean,
    default: false,
  },
  autoOpen: {
    type: Boolean,
    default: false,
  },
})
const emits = defineEmits(['open', 'close'])

const deviceDetectorStore = useDeviceDetectorStore()

const displayControl = computed(
  () => props.control && (props.onlyMobile ? deviceDetectorStore.breakpointDownSm : true),
)

const { setClose, setOpen } = usePopup(props)
const { isModalOpen } = useModalStore()

onMounted(() => {
  if (props.autoOpen) {
    setOpen()
  }
})

useInteractionSource(
  'Popup',
  computed(() => ({ modalName: props.name, isModalOpen: isModalOpen(props.name) })),
)
</script>

<template>
  <span
    v-if="displayControl"
    @click.prevent="setOpen">
    <slot />
  </span>
  <PopupContent
    v-else-if="props.content"
    :name="props.name"
    :collision-group="props.collisionGroup"
    :size="props.size"
    :only-mobile="props.onlyMobile"
    :transition-name="props.transitionName"
    :class-css="props.classCss"
    :box-class-css="props.boxClassCss"
    :class-close-cross="props.classCloseCross"
    :data-tid-close-cross="props.dataTidCloseCross"
    :analytics-id="props.analyticsId"
    :disable-close="props.disableClose"
    @open="emits('open')"
    @close="emits('close')">
    <slot :close="() => setClose(props.analyticsId)" />
  </PopupContent>
</template>
