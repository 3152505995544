import { dataLayerPush } from './utils'

type CheckboxInteraction = {
  type: 'checkbox'
  value: 'check' | 'uncheck'
  name: string
}

// add more types when needed
type ComponentInteraction = CheckboxInteraction

function componentInteraction(component: ComponentInteraction) {
  dataLayerPush({
    event: 'component_interaction',
    component,
  })
}

export { componentInteraction as gtmEventComponentInteraction }
export type { ComponentInteraction }
