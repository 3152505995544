<script setup lang="ts">
import { resizer } from '@/services/resizer'

import type { GiftsClubBenefit } from './giftsClubBenefitsTypes'

type GiftsClubBenefitItemProps = GiftsClubBenefit

const props = defineProps<GiftsClubBenefitItemProps>()
</script>

<template>
  <span class="benefit-item">
    <img
      :src="resizer(props.image, 32, 32, 'SHRINK_ONLY')"
      alt="icon"
      class="image" />

    <div class="text-container text-brand h-100">
      <p class="text-title text-5">{{ props.title }}</p>
      <p
        v-if="props.description"
        class="text-description text-4">
        {{ props.description }}
      </p>
    </div>
  </span>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.benefit-item {
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 16px;
  background-color: color('white');
  border-radius: var(--borderRadiusMedium);
}

.image {
  $size: 32px;

  flex-shrink: 0;
  width: $size;
  height: $size;
}

.text-container {
  display: flex;
  flex-direction: column;
}

.text-title {
  margin: 0;
  font-weight: bold;
}

.text-description {
  margin: 8px 0 0;
}
</style>
