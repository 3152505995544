import { dataLayerPush } from './utils'

type AddressSelectorMessage =
  | 'address_found_delivering'
  | 'address_found_not_delivering'
  | 'address_not_found'

type AddressSelectorType = 'unspecific_address' | 'whole_address'

type DataLayerObj = {
  event: 'address_selector'
  message: AddressSelectorMessage
  location?: string
  type?: AddressSelectorType
}

function addressSelector(
  message: AddressSelectorMessage,
  location: string | undefined,
  type: AddressSelectorType | undefined,
) {
  const dataLayerObj: DataLayerObj = {
    event: 'address_selector',
    message,
    ...(location !== undefined && { location }),
    ...(type !== undefined && { type }),
  }

  dataLayerPush(dataLayerObj)
}

export { addressSelector as gtmEventAddressSelector }

export type { AddressSelectorMessage, AddressSelectorType }
