import { createOperation } from '@/services/apiv2'

import type { FrontApiEmptyResponse } from '../frontApiTypes'

type UpdateClientFlagsPathParam = {
  flag: string
}

type UpdateClientFlagsRequestBody = {
  value: boolean
}

const updateFlags = createOperation<
  FrontApiEmptyResponse,
  FrontApiEmptyResponse,
  UpdateClientFlagsPathParam,
  UpdateClientFlagsRequestBody
>('/client/flags/:flag', {
  method: 'put',
})

export type { UpdateClientFlagsPathParam, UpdateClientFlagsRequestBody }

export default updateFlags
