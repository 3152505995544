import { useQuery } from '@tanstack/vue-query'

import { transportQueryKeys } from '@/constants/queryKeys'
import api from '@/data/api'
import queryClient from '@/services/queryClient'

type TransportWindowsCurrentCart = 0 | 1

function invalidateTransportWindows(currentCart: TransportWindowsCurrentCart) {
  queryClient.invalidateQueries({ queryKey: transportQueryKeys.windows(currentCart) })
}

function useTransportWindows(currentCart: TransportWindowsCurrentCart) {
  return useQuery({
    queryKey: transportQueryKeys.windows(currentCart),
    queryFn: () =>
      api.front.transport.transportWindows({ queryParams: { 'current-cart': currentCart } }),
  })
}

export default useTransportWindows
export { invalidateTransportWindows }
export type { TransportWindowsCurrentCart }
