import { watch } from 'vue'

import dayjs from 'dayjs'
import { storeToRefs } from 'pinia'

import usePreviewTimeslots from '@/components/AddressSelector/usePreviewTimeslots'
import store from '@/store'
import useAddressStore from '@/store/pinia/useAddressStore'
import useCartStore from '@/store/pinia/useCartStore'

import { dataLayerPush } from './utils'

type CheckoutSteps = 'summary' | 'summaryAdditional'

function addShippingInfo(checkoutStep: CheckoutSteps) {
  const { previewTimeslots: getPreviewTimeslots } = usePreviewTimeslots()
  const { userAddress } = storeToRefs(useAddressStore())
  const cartStore = useCartStore()
  const { cartGetters } = storeToRefs(cartStore)

  watch(
    () => [getPreviewTimeslots.value, store.getters.cart.isCartLoaded, userAddress.value],
    () => {
      if (
        !getPreviewTimeslots.value.length ||
        !store.getters.cart.isCartLoaded ||
        !userAddress.value
      )
        return

      const selectedTimeslot = cartGetters.value.getSelectedTimeslot
      dataLayerPush({
        event: 'add_shipping_info',
        checkout: {
          step_number: 4,
          order_type: checkoutStep === 'summary' ? 'default_order' : 'additional_order',
          shipping: cartGetters.value.getCartCheckoutPrices.transport,
          shipping_slot_time: {
            start: selectedTimeslot?.start ? dayjs(selectedTimeslot.start).toISOString() : null,
            end: selectedTimeslot?.end ? dayjs(selectedTimeslot.end).toISOString() : null,
          },
          note_filled: !!userAddress.value.note,
        },
      })
    },
    { immediate: true },
  )
}

export { addShippingInfo as gtmEventAddShippingInfo }
