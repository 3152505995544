<script setup lang="ts">
import { ref, computed, watch } from 'vue'

import { useDebounceFn } from '@vueuse/core'
import { merge } from 'lodash-es'

import { FormText } from '@/components/Form'
import Icon from '@/components/Icon/Icon.vue'
import { useAddressGetAll, useAddressGetSuggestions } from '@/composables/address'
import useUser from '@/composables/useUser'
import type { AddressesResponse } from '@/data/api/frontApi/address/addressTypes'
import { analyticsLoggerAddressSelector } from '@/services/analytics/analyticsLogger'
import appConfig from '@/services/appConfig'
import { t } from '@/services/translations'
import type { UserAddress } from '@/store/pinia/useAddressStore'

export type AddressInputBoxSuggestInputProps = {
  acceptPartialAddress?: boolean
  autofocus?: boolean
  showUserAddresses?: boolean
}

const props = defineProps<AddressInputBoxSuggestInputProps>()
const addressModel = defineModel<UserAddress>({ required: true })
const emit = defineEmits<{
  addressChanged: []
}>()
const { isUserSignedIn } = useUser()

const isAddressSelected = ref(false)
const isInputFocused = ref(props.showUserAddresses)
const focusedAddressIndex = ref(-1)

const { suggestionInputValue, suggestions, isSuccess } = useAddressGetSuggestions()

const debouncedSuggestion = useDebounceFn(suggest, appConfig.inputFormDebouncingTime)

watch(isSuccess, (newValue) => {
  if (newValue && !suggestions.value?.length) {
    analyticsLoggerAddressSelector('address_not_found', suggestionInputValue.value)
  }
})

function suggest() {
  isAddressSelected.value = false
  isInputFocused.value = true

  if (addressModel.value.address.length > 2) {
    suggestionInputValue.value = addressModel.value.address
  }
}

const userAddressSuggestions = computed(() => {
  if (!addressModel.value.address && !!userDeliveryAddresses.value?.length) {
    return userDeliveryAddresses.value.map((address) => ({
      address: address.address,
      wholeAddress: true,
    }))
  }

  return suggestions?.value
})

const isSuggestionsVisible = computed(
  () => userAddressSuggestions.value?.length && !isAddressSelected.value && isInputFocused.value,
)

const focusedAddress = computed(() => {
  if (suggestions.value?.length) {
    return suggestions?.value?.[focusedAddressIndex.value]
  }

  return userAddressSuggestions.value?.[focusedAddressIndex.value]
})

function selectAddress() {
  merge(addressModel.value, focusedAddress.value)

  if (!props.acceptPartialAddress && !addressModel.value.wholeAddress) {
    suggest()
  } else {
    isInputFocused.value = false
    isAddressSelected.value = true

    emit('addressChanged')
  }
}

function keyControl(event: KeyboardEvent) {
  const keyName = event.key

  if (['ArrowUp', 'ArrowDown', 'Enter', 'Escape'].includes(keyName)) {
    event.stopPropagation()
    event.preventDefault()

    const hasSuggestions = suggestions?.value?.length ?? 0

    if (keyName === 'ArrowUp' && focusedAddressIndex.value > 0) {
      focusedAddressIndex.value--
    } else if (keyName === 'ArrowDown' && focusedAddressIndex.value < hasSuggestions - 1) {
      focusedAddressIndex.value++
    } else if (keyName === 'Enter' && focusedAddressIndex.value >= 0) {
      selectAddress()
    } else if (keyName === 'Escape') {
      isInputFocused.value = false
    }
  }
}

const userDeliveryAddresses = ref<AddressesResponse['deliveryAddresses']>()
if (props.showUserAddresses && isUserSignedIn) {
  const { addressSuspense } = useAddressGetAll()
  const { data } = await addressSuspense()
  userDeliveryAddresses.value = data?.deliveryAddresses
}
</script>

<template>
  <div
    class="address-suggest-input"
    @keydown="keyControl">
    <FormText
      v-model="addressModel.address"
      :placeholder="
        props.acceptPartialAddress
          ? t('addressSelector.inputPlaceholder')
          : t('addressSelector.inputPlaceholderExact')
      "
      data-tid="destination-modal__address-input"
      :autofocus="props.autofocus"
      @input="debouncedSuggestion"
      @mousedown="isInputFocused = true">
      <Icon
        icon="pointer"
        class="address-suggest-input__pointer-icon" />
    </FormText>
    <div
      v-if="isSuggestionsVisible"
      class="address-suggestions">
      <div
        v-for="(suggestion, index) in userAddressSuggestions"
        :key="index"
        :class="[
          'address-suggestions__item',
          {
            'address-suggestions__item--active': index === focusedAddressIndex,
          },
        ]"
        :data-tid="
          index === focusedAddressIndex ? 'address-suggest__item--active' : 'address-suggest__item'
        "
        @mouseover="focusedAddressIndex = index"
        @click="selectAddress">
        {{ suggestion.address }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.address-suggest-input {
  position: relative;
  margin-bottom: 10px;

  &__pointer-icon {
    $size: em(26px);

    position: absolute;
    top: 8px;
    left: 6px;
    width: $size;
    height: $size;
    color: color('contrast-red');
  }
}

.address-suggestions {
  position: absolute;
  z-index: $zix-address-suggestions;
  width: 100%;
  margin-top: -1px;
  background: color('white');
  border: 1px solid color('gray-light');

  &__item {
    padding-inline: 6px;
    line-height: 26px;
    cursor: pointer;

    &--active {
      background: color('gray-light');
    }
  }
}

:deep(.form-text) {
  padding-left: 35px;
  margin-top: 0;
}
</style>
