const Vendors = {
  BASE: 1,
  PHARMACY: 2,
} as const

const VENDOR_CONFIG = {
  [Vendors.BASE]: {
    generalName: 'base',
    logo: false,
  },
  [Vendors.PHARMACY]: {
    generalName: 'pharmacy',
    logo: 'dr-max',
  },
} as const

type VendorID = (typeof Vendors)[keyof typeof Vendors]

type VendorType = 'current' | 'other'

export { Vendors, VENDOR_CONFIG }
export type { VendorID, VendorType }
