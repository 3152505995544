import { computed } from 'vue'

import { useQuery } from '@tanstack/vue-query'
import { storeToRefs } from 'pinia'

import { bannerQueryKeys } from '@/constants/queryKeys'
import api from '@/data/api'
import type { BannerType } from '@/data/api/frontApi/banners/bannerTypes'
import appConfig from '@/services/appConfig'
import useUserInterfaceStore from '@/store/pinia/useUserInterfaceStore'

function useBanners(type: BannerType) {
  const { isPharmacy } = storeToRefs(useUserInterfaceStore())

  const isEnabled = computed(() => {
    return type !== 'webBigTipMain' || (appConfig.enableBigTip && !isPharmacy.value)
  })

  const { data: bannersData, isLoading } = useQuery({
    queryKey: bannerQueryKeys.type(type),
    queryFn: () =>
      api.front.banners.getBanners({
        queryParams: {
          type,
        },
      }),
    enabled: isEnabled,
  })

  const banners = computed(() => bannersData.value?.banners ?? [])

  return { banners, isLoading }
}

export default useBanners
