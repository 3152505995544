import type { SocialProviderClaims, EmailProviderClaims } from '@/services/auth/aadTypes'

import { dataLayerPush } from './utils'

function login(claims: EmailProviderClaims | SocialProviderClaims) {
  dataLayerPush({
    event: 'login',
    /**
     * Remove deprecated idpForGtm fallback after 1.1.2025.
     */
    method: claims.idp ?? claims.idpForGtm,
    isNewRegistrationAad: claims.isNewRegistrationAad,
    isNewRegistrationK3w: claims.isNewRegistrationK3w,
  })
}

export { login as gtmEventLogin }
