import { createOperation } from '@/services/apiv2'

import type { ProfileOrderDetailResponse, OrderDetailPathParams } from './profileTypes'

const orderDetail = createOperation<
  ProfileOrderDetailResponse,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  OrderDetailPathParams
>('profile/order/:id', {
  method: 'get',
})

export default orderDetail
