import makeRequest, { type RequestQueryParams } from './makeRequest'

function fetchGet<TData = any>(
  url: string,
  params?: RequestQueryParams,
  nameController?: string,
  isExternal?: boolean,
) {
  return makeRequest<TData>({
    method: 'GET',
    url,
    params,
    nameController,
    isExternal,
  })
}

function fetchPost<TData = any>(
  url: string,
  params?: RequestQueryParams,
  requestData?: any,
  formData?: any,
  nameController?: string,
  isExternal?: boolean,
) {
  return makeRequest<TData>({
    method: 'POST',
    url,
    params,
    requestData,
    formData,
    nameController,
    isExternal,
  })
}

function fetchPatch<TData = any>(
  url: string,
  requestData?: any,
  formData?: any,
  nameController?: string,
  isExternal?: boolean,
) {
  return makeRequest<TData>({
    method: 'PATCH',
    url,
    requestData,
    formData,
    nameController,
    isExternal,
  })
}

function fetchDelete<TData = any>(url: string, nameController?: string, isExternal?: boolean) {
  return makeRequest<TData>({
    method: 'DELETE',
    url,
    nameController,
    isExternal,
  })
}

function fetchPut<TData = any>(
  url: string,
  requestData?: any,
  nameController?: string,
  isExternal?: boolean,
) {
  return makeRequest<TData>({
    method: 'PUT',
    url,
    requestData,
    nameController,
    isExternal,
  })
}

export { fetchGet, fetchPost, fetchPatch, fetchDelete, fetchPut }
