import type { Ref, MaybeRef, InjectionKey } from 'vue'
import { unref, provide, inject } from 'vue'

/**
 * Possible values of interaction source data.
 */
type InteractionSourceValue = string | number | boolean | null | any[] | object | undefined

/**
 * Interaction source is used in analytics (it's in key `position.name` in dataLayer)
 * to identify the source of the interaction (for example clicked element) in tree of elements.
 * It must always contain `name` property and can contain any other properties.
 */
type InteractionSourceRaw = {
  name: string
  [key: string]: InteractionSourceValue
}

/**
 * Additional data for yottly analytics. Must be either array of product IDs
 * or ID of a product.
 */
type YottlySourceRaw =
  | {
      /**
       * Array of product IDs that should be marked as yottly products.
       */
      products: number[]
      productId?: never
    }
  | {
      /**
       * ID of a product.
       */
      productId: number
      products?: never
    }

/**
 * Additional interaction source data with reactivity.
 */
type InteractionSourceRef = MaybeRef<Record<string, InteractionSourceValue>>

/**
 * Additional yottly data with reactivity.
 */
type YottlySourceRef = Ref<YottlySourceRaw>

/**
 * Reactive object used to store "interaction source" data and additional information
 * that helps analytics functions to work properly. Data here are obtained from
 * `useInteractionSource` composable, so it's necessary to use reactive proxies
 * to ensure that reactive data are always up to date.
 */
type InteractionSourceItemRef = {
  name: string
  interactionSourceData?: InteractionSourceRef
  /**
   * Data for yottly analytics.
   */
  yottly?: YottlySourceRef
}

/**
 * Raw interaction source data ready for `getInteractionSource()` function.
 */
type InteractionSourceItem = {
  interactionSource: InteractionSourceRaw
  yottly?: YottlySourceRaw
}

/**
 * Internal symbol used to store interaction source data in Vue's provide/inject.
 */
const interactionSourceSymbol = Symbol('interactionSource') as InjectionKey<
  InteractionSourceItemRef[]
>

function addYottlyFlag(interactionSource: InteractionSourceItem[]) {
  const yottlyProducts = interactionSource.find((data) => data.yottly?.products)?.yottly?.products
  if (!Array.isArray(yottlyProducts)) {
    return interactionSource
  }

  const productIndex = interactionSource.findIndex((data) => data.yottly?.productId)
  const productId = Number(interactionSource[productIndex]?.yottly?.productId)
  if (Number.isNaN(productId)) {
    return interactionSource
  }

  if (yottlyProducts.includes(productId)) {
    interactionSource[productIndex].interactionSource.yottly = true
  }

  return interactionSource
}

/**
 * Marks current component as an interaction source for future analytics operations.
 * If you provide additional data, make sure that it's reactive if it's necessary.
 * You can simply use composable without any arguments. In that case, you will get
 * `getInteractionSource()` function that you can call later, but component won't be
 * marked as an interaction source.
 *
 * @example
 * ```ts
 * // Only mark current component:
 * useInteractionSource('SomeName')
 *
 // Mark current component and provide additional static data:
 * useInteractionSource('SomeName', {
 *   searchText: someComputedValue,
 * })
 *
 * // Mark current component and provide additional reactive data:
 * const searchText = reactive({
 *   searchText: '',
 * })
 * useInteractionSource('SomeName', searchText)
 *
 * // Mark current component and call final interaction source array:
 * const getInteractionSource = useInteractionSource('SomeName')
 * const finalInteractionSource = getInteractionSource({
 *   element: lastClickedElement,
 * })
 *
 * // Only get getInteractionSource() function, don't mark current component:
 * const getInteractionSource = useInteractionSource()
 * ```
 *
 * @param name Name of current interaction source. Leave empty if you only need
 * getInteractionSource() function.
 * @param interactionSourceData Additional data for current interaction source.
 * Must be always object. Also with reactive properties if necessary.
 * @param yottly Data for yottly analytics.
 */
function useInteractionSource(
  name?: string,
  interactionSourceData?: InteractionSourceRef,
  yottly?: YottlySourceRef,
) {
  // First, we try to inject data from parent component (if any).
  const provided = inject(interactionSourceSymbol, [])
  let memoized: InteractionSourceItemRef[]

  if (name) {
    // If name is provided, we create new item (mark current component as an interaction source).
    const newItem: InteractionSourceItemRef = {
      name,
      interactionSourceData,
      yottly,
    }

    // Then, we add new item to the array and provide it to child components.
    memoized = [...provided, newItem]
    provide(interactionSourceSymbol, memoized)
  } else {
    // If name is not provided, we only return current interaction source data.
    memoized = provided
  }

  /**
   * Returns interaction source data for current component.
   * You can provide additional data (like last clicked element)
   * for current interaction source.
   * @param interactiveData Additional data for current interaction source.
   */
  function getInteractionSource(interactiveData?: Record<string, InteractionSourceValue>) {
    const urefedVals = memoized.map((item) => {
      const unrefedItem: InteractionSourceItem = {
        interactionSource: {
          name: item.name,
          ...unref(item.interactionSourceData),
        },
        yottly: item.yottly?.value,
      }
      return unrefedItem
    })

    if (interactiveData && urefedVals.length > 0) {
      const lastItem = urefedVals[urefedVals.length - 1].interactionSource
      Object.assign(lastItem, interactiveData)
    }
    // Process yottly data.
    const dataWithYottlyFlag = addYottlyFlag(urefedVals)

    // Get final interaction source data only.
    const finalInteractionSourceData: InteractionSourceRaw[] = dataWithYottlyFlag.map((item) => ({
      ...item.interactionSource,
    }))

    return finalInteractionSourceData
  }

  return getInteractionSource
}

export default useInteractionSource

export type { YottlySourceRaw, InteractionSourceRaw, InteractionSourceValue }
