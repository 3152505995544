import { computed, ref, watch, type Ref } from 'vue'

import type { CartReturnableCarrier } from '@/services/api/front/cartTypes'
import useCartStore from '@/store/pinia/useCartStore'

import type { InteractionSourceRaw } from '../useInteractionSource'
import type { Product } from '../useProductItem'

// create map with boolean values of carrier (if carrier is used), for each product
let returnableCarrierMap: Record<number, Ref<boolean>> = {}

function useReturnableCarrier(product: Product, interactionSource?: InteractionSourceRaw[]) {
  const cartStore = useCartStore()
  const { cartActions } = cartStore

  const getCarrierQuantity = computed(() => product.origin.returnableCarrier?.quantity ?? 0)

  if (!returnableCarrierMap[product.id]) {
    returnableCarrierMap[product.id] = ref(false)

    watch(
      () => returnableCarrierMap[product.id].value,
      (value) => {
        cartActions[value ? 'useReturnableCarrier' : 'removeReturnableCarrier']({
          productId: product.id,
          interactionSource: interactionSource,
        })
      },
    )
  }

  const inputCarrierValue = computed({
    get() {
      return returnableCarrierMap[product.id].value
    },
    set(value) {
      setCarrierValue(value)
    },
  })

  function setCarrierValue(value: boolean) {
    returnableCarrierMap[product.id].value = value
  }

  function removeProductFromMap() {
    const { [product.id]: _, ...rest } = returnableCarrierMap
    returnableCarrierMap = { ...rest }
  }

  function setCarrierMultibuy(returnableCarrier: CartReturnableCarrier | null, step: number) {
    if (
      (returnableCarrier && returnableCarrier.quantity > 0) ||
      (returnableCarrier?.quantityBreakpoint && step >= returnableCarrier.quantityBreakpoint)
    ) {
      setCarrierValue(true)
    }
  }

  return {
    inputCarrierValue,
    getCarrierQuantity,
    setCarrierValue,
    removeProductFromMap,
    setCarrierMultibuy,
  }
}

export default useReturnableCarrier
