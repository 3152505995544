import createDeliveryAddress from './createDeliveryAddress'
import deleteAddress from './deleteAddress'
import getDeliveryOptions from './getDeliveryOptions'
import getPrelaunchValidate from './getPrelaunchValidate'
import validateGps from './validateGps'
import validateSubdestination from './validateSubdestination'

export default {
  //address: null,
  deleteAddress,
  //suggestAddress: null,
  //validateAddress: null,
  createDeliveryAddress,
  //updateDeliveryAddress: null,
  //createBillingAddress: null,
  //updateBillingAddress: null,
  validateGps,
  getDeliveryOptions,
  getPrelaunchValidate,
  validateSubdestination,
}
