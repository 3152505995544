import { isbot } from 'isbot'

import environments, { type Environment } from '@/constants/environments'

const hostname = window.location.hostname

// Environment and its corresponding regex.
const environmentsToRegex: Record<Environment, RegExp> = {
  production: /\.(kosik\.cz|kosik\.sk|kolichka\.bg)$/,
  stage: /-stage\.kosik\.group$/,
  tit: /kosik-tit(\d+)\.kosik\.group/,
  eoc: /k3w-([a-zA-Z0-9_-]+)\.t\.all\.web\.ne\.kosik\.systems|([a-zA-Z0-9_-]+)-eoc\.kosik\.group/,
  localhost: /(k3w\.l|localhost|127\.0\.0\.1)$/,
}

/**
 * Current environment the app runs on.
 */
const currentEnv = environments.find((env) => environmentsToRegex[env].test(hostname)) ?? null

/**
 * Extracts tit number from current hostname.
 * Returns null if the environment is not a tit.
 */
function getTitNumber() {
  const match = environmentsToRegex['tit'].exec(hostname)
  if (!match?.[1]) return null

  return Number(match[1])
}

/**
 * Extracts EOC name from current hostname.
 * Returns null if the environment is not an EOC.
 */
function getEocName() {
  const match = environmentsToRegex['eoc'].exec(hostname)

  // Matched array contains this:
  // 0 - complete string
  // 1 - EOC name in case of private URL (.kosik.systems)
  // 2 - EOC name in case of public URL (.kosik.group)
  return match?.[2] ?? match?.[1] ?? null
}

/**
 * True if script is running in our server instance (Puppeteer).
 */
const isPuppeteer = navigator.userAgent.includes('KosikPuppeteer/')

/**
 * True if user-agent string is reckognized as a bot/crawler/spider.
 */
const isBot = isbot(navigator.userAgent)

export {
  currentEnv, // Environment never changes, so we export memoized variable right off the bat.
  getTitNumber,
  getEocName,
  isPuppeteer,
  isBot,
}
