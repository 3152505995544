import type { Component } from 'vue'

import AddressBanner from '@/components/AddressBanner/AddressBanner.vue'
import AppBanner from '@/components/AppBanner'
import BannerClub from '@/components/BannerClub'
import ConsentNewsletter from '@/components/Consent/ConsentNewsletter.vue'
import { HomePageBanners } from '@/components/HomePageBanners'
import SpecialOffer from '@/components/SpecialOffer'
import StaticBlock from '@/components/StaticBlock'
import WidgetAdditionalContent from '@/components/Widget/WidgetAdditionalContent.vue'
import WidgetAdmin from '@/components/Widget/WidgetAdmin.vue'
import type { CustomContentItem } from '@/data/api/frontApi/customContent/customContentTypes'

import GiftsClubBenefitsList from './GiftsClubBenefitList'
import GiftsClubCover from './GiftsClubCover'
import GiftsClubProgressBar from './GiftsClubProgressBar'
import HowDoesItWork from './HowDoesItWork'
import VoucherList from './VoucherList'

type ComponentMap = {
  [key in CustomContentItem['type']]:
    | ComponentMapValue
    | ((item: CustomContentItem) => ComponentMapValue)
}

type ComponentMapValue = {
  name: Component
  props?: any
  hideOnPlatform?: CustomContentItem['hideOnPlatform']
}

const componentMap: ComponentMap = {
  addressBanner: {
    name: AddressBanner,
  },
  homePageBanners: {
    name: HomePageBanners,
  },
  newsletter: {
    name: ConsentNewsletter,
  },
  staticBlock: (item) => ({
    name: StaticBlock,
    props: {
      slug: item.id,
    },
  }),
  widget: (item) => ({
    name: WidgetAdmin,
    props: {
      code: item.id,
    },
  }),
  widgetAdditionalOrder: {
    name: WidgetAdditionalContent,
  },
  banner: (item) => ({
    name: BannerClub,
    props: {
      type: item.id,
    },
  }),
  appBanner: {
    name: AppBanner,
  },
  specialOffer: {
    name: SpecialOffer,
  },
  cover: (item) => ({
    name: GiftsClubCover,
    props: {
      title: item.content?.title,
      description: item.content?.description,
    },
  }),
  loyaltyClubsGiftsHowItWorks: (item) => ({
    name: HowDoesItWork,
    props: {
      title: item.content?.title,
      condition: item.content?.condition,
      reward: item.content?.reward,
      description: item.content?.description,
    },
  }),
  list: (item) => ({
    name: GiftsClubBenefitsList,
    props: {
      title: item.content?.title,
      type: item.content?.type,
      items: item.content?.items,
    },
    // BE returns object for FAQ with 'list' type as well, which is not supported by GiftsClubBenefitsList component
    // that's why we hide it by 'hideOnPlatform'
    hideOnPlatform: item.hideOnPlatform,
  }),
  loyaltyClubsGiftsProgress: (item) => ({
    name: GiftsClubProgressBar,
    props: {
      title: item.content?.title,
      ordersWithMinValue: item.content?.ordersWithMinValue,
      orderMinValue: item.content?.orderMinValue,
    },
  }),
  loyaltyClubsVouchersList: (item) => ({
    name: VoucherList,
    props: {
      vouchers: item.content,
    },
  }),
}

export default componentMap
