/**
 * Get filter query string
 * @param query
 * @returns query string
 */
function getFilterQueryString(query: Record<string, any>) {
  let queryString = ''
  for (const [key, value] of Object.entries(query)) {
    if (key.startsWith('utm_') || key === 'vendor') {
      continue
    }
    queryString += `${key}:${Array.isArray(value) ? value.join() : value}|`
  }
  queryString = queryString.slice(0, -1)
  return queryString
}

/**
 * Push state to browser history
 * @param data
 * @param url
 */
function pushHistoryState(data: any, url: string | URL | null) {
  window.history.pushState(data, '', url)
}

export { getFilterQueryString, pushHistoryState }
