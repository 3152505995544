import { createOperation } from '@/services/apiv2'

import type { BenjaminekAddChildrenRequest, BenjaminekChildrenResponse } from './benjaminekTypes'

/**
 * Sends POST request for adding children to Benjaminek club.
 * @see https://sasandboxstdhotapidocs.z1.web.core.windows.net/#/Benjaminek/createBenjaminekChildren
 * @returns Promise of the API response with newly added child, or empty children array if child already exists.
 */
const addChildren = createOperation<
  BenjaminekChildrenResponse,
  BenjaminekChildrenResponse,
  undefined,
  BenjaminekAddChildrenRequest
>('/benjaminek/children', {
  method: 'post',
})

export default addChildren
