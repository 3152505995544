import type { Product } from '@/composables/useProductItem'

import { dataLayerPush } from './utils'

const _mapProduct = (product: Product) => ({
  id: product.id,
  name: product.origin.name,
})

function outOfStock(cartReplaceableProducts: { soldOut: Product[]; partiallySoldOut: Product[] }) {
  const { soldOut: soldOutGetter, partiallySoldOut } = cartReplaceableProducts

  const soldOut = soldOutGetter.map(_mapProduct)
  const partlySoldOut = partiallySoldOut.map(_mapProduct)

  dataLayerPush({
    event: 'out_of_stock',
    soldOut,
    partlySoldOut,
  })
}

export { outOfStock as gtmEventOutOfStock }
