<script setup lang="ts">
type SavingsCardProps = {
  title: string
  description: string
}

const props = defineProps<SavingsCardProps>()
</script>

<!-- Wrapper component with title and description for components on Savings page in Profile -->
<template>
  <section class="wrapper bg-gray-lighter text-center py-4 px-2 p-sm-4">
    <h3 class="text-9 font-weight-bold text-brand mb-0 p-2">{{ props.title }}</h3>
    <p class="text-5 text-brand m-0 p-2">{{ props.description }}</p>

    <!-- Slot with content -->
    <slot />
  </section>
</template>

<style lang="scss" scoped>
.wrapper {
  border-radius: var(--borderRadiusMedium);
}
</style>
