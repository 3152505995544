import dayjs, { type Dayjs } from 'dayjs'

/**
 * Checks if given date is in the past.
 */
function isPast(date: Dayjs) {
  return dayjs(date).isBefore(dayjs())
}

export default isPast
