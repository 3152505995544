import { createI18n } from 'vue-i18n'

import appConfig from '@/services/appConfig'
import pluralizationSlavic from '@/services/pluralizationSlavic'

import messages from '__K3W_DOMAIN__/translations.json'

const i18n = createI18n({
  locale: appConfig.translations,
  messages: { [appConfig.translations]: messages },
  pluralizationRules: { [appConfig.translations]: pluralizationSlavic },
  warnHtmlInMessage: 'off',
})

const { t, tc } = i18n.global

export { i18n, t, tc }
