import { storeToRefs } from 'pinia'

import { toastSuccess } from '@/services/toast'
import { t } from '@/services/translations'
import useAddressStore from '@/store/pinia/useAddressStore'

function useCheckHaveReloadPage() {
  const { shoppingCartChange, productsAvailabilityChange, reloadPage } =
    storeToRefs(useAddressStore())

  function checkHaveReloadPage() {
    if (shoppingCartChange.value || productsAvailabilityChange.value || reloadPage.value) {
      if (reloadPage.value) {
        location.reload()
      } else {
        location.href = '/'
      }

      toastSuccess(t('destinationSwitchMessage'))
    }
  }

  return checkHaveReloadPage
}

export default useCheckHaveReloadPage
