import type { SearchMethodEnum } from '@/services/analytics/analyticsLogger'

import { dataLayerPush } from './utils'

function viewSearchResults(
  searchTerm: string,
  multipleSearchTerm: string,
  method: SearchMethodEnum,
) {
  dataLayerPush({
    event: 'view_search_results',
    search_term: searchTerm,
    search: {
      term: multipleSearchTerm.slice(0, 100),
      method,
    },
  })
}

export { viewSearchResults as gtmEventViewSearchResults }
