import { dataLayerPush } from './utils'

function pageScroll(scrollCount: number) {
  dataLayerPush({
    event: 'pageScroll',
    eventAction: window.location.href,
    eventLabel: scrollCount,
  })
}

export { pageScroll as gtmEventPageScroll }
