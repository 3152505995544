import { defineAsyncComponent } from 'vue'
import type { RouteRecordRaw } from 'vue-router'

import ForceReload from '@pages/ForceReload'
import Home from '@pages/Home.vue'

import PageProductDetail from '@/pages/PageProductDetail.vue'

const PageProducts = defineAsyncComponent(() => import('@pages/PageProducts'))

const staticRoutes: Readonly<RouteRecordRaw[]> = [
  {
    name: 'all',
    path: '/:pathMatch(.*)*',
    component: ForceReload,
    meta: {
      pageType: 'not_found',
      component: 'ForceReload',
    },
  },
  {
    name: 'Home',
    path: '/',
    component: Home,
    meta: {
      gtmPageType: 'homepage',
      component: 'Home',
    },
  },
  {
    name: 'PageProductDetail',
    path: '/p:id(\\d+)-:slug',
    component: PageProductDetail,
    meta: {
      gtmPageType: 'product',
      pageType: 'product',
      component: 'PageProductDetail',
    },
  },
  {
    name: 'PageProducts',
    path: '/c:id(\\d+)-:slug',
    component: PageProducts,
    meta: {
      gtmPageType: 'category',
      pageType: 'category',
      component: 'PageProducts',
    },
  },
  {
    name: 'BrandDetail',
    path: '/b:id(\\d+)-:slug',
    component: PageProducts,
    meta: {
      gtmPageType: 'brand',
      component: 'PageProducts',
    },
  },
  {
    name: 'ListProductPage',
    path: '/l:id(\\d+):separator([-_]{1}):slug',
    component: PageProducts,
    meta: {
      gtmPageType: 'specific_category',
      pageType: 'specific_category',
      component: 'PageProducts',
    },
  },
  {
    name: 'SpecialPageProduct',
    path: '/s:id([\\d+]):separator([-_]{1}):slug',
    component: PageProducts,
    meta: {
      gtmPageType: 'specific_category',
      pageType: 'specific_category',
      component: 'PageProducts',
    },
  },
]

export default staticRoutes
