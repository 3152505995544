<script setup lang="ts">
import { defineAsyncComponent, ref, watch } from 'vue'
import type { Ref } from 'vue'

import { storeToRefs } from 'pinia'

import CustomContent from '@/components/CustomContent/CustomContent.vue'
import OrderRatingHomepage from '@/components/OrderRating/OrderRatingHomepage.vue'
import { InteractionSource } from '@/componentsPure'
import useSetDefaultVendorId from '@/composables/useSetDefaultVendorId'
import useUser from '@/composables/useUser'
import { LocalStorageKeys } from '@/constants/storageKeys'
import { frontApiGetProfileOrders } from '@/services/api/front/profile'
import type { TrackingRange } from '@/services/api/ordersTracking/getOrderTrackingResponse'
import { storageRetrieveLocal, storagePersistLocal } from '@/services/storage'
import { toastInfo } from '@/services/toast'
import { t } from '@/services/translations'
import type { SelectedTimeslot } from '@/store/modules/cart'
import useCartStore from '@/store/pinia/useCartStore'
import { type TransformedReadOrderList, prepareSimpleOrder } from '@/store/utils/prepareOrder'

const OrderStatus = defineAsyncComponent(() => import('@/components/OrderStatus'))

const additionalOrderNotificationMutedDays = 1

useSetDefaultVendorId()

const cartStore = useCartStore()
const { cartGetters } = storeToRefs(cartStore)
const { isUserSignedIn } = useUser()

const orders: Ref<TransformedReadOrderList[] | null> = ref(null)
const fetchOrders = async () => {
  if (isUserSignedIn) {
    const {
      data: { orders: fetchedOrders },
    } = await frontApiGetProfileOrders({ stateCategory: 'ACTIVE', ignoreNotPaid: true })
    orders.value = fetchedOrders?.map((order) => prepareSimpleOrder(order)) ?? null
  }
}
fetchOrders()

const triggerAdditionalOrdersNotification = () => {
  if (
    cartGetters.value.hasAdditionalOrders &&
    !storageRetrieveLocal<boolean>(LocalStorageKeys.additionalOrdersNotified)
  ) {
    storagePersistLocal(
      LocalStorageKeys.additionalOrdersNotified,
      true,
      additionalOrderNotificationMutedDays,
    )
    toastInfo(t('additionalOrder.notification'), { hasLongerTimeout: true })
  }
}

function getTrackingRange(orderTransport: SelectedTimeslot): TrackingRange {
  return {
    start: orderTransport.start,
    end: orderTransport.end,
  }
}

watch(() => cartGetters.value.hasAdditionalOrders, triggerAdditionalOrdersNotification, {
  immediate: true,
})
</script>

<template>
  <div v-if="orders">
    <Suspense>
      <OrderStatus
        v-for="(order, index) in orders"
        :key="order.id"
        :tracking-range="getTrackingRange(order.transport)"
        :order-id="order.id"
        :order-number="order.number"
        :customer-phone="order.deliveryAddress.phone.prefix + order.deliveryAddress.phone.number"
        :has-tracking="order.tracking"
        :request-interval-seconds="60"
        :class="{
          'mt-1': index,
        }"
        :data-tid="`neon-hp-order-${index}`" />
    </Suspense>
  </div>
  <OrderRatingHomepage
    class="container"
    data-tid="order-rating-on-homepage" />
  <CustomContent type="homepage">
    <template #options="{ component, componentName, index }">
      <InteractionSource
        name="Home"
        :data="{ boxListingIndex: index }">
        <div
          :class="{
            container: [
              'widget',
              'appBanner',
              'specialOffer',
              'homePageBanners',
              'addressBanner',
            ].includes(componentName),
          }">
          <Component
            :is="component.name"
            v-bind="component.props" />
        </div>
      </InteractionSource>
    </template>
  </CustomContent>
</template>
