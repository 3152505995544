import { useQuery } from '@tanstack/vue-query'

import { clientQueryKeys } from '@/constants/queryKeys'
import api from '@/data/api'
import type { ClientSavingsQueryParams } from '@/data/api/frontApi/client/clientTypes'

function useClientGetSavings(queryParams: ClientSavingsQueryParams = {}) {
  return useQuery({
    queryKey: clientQueryKeys.savings(queryParams),
    queryFn: () =>
      api.front.client.savings({
        queryParams,
      }),
  })
}

export default useClientGetSavings
