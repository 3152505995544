<script setup lang="ts">
import { computed } from 'vue'

import { storeToRefs } from 'pinia'

import { CarouselBanners } from '@/components/HomePageBanners'
import { useBanners } from '@/composables/banner'
import useDeviceDetectorStore from '@/store/pinia/useDeviceDetectorStore'

import MainBanner from './MainBanner.vue'

const deviceDetectorStore = useDeviceDetectorStore()
const { breakpointUpSm } = storeToRefs(deviceDetectorStore)

const { banners, isLoading } = useBanners('homepageMain')

const firstBanner = computed(() => (banners.value?.[0]?.images.mobile ? banners.value[0] : null))

const carouselBanners = computed(() => {
  if (breakpointUpSm.value && firstBanner.value) {
    return banners.value.slice(1)
  }
  return banners.value
})
</script>

<template>
  <div v-if="isLoading">
    <div class="container">
      <span
        v-if="breakpointUpSm"
        class="a-placeholder-text custom-loader custom-loader__main-banner d-inline-block my-3" />
    </div>
  </div>
  <div
    v-else
    class="mb-4 mt-2 mt-sm-3">
    <MainBanner
      v-if="firstBanner && breakpointUpSm"
      data-tid="banner-main"
      :banner="firstBanner" />

    <CarouselBanners
      v-if="carouselBanners.length"
      data-tid="banner-secondary"
      :banners="carouselBanners" />
  </div>
</template>

<style scoped lang="scss">
.custom-loader {
  border-radius: 10px;

  &__main-banner {
    width: 100%;
    height: 260px;
  }
}
</style>
