import { dataLayerPush } from './utils'

function newsletterConsent(value: boolean) {
  dataLayerPush({
    event: 'form_newsletter_submission',
    formData: {
      fieldchecked: value,
    },
  })
}

export { newsletterConsent as gtmEventNewsletterConsent }
